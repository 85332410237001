import {useEffect, useMemo} from 'react';
import {useStoreMap, useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import {
    $correctionType, $editMode,
    $massSrvTarifsOpts,
    $srvTarOptsMap, correctionTypeEnum, submitSingleAddFromMassEv, submitTempCorrsSingleEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {$dictsSelects} from '../../../../models/infoModel/index.js';
import {filterOption, optionRender} from '../../../../utils/selectUtils.jsx';
import {isId} from '../../../../utils/helpers.js';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';
import InputNumber from 'antd/es/input-number';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Switch from 'antd/es/switch';
import Button from 'antd/es/button';
import ActivitySubForm from '../../../DictionaryProviders/components/ActivitySubForm.jsx';

export default function ServiceCardSingle({cardKey, isMass, singleFromMass, disabled, account_id, provider_id, srv_id, form, massEdit}) {
    const {t} = useTranslation()

    const type = useUnit($correctionType)

    const submitSingleAccFromMassMode = useUnit(submitSingleAddFromMassEv)

    const fieldPrefix = useMemo(() => {
        return isMass && !singleFromMass ? [] : [account_id?.toString(), `${provider_id?.toString()}#${srv_id?.toString()}`]
    }, [isMass])


    const onSubmitSingle = async () => {
        try {
            const values = await form.validateFields(fieldPrefix, {recursive: true})
            submitSingleAccFromMassMode({...values[account_id][`${provider_id?.toString()}#${srv_id?.toString()}`], account_id})
        } catch (e) {
            console.log(e)
        }
    }


    const items = useMemo(() => {
        return {
            [correctionTypeEnum.new_acc_to_srv]: <Add form={form} isMass={isMass} singleFromMass={singleFromMass} disabled={disabled} fieldPrefix={fieldPrefix} massEdit={massEdit}/>,
            [correctionTypeEnum.change_existing_params]: <Edit form={form} isMass={isMass} disabled={disabled} fieldPrefix={fieldPrefix} massEdit={massEdit}/>,
            [correctionTypeEnum.change_tariff]: <Tariff form={form} isMass={isMass} singleFromMass={singleFromMass} disabled={disabled} fieldPrefix={fieldPrefix} massEdit={massEdit}/>,
            [correctionTypeEnum.resume_srv]: <Dates form={form} disabled={disabled} fieldPrefix={fieldPrefix} massEdit={massEdit}/>,
            [correctionTypeEnum.change_ext_payments]: <ExtPayments form={form} fieldPrefix={fieldPrefix} isMass={isMass} />,
            [correctionTypeEnum.change_saldo]: <Saldo form={form} isMass={isMass} fieldPrefix={fieldPrefix} />
        }
    }, [])

    return <Card key={cardKey} size={'small'} style={{minHeight: '100%', width: '100%'}}>
        {items[type]}
        {singleFromMass && <Button type={'primary'} size={'small'} onClick={() => onSubmitSingle()}>
            {t('Внести изменения в список изменений')}
        </Button>}
    </Card>
}

function Add({form, isMass, singleFromMass, disabled, fieldPrefix, massEdit}) {
    const {t} = useTranslation()
    const {provOpts, srvOpts} = useStoreMap($dictsSelects, (s) => ({
        provOpts: s.providers,
        srvOpts: s.services
    }))

    // for single account
    const selectedProvider = Form.useWatch([...fieldPrefix, 'provider_id'], form)
    const selectedService = Form.useWatch([...fieldPrefix, 'service_id'], form)
    const tarOptsBySrv = useUnit($srvTarOptsMap)
    const tarOptsBySrvFilt = useMemo(() => {
        if (selectedService) {
            return [...tarOptsBySrv[selectedService], {label: t('Создать'), value: -1, emoji: true}]
        } else {
            return [{label: t('Создать'), value: -1, emoji: true}]
        }
    }, [tarOptsBySrv, selectedService])

    // for mass actions
    const selectedTarif = Form.useWatch([...fieldPrefix, 'tarif_id'], form)
    const massSrvTarifOpts = useUnit($massSrvTarifsOpts)
    const tarOptsFilt = useMemo(() => {
        return [...massSrvTarifOpts, {label: t('Создать'), value: -1, emoji: true}]
    }, [massSrvTarifOpts])

    const srvUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)

    return <Row gutter={[16, 0]}>
            <Col span={8}>
                <Form.Item name={[...fieldPrefix, 'provider_id']} label={t('Поставщик')}
                           hidden={isMass || singleFromMass}
                           rules={isMass || singleFromMass ? [] : [{required: true, message: t('Обязательное поле')}]}
                >
                    <Select options={provOpts}
                            disabled={disabled}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                    />
                </Form.Item>
                <Form.Item name={[...fieldPrefix, 'service_id']} label={t('Услуга')}
                           hidden={isMass || singleFromMass}
                           rules={isMass || singleFromMass ? [] : [{required: true, message: t('Обязательное поле')}]}
                >
                    <Select options={srvOpts.filter(i => i?.providers?.includes(selectedProvider))}
                            disabled={disabled}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                    />
                </Form.Item>
                <Form.Item name={[...fieldPrefix, 'tarif_id']} label={t('Тариф')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Select options={(isMass || singleFromMass) ? tarOptsFilt : tarOptsBySrvFilt}
                            disabled={disabled || (!isMass && !singleFromMass && !selectedService)}
                            style={{width: '100%'}}
                            optionRender={optionRender}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                    />
                </Form.Item>
                {selectedTarif === -1 && <>
                    <Form.Item name={[...fieldPrefix, 'tarif_value']} label={t('Значение тарифа')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input style={{width: '100%'}} disabled={disabled}/>
                    </Form.Item>
                    <Form.Item name={[...fieldPrefix, 'tarif_service_unit_id']} label={t('Единица измерения')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select options={srvUnitOpts}
                                disabled={disabled}
                                style={{width: '100%'}}
                                filterOption={filterOption}
                                showSearch
                                allowClear
                                dropdownStyle={{minWidth: 'max-content'}}/>
                    </Form.Item>
                </>}
                <Form.Item name={[...fieldPrefix, 'tarif_date_from']} label={t('Дата начала действия тарифа')}
                           rules={isId(selectedTarif) ? [{required: true, message: t('Обязательное поле')}] : []}
                >
                    <DatePicker style={{width: '100%'}} disabled={disabled}/>
                </Form.Item>
                <Form.Item name={[...fieldPrefix, 'sum_saldo_begin']} label={t('Нач. сальдо')}
                           // rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Input />
                </Form.Item>
            </Col>
            {!isMass && <Col span={6}>
                <Form.Item name={[...fieldPrefix, 'service_square']} label={t('Обслуживаемая площадь')}>
                    <InputNumber step={0.1}
                                 style={{width: '100%'}}
                                 parser={val => {
                                     if (val.includes(',')) {
                                         val = val.replace(',', '.')
                                     }
                                     return Number(val)
                                 }}
                                 disabled={disabled}
                                 formatter={(val) => val.toString().replace('.', ',')}
                    />
                </Form.Item>
                <Form.Item name={[...fieldPrefix, 'people_quantity']} label={t('Кол-во проживающих')}>
                    <InputNumber min={0} style={{width: '100%'}} disabled={disabled}/>
                </Form.Item>
                <Form.Item name={[...fieldPrefix, 'external_number']} label={t('Номер абонента у поставщика')}>
                    <Input style={{width: '100%'}} disabled={disabled}/>
                </Form.Item>
            </Col>}
        <Col span={10}>
            <ActivitySubForm type={'2x2'} form={form} prefix={fieldPrefix} disabled={disabled}/>
        </Col>
        </Row>
}

function Edit({form, isMass, disabled, fieldPrefix, massEdit}) {
    const {t} = useTranslation()

    const changeActivity = Form.useWatch([...fieldPrefix, 'change_activity'], form)

    return <Row gutter={[16, 0]}>
        {!isMass && <Col span={6}>
            <Form.Item name={[...fieldPrefix, 'service_square']} label={t('Обслуживаемая площадь')}>
                <InputNumber step={0.1}
                             style={{width: '100%'}}
                             parser={val => {
                                 if (val.includes(',')) {
                                     val = val.replace(',', '.')
                                 }
                                 return Number(val)
                             }}
                             disabled={disabled}
                             formatter={(val) => val.toString().replace('.', ',')}
                />
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'people_quantity']} label={t('Кол-во проживающих')}>
                <InputNumber min={0} style={{width: '100%'}} disabled={disabled}/>
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'external_number']} label={t('Номер абонента у поставщика')}>
                <Input style={{width: '100%'}} disabled={disabled}/>
            </Form.Item>
        </Col>}
        <Col span={10}>
            {massEdit && <Form.Item name={[...fieldPrefix, 'change_activity']} label={t('Изменить даты')} layout={'horizontal'} colon={false} valuePropName={'checked'}>
                <Switch />
            </Form.Item>}
            <ActivitySubForm type={'2x2'} form={form} prefix={fieldPrefix} disabled={disabled || (massEdit && !changeActivity)}/>
        </Col>
    </Row>
}

function Tariff({form, isMass, singleFromMass, disabled, fieldPrefix, massEdit}) {
    const {t} = useTranslation()
    const {provOpts, srvOpts} = useStoreMap($dictsSelects, (s) => ({
        provOpts: s.providers,
        srvOpts: s.services
    }))

    // for single account
    const selectedProvider = !isMass ? Form.useWatch([...fieldPrefix, 'provider_id'], form) : null
    const selectedService = !isMass ? Form.useWatch([...fieldPrefix, 'service_id'], form) : null
    const tarOptsBySrv = useUnit($srvTarOptsMap)
    const tarOptsBySrvFilt = useMemo(() => {
        if (selectedService) {
            return [...tarOptsBySrv[selectedService], {label: t('Создать'), value: -1, emoji: true}]
        } else {
            return [{label: t('Создать'), value: -1, emoji: true}]
        }
    }, [tarOptsBySrv, selectedService])

    // for mass actions
    const selectedTarif = Form.useWatch([...fieldPrefix, 'tarif_id'], form)
    const changeTarif = Form.useWatch([...fieldPrefix, 'change_tarif'], form)
    const massSrvTarifOpts = useUnit($massSrvTarifsOpts)
    const tarOptsFilt = useMemo(() => {
        return [...massSrvTarifOpts, {label: t('Создать'), value: -1, emoji: true}]
    }, [massSrvTarifOpts])

    const srvUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)

    return <Row gutter={[16, 0]}>
        <Col span={8}>
            <Form.Item name={[...fieldPrefix, 'provider_id']} label={t('Поставщик')}
                       hidden
                       rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <Select options={provOpts}
                        disabled={disabled}
                        style={{width: '100%'}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'service_id']} label={t('Услуга')}
                       hidden
                       rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <Select options={srvOpts.filter(i => i?.providers?.includes(selectedProvider))}
                        disabled={disabled}
                        style={{width: '100%'}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
            {massEdit && <Form.Item name={[...fieldPrefix, 'change_tarif']} label={t('Изменить тариф')} layout={'horizontal'} colon={false} valuePropName={'checked'}>
                <Switch />
            </Form.Item>}
            <Form.Item name={[...fieldPrefix, 'tarif_id']} label={t('Тариф')}
                       rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <Select options={(isMass || singleFromMass) ? tarOptsFilt : tarOptsBySrvFilt}
                        disabled={disabled || ((isMass || singleFromMass || massEdit) && !changeTarif) || (!isMass && !singleFromMass && !selectedService)}
                        style={{width: '100%'}}
                        optionRender={optionRender}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
            {selectedTarif === -1 && <>
                <Form.Item name={[...fieldPrefix, 'tarif_value']} label={t('Значение тарифа')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Input style={{width: '100%'}} disabled={disabled || (massEdit && !changeTarif)}/>
                </Form.Item>
                <Form.Item name={[...fieldPrefix, 'tarif_service_unit_id']} label={t('Единица измерения')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Select options={srvUnitOpts}
                            disabled={disabled || (massEdit && !changeTarif)}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}/>
                </Form.Item>
            </>}
            <Form.Item name={[...fieldPrefix, 'tarif_date_from']} label={t('Дата начала действия тарифа')}
                       rules={isId(selectedTarif) ? [{required: true, message: t('Обязательное поле')}] : []}
            >
                <DatePicker style={{width: '100%'}} disabled={disabled || (massEdit && !changeTarif)}/>
            </Form.Item>
        </Col>
    </Row>
}

function Dates({form, disabled, fieldPrefix, massEdit}) {
    const {t} = useTranslation()

    const changeActivity = Form.useWatch([...fieldPrefix, 'change_activity'], form)

    return <Row gutter={[16, 0]}>
        <Col span={10}>
            {massEdit && <Form.Item name={[...fieldPrefix, 'change_activity']} label={t('Изменить даты')} layout={'horizontal'} colon={false} valuePropName={'checked'}>
                <Switch />
            </Form.Item>}
            <ActivitySubForm type={'2x2'} form={form} prefix={fieldPrefix} disabled={disabled || (massEdit && !changeActivity)}/>
        </Col>
    </Row>
}

function ExtPayments({form, isMass, fieldPrefix}) {
    const {t} = useTranslation()
    const {provOpts, srvOpts} = useStoreMap($dictsSelects, (s) => ({
        provOpts: s.providers,
        srvOpts: s.services
    }))
    const editMode = useUnit($editMode)

    // for single account
    const selectedProvider = !isMass ? Form.useWatch([...fieldPrefix, 'provider_id'], form) : null

    return <Row gutter={[16, 0]}>
        <Col span={8}>
            <Form.Item name={[...fieldPrefix, 'provider_id']} label={t('Поставщик')}
                       hidden={isMass || editMode === 'mass'}
                       rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <Select options={provOpts}
                        disabled={true}
                        style={{width: '100%'}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'service_id']} label={t('Услуга')}
                       hidden={isMass || editMode === 'mass'}
                       rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <Select options={srvOpts.filter(i => i?.providers?.includes(selectedProvider))}
                        disabled={true}
                        style={{width: '100%'}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'ex_payment_value']} label={t('Сумма платежа')}
                       rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <Input />
            </Form.Item>
        </Col>
    </Row>
}

function Saldo({form, isMass, fieldPrefix}) {
    const {t} = useTranslation()

    const {provOpts, srvOpts} = useStoreMap($dictsSelects, (s) => ({
        provOpts: s.providers,
        srvOpts: s.services
    }))
    const editMode = useUnit($editMode)

    // for single account
    const selectedProvider = !isMass ? Form.useWatch([...fieldPrefix, 'provider_id'], form) : null

    const changeCorrValue = Form.useWatch([...fieldPrefix, 'change_correction_value'], form)
    const changeSaldoEndValue = Form.useWatch([...fieldPrefix, 'change_saldo_end_value'], form)
    const beginSaldo = isMass ? 0 : Form.useWatch([...fieldPrefix, 'sum_saldo_begin'], form) ?? 0
    const accrualVal = isMass ? 0 : Form.useWatch([...fieldPrefix, 'accrual'], form) ?? 0
    const paymentsVal = isMass ? 0 : Form.useWatch([...fieldPrefix, 'payments'], form) ?? 0

    return <Row>
        <Col span={24}>
            <Row gutter={[16, 0]} align={'bottom'}>
                <Col span={isMass ? 0 : 4}>
                    <Form.Item name={[...fieldPrefix, 'sum_saldo_begin']} label={t('Сумма платежа')}
                               hidden={isMass}
                    >
                        <InputNumber disabled style={{backgroundColor: 'transparent', color: 'rgba(0, 0, 0, 0.88)'}}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={[...fieldPrefix, 'change_correction_value']} label={t('Внести корректировку')} valuePropName={'checked'}>
                        <Switch />
                    </Form.Item>
                    <Form.Item name={[...fieldPrefix, 'correction_value']} label={t('Корректировка')}
                               rules={changeCorrValue ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <InputNumber disabled={!changeCorrValue}
                               onBlur={({target}) => form.setFieldValue([...fieldPrefix, 'saldo_end_value'], Number(beginSaldo ?? 0) + Number(target.value ?? 0) + Number(accrualVal ?? 0) - Number(paymentsVal ?? 0))}
                        />
                    </Form.Item>
                </Col>
                <Col span={isMass ? 0 : 4}>
                    <Form.Item name={[...fieldPrefix, 'accrual']} label={t('Начисления')}
                               hidden={isMass}
                    >
                        <InputNumber disabled style={{backgroundColor: 'transparent', color: 'rgba(0, 0, 0, 0.88)'}}/>
                    </Form.Item>
                </Col>
                <Col span={isMass ? 0 : 4}>
                    <Form.Item name={[...fieldPrefix, 'payments']} label={t('Оплаты')}
                               hidden={isMass}
                    >
                        <InputNumber disabled style={{backgroundColor: 'transparent', color: 'rgba(0, 0, 0, 0.88)'}}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={[...fieldPrefix, 'change_saldo_end_value']} label={t('Изменить конечное сальдо')} valuePropName={'checked'}>
                        <Switch />
                    </Form.Item>
                    <Form.Item name={[...fieldPrefix, 'saldo_end_value']} label={t('Конечное сальдо')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <InputNumber disabled={!changeSaldoEndValue}
                               onBlur={({target}) => form.setFieldValue([...fieldPrefix, 'correction_value'], Number(target.value ?? 0) - Number(beginSaldo ?? 0) - Number(accrualVal ?? 0) + Number(paymentsVal ?? 0))}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Col>
        <Col span={24}>
            <Row gutter={[16, 0]}>
                <Col span={6}>
                    <Form.Item name={[...fieldPrefix, 'provider_id']} label={t('Поставщик')}
                               hidden={isMass || editMode === 'mass'}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select options={provOpts}
                                disabled={true}
                                style={{width: '100%'}}
                                filterOption={filterOption}
                                showSearch
                                allowClear
                                dropdownStyle={{minWidth: 'max-content'}}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={[...fieldPrefix, 'service_id']} label={t('Услуга')}
                               hidden={isMass || editMode === 'mass'}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select options={srvOpts.filter(i => i?.providers?.includes(selectedProvider))}
                                disabled={true}
                                style={{width: '100%'}}
                                filterOption={filterOption}
                                showSearch
                                allowClear
                                dropdownStyle={{minWidth: 'max-content'}}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Col>
    </Row>
}