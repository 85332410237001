import queryString from "query-string";
import {useTranslation} from 'react-i18next';
import Col from "antd/es/col";
import Row from "antd/es/row";
import Table from "antd/es/table";
import Card from "antd/es/card";
import StarredTitle from "../../components/StarredTitle/StarredTitle.jsx";
import Button from "antd/es/button";
import {Popconfirm, Select} from "antd";
import {useStoreMap, useUnit} from "effector-react";
import {$dictsSelects} from "../../models/infoModel";
import {useEffect, useState} from "react";
import Form from "antd/es/form";
import Space from "antd/es/space";

import {
    $addressList,
    $addressPagination,
    onSwapEv,
    setPaginationEv,
    getAddressEv,
} from "../../models/swapAddressModel";
import AddressREOSearchForm from "../../components/AddressReoSearchForms/AddressREOSearchForm.jsx";
import {isId} from "../../utils/helpers.js";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";


export default function SwapAddress() {
    const {t} = useTranslation()
    const [newDistrict, setNewDistrict] = useState(null)
    const [addressFilterForm] = Form.useForm()
    const {data, count} = useUnit($addressList)
    const getAddress = useUnit(getAddressEv)
    const town_id = Form.useWatch('town_id', addressFilterForm)
    const {district} = useStoreMap($dictsSelects, sel => ({
        district: sel.districts,
    }))
    const districtOpts = district.filter(item => item.town_id === town_id)
    const pagination = useUnit($addressPagination)
    const setPagination = useUnit(setPaginationEv)
    const onSwapEvent = useUnit(onSwapEv)
    const newData = data?.map(item => {
        return {
            ...item, key: item?.id
        }
    })
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const rowSelection = {
        selectedRowKeys, onChange: (selectedRowKeys, _) => {
            setSelectedRowKeys(selectedRowKeys)
        },
    };
    const onFinish = (values) => {
        const parsed = queryString.stringify(values, {skipEmptyString: true, skipNull: true})
        getAddress(parsed)
    }
    const handleRowClick = (record) => {
        if ((selectedRowKeys || []).includes(record.id))
            setSelectedRowKeys(selectedRowKeys.filter(id => record.id !== id))
        else
            setSelectedRowKeys([...selectedRowKeys, record.id])
    };
    const columns = [
        {
            title: t('Населенный пункт'),
            key: 'address',
            render: (v) => <MappedDictItem id={v?.town_id} type={'town'} link={false}/>
        },
        {
            title: t('Район'),
            key: 'address',
            render: (v) => <MappedDictItem id={v?.town_distr_id} type={'town_distr'} link={false}/>
        },
        {
            title: t('Улица'),
            key: 'address',
            render: (v) => <MappedDictItem id={v?.street_id} type={'street'} link={false}/>
        },
        {
            title: t('Дом'),
            key: 'address',
            render: (v) => v?.house || '',
        },
        {
            title: t('Корпус'),
            key: 'address',
            render: (v) => v?.corpus || '',
        },
    ]
    const tableOnChange = (value) => {
        setPagination(value)
    }
    const onSwap = () => {
        onSwapEvent({district: newDistrict, selected: selectedRowKeys})
        setSelectedRowKeys([])
    }
    useEffect(() => {
        setNewDistrict(undefined)
    }, [town_id]);
    const nonSelected = (selectedRowKeys || []).length === 0

    return <Row gutter={[8, 8]}>
        <Col span={24}>
            <Form
                size={'small'}
                layout={'vertical'}
                form={addressFilterForm}
                onFinish={(values) => onFinish(values)}
            >
                <Card size={'small'} title={t('Выберите ОН')}>
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <AddressREOSearchForm
                                extForm={addressFilterForm}
                                extButtons={true}
                                onlyItems={true}
                                itemsAsRow={true}
                            />
                        </Col>
                        <Col span={24}>
                            <Space>
                                <Button
                                    type="primary"
                                    disabled={!isId(town_id)}
                                    onClick={() => addressFilterForm.submit()}
                                >
                                    Применить фильтр
                                </Button>
                                <Button
                                    onClick={() => addressFilterForm.resetFields()}
                                    disabled={!isId(town_id)}
                                >
                                    Очистить фильтр
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Col>
        <Col span={24}>
            <Card
                title={<StarredTitle title={t('Район назначения')} required={false}/>}
                size={'small'}>
                <Row gutter={[8, 8]}>
                    <Col span={5}>
                        <Select
                            options={districtOpts}
                            showSearch
                            allowClear
                            size={'small'}
                            value={newDistrict}
                            disabled={!isId(town_id) || newData.length === 0}
                            onChange={(value) => setNewDistrict(value)}
                            style={{width: '100%'}}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        />
                    </Col>
                    <Col>
                        <Popconfirm
                            title={'У выбранных адресов изменится район'}
                            okText={'Назначить'}
                            onConfirm={onSwap}
                        >
                            <Button
                                size={'small'}
                                type={'primary'}
                                disabled={!isId(town_id) || !isId(newDistrict) || nonSelected}
                            >
                                Назначить
                            </Button>
                        </Popconfirm>
                    </Col>
                    <Col span={24}>
                        <Table
                            dataSource={newData}
                            columns={columns}
                            size={'small'}
                            rowSelection={rowSelection}
                            onRow={(record) => ({
                                onClick: () => handleRowClick(record),
                            })}
                            onChange={tableOnChange}
                            pagination={{
                                ...pagination, total: count
                            }}
                            rowKey={'id'}
                            locale={{
                                emptyText: <div className={'empty-table-block'}>
                                    {t('Объекты не найдены. Измените или примените параметры фильтрации')}
                                </div>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </Col>
    </Row>
}
