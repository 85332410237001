import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $bankAccsOpts, $embeddedBaForm,
    $provBankAccMap,
    $providerServBaREOs,
    $providerSrvMap,
    $provSrvsSelOpts,
    $selectedServBaREO,
    addServBaREOEv,
    applyServBaREOEv,
    deleteServBaREOEv,
    resetServBaREOEv,
    selectServBaREOEv, setBaEmbeddedFormEv,
} from '../../../../models/dictionaryProvidersModel/index.js';
import {
    $foundReos,
    $multiSelectedReos, resetFiltersEv,
    resetMultiSelectedREOsEv
} from '../../../../models/accountsREOSearchModel/index.js';
import {filterOption, optionRender} from '../../../../utils/selectUtils.jsx';
import Table from 'antd/es/table';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import {CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import Popconfirm from 'antd/es/popconfirm';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Switch from 'antd/es/switch';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import ProviderSrvREOSearchForm
    from '../ProviderREOSearchForm.jsx';
import Input from 'antd/es/input';
import Divider from 'antd/es/divider';
import {paginationConfig} from '../../../../utils/paginationConfig.js';
import ProviderBankAccounts from '../ProviderBankAccounts.jsx';
import ShowAddressCard from '../../../../components/AddressReoSearchForms/ShowAddressCard.jsx';
import AddressREOSearchForm
    from '../../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx';
import {parseHouses} from '../../../../models/accountsREOSearchModel/utils.js';

const searchButtonsStyle = {
    display: 'flex', alignItems: 'end', justifyContent: 'center'
}

export default function ProvServREOForm({isView}) {
    const {t} = useTranslation()

    const [baREOCreateForm] = Form.useForm()
    const [baREOUpdForm] = Form.useForm()

    const [filtersForm] = Form.useForm()

    const [createMode, setCreateMode] = useState(false)

    const foundReos = useUnit($foundReos)
    const selectedREOs = useUnit($multiSelectedReos)
    const resetAllSelectedREOs = useUnit(resetMultiSelectedREOsEv)
    const resetReoFilters = useUnit(resetFiltersEv)

    const serviceOpts = useUnit($provSrvsSelOpts)
    const srvMap = useUnit($providerSrvMap)

    const baOpts = useUnit($bankAccsOpts)
    const baMap = useUnit($provBankAccMap)
    const embeddedOpen = useUnit($embeddedBaForm)
    const openBaEmbedded = useUnit(setBaEmbeddedFormEv)

    const baREOs = useUnit($providerServBaREOs)
    const selectedBaREO = useUnit($selectedServBaREO)
    const selectBaREO = useUnit(selectServBaREOEv)
    const resetSelBaREO = useUnit(resetServBaREOEv)

    useEffect(() => {
        if (selectedBaREO) {
            baREOUpdForm.setFieldsValue(selectedBaREO)
        }
    }, [selectedBaREO]);

    useEffect(() => {
        if (baOpts.length > 0 && !embeddedOpen) {
            const selectedBa = baREOCreateForm.getFieldValue('bank_account_id')
            if (baOpts.some(b => b.value < 0) && selectedBa === 'new') {
                const newBa = baOpts.find(b => b.value < 0)
                baREOCreateForm.setFieldsValue({bank_account_id: newBa.value})
            }
        }
    }, [baOpts])

    useEffect(() => {
        if (baOpts.length > 0 && !embeddedOpen && selectedBaREO) {
            const selectedBa = baREOUpdForm.getFieldValue('bank_account_id')
            if (baOpts.some(b => b.value < 0) && selectedBa === 'new') {
                const newBa = baOpts.find(b => b.value < 0)
                baREOCreateForm.setFieldsValue({bank_account_id: newBa.value})
            }
        }
    }, [baOpts, selectedBaREO])

    const addBaREO = useUnit(addServBaREOEv)
    const changeBaREO = useUnit(applyServBaREOEv)
    const deleteBaREO = useUnit(deleteServBaREOEv)

    const onCreate = (values) => {
        const data = baREOCreateForm.getFieldsValue(true)
        const {type, ...payload} = {...values, ...data}
        addBaREO({type, payload})
        baREOCreateForm.resetFields()
    }

    const onUpdate = values => {
        changeBaREO({id: selectedBaREO.id, payload: values})
        resetSelBaREO()
        baREOUpdForm.resetFields()
    }

    const onCancelCreate = () => {
        setCreateMode(false)
        baREOCreateForm.resetFields()
        if (embeddedOpen) {
            openBaEmbedded(false)
        }
    }

    const onCancel = () => {
        resetSelBaREO()
        baREOUpdForm.resetFields()
        if (embeddedOpen) {
            openBaEmbedded(false)
        }
    }

    const [dataSource, setDataSource] = useState([])
    const [filters, setFilters] = useState(null)

    const onFilterValsChange = (cur) => {
        if (Object.hasOwn(cur, 'town_id')) {
            filtersForm.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus'])
        }
    }

    const onFilter = (values) => {
        for (const [key, value] of Object.entries(values)) {
            if (value === undefined || value === '' || value === null) {
                delete values[key]
            }
        }
        setFilters(values)
    }

    useEffect(() => {
        if (filters && Object.values(filters)?.some(v => v !== null && v !== undefined)) {
            let result = [...baREOs]
            for (const [key, value] of Object.entries(filters)) {
                if (key === 'house_in') {
                    const houses = parseHouses(value)
                    result = result.filter(i => houses.includes(i.house))
                } else {
                    result = result.filter(i => i[key] === value)
                }
            }
            setDataSource(result)
        } else {
            setDataSource(baREOs)
        }
    }, [filters, baREOs])

    useEffect(() => {
        if (baREOs.length > 0) {
            resetAllSelectedREOs()
            resetReoFilters()
            setCreateMode(false)
        }
    }, [baREOs]);


    const columns = [
        {
            title: t('Услуга'),
            dataIndex: 'provider_service_id',
            width: '20%',
            sorter: (a, b) => a.provider_service_id - b.provider_service_id && a.bank_account_id - b.bank_account_id,
            render: (id) => <MappedDictItem id={srvMap[id]} type={'services'}/>
        },
        // {
        //     title: t('ОН'),
        //     width: '10%',
        //     dataIndex: 'real_estate_object_id',
        //     render: (id) => <MappedDictItem id={id} type={'real-estate-object'}/>
        // },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            width: '10%',
            render: (id) => <MappedDictItem id={id} type={'town'}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            render: (id) => <MappedDictItem id={id} type={'street'}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
        },
        {
            title: t('Р/С'),
            dataIndex: 'bank_account_id',
            render: (id) => baMap[id],
        },
        {
            title: createMode ? null : <Tooltip title={t('Добавить')}>
                <Button type={'primary'} icon={<PlusOutlined/>}
                        onClick={() => setCreateMode(true)}/>
            </Tooltip>,
            hidden: isView,
            width: '96px',
            align: 'center',
            dataIndex: 'id',
            render: (id) => {
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined/>}
                                onClick={() => selectBaREO(id)}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={() => deleteBaREO(id)}
                                placement={'left'}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button icon={<CloseOutlined/>} type={'primary'} danger/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            },
        }
    ]

    return <>
        {!isView && createMode && <>
            <Card title={'Добавление расчетного счета для ОН'} size={'small'}
                  style={{marginBottom: '8px'}}>
                <ProviderSrvREOSearchForm fromProvider/>
                <Divider/>
                {embeddedOpen && <>
                    <ProviderBankAccounts embedded={true} externalForm={baREOCreateForm}/>
                    <Divider/>
                </>}
                <Form form={baREOCreateForm} layout={'vertical'}
                      onFinish={onCreate}
                      size={'small'}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item name={'provider_service_id'} label={t('Услуга')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Select options={serviceOpts}
                                        filterOption={filterOption}
                                        style={{width: '100%'}}
                                        showSearch
                                        allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name={'bank_account_id'} label={t('Расчетный счет')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Select options={[...baOpts, {
                                    label: 'Создать',
                                    value: 'new',
                                    emoji: true
                                }]}
                                        filterOption={filterOption}
                                        optionRender={optionRender}
                                        showSearch
                                        dropdownStyle={{minWidth: 'fit-content'}}
                                        allowClear
                                        onChange={(v) => {
                                            if (v === 'new') {
                                                openBaEmbedded(true)
                                            } else {
                                                openBaEmbedded(false)
                                            }
                                        }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'rewrite_old'}
                                       label={t('Обновить р/с в уже добавленных ОН')}
                                       valuePropName={'checked'}
                                       initialValue={false}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify={'space-between'}>
                        <ButtonGroup>
                            <Tooltip
                                title={!selectedREOs?.length ? t('Укажите Объекты недвижимости') : null}>
                                <Button type={'primary'}
                                        onClick={() => {
                                            baREOCreateForm.setFieldsValue({type: 'selected'})
                                            baREOCreateForm.submit()
                                        }}
                                        disabled={!selectedREOs?.length}
                                >
                                    {t('Добавить выделенные')}
                                </Button>
                            </Tooltip>
                            <Tooltip
                                title={foundReos?.length === 0 ? t('Объекты недвижимости отсутствуют') : null}>
                                <Button
                                    onClick={() => {
                                        baREOCreateForm.setFieldsValue({type: 'all'})
                                        baREOCreateForm.submit()
                                    }}
                                    disabled={foundReos?.length === 0}
                                >
                                    {t('Добавить все подходящие под фильтр')}
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                        <Button danger type={'primary'}
                                onClick={() => onCancelCreate()}>{t('Отмена')}</Button>
                    </Row>
                </Form>
            </Card>
            <Divider/>
        </>}

        {/* filter */}
        <Card size={'small'}>
            <Form form={filtersForm} layout={'vertical'} size={'small'} onFinish={onFilter} onValuesChange={onFilterValsChange}>
                <Row gutter={[8, 8]}>
                    <Col span={6}>
                        <Form.Item name={'provider_service_id'} label={t('Услуга')}>
                            <Select
                                options={serviceOpts}
                                filterOption={filterOption}
                                size={'small'}
                                style={{width: '100%'}}
                                showSearch
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={15}>
                        <AddressREOSearchForm
                            extForm={filtersForm} itemsAsRow onlyItems extButtons
                            sizes={{
                                town: 4,
                                corpus: 2,
                                street: 7,
                                house: 6,
                                town_distr: 5,
                            }}
                        />
                    </Col>
                    <Col span={3} style={searchButtonsStyle}>
                        <Form.Item>
                            <ButtonGroup size={'small'}>
                                <Button onClick={filtersForm.submit} type={'primary'}>
                                    {t('Найти')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        filtersForm.resetFields()
                                        setFilters(null)
                                    }}
                                    disabled={!filters}
                                >
                                    {t('Очистить')}
                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>

        {/* table & edit form */}
        <Row gutter={[16, 16]} style={{marginTop: 8}}>
            <Col span={selectedBaREO ? 14 : 24}>
                {/*<Card title={'Расчетные счета для ОН'} size={'small'} style={{minHeight: '100%'}}>*/}
                    <Table dataSource={dataSource}
                           columns={columns}
                           bordered
                           className={'table-container'}
                           pagination={paginationConfig}
                           size={'small'}
                           rowClassName={(record) => record.id === selectedBaREO?.id ? 'selectedRowObject' : null}
                    />
                {/*</Card>*/}
            </Col>
            {selectedBaREO && <Col span={10}>
                <Card title={'Редактирование Р/С по услуге для ОН'} size={'small'}>
                    <Row gutter={[8, 12]}>
                        <Col span={24}>
                            {t('Услуга')}: <MappedDictItem
                            id={srvMap[selectedBaREO.provider_service_id]}
                            type={'services'} link={false}
                        />
                        </Col>
                        <Col span={24}>
                            {t('ОН')}: <ShowAddressCard raw={selectedBaREO} textOnly/>
                        </Col>
                        <Col span={24}>
                            {embeddedOpen && <>
                                <ProviderBankAccounts
                                    embedded={true} externalForm={baREOUpdForm} nonHeight
                                />
                            </>}
                            <Form form={baREOUpdForm} layout={'vertical'} onFinish={onUpdate}
                                  size={'small'}>
                                <Form.Item name={'bank_account_id'} label={t('Расчетный счет')}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Select options={[...baOpts, {
                                        label: 'Создать',
                                        value: 'new',
                                        emoji: true
                                    }]}
                                            filterOption={filterOption}
                                            optionRender={optionRender}
                                            showSearch
                                            dropdownStyle={{minWidth: 'fit-content'}}
                                            allowClear
                                            onChange={(v) => {
                                                if (v === 'new') {
                                                    openBaEmbedded(true)
                                                } else {
                                                    openBaEmbedded(false)
                                                }
                                            }}
                                    />
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={24}>
                            <ButtonGroup size={'small'}>
                                <Button type={'primary'} onClick={() => baREOUpdForm.submit()}>
                                    {t('Применить')}
                                </Button>
                                <Button onClick={onCancel} disabled={!selectedBaREO}>
                                    {t('Отменить')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card>
            </Col>}
        </Row>
    </>
}
