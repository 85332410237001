import Table from "antd/es/table";
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
    $accountsProviderServiceList,
} from "../../../models/dictionaryAccountsModel/index.js";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import FormatedNumber from "../../../components/FormatedNumber.jsx";
import {CloseCircleOutlined} from "@ant-design/icons";
import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import Space from "antd/es/space";


export default function ProviderServices({isView}) {

    const {t} = useTranslation()

    const {data} = useUnit($accountsProviderServiceList)

    console.log('provider service data', data)

    const columns = [
        {
            title: t('Услуга'),
            dataIndex: 'service_id',
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'services'}/>,
        },
        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'providers'}/>,
        },
        {
            title: t('Площадь'),
            dataIndex: 'service_square',
            render: (id) => id,
        },
        {
            title: t('Кол-во проживающих'),
            dataIndex: 'people_quantity',
            render: (id) => id,
        },
        {
            title: t('№ договора'),
            dataIndex: 'external_number'
        },
        {
            title: t('Тариф'),
            dataIndex: 'tarifs',
            render: (v) => {
                if (v?.length > 0) {
                    return <Row gutter={[8, 0]}>
                        {v.sort((a,b) => a.date_from > b.date_from ? -1 : 1).map(v => [
                            <Col span={24}>
                                <Space>
                                    <FormatedNumber value={v.value}/>
                                    <MappedDictItem
                                        id={v.service_unit_id}
                                        type={'service-unit-st'}
                                        link={false}
                                        withID={false}
                                    />
                                    <span>
                                        {t('с')} <CustomDates data={v.date_from}  type={'day'}/>
                                    </span>
                                </Space>
                            </Col>,
                        ])}
                    </Row>
                }
                return <CloseCircleOutlined style={{color: 'red'}}/>
            },
        },
        // FIXME no_tarif -- color?
        // date_from
        // date_to
        // is_not_accrued
        // not_accrued_from
        // not_accrued_to
        // is_not_balance_transfer
        // not_balance_transfer_from
        // not_balance_transfer_to
        // is_not_printed
        // not_printed_from
        // not_printed_to
        // { // FIXME temp disable
        //     title: t('Номер договора поставщика с абонентом'),
        //     dataIndex: 'external_number',
        //     width: '20%',
        // },
    ]


    return <>

        <Table
            dataSource={data}
            columns={columns}
            bordered
            rowKey={'service_id'}
            className={'table-container'}
            pagination={false}
            size={'small'}
            // rowClassName={(record) => record.id === selectedService?.id ? 'selectedRowObject' : null}
        />
    </>
}
