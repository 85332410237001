import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';

import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link} from 'react-router-dom';

import {
    $servicesList, editServicesOptionEv,
    getServicesOptionsListFx

} from "../../models/adminServicesModel/index.js";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {AdminServicesListGate} from "../../models/adminServicesModel/index.js";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    SignatureOutlined,
    KeyOutlined,
    SafetyOutlined,
    FileProtectOutlined


} from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group.js";
import Tooltip from "antd/es/tooltip/index.js";
import Button from "antd/es/button/index.js";
import {Popconfirm} from "antd";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";

const opt = [
    {
        label: 'Все',
        value: 'all',
    },
    {
        label: 'Да',
        value: true,
    },
    {
        label: 'Нет',
        value: false,
    }
]

export default function AdminServices() {
    const {t} = useTranslation()
    const {data, count} = useUnit($servicesList)
    const loading = useUnit(getServicesOptionsListFx.pending)
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.admin-services`)
    const editServicesOption = useUnit(editServicesOptionEv)

    const updateServiceOption = (row, type) => {
        const payload = {
            id: row.id,
            type: type,
            value: !row[type]
        }
        editServicesOption(payload)
    }

    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t(`Название ${genitive_form}`),
            dataIndex: 'name',
            sorter: true,
            key: 'name',
            render: (name, record) => <MappedDictItem id={record.id} type={'services'}/>
        },

        {
            title: t(`Содержит перс. данные`),
            dataIndex: 'is_ext_number_is_personal',
            sorter: true,
            key: 'is_ext_number_is_personal',
            render: (is_ext_number_is_personal) => is_ext_number_is_personal === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}}/>
        },
        {
            title: t(`Редактирование запрещено`),
            dataIndex: 'is_ext_number_edit_prohibited',
            sorter: true,
            key: 'is_ext_number_edit_prohibited',
            render: (is_ext_number_edit_prohibited) => is_ext_number_edit_prohibited === true ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}}/>
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (_, row) => <ButtonGroup>
                <Popconfirm title={t('Вы уверены, что хотите разрешить показывать ИИН в этой записи?')}
                            okText={t('Да')}
                            onConfirm={() => updateServiceOption(row, 'is_ext_number_is_personal')}
                            placement={'left'}
                >
                    <Tooltip title={
                        row.is_ext_number_is_personal ?
                        t('Скрыть ИИН') :
                            t('Показывать ИИН')
                    } placement={'top'}>
                        {row.is_ext_number_is_personal ?
                        <Button icon={<EyeOutlined />} danger />  :
                        <Button icon={<EyeOutlined />} type='primary' />}
                    </Tooltip>
                </Popconfirm>
                <Popconfirm title={t('Вы уверены, что хотите запретить редактирование этой записи?')}
                            okText={t('Да')}
                            onConfirm={() => updateServiceOption(row, 'is_ext_number_edit_prohibited')}
                            placement={'left'}
                >
                    <Tooltip title={
                        row.is_ext_number_edit_prohibited ?
                            t('Запретить редактирование') :
                            t('Разрешить редактирование')
                    } placement={'top'}>
                        {
                            row.is_ext_number_edit_prohibited ?
                                <Button icon={<FileProtectOutlined />} danger/> :
                                <Button icon={<FileProtectOutlined />} type={'primary'} />
                        }
                    </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код/Название'), type: 'input'},
        {name: 'is_ext_number_edit_prohibited', label: t('Редактирование запрещено'), type: 'select', options: opt},
        {name: 'is_ext_number_is_personal', label: t('Содержит перс. данные'), type: 'select', options: opt},

    ]

    console.log('data', data)

    return <>
        <AdminServicesListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
            showActions={false}
        />
    </>
}
