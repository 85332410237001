import {useTranslation} from 'react-i18next';
import {
    $selectedProvider
} from '../../../../models/dictionaryProvidersModel/index.js';
import {useUnit} from 'effector-react';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import ProviderBankAccounts from '../ProviderBankAccounts.jsx';
import MappedDictItem from "../../../../components/MappedDictItem/MappedDictItem.jsx";
import {
    CheckOutlined,
    MinusOutlined
} from "@ant-design/icons";
import CustomDates from "../../../../components/CustomDates/CustomDates.jsx";
import ProviderAgreements from '../ProviderAgreements.jsx';
import './ProviderMainForm.less'
import Descriptions from "antd/es/descriptions";


export default function ProviderMainView({isView}) {
    const {t} = useTranslation()
    const provider = useUnit($selectedProvider) || {}

    const commonDItems = [
        {
            key: '1',
            label: t('Населенный пункт'),
            children: <MappedDictItem id={provider.town_id} type={'town'}/>,
            // span: { xl: 2, xxl: 2 },
        },
        {
            key: '2',
            label: t('Адм. р-н'),
            children: <MappedDictItem id={provider.town_distr_id} type={'town_distr'}/>,
        },
        {
            key: '3',
            label: t('БИН/ИИН'),
            children: provider.bin_iin,
        },
        {
            key: '4',
            label: t('Бенефициар'),
            children: provider.is_beneficiary ? <CheckOutlined/> : <MinusOutlined/>,
        },
        {
            key: '5',
            label: t('Поставщик-Бенефициар'),
            children: <MappedDictItem id={provider.beneficiary_id} type={'providers'}/>
        },
        {
            key: '6',
            label: t('Заметки'),
            children: provider.notes ?? <MinusOutlined/>,
        },
    ]

    const dictDItems = [
        {
            key: '1',
            label: t('Категория организации'),
            children: <MappedDictItem
                id={provider.organization_type_id} type={'organization_type'}
            />,
        },
        {
            key: '2',
            label: t('КНП'),
            children: <MappedDictItem id={provider.knp_id} type={'knp'} asTooltip/>,
        },
        {
            key: '3',
            label: t('КБЕ'),
            children: <MappedDictItem id={provider.kbe_id} type={'kbe'} asTooltip/>,
        },
        {
            key: '4',
            label: t('Тип расчетов с ЕРЦ'),
            children: <MappedDictItem id={provider.calc_type_id} type={'calc_type'}/>,
        },
        {
            key: '5',
            label: t('КБК'),
            children: <MappedDictItem id={provider.kbk_id} type={'kbk'} asTooltip/>,
        },
        {
            key: '6',
            label: t('TSH'),
            children: provider.tsh ?? <MinusOutlined/>,
        },
    ]

    const contactsDItems = [
        {
            key: '1',
            label: t('E-mail'),
            children: provider.email ?? <MinusOutlined/>,
        },
//contacts
        {
            key: '2',
            label: t('Контакты'),
            children: provider.accountant_contact ?? <MinusOutlined/>,
        },
//fio
        {
            key: '3',
            label: t('ФИО Руководителя'),
            children: provider.chief_contact ?? <MinusOutlined/>,
        },
        {
            key: '4',
            label: t('Юридический адрес'),
            children: provider.judical_address ?? <MinusOutlined/>,
            span: 3,
        },
        {
            key: '5',
            label: t('Фактический адрес'),
            children: (provider.is_addresses_same
                    ? provider.judical_address
                    : provider.actual_address
            ) ?? <MinusOutlined/>,
            span: 3,
        }
    ]

    const activityDItems = [
        {
            key: '1',
            label: t('Оказывает услуги'),
            children: <>
                <CustomDates type='day' data={provider.date_from}/>
                <CustomDates type='day' data={provider.date_to}/>
            </>,
        },
        {
            key: '2',
            label: t('Не начислять'),
            children: <>
                <CustomDates type='day' data={provider.not_accrued_from}/>
                <CustomDates type='day' data={provider.not_accrued_to}/>
            </>,
        },
        {
            key: '3',
            label: t('Не переводить сальдо'),
            children: <>
                <CustomDates type='day' data={provider.not_balance_transfer_from}/>
                <CustomDates type='day' data={provider.not_balance_transfer_to}/>
            </>,
        },
        {
            key: '4',
            label: t('Не печатать'),
            children: <>
                <CustomDates type='day' data={provider.not_printed_from}/>
                <CustomDates type='day' data={provider.not_printed_to}/>
            </>
        },
    ]

    return <Form style={{background: 'white', padding: '16px 8px 8px 8px', marginTop: '-8px'}}>
        <Row gutter={[8, 8]} style={{marginBottom: '8px'}}>
            <Col span={12}>
                <Descriptions
                    column={2}
                    // title={t('Основная информация')}
                    bordered={true}
                    size={'small'}
                    // extra={<Button type="primary">Edit</Button>}
                    items={commonDItems}
                />
            </Col>
            <Col span={12}>
                <Descriptions column={2} bordered={true} size={'small'} items={dictDItems}/>
            </Col>
        </Row>
        <Row gutter={[8, 8]} style={{marginBottom: '8px'}}>
            <Col span={12}>
                <ProviderBankAccounts
                    isView={isView}
                    isBeneficiary={provider.is_beneficiary}
                    beneficiaryId={provider.beneficiary_id}
                />
            </Col>
            <Col span={12}>
                <ProviderAgreements isView={isView}/>
            </Col>
        </Row>
        <Row gutter={[8, 8]}>
            <Col span={11}>
                <Descriptions column={3} bordered={true} size={'small'} items={contactsDItems}/>
            </Col>
            <Col span={13}>
                <Descriptions
                    column={4} bordered={true} size={'small'} items={activityDItems}
                    layout={'vertical'}
                />
            </Col>
        </Row>
    </Form>
}
