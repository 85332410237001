import {createEffect} from "effector";

export const sendSaveMainPageFx = createEffect().use(async (payload) => {
	return new Promise((resolve) =>
		setTimeout(() => {
			console.log('sendSaveMainPageFx:: payload -', payload)
			return resolve(payload);
		}, 1000)
	);
})
