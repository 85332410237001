import {useMemo} from 'react';
import {useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import {
    $accsTable, $correctionPeriod,
    $correctionType,
    $excludedAccs,
    $foundAccs,
    $multiSelectedAccs, $pageMode,
    $selectedAcc, $selectedProvider, $selectedSrv,
    addExcludedAccEv,
    correctionTypeEnum, deleteSingleCorrectionEv, getAccsFx,
    removeExcludedAccEv,
    setMultiSelectedAccsEv,
    setSelectedAccEv,
    setTableParamsEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {paginationConfig} from '../../../../utils/paginationConfig.js';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import ServiceCardMass from './ServiceCardMass.jsx';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import SaldoForTable from '../components/SaldoForTable.jsx';
import styles from '../CorrectionStyles.module.css'
import ActivitySubForm from '../../../DictionaryProviders/components/ActivitySubForm.jsx';
import ExtPaymentsForTable from '../components/ExtPaymentsForTable.jsx';
import TarifForTable from '../components/TarifForTable.jsx';

export default function ComplexTable({srvForm, resetSrvsForm, resetSelected}) {
    const {t} = useTranslation()

    const selType = useUnit($correctionType)
    const selPeriod = useUnit($correctionPeriod)
    const selProv = useUnit($selectedProvider)
    const selProvSrv = useUnit($selectedSrv)
    const pageMode = useUnit($pageMode)

    const accsLoading = useUnit(getAccsFx.pending)
    const {count, data: foundAccs} = useUnit($foundAccs)
    const setTable = useUnit(setTableParamsEv)
    const {page, limit, sort_field, sort_order} = useUnit($accsTable)

    const showExpand = useMemo(() => {
        return !(selType === correctionTypeEnum.change_tariff || selType === correctionTypeEnum.change_saldo || selType === correctionTypeEnum.change_ext_payments || selType === correctionTypeEnum.resume_srv)
    }, [selType])

    const selectAcc = useUnit(setSelectedAccEv)
    const selectMultiAccs = useUnit(setMultiSelectedAccsEv)
    const selectedAcc = useUnit($selectedAcc)
    const multiAccs = useUnit($multiSelectedAccs)

    const excludedAccs = useUnit($excludedAccs)
    const excludeAcc = useUnit(addExcludedAccEv)
    const returnAcc = useUnit(removeExcludedAccEv)

    const deleteCorrection = useUnit(deleteSingleCorrectionEv)

    const onChangeTable = ({current, pageSize}, filters, {field, order}) => {
        const payload = []
        if (current !== page || pageSize !== limit) {
            payload.push({key: 'page', value: current})
            payload.push({key: 'limit', value: pageSize})
        }

        if (field !== sort_field || order !== sort_order) {
            if (!order) {
                payload.push({key: 'sort_field', value: null})
                payload.push({key: 'sort_order', value: null})
            } else {
                payload.push({key: 'sort_field', value: field})
                payload.push({
                    key: 'sort_order',
                    value: order === 'ascend' ? 'asc' : 'desc'
                })
            }
        }
        setTable(payload)
        resetSrvsForm()
        resetSelected()
    }

    const columns = [
        Table.SELECTION_COLUMN,
        {
            title: t('Номер ЛС'),
            dataIndex: 'account_id',
            render: (id) => <MappedDictItem id={id} type={'accounts'}/>
        },
        {
            title: t('ФИО'),
            dataIndex: 'name_ru',
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            key: 'town_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town'} link={false}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            key: 'town_distr_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town_distr'} link={false}/>,
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            key: 'street_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'street'} link={false}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
            key: 'house'
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            key: 'corpus'
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
            key: 'flat'
        },
        // {
        //     title: t('ИИН'),
        //     dataIndex: 'iin',
        //     hide: true,
        //     key: 'iin'
        // },
        {
            dataIndex: 'account_id',
            key: 'actions',
            align: 'center',
            render: (id) => {
                if (pageMode === 'edit') {
                    return excludedAccs.includes(id)
                        ? <Button type={'primary'} size={'small'} onClick={() => returnAcc(id)}>{t('Вернуть')}</Button>
                        : <Button type={'primary'} danger size={'small'} onClick={() => excludeAcc(id)}>{t('Исключить')}</Button>
                } else if (pageMode === 'view') {
                    return <Button type={'primary'} danger size={'small'} onClick={() => deleteCorrection({account_id: id})}>{t('Удалить')}</Button>
                }
            }
        }
    ]

    const columnsFinal = useMemo(() => {
        if (foundAccs.length > 0) {
            if (selType === correctionTypeEnum.change_tariff) {
                const tarifForm = {
                    dataIndex: 'services',
                    key: 'tarif_form',
                    width: '25%',
                    render: (services, record) => {
                        const srv = services?.find(s => s.service_id === selProvSrv && s.provider_id === selProv)
                        const corrsSortedByTarDate = record.corrections.toSorted((a, b) => dayjs(b.tarif_date_from).unix() - dayjs(a.tarif_date_from).unix())
                        const corrBySrvProv = corrsSortedByTarDate?.filter(c => c.service_id === selProvSrv && c.provider_id === selProv)
                        let corr = selPeriod === 'current'
                            ? corrBySrvProv?.find(t => dayjs(t.tarif_date_from).isBefore(dayjs(dayjs().endOf('month'))))
                            : corrBySrvProv?.find(t => dayjs(t.tarif_date_from).isAfter(dayjs(dayjs(dayjs().endOf('month')).endOf('month'))))
                        if (!corr) {
                            corr = corrBySrvProv[0]
                        }

                        return <TarifForTable form={srvForm} fieldPrefix={[record.account_id]} srv={srv} cor={corr}/>}
                }
                return columns.toSpliced(9, 0, tarifForm)
            } else if (selType === correctionTypeEnum.change_saldo) {
                const saldoForm = {
                    dataIndex: 'services',
                    key: 'saldo_form',
                    width: '50%',
                    render: (services, record) => {
                        const srv = services?.find(s => s.service_id === selProvSrv && s.provider_id === selProv)
                        const corr = record?.corrections?.find(c => c.service_id === selProvSrv && c.provider_id === selProv)

                        return <SaldoForTable form={srvForm} fieldPrefix={[record.account_id]} srv={srv} existCorr={corr}/>
                    }
                }
                return columns.toSpliced(9, 0, saldoForm)
            } else if (selType === correctionTypeEnum.resume_srv) {
                const datesForm = {
                    dataIndex: 'services',
                    key: 'dates_form',
                    width: '50%',
                    render: (services, record) => {
                        // FIXME add dates fill from existing srvs or corrections
                        const srv = services?.find(s => s.service_id === selProvSrv && s.provider_id === selProv)
                        const corr = record?.corrections?.find(c => c.service_id === selProvSrv && c.provider_id === selProv)
                        return <ActivitySubForm form={srvForm} splitRows={window.innerWidth < 1800} prefix={[record.account_id]}/>
                    }
                }
                return columns.toSpliced(9, 0, datesForm)
            } else if (selType === correctionTypeEnum.change_ext_payments) {
                const paymentForm = {
                    dataIndex: 'services',
                    key: 'payments_form',
                    width: '50%',
                    render: (services, record) => {
                        const srv = services?.find(s => s.service_id === selProvSrv && s.provider_id === selProv)
                        const corr = record?.corrections?.find(c => c.service_id === selProvSrv && c.provider_id === selProv)

                        return <ExtPaymentsForTable form={srvForm} fieldPrefix={[record.account_id]} srv={srv} existCorr={corr}/>
                    }
                }
                return columns.toSpliced(9, 0, paymentForm)
            } else {
                if (showExpand) {
                    return columns.toSpliced(1, 0, Table.EXPAND_COLUMN)
                } else {
                    return columns
                }
            }
        } else {
            if (showExpand) {
                return columns.toSpliced(1, 0, Table.EXPAND_COLUMN)
            } else {
                return columns
            }
        }
    }, [selType, selPeriod, foundAccs, showExpand])

    return <Table
        loading={accsLoading}
        size={'small'}
        footer={() =>
            [correctionTypeEnum.change_tariff, correctionTypeEnum.change_saldo, correctionTypeEnum.change_ext_payments].includes(selType)
                ? <Button size={'small'} onClick={() => srvForm.submit()}>{t('Применить изменения в таблице')}</Button>
                : false
        }
        bordered
        columns={columnsFinal}
        dataSource={foundAccs}
        onChange={onChangeTable}
        pagination={{
            ...paginationConfig,
            pageSize: parseInt(limit),
            current: parseInt(page),
            total: count
        }}
        rowKey={'account_id'}
        rowClassName={row => excludedAccs.includes(row.account_id) ? styles.rowInactive : null}
        expandable={showExpand ? {
            expandedRowRender: (record) => <ServiceCardMass singleFromMass account_id={record.account_id} provider_id={selProv} srv_id={selProvSrv} form={srvForm} />,
            expandedRowKeys: [selectedAcc],
            rowExpandable: (record) => !excludedAccs.includes(record.account_id),
            onExpand: (expanded, record) => expanded ? selectAcc(record.account_id) : selectedAcc && selectAcc(null),
        } : false}
        rowSelection={
            {
                type: 'checkbox',
                selectedRowKeys: multiAccs,
                onChange: ids => {
                    if (ids.some(id => excludedAccs.includes(+id))) {
                        return
                    } else {
                        selectMultiAccs(ids)
                    }
                }
            }
        }
    />
}