import React from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {$paymentsElementsData} from "../../../models/paymentsModel/index.js";
import Table from "antd/es/table/index.js";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";

function InnerTable({id}) {
	const {t} = useTranslation()
	const {data} = useUnit($paymentsElementsData)

	const innerColumns = [
		{
			title: t('payment_id'),
			dataIndex: 'payment_id',
			key: 'payment_id'
		},
		{
			title: t('service_id'),
			dataIndex: 'service_id',
			key: 'service_id',
			render: (service_id) => <MappedDictItem id={service_id} type={'services'} link={false}/>,
		},
		{
			title: t('pay_counts'),
			dataIndex: 'pay_counts',
			key: 'pay_counts'
		},
		{
			title: t('last_counts'),
			dataIndex: 'last_counts',
			key: 'last_counts'
		},
		{
			title: t('pay_sum'),
			dataIndex: 'pay_sum',
			key: 'pay_sum'
		},
	]

	return (
		<Table
			style={{marginTop: 20}}
			dataSource={[data.find(item => item.payment_id === id)]}
			columns={innerColumns}
			pagination={false}
		/>
	);
}

export default InnerTable;
