import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
    $paymentsData, getPaymentsDataFx, PaymentsGate
} from "../../../models/dictionaryAccountsModel/index.js";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import FilterBlock from "../../../components/FiltersBlock/FiltersBlock.jsx";
import DictionaryProTable from "../../../components/DictionaryProTable/DictionaryProTable.jsx";
import {useParams} from "react-router-dom";
import InnerTable from "./InnerTable.jsx";
import React from "react";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import Space from "antd/es/space";

const options = [
    {
        label: 'Да',
        value: true,
    },
    {
        label: 'Нет',
        value: false,
    },
    {
        label: 'Все',
        value: undefined,
    }
]

export default function Payments({isView}) {

    const {t} = useTranslation()
    const {search} = searchAndActions()

    const {id} = useParams()


    const {data, count} = useUnit($paymentsData)
    const loading = useUnit(getPaymentsDataFx.pending)


    const columns = [
        {
            title: t('Код платежа'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('Дата ЕПД'),
            dataIndex: 'report_date',
            key: 'report_date',
            render: (v) => <CustomDates data={v} type={'month'}/>,
        },
        // {
        //     title: t('Инвойс'),
        //     dataIndex: 'invoice',
        //     key: 'invoice',
        // },
        {
            title: t('Номер ЛС'),
            dataIndex: 'account_id',
            key: 'account_id',
        },
        {
            title: t('Дата оплаты'),
            dataIndex: 'bank_payment_date',
            key: 'bank_payment_date',
            render: (v) => <CustomDates data={v}/>,
        },
        // {
        //     title: t('sysref'),
        //     dataIndex: 'sysref',
        //     key: 'sysref',
        // },
        {
            title: t('Банк'),
            dataIndex: 'bank_id',
            key: 'bank_id',
            render: (bank_id) => <MappedDictItem id={bank_id} type={'bank'} link={false}/>,
        },
        // {
        //     title: t('sysbankref'),
        //     dataIndex: 'sysbankref',
        //     key: 'sysbankref',
        // },
        {
            title: t('Статус подтверждения'),
            dataIndex: 'is_confirmed',
            key: 'is_confirmed',
            render: (v, r) => (
                v ? <Space>
                    <CheckCircleOutlined style={{color: 'lightgreen'}}/>
                    <CustomDates data={r.is_confirmed}/>
                </Space> : <CloseCircleOutlined/>
            )
        },
        {
            title: t('Статус возврата'),
            dataIndex: 'is_rollback',
            key: 'is_rollback',
            render: (v, r) => (
                v ? <>
                    <CheckCircleOutlined style={{color: 'red'}}/>
                    <CustomDates data={r.rollback_date}/>
                </> : <CloseCircleOutlined/>
            )
        },
    ]

    const filters = [
        {name: 'service_id', label: t('Услуга'), type: 'select', dict: 'services'},
        {name: 'report_date', label: t('Дата ЕПД'), type: 'date'},
        // {name: 'sysref', label: t('sysref'), type: 'input'},
        // {name: 'bank_id', label: t('Банк'), type: 'select', dict: 'bank'},
        // {name: 'sysbankref', label: t('sysbankref'), type: 'input'},
        // {name: 'is_confirmed', label: t('Подтвержден'), type: 'select', options},
        // {name: 'is_rollback', label: t('Возврат'), type: 'select', options},
        // {name: 'bank_payment_date_from', label: t('bank_payment_date_from'), type: 'date'},
        // {name: 'bank_payment_date_to', label: t('bank_payment_date_to'), type: 'date'},
        // {name: 'erc_confirm_date_from', label: t('erc_confirm_date_from'), type: 'date'},
        // {name: 'erc_confirm_date_to', label: t('erc_confirm_date_to'), type: 'date'},
        // {name: 'rollback_date_from', label: t('rollback_date_from'), type: 'date'},
        // {name: 'rollback_date_to', label: t('rollback_date_to'), type: 'date'},
    ]


    return <>
        <PaymentsGate id={id} search={search.toString()}/>

        <FilterBlock items={filters}/>
        <DictionaryProTable
            data={data}
            count={count}
            initColumns={columns}
            loading={loading}
            showActions={false}
            expandable={{
                expandedRowRender: (record) => (
                    <InnerTable id={record.id}/>
                )
            }}
        />

    </>
}
