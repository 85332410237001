import {createEffect, createEvent, sample} from 'effector';
import i18n from '../../i18n.js';
import {notification} from 'antd';
import api from '../../api/api.js';
import {ButtonDublicate} from "./ButtonDublicate.jsx";
import {ButtonDublicateDeleted} from "./ButtonDublicateDeleted.jsx";
export const submitGlobalUpdateEv = createEvent()
export const universalDictEndpointFx = createEffect()
    .use(async (actions) => {
        return (await api().post('/operations/', actions)).data
    })



export const triggerSuccessOpsEv = createEvent()

sample({
    clock: submitGlobalUpdateEv,
    target: universalDictEndpointFx
})

sample({
    clock: universalDictEndpointFx.doneData,
    filter: ops => ops.every(op => op.success === true),
    target: triggerSuccessOpsEv
})

universalDictEndpointFx.doneData.watch((ev) => {
    if (ev.every((item) => item.success === true)) {
        notification.success({message: i18n.t('Успешно!'), description: i18n.t('Данные обновлены')})
    } else if (ev.some((item) => item.success === false)) {

        const dublicateErrors = ev.filter((item) => item.exception_type === 'DuplicateException').map(
            item => {
                return {
                    type: item.type,
                    id: item.value.id,
                    detail: item.detail,
                }
            }
        )
        if (dublicateErrors.length > 0) {
            // "Duplicate 'kbk' item with id='12'"
            // "Duplicate 'kbk' deleted item with id='11'"
            dublicateErrors.map(item => {
                if (item.detail.includes('deleted')) {
                    return notification.error(
                        {
                            message: i18n.t('Ошибка'),
                            duration: 0,
                            description: ButtonDublicateDeleted(item.type, item.id)
                        }
                    )
                } else {
                    return notification.error(
                        {
                            message: i18n.t('Ошибка'),
                            duration: 0,
                            description: ButtonDublicate(item.type, item.id)
                        }
                    )
                }

            })
        } else {
            const errors = ev.filter((item) => item.success === false).map((item) => item.detail).join('\n')
            notification.error({message: i18n.t('Ошибка'), description: errors})
        }


    }
})

universalDictEndpointFx.failData.watch((err) => {
    if (err) {
        notification.error({message: i18n.t('Ошибка'), description: err.message ?? err})
    }
})
