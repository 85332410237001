import {createGate} from "effector-react";
import {createEvent} from "effector";

export const PaymentGate = createGate()
export const PaymentAppiesGate = createGate()
export const PaymentExportGate = createGate()
export const PaymentBankApiGate = createGate()
export const TableErrorGate = createGate()

export const getPaymentByIdEv = createEvent()
export const getPaymentErrorByIdEv = createEvent()

export const onConfirmSingleEv = createEvent()
export const onConfirmSelectedEv = createEvent()
export const onConfirmAllEv = createEvent()

export const onConfirmAppliesSingleEv = createEvent()
export const onConfirmAppliesSelectedEv = createEvent()
export const onConfirmAppliesAllEv = createEvent()
export const onCancelAppliesSingleEv = createEvent()

export const onConfirmExportSingleEv = createEvent()
export const onConfirmExportSelectedEv = createEvent()
export const onConfirmExportAllEv = createEvent()

export const onPrepareExportSingleEv = createEvent()
export const onExportToBankSingleEv = createEvent()

export const openPaymentConfirmModalEv = createEvent()
export const closePaymentConfirmModalEv = createEvent()
export const dataPaymentConfirmModalEv = createEvent()

export const openPaymentBankApiAuditModalEv = createEvent()
export const closePaymentBankApiAuditModalEv = createEvent()
export const dataPaymentBankApiAuditModalEv = createEvent()

export const bankApiAuditDownloadFullEv = createEvent()
export const bankApiAuditDownloadShortEv = createEvent()

export const openPaymentExportModalEv = createEvent()
export const closePaymentExportModalEv = createEvent()
export const dataPaymentExportModalEv = createEvent()

export const getPaymentElementsEv = createEvent()
export const changeServiceInErrorEv = createEvent()
export const resetTableErrorEv = createEvent()

export const getExportedPaymentsEv = createEvent()

export const bankApiAuditChangeFilterEv = createEvent()

export const exportPaymentConfirmEv = createEvent()
export const exportPaymentAppliesEv = createEvent()
export const exportPaymentExportEv = createEvent()

export const exportConfirmTableEv = createEvent()
export const exportAppliesTableEv = createEvent()
export const exportAppliesErrorEv = createEvent()
export const exportExportTableEv = createEvent()
