import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getCorrectionListFx = createEffect()
    .use(async (filters) => (await api().get(`/correction/${filters.length > 0 ? `?${filters}` : ''}`)).data)


export const getCorrectionByIdFx = createEffect()
    // .use(async (filters) => (await api().get(`/correction/${filters.length > 0 ? `?${filters}` : ''}`)).data)
    .use(async (filters) => {
        // debugger
        return await api().get(`/correction/${filters > 0 ? `${filters}` : ''}`)
    })

export const createCorrectionFx = createEffect()
    .use(async (data) => (await api().post(`/correction/`, data)).data)

export const cancelCorrectionFx = createEffect()
    .use(async (id) => {
        return await api().post(`/correction/cancel?correction_id=${id}`)
    })

export const finishCorrectionFx = createEffect()
    .use(async (id) => {
        return await api().post(`/correction/finish?correction_id=${id}`)
    })

export const deleteCorrectionFx = createEffect()
    .use(async (id) => (await api().delete(`/correction/${id}`)).data)
