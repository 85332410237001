import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $orgTypeEditStatus, $selectedOrgType,
    OrgTypeEditGate,
    getOrgTypeByIdFx,
    submitEditOrgTypeEv
} from '../../../models/dictionaryOrgTypeModel';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import useFormChanged from '../../../hooks/useFormChanged.js';

export default function EditOrgType() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [orgTypeForm] = Form.useForm()
    const submit = useUnit(submitEditOrgTypeEv)
    const loading = useUnit(getOrgTypeByIdFx.pending)
    const editLoading = useUnit(universalDictEndpointFx.pending)
    const status = useUnit($orgTypeEditStatus)

    const selectedOrgType = useUnit($selectedOrgType)

    const [check, changedFields] = useFormChanged(selectedOrgType, ['name_kz', 'name_ru'])
    const genitive_form = t(`breadcrumbs.genitive.organization_type`);

    useEffect(() => {
        if (status) {
            navigate('/dictionaries/organization_type')
        }
    }, [status]);

    useEffect(() => {
        if (selectedOrgType) {
            orgTypeForm.setFieldsValue(selectedOrgType)
        }
    }, [selectedOrgType]);

    return <>
        <OrgTypeEditGate id={id}/>
        <Form form={orgTypeForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              onValuesChange={(_, all) => check(all)}
              validateTrigger={'onBlur'}
        >
            <Card title={t('Свойства категории организации')} size={'small'} loading={loading}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    orgTypeForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={editLoading} onClick={() => {
                    orgTypeForm.submit()
                }} type={'primary'}>
                    {t('Сохранить')}
                </Button>
            </Space>
        </Row>
    </>
}
