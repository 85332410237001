import {useTranslation} from 'react-i18next';
import {
    $selectedProvider, resetLinksOnBenefChangeEv, resetProvBankAccountsEv, setBeneficiaryEv,
    submitCreateProviderEv, submitEditProviderEv
} from '../../../../models/dictionaryProvidersModel/index.js';
import {useStoreMap, useUnit} from 'effector-react';
import {useEffect} from 'react';
import {$dictsSelects} from '../../../../models/infoModel/index.js';
import Form from 'antd/es/form';
import Card from 'antd/es/card';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import {filterOption} from '../../../../utils/selectUtils.jsx';
import {binValidator} from '../../../../utils/validateBIN.js';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import ProviderBankAccounts from '../ProviderBankAccounts.jsx';
import dayjs from 'dayjs';
import MappedDictItem from "../../../../components/MappedDictItem/MappedDictItem.jsx";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import CustomDates from "../../../../components/CustomDates/CustomDates.jsx";
import ProviderAgreements from '../ProviderAgreements.jsx';
import './ProviderMainForm.less'
import {isId} from '../../../../utils/helpers.js';
import {InputNumber} from "antd";

const ModedSwitch = (props) => {
    const ecMode = props.ecMode
    const newProps = {...props}
    delete  newProps.ecMode
    if (ecMode) {
        return <Switch {...newProps}/>
    } else {
        return props.value ? <CheckCircleOutlined /> : <CloseCircleOutlined />
    }
}

const viewStyles = {
    disabled: true,
    bordered: false,
    style: {
        color: 'black',
        padding: '0px',
    },
    className: 'is-view',
}
const ecStyles = {}

export default function ProviderMainForm({form, edit, isView}) {
    console.log("=>(ProviderMainForm.jsx:52) isView", isView);
    const {t} = useTranslation()

    const submitCreate = useUnit(submitCreateProviderEv)
    const submitEdit = useUnit(submitEditProviderEv)

    const setBeneficiary = useUnit(setBeneficiaryEv)
    const resetBAs = useUnit(resetProvBankAccountsEv)
    const resetLinks = useUnit(resetLinksOnBenefChangeEv)

    const {
        townOpts,
        townDistrOpts,
        kbkOpts,
        calcTypeOpts,
        knpOpts,
        orgTypeOpts,
        kbeOpts,
        beneficiariesOpts
    } = useStoreMap($dictsSelects, sel => ({
        townOpts: sel.towns,
        townDistrOpts: sel.districts,
        kbkOpts: sel.kbk,
        taxBranchOpts: sel.taxBranch,
        calcTypeOpts: sel.calcType,
        knpOpts: sel.knp,
        orgTypeOpts: sel.orgType,
        kbeOpts: sel.kbe,
        beneficiariesOpts: sel.providerBeneficiaries
    }))

    const selectedProvider = useUnit($selectedProvider)

    useEffect(() => {
        if (edit && selectedProvider) {
            const prepared = {...selectedProvider}
            for (const [key, value] of Object.entries(prepared)) {
                if (key.endsWith('_from') || key.endsWith('_to')) {
                    prepared[key] = value ? dayjs(value) : null
                }
            }
            form.setFieldsValue(prepared)
        }
    }, [edit, selectedProvider]);

    const townId = Form.useWatch('town_id', form)
    const sameAddr = Form.useWatch('is_addresses_same', form)
    const isNotAccrued = Form.useWatch('is_not_accrued', form)
    const isNotBalanceTransfer = Form.useWatch('is_not_balance_transfer', form)
    const isNotPrinted = Form.useWatch('is_not_printed', form)
    const isBeneficiary = Form.useWatch('is_beneficiary', form)
    const beneficiaryId = Form.useWatch('beneficiary_id', form)

    useEffect(() => {
        if (!isBeneficiary && beneficiaryId) {
            setBeneficiary(beneficiaryId)
            resetBAs()
            resetLinks()
        } else if (isBeneficiary && beneficiaryId) {
            resetBAs()
            resetLinks()
            setBeneficiary(null)
            form.resetFields(['beneficiary_id'])
        } else {
            form.resetFields(['beneficiary_id'])
            setBeneficiary(null)
        }

    }, [isBeneficiary, beneficiaryId])

    const onValuesChange = (cur, all) => {
        if (cur.is_not_accrued === false && all.is_not_balance_transfer === true) {
            form.setFieldsValue({is_not_balance_transfer: false})
        } else if (cur.is_not_balance_transfer === true && all.is_not_accrued === false) {
            form.setFieldsValue({is_not_accrued: true})
        } else if (Object.hasOwn(cur, 'town_id') && isId(all?.town_distr_id)) {
            form.resetFields(['town_distr_id'])
        }
    }

    const onFinish = (values) => {
        if (values.is_addresses_same) {
            values['actual_address'] = values.judical_address
        }
        if (values.name_ru) {
            values['name_kz'] = values.name_ru
        }
        for (const [key, value] of Object.entries(values)) {
            if ((key === 'date_from' || key === 'date_to') && value && dayjs(value).isValid()) {
                values[key] = dayjs(value).format()
            } else if (key.startsWith('not_') && key.endsWith('from') && value && dayjs(value).isValid()) {
                values[key] = dayjs(value).date(dayjs(value).startOf('month').date()).format()
            } else if (key.startsWith('not_') && key.endsWith('to') && value && dayjs(value).isValid()) {
                values[key] = dayjs(value).date(dayjs(value).endOf('month').date()).format()
            }
        }
        if (edit) {
            submitEdit(values)
        } else {
            submitCreate(values)
        }
    }

    const ecMode = !isView
    const commonInputParams = isView ? viewStyles : ecStyles

    console.log('selectedProvider', selectedProvider)

    return <Form
        layout={'vertical'} form={form} onFinish={onFinish} size={'small'}
        className={isView ? 'provider-view-main' : ''}
        onValuesChange={onValuesChange}
    >
        <Row gutter={[8, 8]} style={{marginBottom: '8px'}}>
            <Col span={12}>
                <Card size={'small'} style={{height: '100%'}} >
                    <Row gutter={[8, 0]}>
                        <Col span={8}>
                            <Form.Item
                                name={'town_id'} label={t('Населенный пункт')}
                                rules={ecMode ?
                                    [{required: true, message: t('Обязательное поле')}] : []
                                }
                            >
                                { ecMode ? <Select
                                    options={townOpts} filterOption={filterOption} showSearch
                                    allowClear
                                /> : selectedProvider?.town_id
                                    ? <MappedDictItem id={selectedProvider?.town_id} type={'town'}/>
                                    : '-' }
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                name={'name_ru'} label={t('Название поставщика')}
                                rules={ecMode ?
                                    [{required: true, message: t('Обязательное поле')}] : []
                                }
                            >
                                <Input {...commonInputParams} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'bin_iin'}
                                       label={t('БИН/ИИН')}
                                       rules={ecMode ? [
                                           {required: true, message: t('Обязательное поле')},
                                           {validator: (rule, value) => binValidator(rule, value, false)}
                                       ] : []}
                            >
                                <Input showCount={ecMode} maxLength={12} {...commonInputParams}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={'organization_type_id'}
                                label={t('Категория организации')}
                                rules={ ecMode ?
                                    [{required: true, message: t('Обязательное поле')}] : []
                                }
                            >
                                {ecMode ? <Select
                                    options={orgTypeOpts} filterOption={filterOption}
                                    showSearch allowClear dropdownStyle={{width: 400}}
                                /> : selectedProvider?.organization_type_id
                                    ? <MappedDictItem id={selectedProvider?.organization_type_id} type={'organization_type'}/>
                                    : '-' }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={'calc_type_id'} label={t('Тип расчетов с ЕРЦ')}
                                rules={ ecMode ?
                                    [{required: true, message: t('Обязательное поле')}] : []
                                }
                            >
                                {ecMode ? <Select
                                    options={calcTypeOpts} filterOption={filterOption}
                                        showSearch allowClear dropdownStyle={{width: 400}}
                                /> : selectedProvider?.calc_type_id
                                    ? <MappedDictItem id={selectedProvider?.calc_type_id} type={'calc_type'}/>
                                    : '-' }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={'knp_id'} label={t('КНП')}
                                rules={ ecMode ?
                                    [{required: true, message: t('Обязательное поле')}] : []
                                }
                            >
                                {ecMode ? <Select
                                    options={knpOpts} filterOption={filterOption} showSearch
                                        allowClear dropdownStyle={{width: 400}}
                                /> : selectedProvider?.knp_id
                                    ? <MappedDictItem id={selectedProvider?.knp_id} type={'knp'}/>
                                    : '-' }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={'kbe_id'} label={t('КБЕ')}
                                rules={ ecMode ?
                                    [{required: true, message: t('Обязательное поле')}] : []
                                }
                            >
                                {ecMode ? <Select
                                    options={kbeOpts} filterOption={filterOption} showSearch
                                        allowClear dropdownStyle={{width: 400}}
                                /> : selectedProvider?.kbe_id
                                    ? <MappedDictItem id={selectedProvider?.kbe_id} type={'kbe'}/>
                                    : '-' }
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={12}>
                <Card size={'small'} style={{height: '100%'}} >
                    <Row gutter={[8, 0]}>
                        <Col span={8}>
                            <Form.Item
                                name={'town_distr_id'} label={t('Район населенного пункта')}
                            >
                                { ecMode ? <Select
                                    options={isId(townId) ? townDistrOpts.filter(i => i.town_id === townId) : []}
                                    filterOption={filterOption}
                                    showSearch
                                    disabled={!isId(townId)}
                                    allowClear
                                /> : selectedProvider?.town_distr_id
                                    ? <MappedDictItem id={selectedProvider?.town_distr_id} type={'town_distr'}/>
                                    : '-' }
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item name={'kbk_id'} label={t('КБК')}>
                                {ecMode ? <Select
                                    options={kbkOpts} filterOption={filterOption} showSearch
                                    allowClear dropdownStyle={{width: 600}}
                                /> : selectedProvider?.kbk_id
                                    ? <MappedDictItem id={selectedProvider?.kbk_id} type={'kbk'}/>
                                    : '-' }
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item name={'is_beneficiary'} label={t('Бенефициар')}
                                       tooltip={t('В случае, если поставщик предоставляет р/с другим поставщикам и не оказывает услуг, то следует включить данный флаг')}
                                       valuePropName={'checked'} initialValue={false}
                            >
                                <ModedSwitch ecMode={ecMode} value={selectedProvider?.is_beneficiary}/>
                            </Form.Item>
                        </Col>

                        <Col span={20}>
                            <Form.Item name={'beneficiary_id'} label={t('Поставщик-Бенефициар')}
                                       tooltip={t('В случае, если поставщик использует р/с другого поставщика(поставщика с флагом бенефициара), то здесь должен быть выбран данный поставщик')}
                                       rules={[{
                                           required: false,
                                           message: t('Необязательное поле')
                                       }]}
                            >
                                {ecMode
                                    ? <Select
                                        disabled={isBeneficiary} options={beneficiariesOpts} filterOption={filterOption}
                                        showSearch allowClear dropdownStyle={{width: 400}}
                                        onChange={(v) => {
                                            if (v === undefined) {
                                                resetBAs()
                                                setBeneficiary(null)
                                            }
                                        }}
                                    />
                                    : selectedProvider?.beneficiary_id
                                        ? <MappedDictItem id={selectedProvider?.beneficiary_id} type={'providers'}/>
                                        : '-'
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'notes'} label={t('Заметки')}
                                       tooltip={t('Выводятся если ячейки не объединены')}>
                                {ecMode
                                    ? <Input {...commonInputParams} disabled={isBeneficiary}/>
                                    : selectedProvider?.notes ?? '-'
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'tsh'} label={t('TSH')}>
                                <InputNumber
                                    {...commonInputParams} max={999} min={0} precision={0} stringMode
                                />
                            </Form.Item>
                        </Col>
                        {/*<Col span={12}>*/}
                        {/*    <Form.Item name={'tax_branch_id'} label={t('Налоговое отделение')}>*/}
                        {/*        {ecMode ? <Select*/}
                        {/*            options={taxBranchOpts} filterOption={filterOption}*/}
                        {/*                showSearch*/}
                        {/*                allowClear*/}
                        {/*                dropdownStyle={{width: 500}}*/}
                        {/*        /> : selectedProvider?.tax_branch_id*/}
                        {/*            ? <MappedDictItem id={selectedProvider?.tax_branch_id} type={'tax_branch'}/>*/}
                        {/*            : '-' }*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                    </Row>
                </Card>
            </Col>
        </Row>
        <Row gutter={[8, 8]} style={{marginBottom: '8px'}}>
            <Col span={12}>
                <ProviderBankAccounts isView={isView} isBeneficiary={isBeneficiary} beneficiaryId={beneficiaryId}/>
            </Col>
            <Col span={12}>
                <ProviderAgreements isView={isView} />
            </Col>
        </Row>
        <Row gutter={[8, 8]} >
            <Col span={11}>
                <Card title={t('Контактные данные')} size={'small'} style={{height: '100%'}} >
                    <Row gutter={[8, 0]}>
                        <Col span={6}>
                            <Form.Item name={'email'} label={t('E-mail')}>
                                {ecMode
                                    ? <Input {...commonInputParams} />
                                    : selectedProvider?.email ?? '-'
                                }
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item name={'accountant_contact'}
                                       label={t('Контакты')}>
                                {ecMode
                                    ? <Input {...commonInputParams} />
                                    : selectedProvider?.accountant_contact ?? '-'
                                }
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item name={'chief_contact'} label={t('ФИО Руководителя')}>
                                {ecMode
                                    ? <Input {...commonInputParams} />
                                    : selectedProvider?.chief_contact ?? '-'
                                }
                            </Form.Item>
                        </Col>
                        <Col span={17}>
                            <Form.Item name={'judical_address'} label={t('Юридический адрес')}>
                                {ecMode
                                    ? <Input {...commonInputParams} />
                                    : selectedProvider?.judical_address ?? '-'
                                }
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item name={'is_addresses_same'}
                                       label={t('Юр. совпадает с факт.')}
                                       valuePropName={'checked'}
                                       initialValue={true}
                            >
                                <ModedSwitch ecMode={ecMode} value={selectedProvider?.is_addresses_same}/>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{display: sameAddr ? 'none' : 'block'}}>
                            <Form.Item name={'actual_address'} label={t('Фактический адрес')}>
                                {ecMode
                                    ? <Input {...{disabled: sameAddr, ...commonInputParams}} />
                                    : selectedProvider?.actual_address ?? '-'
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={13}>
                <Card title={t('Активность')} size={'small'} style={{height: '100%'}}>
                    <Row gutter={[16, 0]}>
                        <Col span={6}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item name={'date_from'}
                                               label={t('Оказывает услуги с')}>
                                        {ecMode ? <DatePicker
                                            disabled={isBeneficiary}
                                            style={{width: '100%'}}
                                        /> : <CustomDates type='day' data={selectedProvider?.date_from} /> }
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name={'date_to'}
                                               label={t('Оказывает услуги до')}>
                                        {ecMode ? <DatePicker
                                            disabled={isBeneficiary}
                                            style={{width: '100%'}}
                                        /> : <CustomDates type='day' data={selectedProvider?.date_to} /> }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item name={'is_not_accrued'}
                                               label={t('Не начислять')}
                                               valuePropName={'checked'}
                                               initialValue={false}>
                                        <ModedSwitch ecMode={ecMode} disabled={isBeneficiary} value={selectedProvider?.is_not_accrued}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'not_accrued_from'}
                                               label={t('С')}>
                                        {ecMode ? <DatePicker.MonthPicker
                                            style={{width: '100%'}}
                                            disabled={!isNotAccrued || isBeneficiary}
                                        /> : <CustomDates type='day' data={selectedProvider?.not_accrued_from} /> }
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'not_accrued_to'}
                                               label={t('По')}>
                                        {ecMode ? <DatePicker.MonthPicker
                                            style={{width: '100%'}}
                                            disabled={!isNotAccrued || isBeneficiary}
                                        /> : <CustomDates type='day' data={selectedProvider?.not_accrued_to} /> }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item name={'is_not_balance_transfer'}
                                               label={t('Не переводить сальдо')}
                                               valuePropName={'checked'}
                                               initialValue={false}
                                    >
                                        <ModedSwitch ecMode={ecMode} disabled={isBeneficiary} value={selectedProvider?.is_not_balance_transfer}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'not_balance_transfer_from'}
                                               label={t('С')}>
                                        {ecMode ? <DatePicker.MonthPicker
                                            style={{width: '100%'}}
                                            disabled={!isNotBalanceTransfer || isBeneficiary}
                                        /> : <CustomDates type='day' data={selectedProvider?.not_balance_transfer_from} /> }
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'not_balance_transfer_to'}
                                               label={t('По')}>
                                        {ecMode ? <DatePicker.MonthPicker
                                            style={{width: '100%'}}
                                            disabled={!isNotBalanceTransfer || isBeneficiary}
                                        /> : <CustomDates type='day' data={selectedProvider?.not_balance_transfer_to} /> }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item name={'is_not_printed'}
                                               label={t('Не печатать')}
                                               valuePropName={'checked'}
                                               initialValue={false}
                                    >
                                        <ModedSwitch ecMode={ecMode} disabled={isBeneficiary} value={selectedProvider?.is_not_printed}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'not_printed_from'}
                                               label={t('С')}>
                                        {ecMode ? <DatePicker.MonthPicker
                                            style={{width: '100%'}}
                                            disabled={!isNotPrinted || isBeneficiary}
                                        /> : <CustomDates type='day' data={selectedProvider?.not_printed_from} /> }
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'not_printed_to'}
                                               label={t('По')}>
                                        {ecMode ? <DatePicker.MonthPicker
                                            style={{width: '100%'}}
                                            disabled={!isNotPrinted || isBeneficiary}
                                        /> : <CustomDates type='day' data={selectedProvider?.not_printed_to} /> }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </Form>
}
