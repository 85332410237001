import Row from 'antd/es/row';
import Form from 'antd/es/form';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $createdAccountId,
    setAfterCreateActionEv,
    resetAfterCreationAttrs,
    $afterCreateAction, submitCreateAccountEv, AccountCreateGate
} from '../../../models/dictionaryAccountsModel/index.js';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {
    $createdREO,
    $selectedREOFromAddress, resetCreatedREOEv, resetSelectionEv
} from '../../../models/accountsREOSearchModel/index.js';
import CreateAccountCommon from "./CreateAccountCommon.jsx";
import Tooltip from 'antd/es/tooltip';
import Col from "antd/es/col";

export default function CreateAccount() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const createdId = useUnit($createdAccountId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const submit = useUnit(submitCreateAccountEv)

    const selectedReo = useUnit($selectedREOFromAddress)
    const createdReo = useUnit($createdREO)
    const resetCreatedReo = useUnit(resetCreatedREOEv)
    const resetAddress = useUnit(resetSelectionEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/accounts/edit/${createdId}`)
            } else if (action === 'new') {
                accountForm.resetFields()
                navigate(
                    `/dictionaries/accounts/create?created_prev_id=${createdId}`,
                    {replace: true}
                )
            }
            resetCreatedReo()
            resetAddress()
            resetAttrs()
        }
    }, [createdId, action]);

    const [accountForm] = Form.useForm()

    const onValuesChange = (changedValues) => {
        if (Object.hasOwn(changedValues, 'full_square')) {
            accountForm.validateFields(['living_square', 'heated_square'])
        }
    }

    const onSubmit = (values) => {
        values['name_kz'] = values['name_ru']
        return submit(values)
    }

    return <>
        <AccountCreateGate/>
        <Form form={accountForm} layout={'vertical'} onFinish={onSubmit}
              onValuesChange={onValuesChange} size={'small'}>
            <Row>
                <Col span={24}>
                    <CreateAccountCommon/>
                </Col>
                <Space>
                    <Button danger onClick={() => {
                        accountForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Tooltip
                        title={!(selectedReo || createdReo) ? t('Выберите или создайте Объект Недвижимости') : null}>
                        <Button loading={loading}
                                onClick={() => {
                                    setAction('new')
                                    accountForm.submit()
                                }}
                                type={'primary'}
                                ghost
                                disabled={!(selectedReo || createdReo)}
                        >
                            {t('Сохранить и создать следующий')}
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={!(selectedReo || createdReo) ? t('Выберите или создайте Объект Недвижимости') : null}>
                        <Button loading={loading}
                                onClick={() => {
                                    setAction('edit')
                                    accountForm.submit()
                                }}
                                type={'primary'}
                                disabled={!(selectedReo || createdReo)}
                        >
                            {t('Сохранить и продолжить редактирование')}
                        </Button>
                    </Tooltip>
                </Space>
            </Row>
        </Form>
    </>
}
