import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $bankAccsOpts, $embeddedBaForm,
    $providerServices,
    $selectedProvService,
    addProvServiceEv,
    applySrvParamsEv,
    resetSelectedProvSrvEv,
    setBaEmbeddedFormEv
} from '../../../../models/dictionaryProvidersModel/index.js';
import {$dictsSelects} from '../../../../models/infoModel/index.js';
import {filterOption, optionRender} from '../../../../utils/selectUtils.jsx';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
// import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Table from 'antd/es/table';
import ButtonGroup from 'antd/es/button/button-group';
import dayjs from 'dayjs';
import ActivitySubForm from '../ActivitySubForm.jsx';
import * as styles from './ServiceCreateEdit.module.css'
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import MappedDictItem from "../../../../components/MappedDictItem/MappedDictItem.jsx";
import Popconfirm from "antd/es/popconfirm";
import {isId} from "../../../../utils/helpers.js";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import ProviderBankAccounts from "../ProviderBankAccounts.jsx";
import Divider from "antd/es/divider";

const serviceDefaults = {
    tarifs: [
        {id: -1, value: 0.0, service_unit_id: 0, inactive: false},
    ],
    preferences: [],
    is_paid_service: true,
    is_penalty_charge: true,
    penalty_percent: 0.1,
    is_penalty_priority: true,
    penalty_calculation_date: 1,
    add_tariffs_on_import: true,
}

// const accountFieldOpts = [
//     {label: 'Количество проживающих', value: 'people_quantity'},
//     {label: 'Общая площадь', value: 'full_square'},
//     {label: 'Жилая площадь', value: 'living_square'},
//     {label: 'Отапливаемая площадь', value: 'heated_square'},
// ]
//
// const penaltyDateOpts = [
//     {label: '1', value: 1},
//     {label: '26', value: 26},
// ]


const SaveTariffsSwitch = (props) => {
    const {t} = useTranslation()
    const {value = {}, onChange} = props;
    const triggerChange = (changedValue) => {
        onChange?.(changedValue)
    };
    const [open, setOpen] = useState(false);
    // const [newValue, setNewValue] = useState(value);

    const setTarifsValue = () => {
        setOpen(false)
        triggerChange(false)
    };

    const cancel = () => {
        setOpen(false)
    };

    const handleOpenChange = (newOpen) => {
        if (!newOpen) {
            setOpen(newOpen)
            return
        }
        if (!value) {
            setOpen(false)
            triggerChange(true)
        } else {
            setOpen(true)
        }
    };

    const onSwitchChange = (v) => {
        // console.log('onChange', v)
        if (!v) {
            setOpen(true)
        }
    }

    // console.log('XXXX', value, open)
    return <Popconfirm
        title={t('Внимание!')}
        description={<div>
            {t('Вы хотите отключить создание тарифов при загрузке файла начислений.')}<br/>
            {t('Это может привести к ситуации, когда система будет присоединять к ЛС тариф со значением 0. ')}<br/>
            {t('Отключайте данный флаг только для поставщиков, у которых большое число уникальных тарифов, ')}<br/>
            {t('которые проводят расчеты самостоятельно и по которым Система не проводит расчетов начислений!')}
        </div>}
        open={open}
        onOpenChange={handleOpenChange}
        onConfirm={setTarifsValue}
        onCancel={cancel}
        okText={t('Да, я уверен, что хочу отключить создание тарифов при загрузке')}
        cancelText={t('Нет, не нужно отключать')}
    >
        <Switch onChange={onSwitchChange} value={value}/>
    </Popconfirm>
};


export default function ServiceCreateEdit(props) {
    const {} = props
    const {t} = useTranslation()

    const [srvParamsForm] = Form.useForm()
    const serviceRef = useRef()

    const {serviceOpts} = useStoreMap($dictsSelects, sel => ({
        serviceOpts: sel.services,
        // algorithmOpts: sel.algorithms,
    }))

    const baOpts = useUnit($bankAccsOpts)
    const embeddedOpen = useUnit($embeddedBaForm)
    const openBaEmbedded = useUnit(setBaEmbeddedFormEv)

    const services = useUnit($providerServices)
    const selectedService = useUnit($selectedProvService)
    const resetService = useUnit(resetSelectedProvSrvEv)

    useEffect(() => {
        if (selectedService) {
            const prepared = {...selectedService}
            for (const [key, value] of Object.entries(prepared)) {
                if ((key.includes('_from') || key.includes('_to')) && key !== 'copy_to_account') {
                    prepared[key] = value ? dayjs(value) : null
                }
            }
            console.log('set service edit form', prepared)
            srvParamsForm.setFieldsValue(prepared)
        } else if (!selectedService) {
            srvParamsForm.resetFields()
        }
    }, [selectedService]);

    useEffect(() => {
        if (baOpts.length > 0 && !embeddedOpen) {
            const selectedBa = srvParamsForm.getFieldValue('bank_account_id')
            if (baOpts.some(b => b.value < 0) && selectedBa === 'new') {
                const newBa = baOpts.find(b => b.value < 0)
                srvParamsForm.setFieldsValue({bank_account_id: newBa.value})
            }
        }
    }, [baOpts])

    const [serviceToAdd, setServiceToAdd] = useState(null)

    const addService = useUnit(addProvServiceEv)
    const saveSrvParams = useUnit(applySrvParamsEv)
    const onSubmitParams = (values) => {
        console.log('EEEEEEEEEEEEEEEEEEEE', values)
        if (selectedService) {
            saveSrvParams(values)
        } else {
            addService(values)
        }
        srvParamsForm.resetFields()
        resetService()
    }

    const nonPenalty = !Form.useWatch('is_penalty_charge', srvParamsForm)
    const penaltyServiceId = Form.useWatch('penalty_service_id', srvParamsForm)
    const title = selectedService ? t('Редактирование услуги') : t('Добавление услуги')
    const penaltyServiceOpts = serviceOpts
    // const penaltyServiceOpts = serviceOpts.filter(s => s.is_penalty)

    console.log('ServiceCreateEdit tf', isId(penaltyServiceId), selectedService, srvParamsForm.getFieldValue())
    return <Form
        layout={'vertical'} form={srvParamsForm} size={'small'}
        onFinish={onSubmitParams}
        name={'service'}
        initialValues={serviceDefaults}
    >
        <Form.Item name={'tarifs'} hidden/>
        <Form.Item name={'preferences'} hidden/>
        <Form.Item name={'commissions'} hidden/>
        <Card
            size={'small'} className={styles.noPaddingFormItem}
            title={<Row gutter={[8, 8]} className={styles.biggerHeader} align={'middle'}>
                <Col span={11}>
                    <Form.Item
                        name={'service_id'} label={title}
                        layout={'horizontal'}
                        className={styles.horizontalHeader}
                        rules={[{
                            required: true,
                            message: t('Обязательное поле')
                        }]}
                    >
                        <Select
                            size={'small'}
                            options={selectedService ? serviceOpts : serviceOpts.filter(i => !services.some(srv => srv.service_id === i.value))}
                            filterOption={filterOption}
                            style={{width: '100%'}}
                            showSearch
                            allowClear
                            disabled={selectedService}
                            value={serviceToAdd}
                            onSelect={(id) => setServiceToAdd(id)}
                            onClear={() => setServiceToAdd(null)}
                            ref={serviceRef}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={'is_paid_service'}
                        valuePropName={'checked'}
                        className={styles.horizontalHeader}
                    >
                        <Switch
                            checkedChildren="Платная"
                            unCheckedChildren="Бесплатная"
                        />
                    </Form.Item>
                </Col>
            </Row>}
        >
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        <Col span={11}>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <Card
                                        size={'small'}
                                        className={styles.innerCard}
                                    >
                                        <Row gutter={[8, 0]}>
                                            <Col span={2}>
                                                <Form.Item
                                                    name={'is_penalty_charge'}
                                                    label={t('Пеня')}
                                                    valuePropName={'checked'}
                                                >
                                                    <Switch
                                                        checkedChildren={t("вкл")}
                                                        unCheckedChildren={t("выкл")}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={3}>
                                                <Form.Item name={'is_penalty_priority'}
                                                           label={t('Приоритет')}
                                                           valuePropName={'checked'}
                                                >
                                                    <Switch
                                                        disabled={nonPenalty}
                                                        checkedChildren="пени"
                                                        unCheckedChildren="долга"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item name={'penalty_percent'}
                                                           label={t('%')}
                                                >
                                                    <InputNumber
                                                        style={{width: '100%'}}
                                                        decimalSeparator={','}
                                                        disabled={nonPenalty}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {/*<Col span={3}>*/}
                                            {/*    <Form.Item*/}
                                            {/*        name={'penalty_calculation_date'}*/}
                                            {/*        label={t('День')}*/}
                                            {/*        rules={[{*/}
                                            {/*            pattern: /^(0?[1-9]|[12][0-9]|30)$/,*/}
                                            {/*            message: 'Число от 1 до 30'*/}
                                            {/*        }]}*/}
                                            {/*    >*/}
                                            {/*        <Select*/}
                                            {/*            options={penaltyDateOpts}*/}
                                            {/*            filterOption={filterOption}*/}
                                            {/*            showSearch allowClear*/}
                                            {/*            disabled={nonPenalty}*/}
                                            {/*        />*/}
                                            {/*    </Form.Item>*/}
                                            {/*</Col>*/}
                                            <Col span={17}>
                                                <Form.Item
                                                    name={'penalty_service_id'}
                                                    label={t('Пеневая услуга')}
                                                >
                                                    <Select
                                                        size={'small'}
                                                        options={penaltyServiceOpts}
                                                        filterOption={filterOption}
                                                        style={{width: '100%'}}
                                                        showSearch allowClear
                                                        disabled={nonPenalty}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={t('Расчетный счет')}
                                                    name={'bank_account_id'}
                                                >
                                                    <Select
                                                        options={[...baOpts, {
                                                            label: 'Создать',
                                                            value: 'new',
                                                            emoji: true
                                                        }]}
                                                        filterOption={filterOption}
                                                        optionRender={optionRender}
                                                        showSearch
                                                        dropdownStyle={{minWidth: 'fit-content'}}
                                                        allowClear
                                                        // placeholder={t('Расчетный счет')}
                                                        onChange={(v) => {
                                                            if (v === 'new') {
                                                                openBaEmbedded(true)
                                                            } else {
                                                                openBaEmbedded(false)
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                                {embeddedOpen && <>
                                                    <Divider/>
                                                    <ProviderBankAccounts embedded={true}
                                                                          externalForm={srvParamsForm}/>
                                                </>}
                                                {/*{embeddedOpen ? <AddProviderBankAccounts*/}
                                                {/*    embedded={true}*/}
                                                {/*    externalForm={srvParamsForm}*/}
                                                {/*/> : null}*/}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={4}>
                            <Tarifs form={srvParamsForm}/>
                        </Col>
                        <Col span={9}>
                            <ActivitySubForm form={srvParamsForm} type={'2x2'}/>
                            <Card
                                size={'small'}
                                title={null/*t('Параметры')*/}
                                className={[styles.innerCard, styles.optParams]}
                            >
                                <Row gutter={[8, 0]}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'add_tariffs_on_import'}
                                            label={t('Создавать тарифы при загрузке')}
                                            tooltip={t(
                                                'Отключение создания тарифов при загрузке файла начислений ' +
                                                'может привести к ситуации, когда система будет присоединять к ЛС тариф со значением 0. ' +
                                                'Отключайте данный флаг только для поставщиков, у которых большое число уникальных тарифов, ' +
                                                'которые проводят расчеты самостоятельно и по которым Система не проводит расчетов начислений.'
                                            )}
                                        >
                                            <SaveTariffsSwitch/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Commissions form={srvParamsForm}/>
                        </Col>
                        <Col span={12}>
                            <Prefs form={srvParamsForm}/>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <ButtonGroup size={'small'}>
                        <Button type={'primary'} onClick={() => {
                            srvParamsForm.submit()
                            setTimeout(() => serviceRef.current.focus(), 10)
                        }}>
                            {selectedService ?
                                t('Применить изменения') : t('Добавить услугу')
                            }
                        </Button>
                        <Button
                            onClick={() => {
                                resetService()
                                srvParamsForm.resetFields()
                                setTimeout(() => serviceRef.current.focus(), 10)
                            }}
                            danger disabled={!selectedService}
                        >
                            {t('Отмена')}
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Card>
    </Form>
}

function Tarifs(props) {
    const {t} = useTranslation();
    const {form} = props
    const [value, setValue] = useState(null)
    const [serviceUnitId, setServiceUnitId] = useState(null)
    const [status, setStatus] = useState("")
    const [inactive, setInactive] = useState(false)
    const ref = useRef()
    const serUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)

    const formData = Form.useWatch('tarifs', form) || []
    const data = formData.filter(x => !!x.inactive === !!inactive)

    const del = (id) => {
        const newData = formData.filter(i => i.id !== id)
        form.setFieldValue('tarifs', newData)
        setTimeout(() => ref.current.focus(), 10)
    }

    const addTarif = () => {
        let newData
        if (value <= 0) {
            setStatus('error')
            return
        }

        const isPresent = formData.some(i => i.value === value && i.service_unit_id === serviceUnitId)
        if (isPresent) {
            // const isPresent = formData.some(i => i.value === value && !i.inactive)
            const isPresent = formData.some(i => i.value === value && i.service_unit_id === serviceUnitId && !i.inactive)
            if (isPresent) {
                setStatus('present')
                return
            }
            newData = formData.map(x => {
                if (x.value === value) x = {...x, inactive: false}
                // if (x.value === value && x.service_unit_id === serviceUnitId) x = {...x, inactive: false}
                return x
            })
        } else {

            const id = (formData.map(a => a.id).filter(a => a < 0).reduce(
                (a, b) => a < b ? a : b, 0
            ) || -1) - 1
            newData = [
                ...formData,
                {id, value, service_unit_id: serviceUnitId, inactive: false}
            ]
        }
        setValue(null)
        setServiceUnitId(null)
        setStatus('error')
        form.setFieldValue('tarifs', newData)
        setTimeout(() => {
            ref.current.focus()
        }, 10)
    }

    const change = (v) => {
        setValue(v)
        if (status !== '' && v > 0) setStatus('')
        if (status !== 'error' && (!v || v <= 0)) setStatus('error')
    }

    const changeUnits = (v) => {
        setServiceUnitId(v)
        if (status !== '' && v > 0) setStatus('')
        if (status !== 'error' && (!v || v <= 0)) setStatus('error')
    }

    const changeInactive = () => {
        setInactive(!inactive)
    }

    const validation = (status === 'present') ?
        <div style={{fontSize: '12px', color: 'red'}}>
            {t('Тариф существует')}
        </div> : (status === 'error' ?
                <div style={{fontSize: '12px', color: 'red'}}>
                    {t('Введите значение')}
                </div> : null
        )
    // noinspection JSUnusedGlobalSymbols
    const columns = [
        {
            title: t('Тариф'),
            dataIndex: 'value',
            width: 80,
            render: (_, r, _idx) => r.value,
            sortable: true,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.value - b.value,
        },
        {
            title: t('Ед. измер.'),
            dataIndex: 'service_unit_id',

            render: (_, r, _idx) => <MappedDictItem id={r.service_unit_id} type={'service-units'}
                                                    withID={false}/>,
            sortable: true,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.service_unit_id - b.service_unit_id,
        },
        {
            title: <Button icon={inactive ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                           onClick={changeInactive}/>,
            dataIndex: 'id',
            width: '32px',
            render: (id, r) => (id <= 0 && r.value !== 0) ?
                <Tooltip title={t('Удалить')}>
                    <Button icon={<DeleteOutlined/>} onClick={() => del(id)}/>
                </Tooltip> : null,
        },
    ]

    console.log('data', data)

    return <Row gutter={[8, 8]}>
        <Col span={24}>
            <Table
                className={styles.tarifTable}
                size={'small'}
                name={['tarifs']}
                columns={columns}
                bordered
                dataSource={data}
                rowKey={'id'}
                pagination={false}
                scroll={{scrollToFirstRowOnChange: true, y: 250}}
                summary={() => (
                    <Table.Summary fixed={'top'}>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} style={{verticalAlign: 'top'}}>
                                <InputNumber
                                    style={{width: '100%'}}
                                    name={'newTarifValue'} decimalSeparator={','} size={'small'}
                                    value={value}
                                    onChange={change}
                                    // onPressEnter={addTarif}
                                    status={status !== '' ? 'error' : ''}
                                    ref={ref}
                                />
                                {validation}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={0} style={{verticalAlign: 'top'}}>
                                <Select
                                    style={{width: '100%'}}
                                    options={serUnitOpts}
                                    onChange={changeUnits}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    value={serviceUnitId}
                                />
                                {validation}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <Tooltip title={validation} color={'white'}>
                                    <Button
                                        icon={<PlusOutlined/>} type={'primary'} onClick={addTarif}
                                        // disabled={!!validation}
                                        disabled={!(serviceUnitId !== null && value !== null)}
                                    />
                                </Tooltip>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
                // sticky={{
                //     offsetHeader: 64,
                // }}
            />
        </Col>
    </Row>
}

function Prefs(props) {
    const {t} = useTranslation();
    const {form} = props
    const [value, setValue] = useState(null)
    const ref = useRef()

    const formData = Form.useWatch('preferences', form) || []
    const data = (formData[0])?.id === 'x' ? formData : [{id: 'x'}, ...formData]

    const preferencesOpts = useStoreMap($dictsSelects, sel => sel.preference_category)
    const prefsIds = data?.map(i => i.preference_category_id) || []
    const preferencesOptsFiltered = preferencesOpts.filter(
        b => !prefsIds.includes(b.value)
    )

    const del = (id) => {
        const newData = (data || []).filter(i => i.id !== id)
        form.setFieldValue('preferences', newData)
    }

    const add = () => {
        if (!isId(value)) return
        const id = ((data || []).map(a => a.id).filter(a => a < 0).reduce(
            (a, b) => a < b ? a : b, 0
        ) || -1) - 1
        const newData = [
            ...(data || []),
            {id, preference_category_id: value, is_deleted: false}
        ]
        setValue(null)
        form.setFieldValue('preferences', newData)
        if (ref?.current) ref.current.focus()
    }

    const columns = [
        {
            title: t('Льгота'),
            dataIndex: 'preference_category_id',
            key: 'preference_category_id',
            render: (id, _, idx) => {
                if (idx > 0)
                    return <MappedDictItem id={id} type={'preference_category'}/>
                return <Select
                    options={preferencesOptsFiltered}
                    filterOption={filterOption}
                    showSearch
                    allowClear
                    value={value}
                    style={{width: '100%'}}
                    onChange={setValue}
                    ref={ref}
                />
            },
        },
        {
            width: '33px',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => {
                if (id < 0)
                    return <Tooltip title={t('Удалить')}>
                        <Button icon={<CloseOutlined/>} danger onClick={() => del(id)}/>
                    </Tooltip>

                if (id === 'x')
                    return <Button type={'primary'} onClick={add} icon={<PlusOutlined/>}/>
            },
        }
    ]

    return <Row gutter={[8, 8]}>
        <Col span={24}>
            <Table dataSource={data}
                   columns={columns}
                   bordered
                   className={'table-container'}
                   pagination={false}
                   size={'small'}
                   rowKey={'id'}
            />
        </Col>
    </Row>
}

function Commissions(props) {
    const {t} = useTranslation();
    const {form} = props
    const [bank_commission_id, setBankCommissionId] = useState(null)
    const [bank_id, setBankId] = useState(null)
    const ref = useRef()

    const {bankOpts, commOpts} = useStoreMap($dictsSelects, (i => ({
        bankOpts: i.bank,
        commOpts: i.bank_commission
    })))

    const formData = Form.useWatch('commissions', form) || []
    const data = (formData[0])?.id === 'x' ? formData : [{id: 'x'}, ...formData]
    const bankIds = data?.map(i => i.bank_id) || []
    const bankOptsFiltered = bankOpts.filter(
        b => b.is_contract_signed === true && !bankIds.includes(b.value)
    )

    const del = (id) => {
        const newData = (data || []).filter(i => i.id !== id)
        form.setFieldValue('commissions', newData)
    }

    const add = () => {
        if (!isId(bank_id) || !isId(bank_commission_id)) return
        const presentId = (data || []).find(c => c.bank_id === bank_id)?.id
        let newData = null
        if (isId(presentId)) {
            //update
        } else {
            const id = ((data || []).map(
                a => a.id
            ).filter(
                a => a < 0
            ).reduce(
                (a, b) => a < b ? a : b, 0
            ) || -1) - 1
            newData = [
                ...(data || []),
                {id, bank_id, bank_commission_id, is_deleted: false}
            ]
        }
        setBankCommissionId(null)
        setBankId(null)
        form.setFieldValue('commissions', newData)
        if (ref?.current) ref.current.focus()
    }

    const columns = [
        {
            title: t('Банк'),
            dataIndex: 'bank_id',
            render: (id, _, idx) => {
                if (idx > 0)
                    return <MappedDictItem id={id} type={'bank'}/>
                return <Select
                    options={bankOptsFiltered}
                    filterOption={filterOption}
                    style={{width: '100%'}}
                    value={bank_id}
                    ref={ref}
                    onChange={setBankId}
                    showSearch
                    allowClear
                    placeholder={t('Банк')}
                />
            },
        },
        {
            title: t('Комиссия'),
            width: '100px',
            dataIndex: 'bank_commission_id',
            render: (v, _, idx) => {
                if (idx !== 0)
                    return <MappedDictItem id={v} type={'bank_commission'} withID={false}/>
                return <Select
                    options={commOpts}
                    filterOption={filterOption}
                    style={{width: '100%'}}
                    value={bank_commission_id}
                    onChange={setBankCommissionId}
                    showSearch
                    allowClear
                    placeholder={t('Комиссия')}
                />
            },
        },
        {
            width: '33px',
            dataIndex: 'id',
            render: (id) => {
                if (id === 'x')
                    return <Button type={'primary'} onClick={add} icon={<PlusOutlined/>}/>
                return <Popconfirm
                    title={t('Вы уверены, что хотите удалить эту запись?')}
                    okText={t('Да')}
                    onConfirm={() => del(id)}
                    placement={'left'}
                >
                    <Tooltip title={t('Удалить')}>
                        <Button icon={<CloseOutlined/>} danger/>
                    </Tooltip>
                </Popconfirm>
            },
        }
    ]

    return <Row gutter={[8, 8]}>
        <Col span={24}>
            <Table dataSource={data}
                   columns={columns}
                   bordered
                   className={'table-container'}
                   pagination={false}
                   size={'small'}
                   rowKey={'id'}
            />
        </Col>
    </Row>
}
