import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';

export default function TableActions({items}) {

    return <div className={'dictionaryGroupButtons'} style={{display: 'flex', width: '100%', gap: 16, margin: '20px 0'}}>
            {items.filter(item => item.hide !== true).map(i => <Tooltip
                key={i.key} title={i.tip ?? null} placement={'bottom'}
            >
                <Button icon={i.icon}
                        onClick={i.action}
                        href={i.href}
                        type={i.btnType ?? 'default'}
                        danger={i.danger ?? false}
                        size={'small'}
                >
                    {i.label}
                </Button>
            </Tooltip>)}
        </div>
}
