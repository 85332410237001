import {createStore} from 'effector';


export const $correctionList = createStore({data: [], count: 0})

export const $selectedCorrection = createStore(null)

export const $createdCorrectionId = createStore(null)
export const $afterCreateAction = createStore(null)

export const $finalStatus = createStore(null)

export const $redirectPath = createStore(null)
export const $finalAction = createStore(null)
