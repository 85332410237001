import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getAccountsListFx = createEffect()
    .use(async (filters) => (await api().get(`/accounts/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getAccountByIdFx = createEffect()
    .use(async (id) => (await api().get(`/accounts/${id}`)).data)

export const getAccountReEsObjFx = createEffect()
    .use(async (id) => (await api().get(`/real_estate_objects/${id}`)).data)

export const getProviderServicesFx = createEffect()
    .use(async (id) => (await api().get(`/provider_service/?account_id=${id}&ignore_limit=true`)).data)

export const getProviderSrvAccsFx = createEffect()
    .use(async (id) => (await api().get(`/account_provider_service/?account_id=${id}&ignore_limit=true`)).data)

export const getAccrualsListDataFx = createEffect()
    .use(async ({id, filters}) => (await api().get(`/accruals/?account_id=${id}${filters.length > 0 ? `&${filters}` : ''}`)).data)

export const getPaymentsDataFx = createEffect()
    .use(async ({id, filters}) => (await api().get(`/payments/?account_id=${id}${filters.length > 0 ? `&${filters}` : ''}`)).data)

export const getPaymentsElementsFx = createEffect()
    .use(async ({id, filters}) => {
        return (await api().get(`/payment_elements/?account_id=${id}`)).data
    })

export const getProviderServiceFx = createEffect()
    .use(async (payload) => {
        return (await api().get(`/account_provider_service/?${payload}`)).data
    })
