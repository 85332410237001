import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const CorrectionListGate = createGate()
export const CorrectionViewGate = createGate()

export const createCorrectionEv = createEvent()
export const cancelCorrectionEv = createEvent()
export const resetCreatedCorrectionEv = createEvent()

export const deleteCorrectionEv = createEvent()

export const finishCorrectionEv = createEvent()
export const resetFinalStatusEv = createEvent()

export const setFinalActionEv = createEvent()
export const resetFinalActionEv = createEvent()
