import {
    $createdProviderId,
    $providerBankAccounts,
    $providerDefaultBankAccount,
    $providerServices,
    $providersList,
    $selectedProvider,
    $selectedProvService,
    $afterCreateAction,
    $deletedProviderServices,
    $providerServTariffs,
    $provServTariffsInit,
    $deletedServTariffs,
    $providerServPrefs,
    $deletedServPrefs,
    $provServPrefsInit,
    $selectedServPref,
    $providerServComs,
    $deletedServComs,
    $provServComsInit,
    $selectedServCom,
    $providerServicesInit,
    $providerServBaREOs,
    $provServBaREOsInit,
    $selectedServBaREO,
    $deletedServBaREOs,
    $providerServAccs,
    $provServAccsInit,
    $deletedServAccs,
    $selectedServAcc,
    $accountsList,
    $accountsFilters,
    $selectedAccounts,
    $newProviderServAccs,
    $providerServAccsTarifs,
    $provServAccsTarifsInit,
    $provServAccsForTarifs,
    $selectedServAccTarif,
    $provServAccsFilters,
    $deletedServAccsTarifs,
    $filteredServAccs,
    $selectedSrvAccs,
    $providerDefaultBankAccountInit,
    $providerEditStatus,
    $selectedBeneficiary,
    $providerBankAccountsInit,
    $selectedBankAccount,
    $deletedBankAccounts,
    $providerAgreements,
    $providerAgreementsInit,
    $selectedAgreement,
    $provAgreementsFiles,
    $deletedAgreements, $embeddedBaForm, $afterEditAction, $embeddedTariffForm
} from './stores.js';
import {
    getProvidersListFx,
    getProviderByIdFx,
    getProviderBankAccountsFx,
    getProviderServicesFx,
    getProviderDefaultBankAccountFx,
    getProviderSrvPrefFx,
    getProviderSrvComsFx,
    getProviderSrvBaREOsFx,
    getProviderSrvAccsFx,
    getAccountsFx,
    getProviderSrvAccsTarifsFx,
    getProviderSrvAccsForTarifsFx,
    getProviderAgreementsFx, downloadAgreementFileFx, uploadAgreementFileFx
} from './effects.js';
import {
    addProvBankAccountEv,
    addProvServiceEv,
    applySrvParamsEv,
    deleteProviderEv,
    ProviderCreateGate,
    ProviderEditGate,
    ProviderListGate,
    ProviderViewGate,
    resetSelectedProvSrvEv,
    selectProvServiceEv,
    setDefaultBankAccountEv,
    submitCreateProviderEv,
    submitEditProviderEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    deleteSrvEv,
    deleteServPrefEv,
    applyServPrefEv,
    addServPrefEv,
    selectServPrefEv,
    resetServPrefEv,
    deleteServComEv,
    applyServComEv,
    addServComEv,
    selectServComEv,
    resetServComEv,
    resetServBaREOEv,
    deleteServBaREOEv,
    applyServBaREOEv,
    addServBaREOEv,
    selectServBaREOEv,
    deleteServAccEv,
    applyServAccEv,
    addServAccEv,
    selectServAccEv,
    resetServAccEv,
    resetAccountsEv,
    setAccountsFiltersEv,
    selectMultipleAccountsEv,
    getAccountsEv,
    resetServAccTarifEv,
    resetSrvAccsForTarifsEv,
    setSrvAccForTarifsFilterEv,
    applySrvAccForTarifsFiltersEv,
    selectServAccTarifEv,
    applyServAccTarifEv,
    addServAccTarifEv,
    deleteServAccTarifEv,
    selectMultipleSrvAccsForTarifsEv,
    setBeneficiaryEv,
    resetProvBankAccountsEv,
    selectProvBankAccountEv,
    applySrvBankAccountEv,
    deleteProvBankAccountEv,
    deleteProvAgreementEv,
    applyProvAgreementEv,
    selectProvAgreementEv,
    addProvAgreementEv,
    downloadProvAgreementFileEv,
    setBaEmbeddedFormEv,
    resetLinksOnBenefChangeEv,
    setAfterEditActionEv, resetAfterEditAttrs, setEmbeddedTariffFormEv, addServTariffEv
} from './events.js';
import {sample} from 'effector';
import {combineEvents} from 'patronum';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import filterSrvAccs, {
    addNewSrvAcc,
    addNewSrvAccTarifs,
    addNewSrvREO, cleanUpPayload,
    formatPayload,
    prepareSrv
} from './utils.js';
import {isId} from '../../utils/helpers.js';
import queryString from 'query-string';
import {$foundReos, $multiSelectedReos} from '../accountsREOSearchModel/index.js';

const createProviderCompleteEv = combineEvents({events: {ev: submitCreateProviderEv, response: triggerSuccessOpsEv}, reset: ProviderCreateGate.state})
const editProviderCompleteEv = combineEvents({ev: submitEditProviderEv, response: triggerSuccessOpsEv})
const deleteProviderCompleteEv = combineEvents({ev: deleteProviderEv, response: triggerSuccessOpsEv})

$providersList.on(getProvidersListFx.doneData, (_, payload) => payload)
    .reset(ProviderListGate.close)

$selectedProvider.on(getProviderByIdFx.doneData, (_, provider) => provider)
    .reset([ProviderEditGate.close, ProviderViewGate.close])

$selectedBeneficiary.on(setBeneficiaryEv, (_, beneficiaryId) => beneficiaryId)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, resetAfterCreationAttrs, ProviderViewGate.close])

$providerEditStatus.on(editProviderCompleteEv, () => true)
    .reset([ProviderEditGate.close, resetAfterEditAttrs])

$createdProviderId.on(createProviderCompleteEv, (_, {response}) => {
    const prov = response.find(i => i.type === 'providers')
    return prov ? prov?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$afterEditAction.on(setAfterEditActionEv, (_, action) => action)
    .reset(resetAfterEditAttrs)

// Bank accounts
// FIXME NO
$providerBankAccounts
    .on(getProviderBankAccountsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, resetProvBankAccountsEv])

$providerBankAccountsInit
    .on(getProviderBankAccountsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$providerDefaultBankAccount.on(getProviderDefaultBankAccountFx.doneData, (_, payload) => payload.data[0])
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, resetProvBankAccountsEv])

$providerDefaultBankAccountInit.on(getProviderDefaultBankAccountFx.doneData, (_, payload) => payload.data[0])
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$selectedBankAccount.reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$embeddedBaForm.on(setBaEmbeddedFormEv, (_, payload) => payload)
    .reset([$providerBankAccounts.updates])

sample({
    source: $providerBankAccounts,
    clock: setDefaultBankAccountEv,
    fn: (accounts, id) => ({...accounts.find(i => i.id === id), is_new_default: true, bank_account_id: id}),
    target: $providerDefaultBankAccount
})

sample({
    clock: $providerBankAccounts.updates,
    filter: (bankAccs) => bankAccs.some(i => i.is_new_default === true),
    fn: (bankAccs) => {
        const newDefault = bankAccs.find(i => i.is_new_default === true)
        newDefault['bank_account_id'] = newDefault['id']
        return newDefault
    },
    target: $providerDefaultBankAccount
})

sample({
    clock: $selectedBeneficiary.updates,
    filter: (id) => id !== null && id !== undefined,
    fn: (id) => id,
    target: getProviderBankAccountsFx
})

sample({
    source: {provider: $selectedProvider, bankAccs: $providerBankAccounts},
    clock: addProvBankAccountEv,
    fn: ({provider, bankAccs}, payload) => {
        const newState = [...bankAccs]
        if (payload.is_new_default === true) {
            newState.forEach(i => {
                if (Object.hasOwn('is_new_default', i)) {
                    i.is_new_default = false
                }
            })
        }
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.find(i => i.id < 0)
            newState.unshift({...payload, id: lastNew.id - 1, provider_id: provider?.id ?? -1})
        } else {
            newState.unshift({...payload, id: -1, provider_id: provider?.id ?? -1})
        }
        return newState
    },
    target: $providerBankAccounts
})

sample({
    source: $providerBankAccounts,
    clock: selectProvBankAccountEv,
    fn: (ba, id) => id !== null ? ba?.find(s => s.id === id) : null,
    target: $selectedBankAccount
})

sample({
    source: $providerBankAccounts,
    clock: deleteProvBankAccountEv,
    fn: (tarifs, id) => {
        const newState = [...tarifs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerBankAccounts
})

sample({
    source: {init: $providerBankAccountsInit, deleted: $deletedBankAccounts},
    clock: $providerBankAccounts.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedBankAccounts
})

sample({
    source: $providerBankAccounts,
    clock: applySrvBankAccountEv,
    fn: (ba, payload) => {
        const newState = [...ba]
        const idx = newState.findIndex(i => i.id === payload.id)
        newState[idx] = {...newState[idx], ...payload, updated: payload.id > 0}
        return newState
    },
    target: $providerBankAccounts
})

// Provider - Agreements handlers
$providerAgreements.on(getProviderAgreementsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, ProviderViewGate.close])

$providerAgreementsInit.on(getProviderAgreementsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, ProviderViewGate.close])

$provAgreementsFiles
    .on(createProviderCompleteEv, (state, payload) => {
        const createdAgreements = payload.response.filter(i => i.type === 'agreement' && i.operation !== 'delete')
        if (createdAgreements.length > 0) {
            return state.map(file => ({file: file.file, number: file.number, agreement_id: createdAgreements.find(a => a.value.number === file.number)?.value?.id}))
        } else return undefined
    })
    .on(editProviderCompleteEv, (state, payload) => {
        const createdAgreements = payload.response.filter(i => i.type === 'agreement' && i.operation !== 'delete')
        if (createdAgreements.length > 0) {
            return state.map(file => ({file: file.file, number: file.number, agreement_id: createdAgreements.find(a => a.value.number === file.number)?.value?.id}))
        } else return undefined
    })
    .reset([uploadAgreementFileFx.doneData, ProviderEditGate.close, ProviderCreateGate.close])

$selectedAgreement.reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

sample({
    clock: downloadProvAgreementFileEv,
    target: downloadAgreementFileFx
})

sample({
    source: $providerAgreements,
    clock: deleteProvAgreementEv,
    fn: (agreements, id) => {
        const newState = [...agreements]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerAgreements
})

sample({
    source: {init: $providerAgreementsInit, deleted: $deletedAgreements},
    clock: $providerAgreements.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedAgreements
})

sample({
    source: $providerAgreements,
    clock: selectProvAgreementEv,
    fn: (agreements, id) => agreements.find(s => s.id === id),
    target: $selectedAgreement
})

sample({
    source: {agreements: $providerAgreements, provider: $selectedProvider},
    clock: addProvAgreementEv,
    fn: ({agreements, provider}, payload) => {
        const prep = {...payload}
        delete prep.file

        const newState = [...agreements]
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.find(i => i.id < 0)
            newState.unshift({...formatPayload(prep), provider_id: provider?.id ?? -1, id: lastNew.id - 1})
        } else {
            newState.unshift({...formatPayload(prep), provider_id: provider?.id ?? -1, id: -1})
        }
        return newState
    },
    target: $providerAgreements
})

sample({
    source: $provAgreementsFiles,
    clock: addProvAgreementEv,
    filter: (_, payload) => !!payload.file.length > 0,
    fn: (files, payload) => {
        return [...files, {number: payload.number, file: payload.file[0]}]
    },
    target: $provAgreementsFiles
})

sample({
    source: $providerAgreements,
    clock: applyProvAgreementEv,
    fn: (agreements, payload) => {
        const prep = {...payload}
        delete prep.file
        const newState = [...agreements]
        const idx = newState.findIndex(i => i.id === prep.id)
        newState[idx] = {...newState[idx], ...prep, updated: prep.id > 0}
        return newState
    },
    target: $providerAgreements
})

sample({
    source: {files: $provAgreementsFiles, selectedAgr: $selectedAgreement},
    clock: applyProvAgreementEv,
    filter: (_, payload) => !!payload.file,
    fn: ({files, selectedAgr}, payload) => {
        const idx = files.findIndex(i => i.number === payload.number)
        if (idx >= 0) {
            files.splice(idx, 1)
        }
        return [...files, {number: payload.number, file: payload.file, agreement_id: selectedAgr?.id > 0 ? selectedAgr?.id : undefined}]
    },
    target: $provAgreementsFiles
})

sample({
    source: {files: $provAgreementsFiles, agreements: $providerAgreements},
    clock: $providerAgreements.updates,
    fn: ({files, agreements}) => {
        const checkedFiles = [...files]
        for (const value of Object.values(agreements)) {
            if (files.findIndex(i => i.number === value.number) < 0) {
                const idx = checkedFiles.findIndex(i => i.number === value.number)
                if (idx >= 0) {
                    checkedFiles.splice(idx, 1)
                } else continue
            }
        }
        return checkedFiles
    },
    target: $provAgreementsFiles
})

sample({
    source: $provAgreementsFiles,
    clock: [createProviderCompleteEv, editProviderCompleteEv],
    filter: (files) => files.some(i => !!i.file && !!i?.agreement_id),
    fn: (files) => files.filter(i => !!i.file),
    target: uploadAgreementFileFx
})

// Provider - Services handlers
$providerServices
    .on(getProviderServicesFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$providerServicesInit
    .on(getProviderServicesFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$selectedProvService.reset([$providerServices.updates, resetSelectedProvSrvEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

sample({
    source: $providerServices,
    clock: selectProvServiceEv,
    fn: (services, id) => services.find(s => s.service_id === id),
    target: $selectedProvService
})

sample({
    source: {provider: $selectedProvider, services: $providerServices},
    clock: addProvServiceEv,
    fn: ({provider, services}, payload) => {
        const newState = [...services]
        const checked = prepareSrv(payload)
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.findLast(i => i.id < 0)
            newState.push({...checked, id: lastNew.id - 1, provider_id: provider?.id ?? -1})
        } else {
            newState.push({...checked, id: -1, provider_id: provider?.id ?? -1})
        }
        return newState
    },
    target: $providerServices
})

sample({
    source: {all: $providerServices, selected: $selectedProvService},
    clock: applySrvParamsEv,
    fn: ({all, selected}, payload) => {
        const checked = prepareSrv(payload)
        const newState = [...all]
        const idx = newState.findIndex(i => i.id === selected.id)
        newState[idx] = {...newState[idx], ...checked, updated: selected.id > 0}
        return newState
    },
    target: $providerServices
})

sample({
    source: $providerServices,
    clock: deleteSrvEv,
    fn: (services, id) => {
        const newState = [...services]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerServices
})

sample({
    source: {existing: $providerServices, deleted: $deletedProviderServices},
    clock: deleteSrvEv,
    filter: (_, id) => id > 0,
    fn: ({existing, deleted}, id) => {
        return [...deleted, id]
    },
    target: $deletedProviderServices
})
// Проверка на удаленные тарифы из сущесвтующих услуг
// sample({
//     source: {delTars: $deletedServTariffs, init: $providerServicesInit},
//     clock: $providerServices.updates,
//     fn: ({delTars, init}, updated) => {
//         const deletedTarifs = [...delTars]
//         for (const exist of init) {
//             const upd = updated.find(s => s.id === exist.id)
//             if (upd) {
//                 for (const tarif of exist.tarifs) {
//                     if (tarif.id > 0 && !upd.tarifs.some(t => t.id === tarif.id)) {
//                         deletedTarifs.push(tarif.id)
//                     }
//                 }
//             }
//         }
//         return deletedTarifs
//     },
//     target: $deletedServTariffs
// })

// Provider - Services - Tariffs handlers
sample({
    source: $providerServices,
    clock: addServTariffEv,
    fn: (services, {srv_id, payload}) => {
        const newState = [...services]
        const srvIdx = newState.findIndex(i => i.id === srv_id)

        if (srvIdx !== -1) {
            if (newState[srvIdx]?.tarifs.some(i => i.id < 0)) {
                const lastNew = newState[srvIdx]?.tarifs.findLast(i => i.id < 0)
                newState[srvIdx].tarifs.push({...payload, id: lastNew.id - 1})
            } else {
                newState[srvIdx].tarifs.push({...payload, id: -1})
            }
        }
        return newState
    },
    target: $providerServices
})
// $providerServTariffs
//     .on(getProviderSrvTarifsFx.doneData, (_, payload) => payload.data)
//     .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, resetLinksOnBenefChangeEv])
//
// $provServTariffsInit
//     .on(getProviderSrvTarifsFx.doneData, (_, payload) => payload.data)
//     .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetLinksOnBenefChangeEv])
//
// $selectedServTariff.reset([$providerServTariffs.updates, resetServTariffEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])
//
// sample({
//     source: $providerServTariffs,
//     clock: selectServTariffEv,
//     fn: (services, id) => services.find(s => s.id === id),
//     target: $selectedServTariff
// })
//
// sample({
//     source: $providerServTariffs,
//     clock: $providerServices.updates,
//     fn: (tarifs, services) => {
//         const updatedTarifs = [...tarifs]
//         const srvIds = services.map(i => i.id)
//         if (!srvIds.every(i => tarifs.some(t => t.provider_service_id === i))) {
//             const newSrv = services.find(i => !tarifs.some(t => t.provider_service_id === i.id))
//             const lastNew = tarifs.find(i => i.id < 0)
//             updatedTarifs.unshift({provider_service_id: newSrv.id, value: 0, id: lastNew ? lastNew.id - 1 : -1})
//         } else if (srvIds.some(i => !tarifs.some(t => t.provider_service_id === i))) {
//             const deletedSrvTarif = tarifs.findIndex(i => !services.some(s => s.id === i.provider_service_id))
//             updatedTarifs.splice(deletedSrvTarif, 1)
//         }
//
//         return updatedTarifs
//     },
//     target: $providerServTariffs
// })
//
// sample({
//     source: {init: $provServTariffsInit, deleted: $deletedServTariffs},
//     clock: $providerServTariffs.updates,
//     filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
//     fn: ({init, deleted}, current) => {
//         const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
//         return [...deleted, ...del]
//     },
//     target: $deletedServTariffs
// })
//
// sample({
//     source: $providerServTariffs,
//     clock: addServTariffEv,
//     fn: (tarifs, payload) => {
//         const newState = [...tarifs]
//         if (newState.some(i => i.id < 0)) {
//             const lastNew = newState.find(i => i.id < 0)
//             newState.unshift({...payload, id: lastNew.id - 1})
//         } else {
//             newState.unshift({...payload, id: -1})
//         }
//         return newState
//     },
//     target: $providerServTariffs
// })
//
// sample({
//     source: $providerServTariffs,
//     clock: applyServTariffEv,
//     fn: (tarifs, payload) => {
//         const newState = [...tarifs]
//         const idx = newState.findIndex(i => i.id === payload.id)
//         newState[idx] = {...newState[idx], ...payload, updated: payload.id > 0}
//         return newState
//     },
//     target: $providerServTariffs
// })
//
// sample({
//     source: $providerServTariffs,
//     clock: deleteServTariffEv,
//     fn: (tarifs, id) => {
//         const newState = [...tarifs]
//         const idx = newState.findIndex(i => i.id === id)
//         newState.splice(idx, 1)
//         return newState
//     },
//     target: $providerServTariffs
// })

// Provider - Service - Preferences handlers
$providerServPrefs
    .on(getProviderSrvPrefFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$provServPrefsInit
    .on(getProviderSrvPrefFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$selectedServPref.reset([$providerServPrefs.updates, resetServPrefEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

sample({
    source: $providerServPrefs,
    clock: selectServPrefEv,
    fn: (services, id) => services.find(s => s.id === id),
    target: $selectedServPref
})

sample({
    source: {init: $provServPrefsInit, deleted: $deletedServPrefs},
    clock: $providerServPrefs.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedServPrefs
})

sample({
    source: $providerServPrefs,
    clock: addServPrefEv,
    fn: (prefs, payload) => {
        const newState = [...prefs]
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.find(i => i.id < 0)
            newState.unshift({...payload, id: lastNew.id - 1})
        } else {
            newState.unshift({...payload, id: -1})
        }
        return newState
    },
    target: $providerServPrefs
})

sample({
    source: $providerServPrefs,
    clock: applyServPrefEv,
    fn: (prefs, payload) => {
        const newState = [...prefs]
        const idx = newState.findIndex(i => i.id === payload.id)
        newState[idx] = {...newState[idx], ...payload, updated: payload.id > 0}
        return newState
    },
    target: $providerServPrefs
})

sample({
    source: $providerServPrefs,
    clock: deleteServPrefEv,
    fn: (prefs, id) => {
        const newState = [...prefs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerServPrefs
})

// Provider - Service - Bank Commission handlers
$providerServComs
    .on(getProviderSrvComsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$provServComsInit
    .on(getProviderSrvComsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$selectedServCom.reset([$providerServComs.updates, resetServComEv])

sample({
    source: $providerServComs,
    clock: selectServComEv,
    fn: (services, id) => services.find(s => s.id === id),
    target: $selectedServCom
})

sample({
    source: {init: $provServComsInit, deleted: $deletedServComs},
    clock: $providerServComs.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedServComs
})

sample({
    source: $providerServComs,
    clock: addServComEv,
    fn: (comms, payload) => {
        const newState = [...comms]
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.find(i => i.id < 0)
            newState.unshift({...payload, id: lastNew.id - 1})
        } else {
            newState.unshift({...payload, id: -1})
        }
        return newState
    },
    target: $providerServComs
})

sample({
    source: $providerServComs,
    clock: applyServComEv,
    fn: (comms, payload) => {
        const newState = [...comms]
        const idx = newState.findIndex(i => i.id === payload.id)
        newState[idx] = {...newState[idx], ...payload, updated: payload.id > 0}
        return newState
    },
    target: $providerServComs
})

sample({
    source: $providerServComs,
    clock: deleteServComEv,
    fn: (comms, id) => {
        const newState = [...comms]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerServComs
})

// Provider - Service - REO - BA handlers
$providerServBaREOs
    .on(getProviderSrvBaREOsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$provServBaREOsInit
    .on(getProviderSrvBaREOsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$selectedServBaREO.reset([$providerServBaREOs.updates, resetServBaREOEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

sample({
    source: $providerServBaREOs,
    clock: selectServBaREOEv,
    fn: (bareos, id) => bareos.find(s => s.id === id),
    target: $selectedServBaREO
})

sample({
    source: {init: $provServBaREOsInit, deleted: $deletedServBaREOs},
    clock: $providerServBaREOs.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedServBaREOs
})

sample({
    source: {existing: $providerServBaREOs, reos: $multiSelectedReos, allReos: $foundReos},
    clock: addServBaREOEv,
    fn: ({existing, reos, allReos}, {type, payload}) => addNewSrvREO(existing, reos, allReos, type, payload),
    target: $providerServBaREOs
})

sample({
    source: $providerServBaREOs,
    clock: applyServBaREOEv,
    fn: (bareos, payload) => {
        const newState = [...bareos]
        const idx = newState.findIndex(i => i.id === payload.id)
        newState[idx] = {...newState[idx], ...payload, updated: payload.id > 0}
        return newState
    },
    target: $providerServBaREOs
})

sample({
    source: $providerServBaREOs,
    clock: deleteServBaREOEv,
    fn: (tarifs, id) => {
        const newState = [...tarifs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerServBaREOs
})

// Provider - Service - Acc - handlers
$accountsList.on(getAccountsFx.doneData, (_, payload) => payload.data)
    .reset([resetAccountsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$accountsFilters.on(setAccountsFiltersEv, (state, change) => ({...state, ...change}))
    .reset([resetAccountsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$selectedAccounts.on(selectMultipleAccountsEv, (_, ids) => ids)
    .reset([resetAccountsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$providerServAccs
    .on(getProviderSrvAccsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$provServAccsInit
    .on(getProviderSrvAccsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$newProviderServAccs.on($providerServAccs.updates, (_, payload) => payload.filter(({id}) => id < 0))
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$selectedServAcc.reset([$providerServAccs.updates, resetServAccEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

sample({
    source: $accountsFilters.updates,
    clock: getAccountsEv,
    filter: (filt) => {
        return (isId(filt?.town_id) && isId(filt?.street_id)) || filt?.account_in || filt?.iin_name
    },
    // fn: (filters) => queryString.stringify(filters, {skipEmptyString: true, skipNull: true}),
    fn: (filters) => {
        const newFilters = {
            ...filters,
            town_id: filters.town_id === -1 ? 0 : filters.town_id
        }
        return queryString.stringify(newFilters, {skipEmptyString: true, skipNull: true})
    },
    target: getAccountsFx
})

sample({
    source: $providerServAccs,
    clock: selectServAccEv,
    fn: (accounts, id) => accounts.find(s => s.id === id),
    target: $selectedServAcc
})

sample({
    source: {init: $provServAccsInit, deleted: $deletedServAccs},
    clock: $providerServAccs.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedServAccs
})

sample({
    source: {existing: $providerServAccs, selAccs: $selectedAccounts, allAccs: $accountsList},
    clock: addServAccEv,
    fn: ({existing, selAccs, allAccs}, {type, payload}) => addNewSrvAcc(existing, selAccs, allAccs, type, payload),
    target: $providerServAccs
})

sample({
    source: $providerServAccs,
    clock: applyServAccEv,
    fn: (accounts, payload) => {
        const prep = formatPayload(payload.payload)
        const newState = [...accounts]
        const idx = newState.findIndex(i => i.id === payload.id)
        newState[idx] = {...newState[idx], ...prep, updated: payload.id > 0}
        return newState
    },
    target: $providerServAccs
})

sample({
    source: $providerServAccs,
    clock: deleteServAccEv,
    fn: (tarifs, id) => {
        const newState = [...tarifs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerServAccs
})

// Provider - Service - Acc - Tariff - handlers
$providerServAccsTarifs
    .on(getProviderSrvAccsTarifsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$provServAccsTarifsInit
    .on(getProviderSrvAccsTarifsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$provServAccsForTarifs.on(getProviderSrvAccsForTarifsFx.doneData, (_, payload) => payload.data)
    .reset([resetSrvAccsForTarifsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$selectedServAccTarif.reset([$providerServAccsTarifs.updates, resetServAccTarifEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$provServAccsFilters.on(setSrvAccForTarifsFilterEv, (state, change) => ({...state, ...change}))
    .reset([resetSrvAccsForTarifsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$selectedSrvAccs.on(selectMultipleSrvAccsForTarifsEv, (_, payload) => payload)
    .reset([resetSrvAccsForTarifsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$embeddedTariffForm.on(setEmbeddedTariffFormEv, (_, payload) => payload)
    .reset([$providerServices.updates])

sample({
    source: {existing: $provServAccsForTarifs, newAccs: $newProviderServAccs, filters: $provServAccsFilters},
    clock: [applySrvAccForTarifsFiltersEv, $provServAccsForTarifs.updates],
    fn: ({existing, newAccs, filters}) => {
        console.log('AST fn [applySrvAccForTarifsFiltersEv, $provServAccsForTarifs.updates]', existing, newAccs, filters)
        return [...filterSrvAccs(newAccs, filters), ...existing]
    },
    target: $filteredServAccs
})

sample({
    source: {provider: $selectedProvider, filters: $provServAccsFilters},
    clock: applySrvAccForTarifsFiltersEv,
    filter: ({provider, filters}) => {
        return provider?.id >=0 && isId(filters?.provider_service_id) &&
            ((isId(filters?.town_id) && isId(filters?.street_id)) || filters?.account_in || filters?.iin_name)
    },
    fn: ({provider, filters}) => {
        const query = queryString.stringify(filters, {skipEmptyString: true, skipNull: true})
        console.log('AST fn applySrvAccForTarifsFiltersEv', filters, query)
        return {id: provider.id, filters: query}
    },
    target: getProviderSrvAccsForTarifsFx
})

sample({
    source: $providerServAccsTarifs,
    clock: selectServAccTarifEv,
    fn: (accounts, id) => accounts.find(s => s.id === id),
    target: $selectedServAccTarif
})

sample({
    source: {init: $provServAccsTarifsInit, deleted: $deletedServAccsTarifs},
    clock: $providerServAccsTarifs.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedServAccsTarifs
})

sample({
    source: {existing: $providerServAccsTarifs, selSrvAccs: $selectedSrvAccs, allSrvAccs: $filteredServAccs},
    clock: addServAccTarifEv,
    fn: ({existing, selSrvAccs, allSrvAccs}, {type, payload}) => addNewSrvAccTarifs(existing, selSrvAccs, allSrvAccs, type, payload),
    target: $providerServAccsTarifs
})

sample({
    source: $providerServAccsTarifs,
    clock: applyServAccTarifEv,
    fn: (accounts, payload) => {
        const prep = formatPayload(payload.payload)
        const newState = [...accounts]
        const idx = newState.findIndex(i => i.id === payload.id)
        newState[idx] = {...newState[idx], ...prep, updated: payload.id > 0}
        return newState
    },
    target: $providerServAccsTarifs
})

sample({
    source: $providerServAccsTarifs,
    clock: deleteServAccTarifEv,
    fn: (tarifs, id) => {
        const newState = [...tarifs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerServAccsTarifs
})

// General samples
sample({
    clock: [ProviderEditGate.state, ProviderViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: [
        getProviderByIdFx,
        getProviderServicesFx,
        getProviderBankAccountsFx,
        getProviderDefaultBankAccountFx,
        getProviderAgreementsFx,
        // getProviderSrvTarifsFx,
        getProviderSrvPrefFx,
        getProviderSrvComsFx,
        getProviderSrvBaREOsFx,
        getProviderSrvAccsFx,
        getProviderSrvAccsTarifsFx
    ]
})

sample({
    source: ProviderListGate.state,
    clock: [ProviderListGate.state.updates, deleteProviderCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getProvidersListFx
})

sample({
    clock: deleteProviderEv,
    fn: (id) => [{operation: 'delete', type: 'provider', value: {id}}],
    target: submitGlobalUpdateEv
})

sample({
    source: {
        services: $providerServices,
        bankAcs: $providerBankAccounts,
        defaultBA: $providerDefaultBankAccount,
        agreements: $providerAgreements,
        tarifs: $providerServTariffs,
        bareos: $providerServBaREOs,
        prefs: $providerServPrefs,
        coms: $providerServComs,
        accs: $providerServAccs,
        newAccs: $newProviderServAccs,
        accTarifs: $providerServAccsTarifs,
    },
    clock: submitCreateProviderEv,
    fn: ({services, bankAcs, defaultBA, agreements, tarifs, bareos, prefs, coms, accs, newAccs, accTarifs}, provider) => {
        const actions = []
        let idCounter = -1

        const initBAIds = Object.fromEntries(bankAcs.map(i => [i.id, null]))
        const initSrvIds = Object.fromEntries(services.map(i => [i.id, null]))
        const initTarifsIds = Object.fromEntries(tarifs.map(i => [i.id, null]))
        const initAccsIds = Object.fromEntries(newAccs.map(i => [i.id, null]))

        const prepProvider = formatPayload(provider)

        actions.push({operation: 'create', type: 'providers', value: {...prepProvider, id: idCounter}})
        idCounter--
        console.log('create handle', provider)

        if (bankAcs.length > 0) {
            for (const bankAcc of bankAcs) {
                actions.push({operation: 'create', type: 'bank_account', value: {...cleanUpPayload('bank_account', bankAcc), id: idCounter}})
                initBAIds[bankAcc.id] = idCounter
                idCounter--
                if (bankAcc?.id === defaultBA?.id) {
                    actions.push({operation: 'create', type: 'bank_account_default', value: {provider_id: -1, bank_account_id: initBAIds[bankAcc.id], id: idCounter}})
                    idCounter--
                }
            }
        }

        if (agreements.length > 0) {
            for (const agreement of agreements) {
                actions.push({operation: 'create', type: 'agreement', value: {...agreement, id: idCounter}})
                idCounter--
            }
        }

        tarifs = services.map(
            s => (s.tarifs || []).map(
                t => ({...t, provider_service_id: s.id})
            )
        ).flat()
        coms = services.map(
            s => (s.commissions || []).map(
                t => ({...t, provider_service_id: s.id})
            )
        ).flat().filter(o => o.id !== 'x')
        prefs = services.map(
            s => (s.preferences || []).map(
                t => ({...t, provider_service_id: s.id})
            )
        ).flat().filter(o => o.id !== 'x')

        if (services.length > 0) {
            for (let service of services) {
                service = {...service}
                delete service.tarifs
                delete service.commissions
                delete service.preferences

                actions.push({
                    operation: 'create',
                    type: 'provider_service',
                    value: {
                        ...service,
                        bank_account_id: initBAIds?.[service.bank_account_id] ?? null,
                        provider_id: -1,
                        id: idCounter
                    }
                })
                initSrvIds[service.id] = idCounter
                idCounter--
            }
        }

        if (tarifs.length > 0) {
            for (const tariff of tarifs) {
                actions.push({
                    operation: 'create',
                    type: 'tarif',
                    value: {
                        ...tariff,
                        provider_service_id: initSrvIds?.[tariff.provider_service_id] ?? null,
                        id: idCounter
                    }
                })
                initTarifsIds[tariff.id] = idCounter
                idCounter--
            }
        }

        if (bareos.length > 0) {
            for (const baREO of bareos) {
                actions.push({
                    operation: 'create',
                    type: 'real_estate_object_provider_service',
                    value: {
                        ...cleanUpPayload('real_estate_object_provider_service', baREO),
                        provider_service_id: initSrvIds?.[baREO.provider_service_id] ?? null,
                        bank_account_id: initBAIds?.[baREO.bank_account_id] ?? null,
                        id: idCounter
                    }
                })
                idCounter--
            }
        }

        if (prefs.length > 0) {
            for (const pref of prefs) {
                actions.push({
                    operation: 'create',
                    type: 'provider_service_preference',
                    value: {
                        ...pref,
                        provider_service_id: initSrvIds?.[pref.provider_service_id] ?? null,
                        id: idCounter
                    }
                })
                idCounter--
            }
        }

        if (coms.length > 0) {
            for (const com of coms) {
                actions.push({
                    operation: 'create',
                    type: 'provider_service_bank_commission',
                    value: {
                        ...com,
                        provider_service_id: initSrvIds?.[com.provider_service_id] ?? null,
                        id: idCounter
                    }
                })
                idCounter--
            }
        }

        if (newAccs.length > 0) {
            for (const acc of newAccs) {
                actions.push({
                    operation: 'create',
                    type: 'account_provider_service',
                    value: {
                        ...cleanUpPayload('account_provider_service', acc),
                        provider_service_id: initSrvIds?.[acc.provider_service_id] ?? null,
                        id: idCounter
                    }
                })
                initAccsIds[acc.id] = idCounter
                idCounter--
            }
        }

        if (accTarifs.length > 0) {
            for (const accTarif of accTarifs) {
                actions.push({
                    operation: 'create',
                    type: 'account_service_tarif',
                    value: {
                        ...cleanUpPayload('account_service_tarif', accTarif),
                        account_provider_service_id: initAccsIds?.[accTarif.account_provider_service_id] ?? null,
                        tarif_id: initTarifsIds?.[accTarif.tarif_id] ?? null,
                        id: idCounter
                    }
                })
                idCounter--
            }
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {
        current: $selectedProvider,
        services: $providerServices,
        servicesInit: $providerServicesInit,
        deletedServices: $deletedProviderServices,
        bankAcs: $providerBankAccounts,
        bankAcsInit: $providerBankAccountsInit,
        defaultBA: $providerDefaultBankAccount,
        defaultBAInit: $providerDefaultBankAccountInit,
        deletedBankAccs: $deletedBankAccounts,
        agreements: $providerAgreements,
        agreementsInit: $providerAgreementsInit,
        deletedAgreements: $deletedAgreements,
        tarifs: $providerServTariffs,
        tarifsInit: $provServTariffsInit,
        deletedTariffs: $deletedServTariffs,
        bareos: $providerServBaREOs,
        bareosInit: $provServBaREOsInit,
        deletedBaREOs: $deletedServBaREOs,
        prefs: $providerServPrefs,
        prefsInit: $provServPrefsInit,
        deletedPrefs: $deletedServPrefs,
        coms: $providerServComs,
        comsInit: $provServComsInit,
        deletedComs: $deletedServComs,
        accs: $providerServAccs,
        accsInit: $provServAccsInit,
        newAccs: $newProviderServAccs,
        deletedAccs: $deletedServAccs,
        accTarifs: $providerServAccsTarifs,
        accTarifsInit: $provServAccsTarifsInit,
        deletedAccTarifs: $deletedServAccsTarifs
    },
    clock: submitEditProviderEv,
    fn: ({
             current,
             services,
             servicesInit,
             deletedServices,
             bankAcs,
             bankAcsInit,
             deletedBankAccs,
             defaultBA,
             defaultBAInit,
             agreements,
             agreementsInit,
             deletedAgreements,
             tarifs,
             tarifsInit,
             deletedTariffs,
             bareos,
             bareosInit,
             deletedBaREOs,
             prefs,
             prefsInit,
             deletedPrefs,
             coms,
             comsInit,
             deletedComs,
             accs,
             accsInit,
             newAccs,
             deletedAccs,
             accTarifs,
             accTarifsInit,
             deletedAccTarifs
         }, provider) => {
        const actions = []
        let idCounter = -1

        const initBAIds = Object.fromEntries(bankAcs.filter(ba => ba.id < 0).map(i => [i.id, null]))
        const initSrvIds = Object.fromEntries(services.filter(srv => srv.id < 0).map(i => [i.id, null]))
        const initTarifsIds = Object.fromEntries(tarifs.filter(tarif => tarif.id < 0).map(i => [i.id, null]))
        const initAccsIds = Object.fromEntries(newAccs.map(i => [i.id, null]))

        const providerDiff = {}

        for (const [key, value] of Object.entries(provider)) {
            if (current[key] !== value) {
                providerDiff[key] = value
            }
        }

        const prepDiff = formatPayload(providerDiff)

        if (Object.keys(prepDiff).length > 0) {
            actions.push({operation: 'update', type: 'providers', value: {...prepDiff, object_id: current.id}})
        }

        console.log('Edit handlers:', current, provider)

        if (bankAcs.length > 0) {
            for (const bankAcc of bankAcs) {
                if (bankAcc.id < 0) {
                    actions.push({operation: 'create', type: 'bank_account', value: {...cleanUpPayload('bank_account', bankAcc), id: idCounter}})
                    initBAIds[bankAcc.id] = idCounter
                    idCounter--
                    // if (bankAcc?.id === defaultBA?.id && bankAcc?.id !== defaultBAInit?.id) {
                    //     actions.push({operation: 'update', type: 'bank_account_default', value: {provider_id: current.id, bank_account_id: initBAIds[bankAcc.id], id: idCounter}})
                    // }
                } else if (bankAcc.id > 0 && bankAcc.updated === true) {
                    const initBa = bankAcsInit.find(i => i.id === bankAcc.id)
                    if (!initBa) continue

                    const diff = {}
                    for (const [key, value] of Object.entries(cleanUpPayload('bank_account', bankAcc))) {
                        if (value !== initBa[key]) {
                            diff[key] = value
                        }
                    }
                    actions.push({operation: 'update', type: 'bank_account', value: {...diff, object_id: bankAcc.id}})
                }
            }
        }

        if (defaultBA?.id !== defaultBAInit?.id) {
            // FIXs with GS
            if (defaultBAInit?.id === null || defaultBAInit?.id === undefined) {
                actions.push({operation: 'create', type: 'bank_account_default', value: {
                        provider_id: current.id,
                        bank_account_id: defaultBA.bank_account_id > 0 ?
                            defaultBA.bank_account_id :
                            initBAIds[defaultBA.bank_account_id],
                        id: idCounter }
                })
                idCounter--
            } else {
                actions.push({operation: 'update', type: 'bank_account_default', value: {
                        provider_id: current.id,
                        bank_account_id: defaultBA.bank_account_id > 0 ?
                            defaultBA.bank_account_id :
                            initBAIds[defaultBA.bank_account_id],
                        object_id: defaultBAInit.id }
                })
            }

        }

        if (agreements.length > 0) {
            for (const agreement of agreements) {
                if (agreement.id < 0) {
                    actions.push({operation: 'create', type: 'agreement', value: {...agreement, id: idCounter}})
                    idCounter--
                } else if (agreement.id > 0 && agreement.updated === true) {
                    const initAg = agreementsInit.find(i => i.id === agreement.id)
                    if (!initAg) continue

                    const diff = {}
                    for (const [key, value] of Object.entries(agreement)) {
                        if (value !== initAg[key]) {
                            diff[key] = value
                        }
                    }
                    actions.push({operation: 'update', type: 'agreement', value: {...diff, object_id: agreement.id}})
                }
            }
        }

        tarifs = services.map(
            s => (s.tarifs || []).map(
                t => ({...t, provider_service_id: s.id})
            )
        ).flat()
        coms = services.map(
            s => (s.commissions || []).map(
                t => ({...t, provider_service_id: s.id})
            )
        ).flat().filter(o => o.id !== 'x')
        prefs = services.map(
            s => (s.preferences || []).map(
                t => ({...t, provider_service_id: s.id})
            )
        ).flat().filter(o => o.id !== 'x')

        if (services.length > 0) {
            for (let service of services) {
                service = {...service}
                delete service.tarifs
                delete service.commissions
                delete service.preferences

                if (service.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'provider_service',
                        value: {
                            ...service,
                            bank_account_id: service.bank_account_id > 0 ? service.bank_account_id : initBAIds?.[service.bank_account_id] ?? null,
                            provider_id: current.id,
                            id: idCounter
                        }
                    })
                    initSrvIds[service.id] = idCounter
                    idCounter--
                } else if (service.id > 0 && service.updated === true) {
                    const initSrv = servicesInit.find(i => i.id === service.id)
                    if (!initSrv) continue

                    const diff = {}
                    for (const [key, value] of Object.entries(service)) {
                        if (value !== initSrv[key]) {
                            diff[key] = value
                        }
                        if (key === 'provider_service_id' && value < 0) {
                            diff[key] = initSrvIds?.[value]
                        }
                    }
                    actions.push({operation: 'update', type: 'provider_service', value: {...diff, object_id: service.id}})
                }
            }
        }

        if (tarifs.length > 0) {
            for (const tariff of tarifs) {
                if (tariff.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'tarif',
                        value: {
                            ...tariff,
                            provider_service_id: tariff.provider_service_id > 0 ? tariff.provider_service_id : initSrvIds?.[tariff.provider_service_id] ?? null,
                            id: idCounter
                        }
                    })
                    initTarifsIds[tariff.id] = idCounter
                    idCounter--
                } else if (tariff.id > 0 && tariff.updated === true) {
                    const initTarif = tarifsInit.find(i => i.id === tariff.id)
                    if (!initTarif) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(tariff)) {
                        if (value !== initTarif[key]) {
                            diff[key] = value
                        }
                        if (key === 'provider_service_id' && value < 0) {
                            diff[key] = initSrvIds?.[value]
                        }
                    }
                    actions.push({operation: 'update', type: 'tarif', value: {...diff, object_id: tariff.id}})
                }
            }
        }

        if (bareos.length > 0) {
            for (const baREO of bareos) {
                if (baREO.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'real_estate_object_provider_service',
                        value: {
                            ...cleanUpPayload('real_estate_object_provider_service', baREO),
                            provider_service_id: baREO.provider_service_id > 0 ? baREO.provider_service_id : initSrvIds?.[baREO.provider_service_id] ?? null,
                            bank_account_id: baREO.bank_account_id > 0 ? baREO.bank_account_id : initBAIds?.[baREO.bank_account_id] ?? null,
                            id: idCounter
                        }
                    })
                    idCounter--
                } else if (baREO.id > 0 && baREO.updated === true) {
                    const initBaREO = bareosInit.find(i => i.id === baREO.id)
                    if (!initBaREO) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(baREO)) {
                        if (value !== initBaREO[key]) {
                            diff[key] = value
                        }
                        if (key === 'provider_service_id' && value < 0) {
                            diff[key] = initSrvIds?.[value]
                        }
                    }
                    actions.push({operation: 'update', type: 'real_estate_object_provider_service', value: {
                        ...cleanUpPayload('real_estate_object_provider_service', diff),
                        bank_account_id: baREO.bank_account_id > 0 ? baREO.bank_account_id : initBAIds?.[baREO.bank_account_id] ?? null,
                        object_id: baREO.id
                    }})
                }
            }
        }

        if (prefs.length > 0) {
            for (const pref of prefs) {
                if (pref.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'provider_service_preference',
                        value: {
                            ...pref,
                            provider_service_id: pref.provider_service_id > 0 ? pref.provider_service_id : initSrvIds?.[pref.provider_service_id] ?? null,
                            id: idCounter
                        }
                    })
                    idCounter--
                } else if (pref.id > 0 && pref.updated === true) {
                    const initPref = prefsInit.find(i => i.id === pref.id)
                    if (!initPref) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(pref)) {
                        if (value !== initPref[key]) {
                            diff[key] = value
                        }
                        if (key === 'provider_service_id' && value < 0) {
                            diff[key] = initSrvIds?.[value]
                        }
                    }
                    actions.push({operation: 'update', type: 'provider_service_preference', value: {...diff, object_id: pref.id}})
                }
            }
        }

        if (coms.length > 0) {
            for (const com of coms) {
                if (com.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'provider_service_bank_commission',
                        value: {
                            ...com,
                            provider_service_id: com.provider_service_id > 0 ? com.provider_service_id : initSrvIds?.[com.provider_service_id] ?? null,
                            id: idCounter
                        }
                    })
                    idCounter--
                } else if (com.id > 0 && com.updated === true) {
                    const initCom = comsInit.find(i => i.id === com.id)
                    if (!initCom) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(com)) {
                        if (value !== initCom[key]) {
                            diff[key] = value
                        }
                        if (key === 'provider_service_id' && value < 0) {
                            diff[key] = initSrvIds?.[value]
                        }
                    }
                    actions.push({operation: 'update', type: 'provider_service_bank_commission', value: {...diff, object_id: com.id}})
                }
            }
        }

        if (accs.length > 0) {
            for (const acc of accs) {
                // for (const acc of newAccs) {
                if (acc.id > 0 && acc.updated === true) {
                    const initAcc = accsInit.find(i => i.id === acc.id)
                    if (!initAcc) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(acc)) {
                        if (value !== initAcc[key]) {
                            diff[key] = value
                            if (key === 'provider_service_id' && value < 0) {
                                diff[key] = initSrvIds?.[value]
                            }
                        }
                    }
                    actions.push({operation: 'update', type: 'account_provider_service', value: {...cleanUpPayload('account_provider_service', diff), object_id: acc.id}})
                }
                // }
            }
        }

        if (newAccs.length > 0) {
            for (const acc of newAccs) {
                if (acc.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'account_provider_service',
                        value: {
                            ...cleanUpPayload('account_provider_service', acc),
                            provider_service_id: acc.provider_service_id > 0 ? acc.provider_service_id : initSrvIds?.[acc.provider_service_id] ?? null,
                            id: idCounter
                        }
                    })
                    initAccsIds[acc.id] = idCounter
                    idCounter--
                }
            }
        }

        if (accTarifs.length > 0) {
            for (const accTarif of accTarifs) {
                if (accTarif.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'account_service_tarif',
                        value: {
                            ...cleanUpPayload('account_service_tarif', accTarif),
                            account_provider_service_id: accTarif.account_provider_service_id > 0 ? accTarif.account_provider_service_id : initAccsIds?.[accTarif.account_provider_service_id] ?? null,
                            tarif_id: accTarif.tarif_id > 0 ? accTarif.tarif_id : initTarifsIds?.[accTarif.tarif_id] ?? null,
                            id: idCounter
                        }
                    })
                    idCounter--
                } else if (accTarif.id > 0 && accTarif.updated === true) {
                    const initAccTarif = accTarifsInit.find(i => i.id === accTarif.id)
                    if (!initAccTarif) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(accTarif)) {
                        if (value !== initAccTarif[key]) {
                            diff[key] = value
                        }
                        if (key === 'account_provider_service_id' && value < 0) {
                            diff[key] = initAccsIds?.[value]
                        }
                        if (key === 'tarif_id' && value < 0) {
                            diff[key] = initTarifsIds?.[value]
                        }
                    }
                    actions.push({operation: 'update', type: 'account_service_tarif', value: {...cleanUpPayload('account_service_tarif', diff), object_id: accTarif.id}})
                }
            }
        }

        if (deletedBankAccs.length > 0 && typeof provider.beneficiary_id !== 'number') {
            for (const id of deletedBankAccs) {
                actions.push({operation: 'delete', type: 'bank_account', value: {id}})
            }
        }

        if (deletedAgreements.length > 0) {
            for (const id of deletedAgreements) {
                actions.push({operation: 'delete', type: 'agreement', value: {id}})
            }
        }

        let remainIds = services.map(o => o.id)
        deletedServices = servicesInit.filter(o => !remainIds.includes(o.id)).map(o => o.id)
        if (deletedServices.length > 0) {
            for (const id of deletedServices) {
                actions.push({operation: 'delete', type: 'provider_service', value: {id}})
            }
        }

        remainIds = tarifs.map(o => o.id)
        deletedTariffs = tarifsInit.filter(o => !remainIds.includes(o.id)).map(o => o.id)
        if (deletedTariffs.length > 0) {
            for (const id of deletedTariffs) {
                actions.push({operation: 'delete', type: 'tarif', value: {id}})
            }
        }

        remainIds = coms.map(o => o.id)
        deletedComs = comsInit.filter(o => !remainIds.includes(o.id)).map(o => o.id)
        if (deletedComs.length > 0) {
            for (const id of deletedComs) {
                actions.push({operation: 'delete', type: 'provider_service_bank_commission', value: {id}})
            }
        }

        remainIds = prefs.map(o => o.id)
        deletedPrefs = prefsInit.filter(o => !remainIds.includes(o.id)).map(o => o.id)
        if (deletedPrefs.length > 0) {
            for (const id of deletedPrefs) {
                actions.push({operation: 'delete', type: 'provider_service_bank_commission', value: {id}})
            }
        }

        if (deletedBaREOs.length > 0) {
            for (const id of deletedBaREOs) {
                actions.push({operation: 'delete', type: 'real_estate_object_provider_service', value: {id}})
            }
        }

        if (deletedAccs.length > 0) {
            for (const id of deletedAccs) {
                actions.push({operation: 'delete', type: 'account_provider_service', value: {id}})
            }
        }

        if (deletedAccTarifs.length > 0) {
            for (const id of deletedAccTarifs) {
                actions.push({operation: 'delete', type: 'account_service_tarif', value: {id}})
            }
        }

        return actions
    },
    target: submitGlobalUpdateEv
})
