import {$currentUser, $info, $onSelectFilter} from './stores.js';
import {getInfoEv, onSelectFilterEv} from './events.js';
import {getCurrentUserFx, getInfoFx,} from './effects.js';
import {AppGate} from './events.js';
import {sample} from 'effector';
import {universalDictEndpointFx} from '../dictionaryUniversalModel/index.js';
import {WsOpen} from '../webSocketModel/index.js';

// $info.on(getInfoFx.doneData, (_, data) => data)
$info.on(getInfoFx.doneData, (state, data) => {
    // debugger
    return data
})
    .reset(AppGate.close)

$currentUser.on(getCurrentUserFx.doneData, (_, data) => data)
    .reset(AppGate.close)

$onSelectFilter
    .on(onSelectFilterEv, (_, payload) => (payload))


sample({
    clock: [universalDictEndpointFx.doneData, WsOpen, getInfoEv],
    target: [getInfoFx, getCurrentUserFx]
})

