import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {Link} from 'react-router-dom';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {
    getStreetTypesListFx,
    $streetTypesList,
    StreetTypesListGate,
    deleteStreetTypeEv
} from '../../models/dictionaryStreetTypesModel';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";

export default function DictionaryStreetTypesList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($streetTypesList)
    const loading = useUnit(getStreetTypesListFx.pending)
    const deleteStreetType = useUnit(deleteStreetTypeEv)
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.street_type`)


    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t(`Название ${genitive_form} (каз)`),
            dataIndex: 'name_kz',
            sorter: true,
            key: 'name_kz',
            render: (name, record) => <Link
                to={`/dictionaries/street_type/view/${record.id}`}>{name}</Link>
        },
        {
            title: t(`Название ${genitive_form} (рус)`),
            dataIndex: 'name_ru',
            sorter: true,
            key: 'name_ru',
            render: (name, record) => <Link
                to={`/dictionaries/street_type/view/${record.id}`}>{name}</Link>
        },
        {
            title: t(`Краткое название ${genitive_form} (каз)`),
            dataIndex: 'short_name_kz',
            sorter: true,
            key: 'short_name_kz',
        },
        {
            title: t(`Краткое название ${genitive_form} (рус)`),
            dataIndex: 'short_name_ru',
            sorter: true,
            key: 'short_name_ru',
        },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    key: 'created_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => <CustomDates data={date}/>
                },
                {
                    title: t('Кем'),
                    width: '10%',
                    dataIndex: 'created_by',
                    sorter: true,
                    key: 'created_by',
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date, record) => record.updated_at ? <CustomDates data={date}/> : '-'
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    sorter: true,
                    width: '10%',
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Button icon={<EditOutlined/>} type={'primary'}
                            href={`/dictionaries/street_type/edit/${id}`}/>
                </Tooltip>
                <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Button icon={<EyeOutlined/>} href={`/dictionaries/street_type/view/${id}`}/>
                </Tooltip>
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined/>}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => deleteStreetType(id)}
                            placement={'left'}
                >
                    <Tooltip title={t('Удалить')} placement={'top'}>
                        <Button icon={<DeleteOutlined/>} danger/>
                    </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код/Название'), type: 'input'},
        // {name: 'name', label: t('Название'), type: 'input'},
        {
            name: 'created_by',
            label: t('Кем создан'),
            type: 'select',
            dict: 'users',
            multiple: true
        },
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {
            name: 'updated_by',
            label: t('Кем обновлен'),
            type: 'select',
            dict: 'users',
            multiple: true
        },
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]

    return <>
        <StreetTypesListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data} initColumns={columns} count={count} loading={loading}
            exportXlsAction={exportXlsAction}
        />
    </>
}
