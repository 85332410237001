import {useUnit} from 'effector-react';
import {
    $correctionType,
    $editMode,
    $foundAccs, $pageMode, $selectedAcc,
    $selectedProvider,
    $selectedSrv, correctionTypeEnum, deleteAllTempCorrectionsEv,
    getAccsFx,
    resetAccsEv, resetSelectedAccEv, resetSrvsEv,
    searchAccsEv,
    setPageModeEv, submitTableFormEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import AccountSearchForm from '../../../../components/AddressReoSearchForms/AccountSearchForm.jsx';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import MassActions from './MassActionsCollapse.jsx';
import Switch from 'antd/es/switch';
import Row from 'antd/es/row';
import Radio from 'antd/es/radio';
import ComplexTable from './ComplexTable.jsx';

export default function CorrectionFormMass() {
    const {t} = useTranslation()
    const accsLoading = useUnit(getAccsFx.pending)
    const {data: foundAccs} = useUnit($foundAccs)

    const selType = useUnit($correctionType)
    const selProv = useUnit($selectedProvider)
    const selProvSrv = useUnit($selectedSrv)

    const editMode = useUnit($editMode)
    const pageMode = useUnit($pageMode)
    const setPageMode = useUnit(setPageModeEv)

    const searchAccs = useUnit(searchAccsEv)
    const resetAccs = useUnit(resetAccsEv)

    const selectedAcc = useUnit($selectedAcc)
    const resetSelected = useUnit(resetSelectedAccEv)
    const resetSrvs = useUnit(resetSrvsEv)

    const submitTableForm = useUnit(submitTableFormEv)
    const resetCorrection = useUnit(deleteAllTempCorrectionsEv)


    useEffect(() => {
        if (editMode === 'mass' && foundAccs?.length > 0 && selectedAcc) {
            if (selType === correctionTypeEnum.new_acc_to_srv) {
                const acc = foundAccs.find(a => a.account_id === selectedAcc)
                const targetSrv = acc?.services?.find(s => s.provider_id === selProv && s.service_id === selProvSrv)
                const targetCorrection = acc?.corrections?.find(c => c.provider_id === selProv && c.service_id === selProvSrv)
                if (targetSrv) {
                    for (const [key, value] of Object.entries(targetSrv)) {
                        if (['external_number', 'people_quantity', 'service_square', 'is_not_accrued', 'is_not_balance_transfer', 'is_not_printed'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key], value)
                        } else if (['date_from', 'date_to', 'not_accrued_from', 'not_accrued_to', 'not_balance_transfer_from', 'not_balance_transfer_to', 'not_printed_from', 'not_printed_to'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key], dayjs(value))
                        }
                    }
                }
                if (targetCorrection) {
                    for (const [key, value] of Object.entries(targetCorrection)) {
                        if (['external_number_new', 'people_quantity_new', 'service_square_new', 'is_not_accrued_new', 'is_not_balance_transfer_new', 'is_not_printed_new', 'not_accrued_from_new', 'not_accrued_to_new', 'not_balance_transfer_from_new', 'not_balance_transfer_to_new', 'not_printed_from_new', 'not_printed_to_new'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key.replace('_new', '')], value)
                        } else if (['date_from_new', 'tarif_date_from', 'date_to_new', 'not_accrued_from_new', 'not_accrued_to_new', 'not_balance_transfer_from_new', 'not_balance_transfer_to_new', 'not_printed_from_new', 'not_printed_to_new'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key.replace('_new', '')], dayjs(value))
                        } else if (key === 'tarif_id' && !!value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key], value)
                        } else if (key === 'tarif_id' && !value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key], -1)
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, 'tarif_value'], targetCorrection?.tarif_value)
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, 'tarif_service_unit_id'], targetCorrection?.tarif_service_unit_id)
                        } else if (!(key.endsWith('_old') || key.endsWith('_new'))) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key], value)
                        }
                    }
                }
            } else if (selType === correctionTypeEnum.change_existing_params) {
                const acc = foundAccs.find(a => a.account_id === selectedAcc)
                const targetSrv = acc?.services?.find(s => s.provider_id === selProv && s.service_id === selProvSrv)
                const targetCorrection = acc?.corrections?.find(c => c.provider_id === selProv && c.service_id === selProvSrv)
                if (targetSrv) {
                    for (const [key, value] of Object.entries(targetSrv)) {
                        if (['external_number', 'people_quantity', 'service_square', 'is_not_accrued', 'is_not_balance_transfer', 'is_not_printed'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key], value)
                        } else if (['date_from', 'date_to', 'not_accrued_from', 'not_accrued_to', 'not_balance_transfer_from', 'not_balance_transfer_to', 'not_printed_from', 'not_printed_to'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key], dayjs(value))
                        }
                    }
                }
                if (targetCorrection) {
                    for (const [key, value] of Object.entries(targetCorrection)) {
                        if (['external_number_new', 'people_quantity_new', 'service_square_new', 'is_not_accrued_new', 'is_not_balance_transfer_new', 'is_not_printed_new', 'not_accrued_from_new', 'not_accrued_to_new', 'not_balance_transfer_from_new', 'not_balance_transfer_to_new', 'not_printed_from_new', 'not_printed_to_new'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key.replace('_new', '')], value)
                        } else if (['date_from_new', 'tarif_date_from', 'date_to_new', 'not_accrued_from_new', 'not_accrued_to_new', 'not_balance_transfer_from_new', 'not_balance_transfer_to_new', 'not_printed_from_new', 'not_printed_to_new'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key.replace('_new', '')], dayjs(value))
                        } else if (!(key.endsWith('_old') || key.endsWith('_new')) && key !== 'tarif_id') {
                            srvForm.setFieldValue([acc.account_id.toString(), `${selProv.toString()}#${selProvSrv.toString()}`, key], value)
                        }
                    }
                }
            }
        }
    }, [editMode, foundAccs, selType, selectedAcc])

    const resetSrvsForm = () => {
        srvForm.resetFields()
        resetSrvs()
    }

    const fullReset = () => {
        resetSrvsForm()
        resetAccs()
        resetSelected()
    }

    const submitTable = (values) => {
        if ([correctionTypeEnum.change_tariff, correctionTypeEnum.change_saldo, correctionTypeEnum.change_ext_payments].includes(selType)) {
            submitTableForm(values)
        }
    }

    const [searchForm] = Form.useForm();
    const [srvForm] = Form.useForm();

    return <>
        <Card size={'small'}>
            <Form form={searchForm} size={'small'}
                  layout={'vertical'} onFinish={searchAccs}
                  disabled={!selType}
            >
                <AccountSearchForm extForm={searchForm} itemsAsRow onlyItems extButtons/>
                {(selType === correctionTypeEnum.change_existing_params || selType === correctionTypeEnum.change_tariff)
                    && <Form.Item name={'all_accounts'}
                            label={t('Все ЛС прикрепленные к услуге поставщика')}
                            valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>}
                <Form.Item>
                    <ButtonGroup size={'small'}>
                        <Button type={'primary'} onClick={() => searchForm.submit()}
                                loading={accsLoading}>
                            {t('Поиск')}
                        </Button>
                        <Button onClick={() => {
                            fullReset()
                            searchForm.resetFields()
                        }}
                        >
                            {t('Сброс')}
                        </Button>
                    </ButtonGroup>
                </Form.Item>
            </Form>
        </Card>
        <Divider/>
        <Card title={t('Изменения')} size={'small'}>
            {editMode === 'mass' && selProvSrv && foundAccs.length > 0 && <>
                <MassActions />
                <Divider />
            </>}
            <Form form={srvForm} size={'small'} layout={'vertical'}
                  onFinish={(values) => submitTable(values)}
                  onValuesChange={(cur, all) => {console.log(all)}}
            >
                <ComplexTable srvForm={srvForm} resetSrvsForm={resetSrvsForm} resetSelected={resetSelected}/>
            </Form>
            <Row>
                <Divider />
                <Radio.Group value={pageMode}
                             onChange={({target: {value}}) => setPageMode(value)}
                             size={'small'}>
                    <Radio.Button value={'edit'}>{t('Режим внесения изменений')}</Radio.Button>
                    <Radio.Button value={'view'}>{t('Режим просмотра изменений')}</Radio.Button>
                </Radio.Group>
                {pageMode === 'view' && <Button type={'primary'} danger size={'small'}
                                                style={{marginLeft: 16}}
                                                onClick={() => {
                                                    resetSrvsForm()
                                                    resetCorrection()
                                                }}
                >
                    {t('Очистить список изменений')}
                </Button>}
            </Row>
        </Card>
    </>
}