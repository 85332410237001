import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
	$editAlgorithmStatus,
	$selectedAlgorithm, CalcTypeAlgorithmEditGate,
	getAlgorithmByIdFx,
	submitEditAlgorithmEv
} from "../../../models/calcTypeAlgorithmsModel/index.js";
import Form from "antd/es/form/index";
import {universalDictEndpointFx} from "../../../models/dictionaryUniversalModel/index.js";
import {useEffect, useState} from "react";
import useFormChanged from "../../../hooks/useFormChanged.js";
import Card from "antd/es/card";
import Row from "antd/es/row";
import Col from "antd/es/col"
import Input from "antd/es/input"
import Divider from "antd/es/divider"
import Space from "antd/es/space"
import Button from "antd/es/button"
import EditOutlined from "@ant-design/icons/EditOutlined"
import {AlgorithmCalculator, AlgorithmEditor} from "./AlgorithmCalculator.jsx";
import FunctionOutlined from "@ant-design/icons/FunctionOutlined";

export default function EditCalcTypeAlgorithms() {
	const {id} = useParams()
	const navigate = useNavigate()
	const {t} = useTranslation()

	const selected = useUnit($selectedAlgorithm)

	const [calcAlgorithmForm] = Form.useForm()

	const submit = useUnit(submitEditAlgorithmEv)

	const loading = useUnit(getAlgorithmByIdFx.pending)

	const submitLoading = useUnit(universalDictEndpointFx.pending)

	const status = useUnit($editAlgorithmStatus)
	const genitive_form = t(`breadcrumbs.genitive.algorithm`)

	useEffect(() => {
		if(status){
			navigate('/dictionaries/algorithm')
		}
	}, [status]);

	useEffect(() => {
		if(selected){
			calcAlgorithmForm.setFieldsValue(selected)
		}
	}, [selected])

	const [check, changedFields] = useFormChanged(selected, ['name_kz', 'name_ru', 'formula'])

	const formula = calcAlgorithmForm.getFieldValue('formula')
	const confirmFormula = (value) => {
		console.log('confirmFormula', value)
		setFormulaMode('view')
		calcAlgorithmForm.setFieldValue('formula', value)
	}
	const cancelFormula = () => {
		console.log('cancelFormula')
		setFormulaMode('view')
	}
    const [formulaMode, setFormulaMode] = useState('view')
	const calcFormula = () => setFormulaMode('calc')
	const editFormula = () => setFormulaMode('edit')

	console.log('EditCalcTypeAlgorithms', id, formulaMode, formula)
	return <>
		<CalcTypeAlgorithmEditGate id={id}/>
		<Form form={calcAlgorithmForm}
					layout={'vertical'}
					onFinish={(v) => submit(v)}
					validateTrigger={'onBlur'}
					onValuesChange={(_, all) => check(all)}
			        size={'small'}
		>
			<Card loading={loading} title={t('Свойства типа расчёта')} size={'small'}>
				<Row justify={'space-between'}>
					<Col span={9}>
						<Form.Item name={'name_kz'}
											 label={t(`Название ${genitive_form} (каз)`)}
											 rules={[{required: true, message: t('Обязательное поле')}]}
											 validateStatus={changedFields['name_kz']}
						>
							<Input/>
						</Form.Item>
					</Col>
					<Col span={9}>
						<Form.Item name={'name_ru'}
											 label={t(`Название ${genitive_form} (рус)`)}
											 rules={[{required: true, message: t('Обязательное поле')}]}
											 validateStatus={changedFields['name_ru']}
						>
							<Input/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8,8]} style={{alignItems: 'center'}}>
					<Col span={23}>
												<Form.Item
							label={t(`Формула ${genitive_form}`)}
							required={true}
						>
							<Space.Compact style={{width: '100%'}}>
								<Form.Item
									required={true}
									name={'formula'}
									label={t(`Формула ${genitive_form}`)}
									rules={[{required: true, message: t('Обязательное поле')}]}
									noStyle
								>
									<Input disabled={formulaMode!== 'inline'} size={'middle'}/>
								</Form.Item>
								<Button
									icon={<FunctionOutlined />}
									disabled={formulaMode !== 'view'}
									onClick={calcFormula}
								/>
								<Button
									icon={<EditOutlined />}
									disabled={formulaMode !== 'view'}
									onClick={editFormula}
								/>
							</Space.Compact>
						</Form.Item>
					</Col>
					{formulaMode === 'calc' && <Col span={24}>
						<AlgorithmCalculator
							initValue={formula}
							onConfirm={confirmFormula}
							onCancel={cancelFormula}
						/>
					</Col>}
					{formulaMode === 'edit' && <Col span={24}>
						<AlgorithmEditor
							initValue={formula}
							onConfirm={confirmFormula}
							onCancel={cancelFormula}
						/>
					</Col>}
				</Row>
			</Card>
		</Form>
		<Divider orientation={'left'} orientationMargin={0}/>
		<Row>
			<Space>
				<Button danger onClick={() => {
					calcAlgorithmForm.resetFields()
					navigate(-1)
				}}
				>
					{t('Отмена')}
				</Button>
				<Button loading={submitLoading}
								onClick={() => {
									calcAlgorithmForm.submit()
								}}
								type={'primary'}
				>
					{t('Сохранить')}
				</Button>
			</Space>
		</Row>
	</>
}
