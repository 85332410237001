import {createStore} from "effector";

const initialState = [
	{
		title: 'TEST section 1',
		cards: [
			{
				id: '1',
				title: 'TEST card 1',
				dictionaries: [
					'dictionary 1',
					'dictionary 2',
					'dictionary 3',
					'dictionary 4',
					'dictionary 5',
				],
				tasks: [
					'task 1',
					'task 2',
					'task 3',
					'task 4',
					'task 5',
				],
				width: 250,
				height: 236
			},
			{
				id: '2',
				title: 'TEST card 2',
				dictionaries: [
					'dictionary 1',
					'dictionary 2',
					'dictionary 3',
					'dictionary 4',
					'dictionary 5',
				],
				tasks: [
					'task 1',
					'task 2',
					'task 3',
					'task 4',
					'task 5',
				],
				width: 250,
				height: 236
			},
			{
				id: '3',
				title: 'TEST card 3',
				dictionaries: [
					'dictionary 1',
					'dictionary 2',
					'dictionary 3',
					'dictionary 4',
					'dictionary 5',
				],
				tasks: [
					'task 1',
					'task 2',
					'task 3',
					'task 4',
					'task 5',
				],
				width: 250,
				height: 236
			},
			{
				id: '4',
				title: 'TEST card 4',
				dictionaries: [
					'dictionary 1',
					'dictionary 2',
					'dictionary 3',
					'dictionary 4',
					'dictionary 5',
				],
				tasks: [
					'task 1',
					'task 2',
					'task 3',
					'task 4',
					'task 5',
				],
				width: 250,
				height: 236
			}
		]
	},
	{
		title: 'TEST section 2',
		cards: [
			{
				id: '5',
				title: 'TEST card 5',
				dictionaries: [
					'dictionary 1',
					'dictionary 2',
					'dictionary 3',
					'dictionary 4',
					'dictionary 5',
				],
				tasks: [
					'task 1',
					'task 2',
					'task 3',
					'task 4',
					'task 5',
				],
				width: 250,
				height: 236
			},
			{
				id: '6',
				title: 'TEST card 6',
				dictionaries: [
					'dictionary 1',
					'dictionary 2',
					'dictionary 3',
					'dictionary 4',
					'dictionary 5',
				],
				tasks: [
					'task 1',
					'task 2',
					'task 3',
					'task 4',
					'task 5',
				],
				width: 250,
				height: 236
			},
			{
				id: '7',
				title: 'TEST card 7',
				dictionaries: [
					'dictionary 1',
					'dictionary 2',
					'dictionary 3',
					'dictionary 4',
					'dictionary 5',
				],
				tasks: [
					'task 1',
					'task 2',
					'task 3',
					'task 4',
					'task 5',
				],
				width: 250,
				height: 236
			},
			{
				id: '8',
				title: 'TEST card 8',
				dictionaries: [
					'dictionary 1',
					'dictionary 2',
					'dictionary 3',
					'dictionary 4',
					'dictionary 5',
				],
				tasks: [
					'task 1',
					'task 2',
					'task 3',
					'task 4',
					'task 5',
				],
				width: 250,
				height: 236
			}
		]
	}
]

export const $mainPageData = createStore(initialState)
$mainPageData.watch(state => console.log('$mainPageData', state))

export const $hiddenCards = createStore([])
$hiddenCards.watch(state => console.log('$hiddenCards', state))
