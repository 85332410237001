import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Select from 'antd/es/select';
import Divider from 'antd/es/divider';
import PlusOutlined from '@ant-design/icons/es/icons/PlusOutlined';

import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import CreateStTypeEmbeddedForm
    from '../../../components/Embedded/CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';


import {$createdStTypeId} from "../../../models/dictionaryStreetTypesModel/index.js";

import {
    EPDTemplateFieldCreateGate,
    submitCreateEPDTemplateFieldEv,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv, $fieldName, $cellType, $columnNumber, $createdEPDTemplateFieldId,
} from "../../../models/dictionaryEpdTemplateFieldModel/index.js";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import CreateEpdTemplateFieldEmbeddedForm
    from "../../../components/Embedded/CreateEpdTemplateFieldEmbeddedForm/CreateEpdTemplateFieldEmbeddedForm.jsx";
import {
    $createdEPDTemplateField,
    setCreateEpdTemplateFieldEmbeddedEv
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";

export default function CreateEpdTemplateField() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [epdTemplateFieldForm] = Form.useForm()

    const submit = useUnit(submitCreateEPDTemplateFieldEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdEPDTemplateFieldId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const setEpdTemplateField = useUnit(setCreateEpdTemplateFieldEmbeddedEv)
    const fieldNameOpt = useUnit($fieldName)
    const cellTypeOpt = useUnit($cellType)
    const columnNumberOpt = useUnit($columnNumber)

    const templateOpts = useStoreMap($dictsSelects, sel => sel.template)
    const genitive_form = t(`breadcrumbs.genitive.epd-template-fields`);


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/epd-template-fields/edit/${createdId}`)
            } else if (action === 'new') {
                epdTemplateFieldForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <EPDTemplateFieldCreateGate/>
        <Card>
            <Form form={epdTemplateFieldForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>


                <CreateStTypeEmbeddedForm />
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'template_id'}
                                   label={t('Шаблон')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    ...templateOpts,
                                    {label: 'Создать', value: -1, emoji: true}
                                ]}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label} >
                                          {option?.data?.emoji ? <PlusOutlined style={{color: '#1677ff'}}/> :  ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setEpdTemplateField(true)
                                    } else {
                                        epdTemplateFieldForm.resetFields(['town'])
                                        setEpdTemplateField(false)
                                    }
                                }}

                            />
                        </Form.Item>
                        <CreateEpdTemplateFieldEmbeddedForm />
                    </Col>
                    <Col span={9}>
                        <Form.Item name={'field_name'}
                                   label={t(`Название ${genitive_form}`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select
                                options={fieldNameOpt}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'cell_type'}
                                   label={t('Тип ячейки')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select
                                options={cellTypeOpt}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name={'column_number'}
                                   label={t('Номер столбца')}
                                   rules={[
                                       {required: true, message: t('Обязательное поле')},
                                       {
                                           pattern: /^[0-9]+$/,
                                           message: 'Только цифры',
                                       },
                                   ]}
                        >
                            <Select
                                options={columnNumberOpt}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'font_size'}
                                   label={t('Размер шрифта')}
                                   rules={[
                                       {required: false, message: t('Обязательное поле')},
                                       {
                                           pattern: /^[0-9]+$/,
                                           message: 'Только цифры',
                                       },
                                   ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider orientation={'left'} orientationMargin={0}/>
            <Row>
                <Space>
                    <Button danger onClick={() => {
                        epdTemplateFieldForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('new')
                                epdTemplateFieldForm.submit()
                            }}
                            type={'primary'}
                            ghost
                    >
                        {t('Сохранить и создать следующее')}
                    </Button>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('edit')
                                epdTemplateFieldForm.submit()
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                </Space>
            </Row>
        </Card>
    </>
}
