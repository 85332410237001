import React from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import DictionaryProTable from "../../../components/DictionaryProTable/DictionaryProTable.jsx";
import {$paymentSingle, exportConfirmTableEv} from "../../../models/paymentModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import ExcelOutlined from "../../../components/ExcelIcon/EcxelIcon.jsx";
import Col from "antd/es/col";
import Card from "antd/es/card";
import Button from "antd/es/button";
import FormatNumber from "../../../components/FormatNumber/FormatNumber.jsx";
import Table from "antd/es/table";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import queryString from "query-string";

export default function PaymentTable({current}) {
	const {t} = useTranslation()
	const {data, count} = useUnit($paymentSingle)
	// console.log('PaymentTable current', current)

	// const filters = {
	// 	bank_id: current?.bank_id
	// }

	const exportXls = useUnit(exportConfirmTableEv)
	const {search} = searchAndActions()

	const columns = [
		{
			title: t('Дата системы'),
			dataIndex: 'system_payment_date',
			key: 'system_payment_date',
			render: (date) => <CustomDates data={date} />
		},
		{
			title: t('Дата банка'),
			dataIndex: 'bank_payment_date',
			key: 'bank_payment_date',
			render: (date) => <CustomDates data={date} />
		},
		{
			title: t('Лицевой счет'),
			dataIndex: 'account_id',
			key: 'account_id',
			render: (account_id) => <MappedDictItem id={account_id} type={'account'}/>
		},
		{
			title: t('Инвойс'),
			dataIndex: 'invoice',
			key: 'invoice',
		},
		{
			title: t('Сумма платежей'),
			dataIndex: 'pay_sum',
			key: 'pay_sum',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('sysref'),
			dataIndex: 'sysref',
			key: 'sysref',
		},
		{
			title: t('sysbankref'),
			dataIndex: 'sysbankref',
			key: 'sysbankref',
		},
		{
			title: t('Отмена'),
			dataIndex: 'is_rollback',
			key: 'is_rollback',
			render: (is_rollback) => (
				is_rollback ?
					<CheckOutlined style={{color: 'red', fontSize: 18}}/>
					:
					<CloseOutlined style={{color: 'lightgreen', fontSize: 18}}/>
			)
		},
		{
			title: t('Дата отмены'),
			dataIndex: 'rollback_date',
			key: 'rollback_date',
		}
	]


	return (
		<>
			<Card>

			<Col>
				<Button
					onClick={() => exportXls(current)}
					disabled={!data.length > 0}
					size={'small'}
				>
					<ExcelOutlined size={18}/>
					Экспорт
				</Button>
			</Col>
			{/*<DictionaryProTable*/}
			{/*	data={data}*/}
			{/*	initColumns={columns}*/}
			{/*	showActions={false}*/}
			{/*	exportXlsAction={() => console.log('qwe')}*/}
			{/*	rowKey={(r) => {*/}
			{/*		return `${r.id}-${r.payment_element_id}`*/}
			{/*	}}*/}
			{/*/>*/}
			<Table
				dataSource={data}
				columns={columns}
				showActions={false}
				size={'small'}
				rowKey={(r) => {
					return `${r.id}-${r.payment_element_id}`
				}}
			/>
			</Card>

		</>

	);
}
