import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {
    $editPreferenceCategoryStatus,
    $selectedPreferenceCategory, getPreferenceCategoryByIdFx,
    PreferenceCategoryEditGate, submitEditPreferenceCategoryEv
} from '../../../models/dictionaryPreferenceCategoryModel/index.js';
import {InputNumber} from "antd";

export default function EditPreferenceCategory() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selectedData = useUnit($selectedPreferenceCategory)

    const [preferenceCategoryForm] = Form.useForm()

    const submit = useUnit(submitEditPreferenceCategoryEv)

    const loading = useUnit(getPreferenceCategoryByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editPreferenceCategoryStatus)
    const genitive_form = t(`breadcrumbs.genitive.preference-category`);


    useEffect(() => {
        if (status) {
            navigate('/dictionaries/preference-category')
        }
    }, [status]);


    useEffect(() => {
        if (selectedData) {
            preferenceCategoryForm.setFieldsValue(selectedData)
        }
    }, [selectedData]);

    const [check, changedFields] = useFormChanged(selectedData, ['name_kz', 'name_ru'])

    return <>
        <PreferenceCategoryEditGate id={id}/>
        <Form form={preferenceCategoryForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Card title={t('Свойства категории льгот')} size={'small'} loading={loading}>
                <Row justify={'space-between'}>
                    <Col span={7}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name={'discount'}
                                   label={t(`Скидка`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <InputNumber style={{width: '100%'}} decimalSeparator={','} />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    preferenceCategoryForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            preferenceCategoryForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить')}
                </Button>
            </Space>
        </Row>
    </>
}
