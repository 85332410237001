import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {
    $accontsList, $selectedRealEstateObjects, getRealEstateObjectsByIdFx, RealEstateObjectsViewGate
} from "../../../models/dictionaryRealEstateObjectsModel/index.js";
import {
    CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined
} from "@ant-design/icons";
import {$infoMaps} from "../../../models/infoModel";
import ButtonGroup from "antd/es/button/button-group.js";
import Button from "antd/es/button";
import Descriptions from "antd/es/descriptions";
import styles
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.module.css'
import './style.less'
import Space from "antd/es/space";
import Col from "antd/es/col";
import DictionaryProTable from "../../../components/DictionaryProTable/DictionaryProTable.jsx";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import ShowAddressCard from '../../../components/AddressReoSearchForms/ShowAddressCard.jsx';

const cardStyle = {
    width: '100%', height: '100%', padding: '0'
}
const hiddenActions = ['create', 'create_group', 'export']

export default function ViewRealEstateObjects() {
    const lang = localStorage.getItem('lang') ?? 'ru'
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getRealEstateObjectsByIdFx.pending)
    const selected = useUnit($selectedRealEstateObjects)
    const {data, count} = useUnit($accontsList)
    const infoMaps = useUnit($infoMaps)
    const {exportXlsAction} = searchAndActions()

    const itemsDates = [{
        key: 'created_by', label: t('Кем создан'), children: <CreatedBy id={selected?.created_by}/>
    }, {
        key: 'created_at',
        label: t('Дата создания'),
        children: <CustomDates data={selected?.created_at}/>
    }, {
        key: 'updated_by',
        label: t('Кем изменен'),
        children: selected?.updated_by ? <CreatedBy id={selected?.updated_by}/> : '-'
    }, {
        key: 'updated_at',
        label: t('Дата изменения'),
        children: selected?.updated_at ? <CustomDates data={selected?.updated_at}/> : '-'
    }]

    const itemsON = [{
        key: '1',
        label: 'ЖК',
        children: selected?.name_kz ?
            <MappedDictItem id={selected?.name_kz} type={'real_estate_object'}/> : '-',
        span: 2,
    }, {
    //     key: '2',
    //     label: 'Название (каз)',
    //     children: selected?.name_ru ?
    //         <MappedDictItem id={selected?.name_ru} type={'real_estate_object'}/> : '-',
    //     span: 2,
    // }, {
        key: '1',
        label: 'РКА',
        children: selected?.cadastral_number ? selected?.cadastral_number : '-',
        span: 2,
    }, {
        key: '2', label: '2ГИС', children: selected?.gis_url ? selected?.gis_url : '-', span: 2,
    }, {
        key: '2',
        label: 'Участок',
        children: selected?.sector_id ? <MappedDictItem id={selected?.sector_id} type={'sector'}/> : '-',
        span: 2,
    },

        {
            key: '2',
            label: 'Тип объекта недвижимости',
            children: selected?.real_estate_object_type_id ?
                <MappedDictItem id={selected?.real_estate_object_type_id}
                                type={'real_estate_object_type'}/> : '-',
            span: 2,
        },]


    const itemsCount = [{
        key: '1', label: 'Этажей', children: selected?.floor_count ? selected?.floor_count : '-', span: 1,
    }, {
        key: '1',
        label: 'Подъездов',
        children: selected?.entrance_count ? selected?.entrance_count : '-',
        span: 1,
    }, {
        key: '1',
        label: 'Квартир',
        children: selected?.apartment_count ? selected?.apartment_count : '-',
        span: 1,
    },]


    const itemsHave = [{
        key: '1', label: 'Электричество', // children: selected?.is_electricity_network_present ? selected?.is_electricity_network_present : '-',
        children: selected?.is_electricity_network_present ?
            <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> :
            <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>, span: 2,
    }, {
        key: '1',
        label: 'Тепловая сеть',
        children: selected?.is_heat_network_present ?
            <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> :
            <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>,
        span: 2,
    }, {
        key: '1',
        label: 'Лифт',
        children: selected?.is_elevator_present ?
            <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> :
            <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>,
        span: 2,
    }, {
        key: '1',
        label: 'Почтовые ящики',
        children: selected?.is_mailboxes_present ?
            <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> :
            <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>,
        span: 2,
    },]


    const columns = [{
        title: t(`Номер ЛС`),
        dataIndex: 'id',
        sorter: true,
        width: '3%',
        key: 'id',
    }, {
        title: t('ФИО'),
        dataIndex: 'name_kz',
        key: 'fio',
        render: (_, r) => r[`name_${lang}`] || r.name_ru || r.name_kz,
        sorter: true,
    }, {
        title: t('Квартира'),
        dataIndex: 'flat',
        key: 'flat',
    }, {
        title: t('Этаж'),
        dataIndex: 'floor',
        key: 'floor',
        hide: false,
    }, {
        title: t('Кол-во проживающих'),
        dataIndex: 'people_quantity',
        key: 'people_quantity',
    }, {
        title: t('Кол-во комнат'),
        dataIndex: 'room_count',
        key: 'room_count',
        hide: true,
    }, {
        title: t('ИИН'),
        dataIndex: 'iin',
        key: 'iin',
        hide: true,
    }, {
        title: t('Моб. телефон'),
        dataIndex: 'mobile_phone',
        key: 'mobile_phone',
    }, {
        title: t('Email рассылка'),
        dataIndex: 'is_email_delivery',
        align: 'center',
        key: 'is_email_delivery',
        render: (value) => value ? <CheckOutlined/> : <CloseOutlined/>
    }, {
        title: t('Email'),
        dataIndex: 'email',
        key: 'email',
        hide: false,
    }, {
        title: t('Телефон'),
        dataIndex: 'phone',
        key: 'phone',
        render: (value) => <span className={'nonWrap'}>{value ? value : '-'}</span>,
        hide: false,
    }, {
        title: t('Дата регистрации'),
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value) => value ? <CustomDates data={value} type={'day'}/> : '-',
    }, {
        title: t('Код АЭСбыт'),
        dataIndex: 'aesbyt_account',
        key: 'aesbyt_account',
        hide: true,
    }, {
        title: t('Код энергокомпании'),
        dataIndex: 'energy_account',
        key: 'energy_account',
        hide: true,
    }, {
        title: t('Общая площадь'),
        dataIndex: 'full_square',
        key: 'full_square',
        hide: true,
    }, {
        title: t('Жилая площадь'),
        dataIndex: 'living_square',
        key: 'living_square',
        hide: true,
    }, {
        title: t('Отапливаемая площадь'),
        dataIndex: 'heated_square',
        key: 'heated_square',
        hide: true,
    },]

    return <>
        <RealEstateObjectsViewGate id={id}/>
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Card
                    title={<div>
                        <Space>
                            <span>{t('Просмотр Объекта Недвижимости')}</span>
                            <MappedDictItem id={id} type={'real-estate-objects'} link={false}/>
                        </Space>
                    </div>}
                    loading={loading} size={'small'}
                >
                    <Row gutter={[8, 8]}>
                        <Col span={16}>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <ShowAddressCard raw={selected} title={t('Адрес')} />
                                </Col>
                                <Col span={24}>
                                    <Descriptions
                                        bordered items={itemsON} size={'small'}
                                        title={t('Свойства объекта недвижимости')}
                                        className={'reo-view-descr'}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Descriptions
                                        bordered items={itemsCount} size={'small'}
                                        title={t('Количество')}
                                        className={'reo-view-descr'}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Descriptions
                                        bordered items={itemsHave} size={'small'}
                                        title={t('Наличие')}
                                        className={'reo-view-descr'}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <div className={styles.viewSider} style={{width: '100%'}}>
                                <ButtonGroup>
                                    <Button href={`/dictionaries/real-estate-objects/edit/${id}`}
                                            type={'primary'}>
                                        {t('Редактировать')}
                                    </Button>
                                    <Button
                                        href={`/dictionaries/real-estate-objects`}>{t('К списку')}</Button>
                                </ButtonGroup>
                                <Descriptions column={1} items={itemsDates}/>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={24}>
                <Card
                    title={t('Лицевые счета')} size={'small'}
                    style={cardStyle} loading={loading} className={"noPadding reo-view-accounts"}
                >
                    <DictionaryProTable
                        data={data} initColumns={columns} count={count} loading={loading}
                        exportXlsAction={exportXlsAction} hiddenActions={hiddenActions}
                        size={'small'}
                    />
                </Card>
            </Col>
        </Row>
    </>
}
