import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Select from 'antd/es/select';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {
    // CheckCircleOutlined,
    // CloseCircleOutlined,
    // DeleteOutlined,
    // EditOutlined,
    // EyeOutlined,
    // HistoryOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {$dictsSelects, $infoMaps} from "../../../models/infoModel/index.js";
import {
    setCreateRealEstateObjTypeEmbeddedEv, setCreateSectorEmbeddedEv,
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {
    $createdRealEstateObjectsId,
    RealEstateObjectsCreateGate,
    submitCreateRealEstateObjectsEv,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv, onCreateListSetFiltersEv, $onCreateList,
} from "../../../models/dictionaryRealEstateObjectsModel/index.js";
import CreateSectorEmbeddedForm
    from "../../../components/Embedded/CreateSectorEmbeddedForm/CreateSectorEmbeddedForm.jsx";
import {InputNumber, Switch} from "antd";
import CreateRealEstateObjTypeEmbeddedForm
    from "../../../components/Embedded/CreateRealEstateObjTypeEmbeddedForm/CreateRealEstateObjTypeEmbeddedForm.jsx";
import AddressREOSearchForm
    from '../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx';
import {
    $createdMissAddresses, $selectedAddress, resetSelectionEv
} from '../../../models/accountsREOSearchModel/index.js';
import Tooltip from 'antd/es/tooltip';
// import DictionaryProTable from "../../../components/DictionaryProTable/DictionaryProTable.jsx";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
// import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
// import CreatedBy from "../../../components/CreatedBy/CreatedBy.jsx";
// import ButtonGroup from "antd/es/button/button-group.js";
import Table from "antd/es/table";
import {paginationConfig} from "../../../utils/paginationConfig.js";


export default function CreateRealEstateObjects() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [realEstateObjectsForm] = Form.useForm()
    const submit = useUnit(submitCreateRealEstateObjectsEv)
    const loading = useUnit(universalDictEndpointFx.pending)

    const selectedAddress = useUnit($selectedAddress)
    const createdAddresses = useUnit($createdMissAddresses)
    const resetAddress = useUnit(resetSelectionEv)

    const createdId = useUnit($createdRealEstateObjectsId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const inspectorsMap = useStoreMap($infoMaps, maps => maps.inspectorsMap)
    const sectorOpts = useStoreMap($dictsSelects, sel => sel.sector)
    const sectorWithInspectorOpts = sectorOpts.map(item => {
        if (item.value === ' ' || item.value === '-') {
            return {
                label: item.label, value: item.value
            }
        } else {
            return {
                label: `${item.label} (${item.inspector_id ? `${item.inspector_id} ${inspectorsMap[item.inspector_id]?.name}` : 'Без контролера'})`,
                value: item.value
            }
        }
    })


    const realEstateObjectTypesOpts = useStoreMap($dictsSelects, sel => sel.real_estate_object_type)
    const setSectorEmbedded = useUnit(setCreateSectorEmbeddedEv)
    const setRealEstateObjTypeEmbedded = useUnit(setCreateRealEstateObjTypeEmbeddedEv)

    const [addrFields, setAddrFields] = useState({})
    const onSubmitHook = (form) => {
        const vals = form.getFieldValue()
        setAddrFields({town_id: vals?.town_id, street_id: vals?.street_id})
    }

    // console.log('XxX', selectedAddress, createdAddresses, addrFields)

    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                realEstateObjectsForm.resetFields()
                navigate(`/dictionaries/real-estate-objects/edit/${createdId}`)
            } else if (action === 'new') {
                realEstateObjectsForm.resetFields()
            }
            resetAddress()
            resetAttrs()
        }
    }, [createdId, action]);

    return <>
        <RealEstateObjectsCreateGate/>
        <AddressREOSearchForm single
                              isCard
                              itemsAsRow
                              cardTitle={t('Адрес')}
                              onSubmitHook={onSubmitHook}
        />
        <Form
            form={realEstateObjectsForm}
            layout={'vertical'}
            onFinish={(v) => submit(v)}
            size={'small'}
        >
            <Row justify={'space-between'} gutter={[8, 0]} style={{marginTop: '8px'}}>
                <Col span={4}>
                    <Card title={<StarredTitle title={t('Тип объекта недвижимости')}/>}
                          style={{height: '100%'}}
                          size={'small'}>
                        <Form.Item
                            name={'real_estate_object_type_id'}
                            rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select
                                options={[...realEstateObjectTypesOpts, {
                                    label: 'Создать', value: -1, emoji: true
                                }]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setRealEstateObjTypeEmbedded(true)
                                    } else {
                                        // realEstateObjectsForm.resetFields(['sector_id'])
                                        setRealEstateObjTypeEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
                                optionRender={(option) => (<Space>
                                                <span role="img" aria-label={option.data.label}>
                                                  {option?.data?.emoji ? <PlusOutlined
                                                      style={{color: '#1677ff'}}/> : ''}
                                                </span>
                                    {option?.data?.emoji ? <span style={{color: '#1677ff'}}>
                                                {option?.data.label}
                                                </span> : <span>
                                                    {option?.data.label}
                                                </span>}
                                </Space>)}
                            />

                        </Form.Item>
                        <CreateRealEstateObjTypeEmbeddedForm
                            form={realEstateObjectsForm} inCol={true}/>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title={t('Участок')} style={{height: '100%'}} size={'small'}>
                        <Form.Item
                            name={'sector_id'}
                            rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Select
                                options={[...sectorWithInspectorOpts, {
                                    label: 'Создать', value: -1, emoji: true
                                }]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setSectorEmbedded(true)
                                    } else {
                                        setSectorEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}
                                optionRender={(option) => (<Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                    {option?.data?.emoji ? <span style={{color: '#1677ff'}}>
                                                {option?.data.label}
                                            </span> : <span>{option?.data.label}</span>}
                                </Space>)}
                            />
                        </Form.Item>
                        <CreateSectorEmbeddedForm form={realEstateObjectsForm} inCol={true}/>
                    </Card>
                </Col>
                <Col span={9}>
                    <Card size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Row gutter={[8, 0]}>
                            <Col span={14}>
                                <Form.Item
                                    name={'name_ru'}
                                    label={'ЖК'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    name={'cadastral_number'}
                                    label={'РКА'}
                                    rules={[{
                                        required: false,
                                        message: t('Обязательное поле')
                                    }, {pattern: /^[0-9]{16}$/, message: t('16 цифр')},]}
                                >
                                    <Input showCount maxLength={16}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={'floor_count'}
                                    label={'Этажей'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={'entrance_count'}
                                    label={'Подъездов'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={'apartment_count'}
                                    label={'Квартир'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card size={'small'} style={{height: '100%'}} loading={loading}>
                        <Row gutter={[8, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    name={'is_electricity_network_present'}
                                    label={'Электричество'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Switch
                                        defaultChecked={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={'is_heat_network_present'}
                                    label={'Тепловая сеть'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item
                                    name={'is_elevator_present'}
                                    label={'Лифт'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={'is_mailboxes_present'}
                                    label={'Почтовые ящики'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={'gis_url'}
                                    label={'2ГИС'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <OnCreateList town_id={addrFields?.town_id} street_id={addrFields?.street_id}/>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    realEstateObjectsForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Tooltip
                    title={!(selectedAddress || createdAddresses.length > 0) ? t('Адрес не выбран') : (selectedAddress && selectedAddress?.real_estate_object_id !== null) ? t('ОН уже существует') : null}>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('new')
                                realEstateObjectsForm.submit()
                            }}
                            type={'primary'}
                            ghost
                            disabled={!(selectedAddress || createdAddresses.length > 0) || (selectedAddress && selectedAddress?.real_estate_object_id !== null)}
                    >
                        {t('Сохранить ОН и создать следующий')}
                    </Button>
                </Tooltip>
                <Tooltip
                    title={!(selectedAddress || createdAddresses.length > 0) ? t('Адрес не выбран') : (selectedAddress && selectedAddress?.real_estate_object_id !== null) ? t('ОН уже существует') : null}>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('edit')
                                realEstateObjectsForm.submit()
                            }}
                            type={'primary'}
                            disabled={!(selectedAddress || createdAddresses.length > 0) || (selectedAddress && selectedAddress?.real_estate_object_id !== null)}
                    >
                        {t('Сохранить ОН и продолжить редактирование')}
                    </Button>
                </Tooltip>
            </Space>
        </Row>
    </>
}

function OnCreateList({town_id, street_id}) {
    const {t} = useTranslation()
    const setFilters = useUnit(onCreateListSetFiltersEv)
    const {data, count} = useUnit($onCreateList)

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [sortField, setSortField] = useState('house')
    const [sortOrder, setSortOrder] = useState('ascend')

    useEffect(() => {
        setFilters({
            town_id, street_id, page, limit, sortField,
            sortOrder:  sortOrder === 'descend' ? 'desc' : 'asc'
        })
    }, [town_id, street_id, limit, page, sortField, sortOrder]);

    const onTableChange = (pagination, _, sorter) => {
        const {order, field} = sorter
        const {current, pageSize} = pagination
        setLimit(pageSize || 10)
        setPage(current || 1)
        setSortField(order === undefined ? 'house' : field)
        setSortOrder(order || 'ascend')
    }

    const columns = [
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            sorter: true,
            key: 'town_id',
            render: (town_id) => <MappedDictItem id={town_id} type={'town'}/>
        }, {
            title: t('Район города'),
            dataIndex: 'town_distr_id',
            sorter: true,
            key: 'town_distr_id',
            render: (town_distr_id, _record) => <MappedDictItem id={town_distr_id}
                                                                type={'town_distr'}/>
        }, {
            title: t('Улица'),
            dataIndex: 'street_id',
            sorter: true,
            key: 'street_id',
            render: (street_id, _record) => <MappedDictItem id={street_id} type={'street'}/>
        },

        {
            title: t('Дом'),
            dataIndex: 'house',
            sorter: true,
            key: 'house',
            render: (house, _record) => house
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            sorter: true,
            key: 'corpus',
            render: (corpus, _record) => corpus
        },
        {
            title: t('Инспектор'),
            dataIndex: 'inspector_full',
            sorter: true,
            key: 'inspector_full',
            render: (v, _record) => v
        },
    ]

    if ((data || []).length === 0) return null

    return <Card
        title={<div>
            <span style={{marginRight: '8px'}}>{t('Существующие ОН на улице')}</span>
            <MappedDictItem id={street_id} type={'street'} link={false}/>
        </div>}
        size={'small'}
    >
        <Table
            dataSource={[...(data || [])]}
            rowKey={'id'}
            columns={columns}
            size={'small'}
            bordered
            search={false}
            options={{density: false, reload: false}}
            onChange={onTableChange}
            pagination={{
                ...paginationConfig,
                pageSize: parseInt(limit ?? 10),
                current: parseInt(page ?? 0),
                total: count
            }}
            // loading={loading}
        />
    </Card>
}
