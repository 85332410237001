import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import {LogoutOutlined, UserOutlined, IdcardOutlined} from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import Space from 'antd/es/space';
import {$currentUser} from '../../models/infoModel/index.js';
import {logoutEv} from '../../models/authModel/index.js';

export default function ProfileDropdown() {
    const curUser = useUnit($currentUser)
    const onLogout = useUnit(logoutEv)

    const {t} = useTranslation()

    const items = [
        {
            key: '/profile',
            label: <Tooltip title={t('Профиль')} placement={'left'}>
                <Button href={'/profile'} icon={<IdcardOutlined/>}/>
            </Tooltip>
        },
        {
            key: '/logout',
            label: <Tooltip title={t('Выход')} placement={'left'}>
                <Button type={'primary'}
                        danger
                        icon={<LogoutOutlined/>}
                        onClick={() => onLogout()}
                />
            </Tooltip>
        }
    ]

    return <Space>
        <span>{`${curUser?.surname ?? ''} ${curUser?.firstname ?? ''} ${curUser?.patronymic ?? ''}`}</span>
        <Dropdown menu={{items}} placement={'bottom'}>
            <Button icon={<UserOutlined/>}/>
        </Dropdown>
    </Space>
}