import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useStoreMap, useUnit} from "effector-react";
import {
	$paymentExportData,
	dataPaymentExportModalEv, exportPaymentExportEv, getExportedPaymentsEv,
	onPrepareExportSingleEv,
	openPaymentExportModalEv,
	PaymentExportGate,
} from "../../models/paymentModel/index.js";
import {$infoMaps} from "../../models/infoModel/index.js";
import {searchAndActions} from "../../utils/searchAndActions.js";
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import Table from "antd/es/table";
import Divider from "antd/es/divider";
import Tabs from "antd/es/tabs";
import Button from "antd/es/button";
import Row from "antd/es/row";
import Col from "antd/es/col";
import './components/style.css'
import ButtonGroup from "antd/es/button/button-group";
import Tooltip from "antd/es/tooltip";
import {
	QuestionCircleOutlined,
} from "@ant-design/icons";
import {Popconfirm} from "antd";
import ExcelOutlined from "../../components/ExcelIcon/EcxelIcon.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import CreatedBy from "../../components/CreatedBy/CreatedBy.jsx";
import Card from "antd/es/card";
import {paginationConfig} from "../../utils/paginationConfig.js";
import PaymentTableProv from "./components/PaymentTableProv.jsx";
import FormatNumber from "../../components/FormatNumber/FormatNumber.jsx";
import PaymentExportModal from "./components/PaymentExportModal.jsx";
import {$dictsSelects} from "../../models/infoModel/index.js";
import {Link} from "react-router-dom";


const options = [
	{
		label: 'Не подготовлено',
		value: 'not_prepared',
	},
	{
		label: 'Подготовлено',
		value: 'prepared',
	},
	{
		label: 'Не доступно для банка',
		value: 'not_exported',
	},
	{
		label: 'Доступно для банка',
		value: 'success',
	},
]

export default function PaymentExport() {
	const {t} = useTranslation()
	const {data} = useUnit($paymentExportData)

	const {search} = searchAndActions()

	const exportXlsAction = useUnit(exportPaymentExportEv)

	const keysData = data.map(item => {
		return {
			...item,
			key: item.id
		}
	}).filter(item => !dayjs(item?.bank_day).isSame(dayjs(), 'day'))

	const dataPaymentFalse = keysData.filter(item => item.confirmation_status === false)
	const dataPaymentNew = keysData.filter(item => item.export_status === 'new' || item.export_status === 'error')
	const dataPaymentPrepared = keysData.filter(item => item.export_status === 'prepare' || item.export_status === 'prepared')

	// const loading = useUnit(getPaymentDataFx.pending)
	const [selectedRowKey, setSelectedRowKey] = useState(null);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRowData, setSelectedRowData] = useState([]);
	const [checkStatus, setCheckStatus] = useState(false);
	const [checkStatusNew, setCheckStatusNew] = useState(false);
	const [checkStatusPrepare, setCheckStatusPrepare] = useState(false);
	const [currentPayment, setCurrentPayment] = useState(null);
	const selectedNew = selectedRowData.filter(item => item.export_status === 'new' || item.export_status === 'error')
	const selectedPrepared = selectedRowData.filter(item => item.export_status === 'prepare' || item.export_status === 'prepared')

	console.log('selectedPrepared' ,selectedPrepared)

	// const [bankDay, setBankDay] = useState(false);
	const [date, setDate] = useState(null);
	const [activeKey, setActiveKey] = useState('view');

	const onConfirmSingleEvent = useUnit(onPrepareExportSingleEv)
	// const onExportToBankEvent = useUnit(onExportToBankSingleEv)
	const getPayment = useUnit(getExportedPaymentsEv)

	const openModal = useUnit(openPaymentExportModalEv)
	const dataModal = useUnit(dataPaymentExportModalEv)

	const bankOpts = useStoreMap($dictsSelects, sel => sel.bankBranch)?.filter(item => item.is_contract_signed === true)
	const bankBranch = useStoreMap($infoMaps, sel => sel.bankBranchMap)


	const columns = [
		{
			title: t('Дата'),
			dataIndex: 'bank_day',
			key: 'bank_day',
			render: (date) => <CustomDates data={date} type={'day'}/>

		},
		{
			title: t('Банк'),
			dataIndex: 'bank_id',
			key: 'bank_id',
			render: (bank_id) => {
				return <Link to={`/banks/${bankBranch[bank_id]?.id}`}>
					{
						`${bankBranch[bank_id]?.branch} ${bankBranch[bank_id]?.name}`
					}
				</Link>
			}

		},
		{
			title: t('Кол-во платежей'),
			dataIndex: 'payment_count',
			key: 'payment_count',
			// render: (bank_id) => <MappedDictItem id={bank_id} type={'banks'}/>
		},
		{
			title: t('Сумма'),
			dataIndex: 'payment_sum',
			key: 'payment_sum',
			render: (value) => <FormatNumber value={value} type={2}/>
			// render: (bank_id) => <MappedDictItem id={bank_id} type={'banks'}/>
		},
		{
			title: t('Статус'),
			dataIndex: 'export_status',
			key: 'export_status',
			render: (value) => {
				if (value === 'new') {
					return 'Не подготовлено'
				} else if (value === 'processing') {
					return 'В работе'
				} else if (value === 'not_ready') {
					return 'Не распределено по ЛС'
				} else if (value === 'error') {
					return 'Ошибка'
				} else if (value === 'prepared') {
					return 'Подготовлено'
				} else if (value === 'success') {
					return 'Предоставлено банку'
				} else if (value === 'not_ready') {
					return 'Не распределено'
				}
			}
		},
		{
			title: t('Ответственный'),
			dataIndex: 'export_user_id',
			key: 'export_user_id',
			render: (id) => <CreatedBy id={id}/>
		},
		{
			title: t('Дата действия'),
			dataIndex: 'export_date',
			key: 'export_date',
			render: (date) => <CustomDates data={date} />
		},
		{
			title: t('Действия'),
			width: '5%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id, row) => <ButtonGroup>

				{
					row.export_status === 'new' || row.export_status === 'error' ? <Popconfirm title={t('Вы уверены?')}
															 okText={t('Да')}
															 onConfirm={() => onConfirmSingle(row)}
															 placement={'left'}
															 icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
					>
						{
							<Tooltip title={t('Подготовить эту запись')} placement={'top'}>
								<Button
									type={'primary'}
									size={'small'}
									disabled={
										data.some(item =>
											item?.bank_day < row?.bank_day &&
											item?.bank_id === row?.bank_id &&
											(item?.export_status === 'new' || item?.export_status === 'error')
										) || row.export_status === 'not_ready'
									}
								>
									Подготовить
								</Button>
							</Tooltip>
						}
					</Popconfirm> : row.export_status === 'prepared' ? <Popconfirm title={t('Вы уверены?')}
																				 okText={t('Да')}
																				 onConfirm={() => onOpenModal('single', row)}
																				 placement={'left'}
																				 icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
					>
						{
							<Tooltip title={t('Сделать доступным для банка эту запись')} placement={'top'}>
								<Button
									type={'primary'}
									size={'small'}
									disabled={
										data.some(item =>
											item?.bank_day < row?.bank_day &&
											item?.bank_id === row?.bank_id &&
											item?.export_status === 'prepared'
										)
									}
								>
									Сделать доступным для банка
								</Button>
							</Tooltip>
						}
					</Popconfirm> : ''
				}
			</ButtonGroup>
		}

	]

	const filters = [
		{name: 'bank_id', label: t('Банк'), type: 'select', options: bankOpts},
		{name: 'payment_date_from', label: t('Дата от'), type: 'date', },
		{name: 'payment_date_to', label: t('Дата до'), type: 'date', },
		{name: 'export_status', label: t('Статус'), type: 'select', options: options},
	]

	const onSelectChange = (newSelectedRowKeys, newSelectedRowData) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRowData(newSelectedRowData)
		checkInBankDates(newSelectedRowData)
	};

	const checkInBankDates = (newSelected) => {
		// const sorted = newSelected.sort((a, b) => new Date(a.bank_day) - new Date(b.bank_day));

		const dataNew = newSelected.filter(item => item.export_status === 'new' || item.export_status === 'error').sort((a, b) => new Date(a.bank_day) - new Date(b.bank_day))
		const dataPrepare = newSelected.filter(item => item.export_status === 'prepare' || item.export_status === 'prepared').sort((a, b) => new Date(a.bank_day) - new Date(b.bank_day))
		const selectedIdsNew = dataNew.map(item => item.id)
		const selectedIdsPrepare = dataPrepare.map(item => item.id)

		const lastNew = dataNew[dataNew.length - 1]
		const lastPrepare = dataPrepare[dataPrepare.length - 1]

		const resultNew = dataPaymentNew.some(item =>
			item?.bank_day < lastNew?.bank_day &&
			item?.bank_id === lastNew?.bank_id &&
			(item?.export_status === 'new' || item?.export_status === 'error') &&
			!selectedIdsNew.includes(item.id)
		)
		const resultPrepare = dataPaymentPrepared.some(item =>
			item?.bank_day < lastPrepare?.bank_day &&
			item?.bank_id === lastPrepare?.bank_id &&
			(item?.export_status === 'new' || item?.export_status === 'error') &&
			!selectedIdsPrepare.includes(item.id)
		)

		setCheckStatusNew(resultNew)
		setCheckStatusPrepare(resultPrepare)

		// const selectedIds = newSelected.map(item => item.id)
		// const last = sorted[sorted.length - 1]
		// const result  = data.some(item =>
		// 	item?.bank_day < last?.bank_day &&
		// 	item?.bank_id === last?.bank_id &&
		// 	(item?.export_status === 'new' || item?.export_status === 'error') &&
		// 	!selectedIds.includes(item.id)
		// )
		// setCheckStatus(result)
	}

	const onOpenModal = (actionType, data) => {
		let payload = null

		if (actionType === 'selected' || actionType === 'all') {
			const raw =  actionType === 'selected' ? selectedPrepared : dataPaymentPrepared
			payload  = {
				payment_exports_ids:  raw?.map(item => item.id),
			}
		} else if (actionType === 'single') {
			payload = {
				payment_exports_ids: [data.id]
			}
		}
		dataModal({
			page: 'confirm',
			type: actionType,
			// data: actionType === 'single' ?  data : payload
			data: payload
		})
		openModal()

	}

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record) => ({
			disabled: record.export_status === 'success' || record.export_status === 'not_ready' ? true : false
			,
		}),
	};

	const onChangeTab = (payload) => {
		setActiveKey(payload)
	}

	const handleRowClick = (record) => {
		setSelectedRowKey(record.key);
		getPayment(record.id)
		setCurrentPayment(record.id)
	};

	const rowClassName = (record) => {
		return record.key === selectedRowKey ? 'payment-selected-row' : '';
	};

	const tabs = [
		{
			key: 'view',
			label: t('Платежи'),
			children: <PaymentTableProv currentPayment={currentPayment}/>
		},
		{
			key: 'error',
			label: t('Ошибки'),
			// children: <PaymentTableError />
		},
	]

	const onConfirmSingle = (row) => {
		const data = {
			payment_exports_ids: [row.id],
		}
		onConfirmSingleEvent(data)
	}

	const onConfirmAll = () => {
		const data = {
			payment_exports_ids: dataPaymentNew.map(item => item.id),
		}

		onConfirmSingleEvent(data)
	}

	const onConfirmSelected = () => {
		const data = {
			payment_exports_ids: selectedRowData.map(item => item.id),
		}
		onConfirmSingleEvent(data)
	}

	return <>
		<PaymentExportGate search={search.toString()}/>
		<PaymentExportModal/>
		<FilterBlock items={filters} />
		<Divider/>
		<Row gutter={[8, 8]}>
			<Col>
				<Popconfirm
					title={t('Подготовить все не подготовленные строки?')}
					okText={t('Да')}
					onConfirm={() => onConfirmAll()}
					placement={'left'}
					icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
				>
					<Button type={'primary'} disabled={dataPaymentNew.length > 0 ? false : true} size={'small'}>
						Подготовить все не подготовленные ({dataPaymentNew.length > 0 ? dataPaymentNew.length : 0})
					</Button>
				</Popconfirm>
			</Col>
			<Col>
				<Popconfirm
					title={t('Подготовить выделенные строки?')}
					okText={t('Да')}
					onConfirm={() => onConfirmSelected()}
					placement={'left'}
					icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
				>

					<Button
						type={'primary'}
						disabled={(checkStatusNew === false && selectedNew.length > 0) ? false : true}
					>
						Подготовить все отмеченные
						({selectedNew.length > 0 ? selectedNew.length : 0})
					</Button>
				</Popconfirm>
			</Col>
			<Col>
				<Popconfirm
					title={t('Отменить данные действие невозможно')}
					description={<div>
						{t('Отменить данное действие не возможно')}<br/>
						{t('Вы уверены что хотите предоставить данные банку?')}
					</div>}
					okText={t('Да')}
					onConfirm={() => onOpenModal('all')}
					placement={'left'}
					icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
				>
					<Button
						type={'primary'} disabled={dataPaymentPrepared.length > 0 ? false : true}
					>
						Сделать доступным все подготовленные
						({dataPaymentPrepared.length > 0 ? dataPaymentPrepared.length : 0})
					</Button>
				</Popconfirm>
			</Col>
			<Col>
				<Popconfirm
					title={t('Отменить данные действие невозможно')}
					description={<div>
						{t('Отменить данное действие не возможно')}<br/>
						{t('Вы уверены что хотите предоставить данные банку?')}
					</div>}
					okText={t('Да')}
					onConfirm={() => onOpenModal('selected')}
					placement={'left'}
					icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
				>
					<Button
						type={'primary'}
						// disabled={selectedPrepared.length > 0 ? false : true}
						disabled={(checkStatusPrepare === false && selectedPrepared.length > 0) ? false : true}

					>
						Сделать доступным все отмеченные ({selectedPrepared.length > 0 ? selectedPrepared.length : 0})
					</Button>
				</Popconfirm>
			</Col>
			<Col>
				<Button
					onClick={() => exportXlsAction(search)}
					size={'small'}
				>
					<ExcelOutlined size={18}/>
					Экспорт
				</Button>
			</Col>
		</Row>
		<br/>
		<Card size={'small'}>
			<div style={{color: 'red', marginLeft: '10px'}}>
				{checkStatusNew === true || checkStatusPrepare === true ? 'Нельзя пропускать дни в распределении' : ''}
			</div>
			<Table
				columns={columns}
				dataSource={keysData}
				size={'small'}
				rowSelection={rowSelection}
				rowClassName={rowClassName}
				onRow={(record) => ({
					onClick: () => handleRowClick(record),
				})}
				pagination={{
					...paginationConfig,
					pageSize: 10,
					// current: parseInt(page),
					// total: count
				}}
			/>
		</Card>
		<Divider/>
		<Tabs
			defaultActiveKey={'view'}
			items={tabs}
			destroyInactiveTabPane
			activeKey={activeKey}
			onChange={onChangeTab}
			type={'card'}
		/>
	</>
}
