import {combine, createStore} from 'effector';
import i18n from '../../i18n.js';

const lang = localStorage.getItem('lang') ?? 'ru'

export const $info = createStore({
    town: [],
    town_distr: [],
    street: [],
    street_type: [],
    tax_branch: [],
    nationality: [],
    service_class: [],
    bank_account_type: [],
    service: [],
    service_payment_type: [],
    service_unit: [],
    preference_category: [],
    kbe: [],
    kbk: [],
    knp: [],
    accruals: [],
    calc_type: [],
    activity_type: [],
    bank_api_address: [],
    bank_api_info: [],
    map: [],
    bank: [],
    consumer_category: [],
    economy_sector: [],
    inspector: [],
    inspector_category: [],
    sector_inspector_category: [],
    real_estate_object: [],
    user: [],
    sector: [],
    provider: [],
    epd_templates: [],
    currency: [],
    role: [],
    permission: [],
    position: [],
    ksk: [],
    servicesColumns: [],
    calcTypeAlgorithms: [],
    chief_inspector: [],
    real_estate_object_type: [],
    bank_commission: [],
    organization_type: [],
    algorithm: [],
    closed_months: [],
    current_date: null,
    closing_month_task: null
})

export const $infoMaps = combine($info, (info) => {
    const shStTypeMap = Object.fromEntries(info?.street_type?.map(i => [i.id, {short_name_ru: i.short_name_ru, short_name_kz: i.short_name_kz}]) ?? [])
    return {
        townsMap: Object.fromEntries(info?.town?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        districtsMap: Object.fromEntries(info?.town_distr?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        streetsMap: Object.fromEntries(info?.street?.map(i => [i.id, `${shStTypeMap[i.street_type_id][`short_name_${lang}`]} ${i?.[`name_${lang}`]}`]) ?? []),
        streetTypesMap: Object.fromEntries(info?.street_type?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        shortStTypeMap: shStTypeMap,
        nationalityMap: Object.fromEntries(info?.nationality?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        serviceClassesMap: Object.fromEntries(info?.service_class?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        bankAccountTypeMap: Object.fromEntries(info?.bank_account_type?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        bankMap: Object.fromEntries(info?.bank?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        bankBranchMap: Object.fromEntries(info?.bank?.map(i => [i.id, {name: i?.[`name_${lang}`], branch: i.branch, id: i.id }]) ?? []),
        servicesMap: Object.fromEntries(info?.service?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        servicePaymentTypeMap: Object.fromEntries(info?.service_payment_type?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        serviceUnitsMap: Object.fromEntries(info?.service_unit?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        serviceUnitsShortMap: Object.fromEntries(info?.service_unit?.map(i => [i.id, i?.[`short_name_${lang}`] ?? i?.[`name_${lang}`]]) ?? []),
        preferenceCategoryMap: Object.fromEntries(info?.preference_category?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        kbeMap: Object.fromEntries(info?.kbe?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        kbkMap: Object.fromEntries(info?.kbk?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        knpMap: Object.fromEntries(info?.knp?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        accrualsMap: Object.fromEntries(info?.accruals?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        calcTypeMap: Object.fromEntries(info?.calc_type?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        activityTypeMap: Object.fromEntries(info?.activity_type?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        bankApiAddressMap: Object.fromEntries(info?.bank_api_address?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        bankApiInfoMap: Object.fromEntries(info?.bank_api_info?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        // payment_type: Object.fromEntries(info?.service_payment_type_id?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        banksMap: Object.fromEntries(info?.bank?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        consumerCategoryMap: Object.fromEntries(info?.consumer_category?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        economySectorMap: Object.fromEntries(info?.economy_sector?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        chiefInspectorMap: Object.fromEntries(info?.chief_inspector?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        // inspectorsMap: Object.fromEntries(info?.inspector?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        inspectorsMap: Object.fromEntries(info?.inspector?.map(i => [i.id, {chief_inspector_id: i.chief_inspector_id, id: i.id, name: i?.[`name_${lang}`]}]) ?? []),
        inspectorsCategoryMap: Object.fromEntries(info?.inspector_category?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        sectorInspectorsCategoryMap: Object.fromEntries(info?.sector_inspector_category?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        realEstateObjectTypeMap: Object.fromEntries(info?.real_estate_object_type?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        realEstateObjectMap: Object.fromEntries(info?.real_estate_object?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        usersMap: Object.fromEntries(info?.user?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        sectorsMap: Object.fromEntries(info?.sector?.map(i => [i.id]) ?? []),
        providersMap: Object.fromEntries(info?.provider?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        taxBranchesMap: Object.fromEntries(info?.tax_branch?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        addressesMap: Object.fromEntries(info?.address?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        templatesMap: Object.fromEntries(info?.epd_templates?.map(i => [i.id, i?.[`name_${lang}`], i?.[`name_${lang}`]]) ?? []),
        organizationTypeMap: Object.fromEntries(info?.organization_type?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        currencyMap: info?.currency?.map(i => [i.id, i?.[`name_${lang}`]]) ?? [],
        roleMap: info?.role?.map(i => [i.id, i?.[`name_${lang}`]]) ?? [],
        permissionMap: info?.permission?.map(i => [i.id, i?.[`name_${lang}`]]) ?? [],
        positionMap: info?.position?.map(i => [i.id, i?.[`name_${lang}`]]) ?? [],
        kskMap: info?.ksk?.map(i => [i.id, i?.[`name_${lang}`]]) ?? [],
        servicesColumnsMap: Object.fromEntries(info?.servicesColumns?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        calcTypeAlgorithmsMap: Object.fromEntries(info?.calcTypeAlgorithms?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
        bankCommissionMap: Object.fromEntries(info?.bank_commission?.map(i => [i.id, `${i?.commission} % / ${i?.reimbursement} %`]) ?? []),
        algorithmsMap: Object.fromEntries(info?.algorithm?.map(i => [i.id, i?.[`name_${lang}`]]) ?? []),
    }
})


export const $onSelectFilter = createStore(null)

export const $dictsSelects = combine($info, (info) => {
    const empty = {
        label: ' ',
        value: ' '
    }

    const no_inspector = {
        label: 'Без Контролера',
        value: '-',
    }

    const no_sector = {
        label: 'Без участка',
        value: '-',
    }

    const shStTypeMap = Object.fromEntries(info?.street_type?.map(i => [i.id, {short_name_ru: i.short_name_ru, short_name_kz: i.short_name_kz}]) ?? [])

    return {
        towns: [
            empty,
            ...info?.town?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id})),
        ],
        districts: [empty, ...info?.town_distr?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id, town_id: i.town_id}))],
        streets: [empty,...info?.street?.map(i => ({label: `${i.id} ${shStTypeMap[i.street_type_id]?.[`short_name_${lang}`]} ${i?.[`name_${lang}`]}`, value: i.id, town_id: i.town_id}))],
        streetTypes: [empty, ...info?.street_type?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        sectors: [empty, ...info?.sector?.map(i => ({label: `${i.id}`, value: i.id, town_id: i.town_id}))],
        users: info?.user?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]} `, value: i.id})),
        kbk: [empty, ...info?.kbk?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        kbe: [empty, ...info?.kbe?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        knp: [empty, ...info?.knp?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        taxBranch: [empty, ...info?.tax_branch?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        calcType: [empty, ...info?.calc_type?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        orgType: [empty, ...info?.organization_type?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        servicePaymentType: [empty, ...info?.service_payment_type?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        economySector: [empty, ...info?.economy_sector?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        providers: [empty, ...info?.provider?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        serviceClasses: [empty, ...info?.service_class?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        // nationality: [empty, ...info?.nationality?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        preference_category: [empty, ...info?.preference_category?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        consumer_category: [empty, ...info?.consumer_category?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],

        inspector: [empty, no_inspector, ...info?.inspector?.map(i => ({label: `${i.chief_inspector_id}-${i.id} ${i?.[`name_${lang}`]} `, value: i.id, town_id: i.town_id, sector_count: i.sector_count}))],
        chief_inspector: [empty, ...info?.chief_inspector?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]} `, value: i.id}))],

        bank: [empty, ...info?.bank?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]} `, value: i.id, is_contract_signed: i?.is_contract_signed}))],
        bankBranch: [empty, ...info?.bank?.map(i => ({label: `${i.branch} ${i?.[`name_${lang}`]} `, value: i.id, is_contract_signed: i?.is_contract_signed}))],
        payment_type: [empty, ...info?.service_payment_type?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]} `, value: i.id}))],
        sector: [empty, no_sector, ...info?.sector?.map(i => ({label: `${i.id}`, value: i.id, town_id: i.town_id, inspector_id: i.inspector_id}))],
        real_estate_object_type: [empty, ...info?.real_estate_object_type?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id})) ],
        approveStatuses: [empty,{label: i18n.t('approveStatuses.empty'), value: null}, {label: i18n.t('approveStatuses.new'), value: 'new'}, {label: i18n.t('approveStatuses.approved'), value: 'approved'}, {label: i18n.t('approveStatuses.rejected'), value: 'rejected'}],
        service_units: [empty,...info?.service_unit?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        is_actual: [empty, {label: 'Все', value: 'all'}, {label: 'Акутальные', value: true}, {label: 'Неактуальные', value: false}],
        services: [empty, ...info?.service?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id, providers: i?.providers ?? []}))],
        algorithms: [empty, ...info?.algorithm?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        template: [empty, ...info?.epd_templates?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        bank_account_types: [empty, ...info?.bank_account_type?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        bank_commission: [empty, ...info?.bank_commission?.map(i => ({label: `${i?.commission} % / ${i?.reimbursement} %`, value: i.id}))],
        closed_months: [empty, ...info?.closed_months?.map(i => ({label: `${i.year}-${i.month}`, value: i.date}))],
        providerBeneficiaries: [...info?.provider?.filter(i => i.is_beneficiary === true)?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        // currencies: [empty, ...info?.currency?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))],
        // ksk: info?.ksk ? [empty, ...info?.ksk?.map(i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id}))] : [],
    }
})

export const $currentUser = createStore({
    id: null,
    username: null,
    role: null,
    firstname: null,
    surname: null,
    patronymic: null,
    city: null,
    district: null,
    street: null,
    created_at: null,
})

export const $localDict = createStore({
    inspector_ids: 'Контролеры',
    sector_ids: 'Участки',
    reo_ids: 'Объекты недвижимости',
    filters: 'Фильтры',
    report_date: 'Дата отчета',
    select_all: 'Выбраны все',
    town_distr_id: 'Районы города',
    area_id: 'Участок',
    town_id: 'Город',
    account_ids: 'Лицевые счета',
    is_email_delivery: 'Доставлять на E-mail',
    has_email: 'Наличие E-mail',
    surname: 'Фамилия',
    email: 'E-mail',
    ID: 'ID',
    recreate_pdf: 'Пересоздать PDF',
    send_to_bank: 'Передать в БВУ',
    include_payments: 'Учет текущих платежей',
    past_period: 'Закрытый период',
    include_personal_data: 'Перс. данные',
    include_taxes: 'Перс. данные налога',
    generate_type: 'Тип объектов',
    account: 'ЛС',
    reo: 'ОН',
    inspector: 'Контролер',
    sector: 'Участок',


})

export const $epdFieldNameDict = createStore({
    service_name: 'Наименование услуг',
    sum_saldo_begin: 'Сальдо на (начало месяца)',
    sum_pay: 'Оплата',
    pay_count: 'Пред. показание',
    check_count: 'Текущее показание',
    consumption: 'Количество',
    tarif_value: 'Стоимость',
    sum_calc: 'Начислено за (месяц)',
    sum_fine: 'Пеня',
    sum_recalc: 'Перерасчет',
    sum_saldo_end: 'К оплате',
    provider_name: 'Наименование провайдера',
    people_quantity: 'Количество людей'
})

// FIXME HON
export const $epdCellTypeDict = createStore({
    normal: 'Обычный',
    merge: 'Соединенный',
    sum_column: '2+3 ячейка',
    bold: 'Жирный',
    double: 'Двойной',
    column: 'Мин Макс'
})
