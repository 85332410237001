import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useEffect} from 'react';
import {
    $correctionPeriod, $correctionType, $newSrvsByAcc,
    $selectedAcc,
    $srvsByAcc, addSrvEv, correctionTypeEnum
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {isId} from '../../../../utils/helpers.js';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import ServiceCardSingle from './ServiceCardSingle.jsx';
import {PlusOutlined} from '@ant-design/icons';

export default function ServicesBlock({form}) {
    const {t} = useTranslation()

    const newSrvsByAcc = useUnit($newSrvsByAcc)
    const addSrv = useUnit(addSrvEv)

    const srvsByAcc = useUnit($srvsByAcc)
    const selectedAcc = useUnit($selectedAcc)
    const corType = useUnit($correctionType)
    const selectedPeriod = useUnit($correctionPeriod)


    useEffect(() => {
        if (isId(selectedAcc) && srvsByAcc?.length > 0) {
            // if (corType === correctionTypeEnum.change_existing_params || corType === correctionTypeEnum.change_tariff) {
            if (corType !== correctionTypeEnum.new_acc_to_srv) {
                srvsByAcc.forEach(s => {
                    const fieldPrefix = [selectedAcc.toString(), s.provider_id.toString(), s.service_id.toString()]
                    for (const [key, value] of Object.entries(s)) {
                        if (['provider_id', 'service_id', 'external_number', 'people_quantity', 'service_square', 'is_not_accrued', 'is_not_balance_transfer', 'is_not_printed'].includes(key) && value) {
                            form.setFieldValue([...fieldPrefix, key], value)
                        } else if (['date_from', 'date_to', 'not_accrued_from', 'not_accrued_to', 'not_balance_transfer_from', 'not_balance_transfer_to', 'not_printed_from', 'not_printed_to'].includes(key) && value) {
                            form.setFieldValue([...fieldPrefix, key], dayjs(value))
                        }
                    }
                    const tarif = selectedPeriod === 'current'
                        ? s.tariffs
                            ?.toSorted((a, b) => dayjs(b.date_from).unix() - dayjs(a.date_from).unix())
                            ?.find(t => dayjs(t.date_from).isBefore(dayjs(dayjs().endOf('month'))))
                        : s.tariffs
                            ?.toSorted((a, b) => dayjs(b.date_from).unix() - dayjs(a.date_from).unix())
                            ?.find(t => dayjs(t.date_from).isAfter(dayjs(dayjs(dayjs().endOf('month')).endOf('month'))))
                    if (tarif) {
                        form.setFieldValue([...fieldPrefix, 'tarif_id'], tarif?.tarif_id)
                        form.setFieldValue([...fieldPrefix, 'tarif_date_from'], dayjs(tarif?.date_from))
                    }
                })
            }
        }
    }, [selectedAcc, srvsByAcc, corType])

    return <Card size={'small'} title={t('Параметры услуг')} styles={{body: {backgroundColor: '#f6f6f6'}}}>
        <Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
            {corType === correctionTypeEnum.new_acc_to_srv && newSrvsByAcc.length > 0
                && newSrvsByAcc?.map((s, idx) => <Row style={idx > 0 ? {marginTop: 8} : {}}>
                    <ServiceCardSingle isMass={false} account_id={selectedAcc} provider_id={s.provider_id} srv_id={s.id} deletable={srvsByAcc.length > 1} cardKey={s.id} key={s.id} form={form} />
                </Row>)}
            {(corType !== correctionTypeEnum.new_acc_to_srv)
                && srvsByAcc?.map((s, idx) => <Row style={idx > 0 ? {marginTop: 8} : {}}>
                    <ServiceCardSingle isMass={false} account_id={selectedAcc} provider_id={s.provider_id} srv_id={s.service_id} deletable={srvsByAcc.length > 1} cardKey={s.service_id} form={form} />
                </Row>)
            }
            {
                corType === correctionTypeEnum.new_acc_to_srv
                && <Button onClick={addSrv} icon={<PlusOutlined/>} type={'dashed'} style={{marginTop: 8, minHeight: '6.4rem', minWidth: '9.6rem', alignSelf: 'center'}}/>
            }
        </Col>
    </Card>
}