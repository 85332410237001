import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import InputNumber from 'antd/es/input-number';
import Input from 'antd/es/input';
import ActivitySubForm from '../../../DictionaryProviders/components/ActivitySubForm.jsx';

export default function Edit({form, isMass, disabled, fieldPrefix, massEdit}) {
    const {t} = useTranslation()

    return <Row gutter={[32, 0]}>
        {!isMass && <Col span={10}>
            <Row gutter={[16, 0]}>
            <Col span={7}>
                <Form.Item name={[...fieldPrefix, 'service_square']}
                           label={t('Обслуживаемая площадь')}>
                    <InputNumber step={0.1}
                                 style={{width: '100%'}}
                                 parser={val => {
                                     if (val.includes(',')) {
                                         val = val.replace(',', '.')
                                     }
                                     return Number(val)
                                 }}
                                 disabled={disabled}
                                 formatter={(val) => val.toString().replace('.', ',')}
                    />
                </Form.Item>
            </Col>
            <Col span={7}>
                <Form.Item name={[...fieldPrefix, 'people_quantity']}
                           label={t('Кол-во проживающих')}>
                    <InputNumber min={0} style={{width: '100%'}} disabled={disabled}/>
                </Form.Item>
            </Col>
            <Col span={9}>
                <Form.Item name={[...fieldPrefix, 'external_number']}
                           label={t('Номер абонента у поставщика')}>
                    <Input style={{width: '100%'}} disabled={disabled}/>
                </Form.Item>
            </Col>
        </Row>
        </Col>}
        <Col span={isMass ? 24 : 14}>
            <ActivitySubForm horizontal form={form} prefix={fieldPrefix} disabled={disabled} disableChecks={massEdit}/>
        </Col>
    </Row>
}