import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import {useStoreMap} from 'effector-react';
import {$infoMaps} from '../../models/infoModel/index.js';

export default function ShowAddressCard({formated, raw, title, textOnly = false}) {
    const {t} = useTranslation()
    const maps = useStoreMap($infoMaps, info => ({
        towns: info.townsMap,
        distrs: info.districtsMap,
        streets: info.streetsMap
    }))

    if (textOnly) {
        return formated
            ? formated
            : `г. ${maps.towns[raw?.town_id]}, ${raw?.towd_distr_id ? `(${maps.distrs[raw?.towd_distr_id]}),` : ''} ${maps.streets[raw?.street_id]}, ${t('дом')} ${raw?.house}, ${raw?.corpus ? `корпус ${raw?.corpus}` : ''}`
    } else {
        return <Card title={title} size={'small'}>
            {formated
                ? formated
                : `г. ${maps.towns[raw?.town_id]}, ${raw?.towd_distr_id ? `(${maps.distrs[raw?.towd_distr_id]}),` : ''} ${maps.streets[raw?.street_id]}, ${t('дом')} ${raw?.house}, ${raw?.corpus ? `корпус ${raw?.corpus}` : ''}`}
        </Card>
    }
}