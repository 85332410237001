import Card from 'antd/es/card';
import Form from 'antd/es/form';
import AccountSearchForm from '../../../../components/AddressReoSearchForms/AccountSearchForm.jsx';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import {paginationConfig} from '../../../../utils/paginationConfig.js';
import {useUnit} from 'effector-react';
import {
    $accsTable, $correctionType,
    $editMode, $excludedAccs,
    $foundAccs, $pageMode, $selectedAcc,
    addExcludedAccEv, correctionTypeEnum,
    getAccsFx, removeExcludedAccEv,
    resetAccsEv, resetSelectedAccEv, resetSrvsEv,
    searchAccsEv,
    setPageModeEv,
    setSelectedAccEv,
    setTableParamsEv, submitTempCorrsSingleEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {useEffect} from 'react';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import {useTranslation} from 'react-i18next';
import ServicesBlock from './ServicesBlock.jsx';
import Switch from 'antd/es/switch';
import Row from 'antd/es/row';
import Radio from 'antd/es/radio';

export default function CorrectionFormSingle() {
    const {t} = useTranslation()
    const accsLoading = useUnit(getAccsFx.pending)

    const selType = useUnit($correctionType)

    const editMode = useUnit($editMode)
    const pageMode = useUnit($pageMode)
    const setPageMode = useUnit(setPageModeEv)

    const {count, data: foundAccs} = useUnit($foundAccs)
    const setTable = useUnit(setTableParamsEv)
    const {page, limit, sort_field, sort_order} = useUnit($accsTable)

    const searchAccs = useUnit(searchAccsEv)
    const resetAccs = useUnit(resetAccsEv)

    const selectAcc = useUnit(setSelectedAccEv)
    const selectedAcc = useUnit($selectedAcc)
    const resetSelected = useUnit(resetSelectedAccEv)
    const resetSrvs = useUnit(resetSrvsEv)

    const excludedAccs = useUnit($excludedAccs)
    const excludeAcc = useUnit(addExcludedAccEv)
    const returnAcc = useUnit(removeExcludedAccEv)

    useEffect(() => {
        if (editMode === 'acc' && foundAccs.length > 0) {
            foundAccs.forEach(acc => {
                acc?.services?.forEach(s => {
                    for (const [key, value] of Object.entries(s)) {
                        if (['provider_id', 'service_id', 'tarif_id', 'payments', 'accrual', 'external_number', 'people_quantity', 'service_square', 'is_not_accrued', 'is_not_balance_transfer', 'is_not_printed'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${s.provider_id.toString()}#${s.service_id.toString()}`, key], value)
                        } else if (['date_from', 'date_to', 'not_accrued_from', 'not_accrued_to', 'not_balance_transfer_from', 'not_balance_transfer_to', 'not_printed_from', 'not_printed_to'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${s.provider_id.toString()}#${s.service_id.toString()}`, key], dayjs(value))
                        }
                    }
                })
                acc?.corrections?.forEach(c => {
                    for (const [key, value] of Object.entries(c)) {
                        if (['external_number_new', 'people_quantity_new', 'service_square_new', 'is_not_accrued_new', 'is_not_balance_transfer_new', 'is_not_printed_new'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${c.provider_id.toString()}#${c.service_id.toString()}`, key.replace('_new', '')], value)
                        } else if (['date_from_new', 'tarif_date_from', 'date_to_new', 'not_accrued_from_new', 'not_accrued_to_new', 'not_balance_transfer_from_new', 'not_balance_transfer_to_new', 'not_printed_from_new', 'not_printed_to_new'].includes(key) && value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${c.provider_id.toString()}#${c.service_id.toString()}`, key.replace('_new', '')], dayjs(value))
                        } else if (key === 'tarif_id' && !!value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${c.provider_id.toString()}#${c.service_id.toString()}`, key], value)
                        } else if (key === 'tarif_id' && !value) {
                            srvForm.setFieldValue([acc.account_id.toString(), `${c.provider_id.toString()}#${c.service_id.toString()}`, key], -1)
                            srvForm.setFieldValue([acc.account_id.toString(), `${c.provider_id.toString()}#${c.service_id.toString()}`, 'tarif_value'], targetCorrection?.tarif_value)
                            srvForm.setFieldValue([acc.account_id.toString(), `${c.provider_id.toString()}#${c.service_id.toString()}`, 'tarif_service_unit_id'], targetCorrection?.tarif_service_unit_id)
                        } else {
                            srvForm.setFieldValue([acc.account_id.toString(), `${c.provider_id.toString()}#${c.service_id.toString()}`, key], value)
                        }
                    }
                })
            })
        }
    }, [editMode, foundAccs, selType])

    const resetSrvsForm = () => {
        srvForm.resetFields()
        resetSrvs()
    }

    const fullReset = () => {
        resetSrvsForm()
        resetAccs()
        resetSelected()
    }

    const onChangeTable = ({current, pageSize}, filters, {field, order}) => {
        const payload = []
        if (current !== page || pageSize !== limit) {
            payload.push({key: 'page', value: current})
            payload.push({key: 'limit', value: pageSize})
        }

        if (field !== sort_field || order !== sort_order) {
            if (!order) {
                payload.push({key: 'sort_field', value: null})
                payload.push({key: 'sort_order', value: null})
            } else {
                payload.push({key: 'sort_field', value: field})
                payload.push({
                    key: 'sort_order',
                    value: order === 'ascend' ? 'asc' : 'desc'
                })
            }
        }
        setTable(payload)
        resetSrvsForm()
        resetSelected()
    }

    const [searchForm] = Form.useForm();
    const [srvForm] = Form.useForm();

    const columnsAccount = [
        Table.SELECTION_COLUMN,
        Table.EXPAND_COLUMN,
        {
            title: t('Номер ЛС'),
            dataIndex: 'account_id',
            render: (id) => <MappedDictItem id={id} type={'accounts'}/>
        },
        {
            title: t('ФИО'),
            dataIndex: 'name_ru',
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            key: 'town_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town'} link={false}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            key: 'town_distr_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town_distr'} link={false}/>,
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            key: 'street_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'street'} link={false}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
            key: 'house'
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            key: 'corpus'
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
            key: 'flat'
        },
        {
            title: t('ИИН'),
            dataIndex: 'iin',
            hide: true,
            key: 'iin'
        },
        {
            dataIndex: 'account_id',
            key: 'actions',
            align: 'center',
            render: (id) => excludedAccs.includes(id)
                ? <Button type={'primary'} size={'small'} onClick={() => returnAcc(id)}>{t('Вернуть')}</Button>
                : <Button type={'primary'} danger size={'small'} onClick={() => excludeAcc(id)}>{t('Исключить')}</Button>
        }
    ]

    return <>
        <Card size={'small'}>
            <Form form={searchForm} size={'small'}
                  layout={'vertical'} onFinish={searchAccs}
                  disabled={!selType}
            >
                <AccountSearchForm extForm={searchForm} itemsAsRow onlyItems extButtons/>
                {(selType === correctionTypeEnum.change_existing_params || selType === correctionTypeEnum.change_tariff)
                    && <Form.Item name={'all_accounts'}
                            label={t('Все ЛС прикрепленные к услуге поставщика')}
                            valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>}
                <Form.Item>
                    <ButtonGroup size={'small'}>
                        <Button type={'primary'} onClick={() => searchForm.submit()}
                                loading={accsLoading}>
                            {t('Поиск')}
                        </Button>
                        <Button onClick={() => {
                            fullReset()
                            searchForm.resetFields()
                        }}
                        >
                            {t('Сброс')}
                        </Button>
                    </ButtonGroup>
                </Form.Item>
            </Form>
        </Card>
        <Divider/>
        <Card title={t('Изменения')} size={'small'}>
            <Form form={srvForm} size={'small'} layout={'vertical'} onValuesChange={(cur, all) => {
                console.log(cur)
                console.log(all)
            }}>
                <Table
                    loading={accsLoading}
                    size={'small'}
                    bordered
                    columns={columnsAccount}
                    dataSource={foundAccs}
                    onChange={onChangeTable}
                    pagination={{
                        ...paginationConfig,
                        pageSize: parseInt(limit),
                        current: parseInt(page),
                        total: count
                    }}
                    rowKey={'account_id'}
                    expandable={{
                        expandedRowRender: () => <ServicesBlock form={srvForm}/>,
                        expandedRowKeys: [selectedAcc],
                        onExpand: (expanded, record) => expanded ? selectAcc(record.account_id) : selectedAcc && selectAcc(null),
                    }}
                    rowSelection={
                        {
                            type: 'radio',
                            selectedRowKeys: [selectedAcc],
                            onChange: ids => selectAcc(ids[0]),
                        }
                    }
                />
            </Form>
            {foundAccs.length > 0 && <Row justify={'space-between'}>
                <Divider />
                {/*<Col span={4}>*/}
                {/*    <Button type={'primary'} size={'small'} onClick={() => srvForm.submit()}>*/}
                {/*        {t('Внести изменения в список изменений')}*/}
                {/*    </Button>*/}
                {/*</Col>*/}
                    <Radio.Group value={pageMode}
                                 onChange={({target: {value}}) => setPageMode(value)}
                                 size={'small'}>
                        <Radio.Button value={'edit'}>{t('Режим внесения изменений')}</Radio.Button>
                        <Radio.Button value={'view'}>{t('Режим просмотра изменений')}</Radio.Button>
                    </Radio.Group>
                    <Button type={'primary'} danger size={'small'} onClick={() => resetSrvsForm()}>
                        {t('Очистить список изменений')}
                    </Button>
            </Row>}
        </Card>
    </>
}