import {combine, createStore} from 'effector';
import {$infoMaps} from '../infoModel/index.js';

export const $providersList = createStore({data: [], count: 0})
export const $selectedProvider = createStore(null)
export const $providerEditStatus = createStore(false)
export const $selectedBeneficiary = createStore(null)

export const $createdProviderId = createStore(null)
export const $afterCreateAction = createStore(null)
export const $afterEditAction = createStore(null)

export const $providerBankAccounts = createStore([])
export const $providerBankAccountsInit = createStore([])
export const $selectedBankAccount = createStore(null)
export const $deletedBankAccounts = createStore([])
export const $providerDefaultBankAccount = createStore(null)
export const $providerDefaultBankAccountInit = createStore(null)
export const $provBankAccMap = combine($providerBankAccounts, ba => Object.fromEntries(ba.map(i => [i.id, i.account_number])))
export const $bankAccsOpts = combine($providerBankAccounts, $infoMaps, (ba, maps) => ba.map(i => ({label: `${i?.account_number}, ${maps.bankAccountTypeMap?.[i.bank_account_type_id]}, ${maps.banksMap[i?.bank_id]}`, value: i.id})))
export const $embeddedBaForm = createStore(false)


export const $providerAgreements = createStore([])
export const $providerAgreementsInit = createStore([])
export const $selectedAgreement = createStore(null)
export const $deletedAgreements = createStore([])
export const $provAgreementsFiles = createStore([])

export const $providerServices = createStore([]) //{id: -1, service_id: 5, provider_id: -1}
export const $providerSrvMap = combine($providerServices, (srv) => Object.fromEntries(srv.map(i => [i.id, i.service_id])))
export const $provSrvsSelOpts = combine($providerServices, $infoMaps, (srv, maps) => srv.map(i => ({label: `${i.service_id} ${maps.servicesMap[i.service_id]}`, value: i.id})))
export const $providerServicesInit = createStore([])
export const $deletedProviderServices = createStore([])
export const $selectedProvService = createStore(null)
// export const $srvTarifsOpts = combine($providerServices, srvs => srvs?.flatMap(s => s.tarifs.map(t => ({label: t.value, value: t.id, provider_service_id: s.id}))))
// Prepared for service_unit_type in service_tariff
export const $srvTarifsOpts = combine($providerServices, $infoMaps, (srvs, maps) => srvs?.flatMap(s => s.tarifs.map(t => ({label: `${t.value} ${maps.serviceUnitsShortMap[t.service_unit_id] ?? ''}`, value: t.id, provider_service_id: s.id}))))
export const $srvTarifsMap = combine($providerServices, $infoMaps,  (srvs, maps) => Object.fromEntries(srvs?.flatMap(s => s.tarifs.map(t => [t.id, `${t.value} ${maps.serviceUnitsShortMap[t.service_unit_id] ?? ''}`]))))
export const $embeddedTariffForm = createStore(false)

// Сейчас используется только $deletedServTariffs
export const $providerServTariffs = createStore([])
export const $provServTariffsInit = createStore([])
export const $selectedServTariff = createStore(null)
export const $deletedServTariffs = createStore([])
// export const $provSrvTarOpts = combine($providerServTariffs, srvTars => srvTars.map(i => ({label: i.value, value: i.id, provider_service_id: i.provider_service_id})))
// export const $provSrvTarValuesMap = combine($providerServTariffs, srvTars => Object.fromEntries(srvTars.map(i => [i.id, i.value])))

export const $providerServPrefs = createStore([])
export const $provServPrefsInit = createStore([])
export const $selectedServPref = createStore(null)
export const $deletedServPrefs = createStore([])

export const $providerServComs = createStore([])
export const $provServComsInit = createStore([])
export const $selectedServCom = createStore(null)
export const $deletedServComs = createStore([])
export const $existingServComms = combine($providerServComs, srvComs => {
    const map = {}
    srvComs.forEach(i => map[i.provider_service_id] ? map[i.provider_service_id].push(i.bank_id) : map[i.provider_service_id] = [i.bank_id])
    return map
})

export const $providerServBaREOs = createStore([])
export const $provServBaREOsInit = createStore([])
export const $selectedServBaREO = createStore(null)
export const $deletedServBaREOs = createStore([])
export const $existingServBaREOms = combine($providerServBaREOs, srvREOs => {
    const map = {}
    srvREOs.forEach(i => map[i.provider_service_id] ? map[i.provider_service_id].push(i.real_estate_object_id) : map[i.provider_service_id] = [i.real_estate_object_id])
    return map
})

export const $providerServAccs = createStore([])
export const $newProviderServAccs = createStore([])
export const $provServAccsInit = createStore([])
export const $selectedServAcc = createStore(null)
export const $deletedServAccs = createStore([])
export const $accountsList = createStore([])
export const $accountsFilters = createStore({ignore_limit: true})
export const $selectedAccounts = createStore([])
export const $provSrvAccsMap = combine($providerServAccs, $providerSrvMap,  (srvAccs, srvMap) => Object.fromEntries(srvAccs.map(i => [i.id, srvMap[i.provider_service_id]])))
export const $provSrvAccsOpts = combine($providerServAccs, $providerSrvMap, $infoMaps,  (srvAccs, srvMap, info) => srvAccs.map(i => ({value: i.id, label: info?.servicesMap[srvMap[i.provider_service_id]] ?? '-'})))

export const $provServAccsForTarifs = createStore([])
export const $providerServAccsTarifs = createStore([])
export const $provServAccsTarifsInit = createStore([])
export const $provServAccsFilters = createStore({ignore_limit: true})
export const $filteredServAccs = createStore([])
export const $selectedSrvAccs = createStore([])
export const $selectedServAccTarif = createStore(null)
export const $deletedServAccsTarifs = createStore([])
