import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import InputNumber from 'antd/es/input-number';

export default function ExtPaymentsMass({fieldPrefix}) {
    const {t} = useTranslation()
    return <Form.Item name={[...fieldPrefix, 'ex_payment_value']} label={t('Сторонний платеж')}
                      rules={[{required: true, message: t('Обязательное поле')}]}
    >
        <InputNumber style={{width: '100%'}}/>
    </Form.Item>
}