import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';

import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';

import CreateStTypeEmbeddedForm
    from '../../../components/Embedded/CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';
import useFormChanged from '../../../hooks/useFormChanged.js';


import {
    $editBankApiInfoStatus,
    $selectedBankApiInfo,
    BankApiInfoEditGate, getBankApiInfoByIdFx,
    submitEditBankApiInfoEv
} from "../../../models/dictionaryBankApiInfoModel/index.js";
import {Select} from "antd";
import {$dictsSelects, $infoMaps} from "../../../models/infoModel/index.js";
import Table from "antd/es/table/index.js";
import {$bankApiAddressList} from "../../../models/dictionaryBankApiAddressModel/index.js";
import IpAddressesTable from "../../../components/Embedded/IpAddressTable/IpAddressesTable.jsx";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";

export default function EditBankApiInfo() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedBankApiInfo)

    const [bankApiInfoForm] = Form.useForm()

    const submit = useUnit(submitEditBankApiInfoEv)

    const loading = useUnit(getBankApiInfoByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editBankApiInfoStatus)
    const bank_ip = useUnit($bankApiAddressList)
    const {bank} = useStoreMap($dictsSelects, sel => ({
        bank: sel.bank,
    }))

    const columns = [
        {
            width: '5%',
            title: t('Ip'),
            dataIndex: 'ip',
            sorter: (a, b) => a.id - b.id,
            key: 'ip',
        },
    ]



    useEffect(() => {
        if (status) {
            navigate('/dictionaries/bank-api-info')
        }
    }, [status]);


    useEffect(() => {
        if (selected) {
            bankApiInfoForm.setFieldsValue(selected)
        }
    }, [selected]);


    const [check, changedFields] = useFormChanged(selected, ['name_kz', 'name_ru', 'town_id', 'town_distr_id', 'street_type_id'])

    return <>
        <BankApiInfoEditGate id={id}/>
        <Card loading={loading}>
            <Form form={bankApiInfoForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={(v) => submit(v)}
                  validateTrigger={'onBlur'}
                  onValuesChange={(_, all) => check(all)}
            >
                <CreateStTypeEmbeddedForm />
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'bank_id'}
                                   label={t('Банк')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['bank_id']}

                        >
                            <Select
                                disabled={true}
                                options={bank}
                                allowClear
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name={'code'}
                                   label={t('Аббревиатура')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['code']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>

        </Card>

        <Card title={<div><span>{t('IP Адреса')}</span> </div>}
        >
            <IpAddressesTable parent={'bank_id'} parentId={selected?.bank_id}/>
        </Card>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    bankApiInfoForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {bankApiInfoForm.submit()}}
                        type={'primary'}
                >
                    {t('Сохранить')}
                </Button>
            </Space>
        </Row>
    </>
}
