import Form from 'antd/es/form';
import {useTranslation} from 'react-i18next';
import InputNumber from 'antd/es/input-number';

export default function SaldoMass({fieldPrefix}) {
    const {t} = useTranslation()
    return <Form.Item name={[...fieldPrefix, 'sum_saldo_end']} label={t('Конечное сальдо')}
                       rules={[{required: true, message: t('Обязательное поле')}]}
    >
        <InputNumber style={{width: '100%'}}/>
    </Form.Item>
}