import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {
    $editServicePaymentTypeStatus,
    $selectedServicePaymentType,
    getServicePaymentTypeByIdFx,
    ServicePaymentTypeEditGate,
    submitEditServicePaymentTypeEv
} from '../../../models/dictionaryServicePaymentTypeModel/index.js';

export default function EditServicePaymentType() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selectedServicePaymentType = useUnit($selectedServicePaymentType)

    const [servicePaymentTypeForm] = Form.useForm()

    const submit = useUnit(submitEditServicePaymentTypeEv)

    const loading = useUnit(getServicePaymentTypeByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editServicePaymentTypeStatus)
    const genitive_form = t(`breadcrumbs.genitive.service-payment-type`);


    useEffect(() => {
        if (status) {
            navigate('/dictionaries/service-payment-type')
        }
    }, [status]);


    useEffect(() => {
        if (selectedServicePaymentType) {
            servicePaymentTypeForm.setFieldsValue(selectedServicePaymentType)
        }
    }, [selectedServicePaymentType]);

    const [check, changedFields] = useFormChanged(selectedServicePaymentType, ['name_kz', 'name_ru', 'short_name_kz', 'short_name_ru'])

    return <>
        <ServicePaymentTypeEditGate id={id}/>
        <Form form={servicePaymentTypeForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Card loading={loading} title={t('Свойства вида платежа')} size={'small'}>
                <Row justify={'space-between'}>
                    <Col span={5}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'short_name_kz'}
                                   label={t(`Краткое название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['short_name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'short_name_ru'}
                                   label={t(`Краткое название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['short_name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    servicePaymentTypeForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            servicePaymentTypeForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить')}
                </Button>
            </Space>
        </Row>
    </>
}
