import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Select from 'antd/es/select';
import {
    $afterCreateAction, $createdTownIdModal,
    $createdDistrictId,
    DistrictCreateGate,
    resetAfterCreationAttrs,
    resetDistStreetsEv,
    setAfterCreateActionEv,
    submitCreateDistrictEv
} from '../../../models/dictionaryDistrictsModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {setCreateTownEmbeddedEv} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import CreateTownEmbeddedForm
    from '../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import {PlusOutlined} from '@ant-design/icons';
import StarredTitle from '../../../components/StarredTitle/StarredTitle.jsx';

export default function CreateDistrict() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [districtForm] = Form.useForm()

    const submit = useUnit(submitCreateDistrictEv)
    const resetStreets = useUnit(resetDistStreetsEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const townsOpts = useStoreMap($dictsSelects, sel => sel.towns)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)

    const createdId = useUnit($createdDistrictId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const newTown = useUnit($createdTownIdModal)
    const genitive_form = t(`breadcrumbs.genitive.town_distr`)

    useEffect(() => {
        if (newTown) {
            districtForm.setFieldValue('town_id', newTown)
        }
    }, [newTown])

    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/town_distr/edit/${createdId}`)
            } else if (action === 'new') {
                districtForm.resetFields()
                resetStreets()
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <DistrictCreateGate/>
        <Form form={districtForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Населенный пункт')}/>} size={'small'}
                          style={{height: '100%'}}>
                        <Form.Item name={'town_id'}
                                   rules={[{required: true, message: t('Обязательное поле')}]}>
                            <Select
                                options={[...townsOpts, {label: 'Создать', value: -1, emoji: true}]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setTownEmbedded(true)
                                    } else {
                                        districtForm.resetFields(['town'])
                                        setTownEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateTownEmbeddedForm/>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Свойства района города')}/>} size={'small'}
                          style={{height: '100%'}}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    districtForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            districtForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующий')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            districtForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
