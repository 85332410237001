import {
    $afterCreateAction,
    $createdServicesId,
    $editServicesStatus,
    $selectedServices,
    $servicesList, $serviceTypeEmbeddedOpen
} from './stores.js';
import {getServicesListFx, getServicesByIdFx} from './effects.js';
import {
    deleteServicesEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ServicesEditGate,
    ServicesListGate,
    ServicesViewGate,
    submitCreateServicesEv,
    submitEditServicesEv, getServiceEv, setServiceTypeEmbedded, ServicesCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateServicesEv, response: triggerSuccessOpsEv}, reset: ServicesCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditServicesEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteServicesEv, response: triggerSuccessOpsEv})

$servicesList.on(getServicesListFx.doneData, (_, data) => data)
    .reset(ServicesListGate.close)

$selectedServices.on(getServicesByIdFx.doneData, (_, data) => data)
    .reset([ServicesEditGate.close, ServicesViewGate.close])

// FIXME
$createdServicesId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "services")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editServicesStatus.on(editCompleteEv, () => true)
    .reset(ServicesEditGate.close)

$serviceTypeEmbeddedOpen.on(setServiceTypeEmbedded, (_, open) => open)
    .reset([ServicesCreateGate.close, ServicesEditGate.close])

sample({
    clock: getServiceEv,
    target: getServicesByIdFx
})

sample({
    clock: [ServicesEditGate.state, ServicesViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getServicesByIdFx
})

sample({
    clock: ServicesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServicesListFx
})

sample({
    source: ServicesListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServicesListFx
})

sample({
    clock: submitCreateServicesEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        if (payload.service_unit_id < 0) {
            actions.push({operation: 'create', type: 'service_units', value: {...payload.service_unit, id: idCounter}})
            payload.service_unit_id = idCounter
            idCounter--
            delete payload.service_unit
        }

        actions.push({operation: 'create', type: 'services', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedServices,
    clock: submitEditServicesEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.service_unit_id < 0) {
            actions.push({operation: 'create', type: 'service_units', value: {...payload.service_unit, id: idCounter}})
            payload.service_unit_id = idCounter
            idCounter--
            delete payload.service_unit
        }


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'services', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteServicesEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'services', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
