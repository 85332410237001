import React from 'react'
import ReactDOM from 'react-dom/client'
import './models/init';
import {router} from "./router/router.jsx";
import './index.css'
import './i18n.js'
import {RouterProvider} from "react-router-dom";
import {ConfigProvider} from "antd";
import localeRu from 'antd/es/locale/ru_RU';
import localeKz from 'antd/es/locale/kk_KZ';
import ts from 'array.prototype.tospliced'

import dayjs from "dayjs";
import 'dayjs/locale/ru.js';
import 'dayjs/locale/kk.js';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayjsRelativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
console.log('DAY TZ guess', dayjs.tz.guess())
dayjs.tz.setDefault("Asia/Almaty")
dayjs.locale(localStorage.getItem('lang') === 'kz' ? 'kk' : 'ru')
window.dayjs = dayjs


if (Array.prototype.toSpliced === undefined) {
    Array.prototype.toSpliced = ts.shim()
}

const localeDict = {
    ru: localeRu,
    kz: localeKz,
}

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
      <ConfigProvider
          componentSize={'small'}
          locale={localeDict[localStorage.getItem('lang') ?? 'ru']}
          theme={{
              token: {
                  titleColor: 'black',
                  colorText: 'black',
              },
              components: {
                  Tabs: {
                      horizontalItemPadding: '0 0 12px 0',
                      horizontalMargin: '0 0 8px 0',
                      cardBg: 'rgba(255,255,255,0.6)'
                  },
                  Form: {
                      itemMarginBottom: 8,
                      verticalLabelPadding: '0 0 4px',
                      labelHeight: 24,
                      labelColonMarginInlineEnd: 2,
                      labelColonMarginInlineStart: 0
                  },
                  Table: {
                      cellPaddingBlockMD: 8,
                      cellPaddingBlockSM: 4,
                      cellPaddingInlineSM: 4,
                      selectionColumnWidth: 16
                  }
          }}}
      >
          <RouterProvider router={router} />
      </ConfigProvider>
  // </React.StrictMode>,
)
