import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {useUnit} from 'effector-react';
import {
    $providerServAccs,
    $providerSrvMap,
    $provSrvsSelOpts, $selectedAccounts, $selectedServAcc,
    addServAccEv, applyServAccEv,
    deleteServAccEv, resetAccountsEv, resetServAccEv, selectServAccEv,
} from '../../../../models/dictionaryProvidersModel/index.js';
import Form from 'antd/es/form';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import {CloseCircleOutlined, CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import Popconfirm from 'antd/es/popconfirm';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import Table from 'antd/es/table';
import Input from 'antd/es/input';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import {filterOption} from '../../../../utils/selectUtils.jsx';
import {paginationConfig} from '../../../../utils/paginationConfig.js';
import ActivitySubForm from '../ActivitySubForm.jsx';
import AccountsSearchSubForm from '../AccountsSearchSubForm.jsx';
import {ConfigProvider} from 'antd';
import dayjs from 'dayjs';
import InputNumber from 'antd/es/input-number';


export default function ProvServAccForm({isView}) {
    const {t} = useTranslation()

    const [srvAccCreateForm] = Form.useForm()
    const [srvAccUpdForm] = Form.useForm()

    const [createMode, setCreateMode] = useState(false)

    const selectedAccs = useUnit($selectedAccounts)
    const resetAccs = useUnit(resetAccountsEv)

    const serviceOpts = useUnit($provSrvsSelOpts)
    const srvMap = useUnit($providerSrvMap)

    const srvAccs = useUnit($providerServAccs)
    const selectedAcc = useUnit($selectedServAcc)
    const selectAcc = useUnit(selectServAccEv)
    const resetSelAcc = useUnit(resetServAccEv)

    useEffect(() => {
        if (selectedAcc) {
            const prepared = {...selectedAcc}
            for (const [key, value] of Object.entries(prepared)) {
                if (key.includes('from') || key.includes('to')) {
                    prepared[key] = value ? dayjs(value) : null
                }
            }
            srvAccUpdForm.setFieldsValue(prepared)
        }
    }, [selectedAcc]);

    const addAcc = useUnit(addServAccEv)
    const changeAcc = useUnit(applyServAccEv)
    const deleteAcc = useUnit(deleteServAccEv)

    const onCreate = (values) => {
        const data = srvAccCreateForm.getFieldsValue(true)
        const {type, ...payload} = {...values, ...data}
        addAcc({type, payload})
        srvAccCreateForm.resetFields()
    }

    const onUpdate = values => {
        changeAcc({id: selectedAcc.id, payload: values})
        resetSelAcc()
        srvAccUpdForm.resetFields()
    }

    const onCancelCreate = () => {
        setCreateMode(false)
        srvAccCreateForm.resetFields()
    }

    const onCancel = () => {
        resetSelAcc()
        srvAccUpdForm.resetFields()
    }

    const onValuesChangeCreate = (cur, all) => {
        if (cur.is_not_accrued === false && all.is_not_balance_transfer === true) {
            srvAccCreateForm.setFieldsValue({is_not_balance_transfer: false})
        } else if (cur.is_not_balance_transfer === true && all.is_not_accrued === false) {
            srvAccCreateForm.setFieldsValue({is_not_accrued: true})
        }
    }

    const onValuesChangeUpd = (cur, all) => {
        if (cur.is_not_accrued === false && all.is_not_balance_transfer === true) {
            srvAccUpdForm.setFieldsValue({is_not_balance_transfer: false})
        } else if (cur.is_not_balance_transfer === true && all.is_not_accrued === false) {
            srvAccUpdForm.setFieldsValue({is_not_accrued: true})
        }
    }

    const [dataSource, setDataSource] = useState([])
    const [filterBySrv, setFilterBySrv] = useState(null)

    useEffect(() => {
        if (filterBySrv) {
            setDataSource(srvAccs.filter(i => i.provider_service_id === filterBySrv))
        } else {
            setDataSource(srvAccs)
        }
    }, [filterBySrv, srvAccs])

    useEffect(() => {
        if (srvAccs.length > 0) {
            resetAccs()
            setCreateMode(false)
        }
    }, [srvAccs]);


    const columns = [
        {
            title: t('Услуга'),
            dataIndex: 'provider_service_id',
            width: '20%',
            sorter: (a, b) => a.provider_service_id - b.provider_service_id,
            render: (id) => <MappedDictItem id={srvMap[id]} type={'services'}/>
        },
        {
            title: t('Номер ЛС'),
            // width: '10%',
            dataIndex: 'account_id',
            render: (id) => <MappedDictItem id={id} type={'accounts'}/>
        },
        // { // FIXME temp disable
        //     title: t('Номер Абонента'),
        //     dataIndex: 'external_number',
        // },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            width: '10%',
            render: (id) => <MappedDictItem id={id} type={'town'}/>,
        },
        // {
        //     title: t('Район'),
        //     dataIndex: 'town_distr_id',
        //     render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
        // },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            render: (id) => <MappedDictItem id={id} type={'street'}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
        },
        {
            title: t('Площадь'),
            dataIndex: 'service_square'
        },
        {
            title: t('Кол-во проживающих'),
            dataIndex: 'people_quantity'
        },
        {
            title: t('№ договора'),
            dataIndex: 'external_number'
        },
        {
            title: t('Оказание услуги'),
            dataIndex: 'date_from',
            render: (date, r) => {
                const data = []
                if (r.date_from) data.push(<Col span={24}>
                    с {dayjs(r.date_from).format('DD.MM.YYYY')}
                </Col>)
                if (r.date_to) data.push(<Col span={24}>
                    по {dayjs(r.date_to).format('DD.MM.YYYY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}>-</div>
                else
                    return <Row>{data}</Row>
            }
        },
        {
            title: t('Не начислять'),
            dataIndex: 'is_not_accrued',
            render: (v, r) => {
                const data = []
                if (r.not_accrued_from) data.push(<Col span={24}>
                    с {dayjs(r.not_accrued_from).format('DD.MM.YYYY')}
                </Col>)
                if (r.not_accrued_to) data.push(<Col span={24}>
                    по {dayjs(r.not_accrued_to).format('DD.MM.YYYY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}><CloseCircleOutlined/></div>
                else
                    return <Row>{data}</Row>
            }
        },
        {
            title: t('Не переводить сальдо'),
            dataIndex: 'is_not_balance_transfer',
            render: (v, r) => {
                const data = []
                if (r.not_balance_transfer_from) data.push(<Col span={24}>
                    с {dayjs(r.not_balance_transfer_from).format('DD.MM.YYYY')}
                </Col>)
                if (r.not_balance_transfer_to) data.push(<Col span={24}>
                    по {dayjs(r.not_balance_transfer_to).format('DD.MM.YYYY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}><CloseCircleOutlined/></div>
                else
                    return <Row>{data}</Row>
            }
        },
        {
            title: t('Не печатать'),
            dataIndex: 'is_not_printed',
            render: (v, r) => {
                const data = []
                if (r.not_printed_from) data.push(<Col span={24}>
                    с {dayjs(r.not_printed_from).format('DD.MM.YYYY')}
                </Col>)
                if (r.not_printed_to) data.push(<Col span={24}>
                    по {dayjs(r.not_printed_to).format('DD.MM.YYYY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}><CloseCircleOutlined/></div>
                else
                    return <Row>{data}</Row>
            }
        },
        {
            title: createMode ? null : <Tooltip title={t('Добавить')}>
                <Button type={'primary'} icon={<PlusOutlined />} onClick={() => setCreateMode(true)}/>
            </Tooltip>,
            hidden: isView,
            width: '96px',
            align: 'center',
            dataIndex: 'id',
            render: (id) => {
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined/>}
                                onClick={() => selectAcc(id)}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={() => deleteAcc(id)}
                                placement={'left'}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button icon={<CloseOutlined/>} type={'primary'} danger/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            },
        }
    ]

    return <>
        {!isView && createMode &&
            <Card title={'Добавление ЛС для Услуги'} size={'small'} style={{marginBottom: '8px'}}>
                <AccountsSearchSubForm />
                <Divider/>
                <Form form={srvAccCreateForm} layout={'vertical'}
                      onFinish={onCreate}
                      size={'small'}
                      onValuesChange={onValuesChangeCreate}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={6}>
                            <Form.Item name={'provider_service_id'} label={t('Услуга')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Select options={serviceOpts}
                                        filterOption={filterOption}
                                        style={{width: '100%'}}
                                        showSearch
                                        allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'service_square'} label={t('Площадь')}>
                                <InputNumber step={0.1}
                                             style={{width: '100%'}}
                                             parser={val => {
                                                 if (val.includes(',')) {
                                                     val = val.replace(',', '.')
                                                 }
                                                 return Number(val)
                                             }}
                                             formatter={(val) => val.toString().replace('.', ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'people_quantity'} label={t('Кол-во проживающих')}>
                                <InputNumber min={0} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'external_number'} label={t('№ договора с потребителем/ИИН')}>
                                <Input style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <ActivitySubForm form={srvAccCreateForm}/>
                        </Col>
                        <Col span={6}>
                            <Form.Item name={'rewrite_old'}
                                       label={t('Обновить в уже добавленных ЛС')}
                                       valuePropName={'checked'}
                                       initialValue={false}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify={'space-between'}>
                        <ButtonGroup>
                            <Button type={'primary'}
                                    onClick={() => {
                                        srvAccCreateForm.setFieldsValue({type: 'selected'})
                                        srvAccCreateForm.submit()
                                    }}
                                    disabled={!selectedAccs?.length}
                            >
                                {t('Добавить выделенные')}
                            </Button>
                            <Button
                                onClick={() => {
                                    srvAccCreateForm.setFieldsValue({type: 'all'})
                                    srvAccCreateForm.submit()
                                }}
                            >
                                {t('Добавить все подходящие под фильтр')}
                            </Button>
                        </ButtonGroup>
                        <Button danger type={'primary'} onClick={() => onCancelCreate()}>{t('Отмена')}</Button>
                    </Row>
                </Form>
            </Card>
        }


        <Card title={'Фильтры'} size={'small'}>
            <Row gutter={[16, 0]} style={{alignItems: 'flex-end'}}>
                <Col span={4}>
                    <p style={{marginTop: 0, marginBottom: 4}}>{t('Фильтр по Услуге')}</p>
                    <Select
                        options={serviceOpts}
                        size={'small'}
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        showSearch
                        allowClear
                        value={filterBySrv}
                        onSelect={(id) => setFilterBySrv(id)}
                        onClear={() => setFilterBySrv(null)}
                    />
                </Col>
                <Col span={4}>
                    <Button
                        onClick={() => setFilterBySrv(null)}
                        disabled={!filterBySrv}
                        size={'small'}
                    >
                        {t('Очистить фильтр')}
                    </Button>
                </Col>
            </Row>
        </Card>

        <Row gutter={[16, 16]} style={{marginTop: 8}}>
            <Col span={selectedAcc ? 12 : 24}>
                <Card title={'Услуги с ЛС'} size={'small'}>
                    <Table dataSource={dataSource}
                           columns={columns}
                           bordered
                           className={'table-container'}
                           pagination={paginationConfig}
                           size={'small'}
                           rowKey={'id'}
                           rowClassName={(record) => record.id === selectedAcc?.id ? 'selectedRowObject' : null}
                    />
                </Card>
            </Col>
            {selectedAcc && <Col span={12}>
                <Card title={'Редактирование Услуги по ЛС'} size={'small'}>
                    <ConfigProvider theme={{components: {Form: {itemMarginBottom: 16}}}}>
                    <Form form={srvAccUpdForm} layout={'vertical'} onFinish={onUpdate} size={'small'} onValuesChange={onValuesChangeUpd}>
                        <Form.Item name={'provider_service_id'} label={t('Услуга')}>
                            <Select
                                options={serviceOpts}
                                filterOption={filterOption}
                                style={{width: '100%'}}
                                showSearch
                                allowClear
                                disabled
                            />
                        </Form.Item>
                        <Form.Item name={'account_id'} label={t('Номер ЛС')}>
                            <Input disabled/>
                        </Form.Item>
                        {/*FIXME temp disable*/}
                        {/*<Form.Item name={'external_number'} label={t('Номер Абонента')}*/}
                        {/*           tooltip={t('Номер Абонента у поставщика')}*/}
                        {/*>*/}
                        {/*    <Input/>*/}
                        {/*</Form.Item>*/}
                        <Form.Item name={'service_square'} label={t('Площадь')}>
                            <InputNumber step={0.1}
                                         style={{width: '100%'}}
                                         parser={val => {
                                             if (val.includes(',')) {
                                                 val = val.replace(',', '.')
                                             }
                                             return Number(val)
                                         }}
                                         formatter={(val) => val.toString().replace('.', ',')}
                            />
                        </Form.Item>
                        <Form.Item name={'people_quantity'} label={t('Кол-во проживающих')}>
                            <InputNumber min={0} style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item name={'external_number'} label={t('№ договора с потребителем/ИИН')}>
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <ActivitySubForm form={srvAccUpdForm} splitRows/>
                        <Row style={{gap: 8, marginTop: 8}}>
                            <Form.Item noStyle>
                                <Button type={'primary'}
                                        onClick={() => srvAccUpdForm.submit()}
                                >
                                    {t('Применить')}
                                </Button>
                            </Form.Item>
                            <Form.Item noStyle>
                                <Button
                                    onClick={onCancel}
                                    disabled={!selectedAcc}
                                >
                                    {t('Отменить')}
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                    </ConfigProvider>
                </Card>
            </Col>}
        </Row>
    </>
}
