import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $accountsFilters,
    $accountsList, $selectedAccounts, getAccountsEv, resetAccountsEv, selectMultipleAccountsEv,
    setAccountsFiltersEv
} from '../../../models/dictionaryProvidersModel/index.js';
import Row from 'antd/es/row';
import Table from 'antd/es/table';
import Form from 'antd/es/form';
import Col from 'antd/es/col';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import {isId} from '../../../utils/helpers.js';
import AccountSearchForm from "../../../components/AddressReoSearchForms/AccountSearchForm.jsx";

export default function AccountsSearchSubForm() {
    const {t} = useTranslation()

    const accsList = useUnit($accountsList)
    const filters = useUnit($accountsFilters)
    const setFilters = useUnit(setAccountsFiltersEv)
    const applyFilters = useUnit(getAccountsEv)
    const resetFilters = useUnit(resetAccountsEv)

    const selectedAccs = useUnit($selectedAccounts)
    const setSelectedAccs = useUnit(selectMultipleAccountsEv)

    const [accsForm] = Form.useForm()

    const onReset = () => {
        accsForm.resetFields()
        resetFilters()
    }

    const columns = [
        {
            title: t('Номер ЛС'),
            width: '10%',
            dataIndex: 'id',
            render: (id) => <MappedDictItem id={id} type={'accounts'}/>
        },
        {
            title: t('ФИО'),
            dataIndex: 'name_ru',
            render: (name, r) => localStorage.getItem('lang') === 'kz' && r?.name_kz ? r?.name_kz : name
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            width: '10%',
            render: (id) => <MappedDictItem id={id} type={'town'}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            render: (id) => <MappedDictItem id={id} type={'street'}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
        },
    ]

    const account_in = Form.useWatch('account_in', accsForm)
    const iin_name = Form.useWatch('iin_name', accsForm)

    const onValuesChange = (cur) => {
        if (Object.hasOwn(cur, 'town_id')) {
            accsForm.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus', 'flat_in'])
            const vals = accsForm.getFieldsValue()
            setFilters(vals)
        } else {
            setFilters(cur)
        }
    }

    return <>
        <Form form={accsForm}
              layout={'vertical'}
              size={'small'}
              onValuesChange={onValuesChange}
        >
            <Row>
                <Col span={24}>
                    <AccountSearchForm extForm={accsForm} extButtons onlyItems itemsAsRow/>
                </Col>
                <Col>
                    <ButtonGroup size={'small'}>
                        <Button type={'primary'}
                                disabled={!(isId(filters.street_id) || account_in || iin_name)}
                                onClick={applyFilters}>{t('Найти ЛС')}</Button>
                        <Button onClick={onReset}>{t('Очистить поиск')}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
        <Row style={{marginTop: 8}}>
            <Col span={24}>
                <Table className={'table-container'}
                       dataSource={accsList}
                       pagination={paginationConfig}
                       columns={columns}
                       rowKey={'id'}
                       bordered
                       size={'small'}
                       rowSelection={{
                           onChange: (ids) => setSelectedAccs(ids),
                           selectedRowKeys: selectedAccs
                       }}
                />
            </Col>
        </Row>
    </>
}
