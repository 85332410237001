import React, { createContext, useContext, useEffect, useState } from 'react';
import {WsOpen} from "../models/webSocketModel/index.js";
import {getUrlPdfEv} from "../models/controllersModel/index.js";
import {acceptMessageUploadEv as accrualsLoadFileUpdatedEv} from '../models/fileUploadModel';
import {acceptMessageUploadEv as agreementLoadFileUpdatedEv} from '../models/uploadAgreementsModel';
import {acceptMessageUploadEv as reoLoadFileUpdatedEv} from '../models/uploadREOModel';
import {acceptMessageUploadEv as accountLoadFileUpdatedEv} from '../models/uploadAccountsModel';
import {acceptMessageUploadEv as updateAccountsLoadFileUpdatedEv} from '../models/uploadUpdateAccountsModel';
import {getAccountUrlPdfEv, sendEmailAccountsEv} from "../models/accountsModel/index.js";
import {acceptTaskFromSocketEv} from '../models/tasksGlobalModel';
import cookies from "../utils/cookie.jsx";
import {acceptMessageUploadEmailEv} from "../models/taskPdfToControllersModel/index.js";
import {setCloseMonthBlockingModal} from '../models/closeMonthBlockingModalModel/index.js';
import {getInfoEv} from '../models/infoModel/index.js';

const WebSocketContext = createContext(null);

function connectSocket ()  {
    const proto = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    const wsu = window.location.origin.includes('localhost') ? 'wss://erc-dev.frontier.kz' :  proto + window.location.origin.split(':')[1]
    const access_token = cookies.get('access_token')

    return new WebSocket(wsu + `/api/v1/websocket/ws?token=${access_token}`)
}

export const WebSocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);

    useEffect(() => {
        if (!ws) {
            const socket = connectSocket()
            socket.onopen = () => {
                WsOpen()
                socket.send('Ready'); // Пример отправки сообщения
            };

            socket.onmessage = (event) => {
                const payload = JSON.parse(event.data).payload;
                if (payload.action === 'update') {
                    if (payload.object_type === 'print_pdf_by_inspector') {
                        console.log(payload.data)
                        getUrlPdfEv(payload.data)
                    }
                    if (payload.object_type === 'accruals_load_file') {
                        console.log('payload.data', payload.data)
                        accrualsLoadFileUpdatedEv(payload.data)
                    }
                    if (payload.object_type === 'send_email_with_pdf') {
                        sendEmailAccountsEv(payload.data)
                    }
                    if (payload.object_type === 'epd') {
                        getAccountUrlPdfEv(payload.data)
                    }
                    if (payload.object_type === 'tasks') {
                        acceptTaskFromSocketEv(payload.data)
                    }
                    if (payload.object_type === 'import_reo') {
                        console.log('payload.data', payload.data)
                        reoLoadFileUpdatedEv(payload.data)
                    }
                    if (payload.object_type === 'import_external_number') {
                        console.log('payload.data', payload.data)
                        agreementLoadFileUpdatedEv(payload.data)
                    }
                    if (payload.object_type === 'import_update_account') {
                        console.log('payload.data', payload.data)
                        updateAccountsLoadFileUpdatedEv(payload.data)
                    }

                    if (payload.object_type === 'import_account') {
                        console.log('payload.data', payload.data)
                        accountLoadFileUpdatedEv(payload.data)
                    }
                    if (payload.object_type === 'import_email') {
                        console.log('payload.data', payload.data)
                        acceptMessageUploadEmailEv(payload.data)
                    }
                }
                if (payload.object_type === 'close_month' && payload.action === 'update') {
                    getInfoEv()
                }

                console.log('Получено сообщение:', event.data);
            };

            socket.onclose = () => {
                console.log('Соединение закрыто');
                setTimeout(() => {
                    setWs(null)
                }, 10000)
            };

            socket.onerror = (error) => {
                console.error('Ошибка WebSocket:', error);
            };

            setWs(socket);

            return () => {
                if (ws) {
                    socket.close();
                    setWs(null)
                }
            };
        }
    }, [ws]);

    const sendMessage = (message) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(message);
        } else {
            console.error('WebSocket не готов к отправке сообщений');
        }
    };

    return (
        <WebSocketContext.Provider value={{ ws, sendMessage }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
