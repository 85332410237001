import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import styles from './ViewDictionaryItemCommon.module.css';

export default function ViewDictionaryItemCommon(
    {
        items,
        itemsDates,
        customItems,
        id,
        href,
        showNav = true
    }) {
    const {t} = useTranslation();
    return <div className={styles.viewContainer}>
        {
            items && items.length > 0 && <div
                className={items.length % 2 === 0 ? styles.viewCommonInfoGrid2 : styles.viewCommonInfoGrid3}>
                {items.map(i => <Card key={i.key} title={i.label} size={'small'}>
                    {i.children}
                </Card>)}
            </div>
        }
        {customItems && <div className={styles.viewCommonInfoCustom}>{customItems}</div>}
        <div className={styles.viewSider}>
            {showNav && <ButtonGroup>
                <Button href={`/dictionaries/${href}/edit/${id}`} type={'primary'}>
                    {t('Редактировать')}
                </Button>
                <Button href={`/dictionaries/${href}`}>{t('К списку')}</Button>
            </ButtonGroup>}
            <Descriptions column={1} items={itemsDates}/>
        </div>
    </div>
}
