import {useEffect, useState} from 'react';
import useResizableColumns from '../../hooks/useResizableColumns.jsx';
import {usePaginationControl} from '../../hooks/usePaginationControl.js';
import {useServerSort} from '../../hooks/useServerSort.js';
import {ProTable} from '@ant-design/pro-components';
import {paginationConfig} from '../../utils/paginationConfig.js';
import './style.css'
import TableActions from "../TableActions/TableActions.jsx";
import ExcelOutlined from "../ExcelIcon/EcxelIcon.jsx";
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import {useLocation, useNavigate} from 'react-router-dom';
import {useServerSortPage} from '../../hooks/useServerSortPage.js';
import {useUnit} from "effector-react";
import {saveUserSettingsEv} from "../../models/userSettingsModel/index.js";
import {createCorrectionEv} from '../../models/dictionaryCorrectionModel/index.js';

export default function DictionaryProTable(
    {
        exportXlsAction,
        data,
        count,
        initColumns,
        loading,
        showActions = true,
        // showAction,
        hiddenActions = [],
        customOnChange,
        extLimit,
        extPage,
        left = [],
        ...restProps
    }
) {
    const [curPage, limit, sortField, sortOrder, onChangeTable] = useServerSortPage()

    const [columns, components] = useResizableColumns(initColumns)
    const {t} = useTranslation()
    const navigate = useNavigate()
    const saveUserSettings = useUnit(saveUserSettingsEv)
    const createCorrection = useUnit(createCorrectionEv)

    const initColumnsState = Object.fromEntries(initColumns.map((col, idx) => [
        col.key,
        {
            fixed: (col.key === 'id' || left.includes(col.key)) ? 'left' : col.key === 'actions' ? 'right' : false,
            disable: ['id', 'actions'].includes(col.key),
            order: idx,
            drag: !['id', 'actions'].includes(col.key),
            show: !col.hide,
        }]))

    const [columnsState, setColumnsState] = useState(initColumnsState);

    const changeColumnState = (value) => {
      setColumnsState(value)
      saveUserSettings({
        key: `${pathname.slice(1)}.table_settings`,
        value: JSON.stringify(value)
      })
    }
    const {pathname} = useLocation()

    const actionItems = [
        {
            label: `Создать ${
                pathname.includes('create' || 'view' || 'edit') ?
                t(`breadcrumbs.accusative.${pathname.split('/dictionaries/').pop()}`).toLowerCase()
                    :
                    t(`breadcrumbs.accusative.${pathname.split('/dictionaries/').pop()?.split('/')?.shift()}`).toLowerCase()
            }`,
            icon: <PlusOutlined size={18}/>,
            tip: t(`Создать ${t(`breadcrumbs.accusative.${pathname.split('/dictionaries/').pop()?.split('/')?.shift()}`).toLowerCase()}`),
            btnType: 'primary',
            action: () => navigate(`${pathname}/create`),
            hide: hiddenActions.includes('create'),
            key: 'create',
        },
        {
            label: t('Создать массовую корректировку'),
            icon: <PlusOutlined size={18}/>,
            tip: t('Создать массовую корректировку'),
            btnType: 'primary',
            action: () => createCorrection({mass: true}),
            hide: window.location.pathname !== '/corrections',
            key: 'create_mass_correction',
        },
        {
            label: t('Создать корректировку по одному ЛС'),
            icon: <PlusOutlined size={18}/>,
            tip: t('Создать корректировку по одному ЛС'),
            btnType: 'primary',
            action: () => createCorrection({mass: false}),
            hide: window.location.pathname !== '/corrections',
            key: 'create_single_correction',
        },
        {
            label: `Создать группу ${window.location.href.includes('/address') ? 'домов' : 'ОН'}`,
            icon: <PlusOutlined size={18}/>,
            tip: `Создать группу домов`,
            btnType: 'primary',
            hide: hiddenActions.includes('create_group') || (window.location.href.includes('/address') || window.location.href.includes('/real-estate-objects') ? false : true),
            action: () => navigate(`${pathname}/${pathname.includes('address') ? 'create-address-group' : 'create-group'}`),
            key: 'create_group',
        },
        {
            label: t('Выгрузка'),
            icon: <ExcelOutlined size={18}/>,
            tip: t('Выгрузка данных из таблицы с учетом текущих фильтров'),
            action: () => exportXlsAction(),
            hide: hiddenActions.includes('export'),
            key: 'export',
        },

    ]
    const rowKey = data && data[0] && data[0].id !== undefined ? 'id' : undefined

    return <div className={'table_parent'} id={showActions ? 'dictionaryProTable' : undefined}>
        {showActions && <TableActions items={actionItems}/>}
        <ProTable dataSource={[...(data || [])]}
                  rowKey={rowKey}
                  columns={columns}
                  loading={loading}
                  size={'small'}
                  bordered
                  search={false}
                  options={{density: false, reload: false}}
                  onChange={customOnChange ? customOnChange : ({current, pageSize}, filters, {field, order}) => {
                      const payload = {page: curPage, limit: limit}
                      if (current !== curPage || pageSize !== limit) {
                          payload.limit = pageSize
                          payload.page = current
                      }
                      if (!sortField && !sortOrder && !order) {
                          return onChangeTable(payload)
                      } else if (field !== sortField || order !== sortOrder) {
                          if (!order) {
                              payload.sort_field = null
                              payload.sort_order = null
                              onChangeTable(payload)
                          } else {
                              payload.sort_field = field
                              payload.sort_order = order === 'ascend' ? 'asc' : 'desc'
                              onChangeTable(payload)
                          }
                      }
                  }}
                  components={components}
                  pagination={{
                      ...paginationConfig,
                      pageSize: parseInt(extLimit ?? limit),
                      current: parseInt(extPage ?? curPage),
                      total: count
                  }}
                  columnsState={{value: columnsState, onChange: changeColumnState}}
                  rowClassName={'table-row'}
                  tableClassName={'table-container'}
                  {...restProps}
        /></div>
}
