import {createEffect} from 'effector';
import {users} from '../mockEntities.js';
import api from '../../api/api.js';

export const getInfoFx = createEffect()
    .use(async () => (await api().get('/info/')).data)

export const getCurrentUserFx = createEffect()
    .use(async () => {
        return new Promise((resolve, reject) => {
            return setTimeout(() => resolve(users[0]), 200)
        })
    })
