import {createEffect, createEvent, sample} from 'effector';
import api from '../../api/api.js';

export const downloadExcelEv = createEvent()
export const downloadExcelFx = createEffect()
.use(async ({type, filters}) => {
    const params = filters.size > 0 ? `?${filters.toString()}` : ''
    const file = await api().get(`/${type}/excel/${params}`, {responseType: 'blob'})
    const link = document.createElement('a')
    const url = URL.createObjectURL(file.data)
    link.href = url
    link.target = '_blank'
    link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
})

sample({
    clock: downloadExcelEv,
    target: downloadExcelFx
})