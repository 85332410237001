import React from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {$exportedPaymentsSt, exportExportTableEv} from "../../../models/paymentModel/index.js";
import Button from "antd/es/button/index.js";
import ExcelOutlined from "../../../components/ExcelIcon/EcxelIcon.jsx";
import Col from "antd/es/col/index.js";
import Card from "antd/es/card";
import Table from "antd/es/table";
import {searchAndActions} from "../../../utils/searchAndActions.js";

export default function PaymentTableProv({currentPayment}) {
	const {t} = useTranslation()
	const {data, count} = useUnit($exportedPaymentsSt)
	console.log('currentPayment',currentPayment)
	const exportXls = useUnit(exportExportTableEv)
	const {search} = searchAndActions()
	const columns = [
		// {
		// 	title: t('Поставщик'),
		// 	dataIndex: 'provider_id',
		// 	key: 'provider_id',
		// },
		{
			title: t('Дата платежа'),
			dataIndex: 'dat',
			key: 'dat',
		},
		{
			title: t('БИК банка'),
			dataIndex: 'kodbn',
			key: 'kodbn',
		},
		{
			title: t('Р/С поставщика'),
			dataIndex: 'numrs',
			key: 'numrs',
		},
		{
			title: t('Код р-ного поставщика'),
			dataIndex: 'tsh',
			key: 'tsh',
		},
		{
			title: t('БИН'),
			dataIndex: 'bin',
			key: 'bin',
		},
		{
			title: t('Сумма платежа'),
			dataIndex: 'sump',
			key: 'sump',
		},
		{
			title: t('Название поставщика'),
			dataIndex: 'name_pol',
			key: 'name_pol',
		},
		{
			title: t('Тип платежа'),
			dataIndex: 'nazn_pl',
			key: 'nazn_pl',
		},
		{
			title: t('Код услуги'),
			dataIndex: 'serv_id',
			key: 'serv_id',
		},
		{
			title: t('КБК'),
			dataIndex: 'kodd',
			key: 'kodd',
		},
		{
			title: t('-'),
			dataIndex: 'fl',
			key: 'fl',
		},
		{
			title: t('КБЕ'),
			dataIndex: 'kbe',
			key: 'kbe',
		},

		{
			title: t('КНП'),
			dataIndex: 'knp',
			key: 'knp',
		},
		{
			title: t('ИИН'),
			dataIndex: 'iin_abon',
			key: 'iin_abon',
		},
		{
			title: t('ФИО'),
			dataIndex: 'fio',
			key: 'fio',
		},


	]

	return (
		<>
			<Card>
				<Col>
					<Button
						onClick={() => exportXls(currentPayment)}
						size={'small'}
					>
						<ExcelOutlined size={18}/>
						Экспорт
					</Button>
				</Col>
				<br/>
				<Table
					dataSource={data}
					// dataSource={[]}
					columns={columns}
				/>
			</Card>
		</>

	);
}

