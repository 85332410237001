import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $editTownStatus, $selectedTown,
    TownEditGate,
    getTownByIdFx,
    submitEditTownEv
} from '../../../models/dictionaryTownsModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import CreateEditDistrictEmbedded
    from '../../../components/Embedded/CreateEditDistrictEmbedded/CreateEditDistrictEmbedded.jsx';
import DistrictsTable from '../../../components/Embedded/DistrictsTable/DistrictsTable.jsx';
import useFormChanged from '../../../hooks/useFormChanged.js';
import StreetsTable from '../../../components/Embedded/StreetsTable/StreetsTable.jsx';

export default function EditTown() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [cityForm] = Form.useForm()
    const submit = useUnit(submitEditTownEv)
    const loading = useUnit(getTownByIdFx.pending)
    const editLoading = useUnit(universalDictEndpointFx.pending)
    const status = useUnit($editTownStatus)

    const selectedTown = useUnit($selectedTown)

    const [check, changedFields] = useFormChanged(selectedTown, ['name_kz', 'name_ru', 'index'])

    useEffect(() => {
        if (status) {
            navigate('/dictionaries/town')
        }
    }, [status]);

    useEffect(() => {
        if (selectedTown) {
            cityForm.setFieldsValue(selectedTown)
        }
    }, [selectedTown]);

    return <>
        <TownEditGate id={id}/>
        <Form form={cityForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              onValuesChange={(_, all) => check(all)}
              validateTrigger={'onBlur'}
        >
            <Card title={t('Свойства города')} loading={loading} size={'small'}>
                <Row justify={'space-between'}>
                    <Col span={7}>
                        <Form.Item name={'name_ru'}
                                   label={t('Название города (рус)')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name={'name_kz'}
                                   label={t('Название города (каз)')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name={'index'}
                                   label={t('Индекс')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['index']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Card title={t('Районы')} size={'small'} style={{marginTop: 16}}>
            <DistrictsTable parent={'town_id'} parentId={id}/>
        </Card>
        <Card title={t('Улицы')} size={'small'} style={{marginTop: 16}}>
            <StreetsTable parent={'town_id'} parentId={id}/>
        </Card>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    cityForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={editLoading} onClick={() => {
                    cityForm.submit()
                }} type={'primary'}>
                    {t('Сохранить')}
                </Button>
            </Space>
        </Row>
    </>
}
