import StickyBox from 'react-sticky-box'
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useNavigate} from 'react-router-dom';
import {
    $afterCreateAction,
    setAfterCreateActionEv,
    resetAfterCreationAttrs,
    $createdProviderId,
    ProviderCreateGate,
    uploadAgreementFileFx,
    $provAgreementsFiles,
    $providerDefaultBankAccount,
    setBaEmbeddedFormEv, $embeddedBaForm
} from '../../../models/dictionaryProvidersModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import Tabs from 'antd/es/tabs';
import ProviderMainForm from './tabComponents/ProviderMainForm.jsx';
import ProvServForm from './tabComponents/ProvServForm2.jsx';
// import ProvServTariffForm from './tabComponents/ProvServTariffForm.jsx';
import ProvServAccForm from './tabComponents/ProvServAccForm.jsx';
import ProvServAccTariffForm from './tabComponents/ProvServAccTariffForm.jsx';
import ProvServREOForm from './tabComponents/ProvServREOForm.jsx';
// import ProvServCommissionForm from "./tabComponents/ProvServCommissionForm.jsx";
// import ProvServPrefsForm from "./tabComponents/ProvServPrefsForm.jsx";
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Form from 'antd/es/form';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import {HomeOutlined} from "@ant-design/icons";


export default function CreateProvider() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [providerForm] = Form.useForm()
    const loading = useUnit(universalDictEndpointFx.pending)
    const fileUploadLoading = useUnit(uploadAgreementFileFx.pending)

    const isBeneficiary = Form.useWatch('is_beneficiary', providerForm)

    const createdId = useUnit($createdProviderId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const filesToUpload = useUnit($provAgreementsFiles)

    const defaultBa = useUnit($providerDefaultBankAccount)
    const embeddedOpen = useUnit($embeddedBaForm)
    const setEmbeddedBA = useUnit(setBaEmbeddedFormEv)

    const [activeTab, setActiveTab] = useState('main')

    useEffect(() => {
        if (createdId !== null && filesToUpload.length === 0) {
            if (action === 'edit') {
                navigate(`/dictionaries/providers/edit/${createdId}`)
            } else if (action === 'new') {
                providerForm.resetFields()
            } else if (action === 'list') {
                providerForm.resetFields()
                navigate(`/dictionaries/providers`)
            }
            resetAttrs()
        }
    }, [createdId, action, filesToUpload]);

    const tabs = [
        {
            key: 'main',
            label: t('Основная информация'),
            children: <ProviderMainForm form={providerForm}/>
        },
        {
            key: 'provServ',
            label: t('Услуги и Тарифы'),
            disabled: isBeneficiary,
            children: <ProvServForm/>
        },
        // {
        //     key: 'provServTariff',
        //     disabled: isBeneficiary,
        //     label: t('Тарифы'),
        //     children: <ProvServTariffForm />
        // },
        {
            key: 'provServAcc',
            disabled: isBeneficiary,
            label: t('ЛС'),
            children: <ProvServAccForm/>
        },
        {
            key: 'provServAccTariff',
            disabled: isBeneficiary,
            label: t('Тарифы ЛС'),
            children: <ProvServAccTariffForm/>
        },
        {
            key: 'provServReo',
            disabled: isBeneficiary,
            label: <HomeOutlined/>,
            children: <ProvServREOForm/>
        },
        // {
        //     key: 'provServCommission',
        //     disabled: isBeneficiary,
        //     label: t('Комиссии банков'),
        //     children: <ProvServCommissionForm/>
        // },
        // {
        //     key: 'provServPrefs',
        //     disabled: isBeneficiary,
        //     label: t('Льготы'),
        //     children: <ProvServPrefsForm/>
        // },
    ]

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox offsetTop={24} style={{zIndex: 11}}>
            <DefaultTabBar
                {...props}
                style={{
                    background: '#f5f5f5',
                    borderBottom: '1px solid #ffffff',
                    padding: '0 32px',
                    margin: '0 -32px 8px',
                }}
            />
        </StickyBox>
    )

    return <>
        <ProviderCreateGate/>
            <Tabs defaultActiveKey="main" activeKey={activeTab} items={tabs} type={'card'}
                  onChange={(tabKey) => {
                      if (embeddedOpen) {
                          setEmbeddedBA(false)
                      }
                      setActiveTab(tabKey)
                  }}
                  renderTabBar={renderTabBar}
            />
        <Divider/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    providerForm.resetFields()
                    resetAttrs()
                    navigate('/dictionaries/providers')
                }}
                >
                    {t('Отмена')}
                </Button>
                <Tooltip title={!defaultBa ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={loading || fileUploadLoading}
                            onClick={() => {
                                setAction('new')
                                providerForm.submit()
                            }}
                            disabled={!defaultBa}
                            type={'primary'}
                            ghost
                    >
                        {t('Сохранить и создать следующего')}
                    </Button>
                </Tooltip>
                <Tooltip title={!defaultBa ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={loading || fileUploadLoading}
                            onClick={() => {
                                setAction('edit')
                                providerForm.submit()
                            }}
                            disabled={!defaultBa}
                            type={'primary'}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                </Tooltip>
                <Tooltip title={!defaultBa ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('list')
                                providerForm.submit()
                            }}
                            disabled={!defaultBa}
                    >
                        {t('Сохранить и перейти к списку')}
                    </Button>
                </Tooltip>
            </Space>
        </Row>
    </>
}
