import {useTranslation} from 'react-i18next';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    CheckCircleOutlined, CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link} from 'react-router-dom';
import {Popconfirm} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {
    $realEstateObjectsList, deleteRealEstateObjectsEv, getRealEstateObjectsListFx,
    RealEstateObjectsListGate,

} from "../../models/dictionaryRealEstateObjectsModel/index.js";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import AddressREOSearchForm from '../../components/AddressReoSearchForms/AddressREOSearchForm.jsx';

export default function DictionaryRealEstateObjectsList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($realEstateObjectsList)
    const loading = useUnit(getRealEstateObjectsListFx.pending)
    const onDelete = useUnit(deleteRealEstateObjectsEv)

    const {search, exportXlsAction} = searchAndActions()
    // const genitive_form = t(`breadcrumbs.genitive.real-estate-objects`)

    const columns = [
        // {
        //     width: '5%',
        //     title: t(`Код ОН`),
        //     dataIndex: 'id',
        //     sorter: true,
        //     key: 'id',
        // },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            sorter: true,
            key: 'town_id',
            render: (town_id) => <MappedDictItem id={town_id} type={'town'}/>
        },
        {
            title: t('Район города'),
            dataIndex: 'town_distr_id',
            sorter: true,
            key: 'town_distr_id',
            render: (town_distr_id, record) => <MappedDictItem id={town_distr_id} type={'town_distr'}/>
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            sorter: true,
            key: 'street_id',
            render: (street_id, record) => <MappedDictItem id={street_id} type={'street'}/>
        },

        {
            title: t('Дом'),
            dataIndex: 'house',
            sorter: true,
            key: 'house',
            render: (house, record) => house
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            sorter: true,
            key: 'corpus',
            render: (corpus, record) => corpus
        },

        {
            title: t('ЖК'),
            dataIndex: 'name_kz',
            sorter: true,
            key: 'name_kz',
            render: (_, r) => {
                let name = null
                if (r.name_kz) name = r.name_kz
                if (!name && r.name_ru) name = r.name_ru
                if (name) return <Link to={`/dictionaries/real-estate-objects/view/${r.id}`}>
                    {name}
                </Link>
                return '-'
            }
        },
        // {
        //     title: t('Название (рус)'),
        //     dataIndex: 'name_ru',
        //     sorter: true,
        //     key: 'name_ru',
        //     render: (name_ru, record) => name_ru !== '-' ? <Link to={`/dictionaries/real-estate-objects/view/${record.id}`}>{name_ru}</Link> : '-'
        // },
        {
            title: t('Тип ОН'),
            dataIndex: 'real_estate_object_type_id',
            sorter: true,
            key: 'real_estate_object_type_id',
            render: (real_estate_object_type_id, record) => <MappedDictItem id={real_estate_object_type_id} type={'real_estate_object_type'}/>
        },

        {
            title: t('РКА'),
            dataIndex: 'cadastral_number',
            sorter: true,
            hide: true,
            key: 'cadastral_number',
            render: (cadastral_number, record) => cadastral_number
        },
        {
            title: t('Участок'),
            dataIndex: 'sector_id',
            sorter: true,
            key: 'sector_id',
            render: (sector_id, record) => <MappedDictItem id={sector_id} type={'sector'}/>
        },

        {
            title: t('Контролер'),
            dataIndex: 'inspector_id',
            sorter: true,
            key: 'inspector_id',
            render: (inspector_id, record) => <MappedDictItem id={inspector_id} type={'inspector'}/>
        },


        {
            title: t('Индекс'),
            dataIndex: 'index',
            sorter: true,
            hide: true,
            key: 'index',
            render: (index, record) => index
        },
        {
            title: t('Кол-во этажей'),
            dataIndex: 'floor_count',
            sorter: true,
            hide: true,
            align: 'center',
            key: 'floor_count',
            render: (floor_count, record) => floor_count === true ? floor_count : '-'
        },
        {
            title: t('Кол-во подъездов'),
            dataIndex: 'entrance_count',
            sorter: true,
            align: 'center',
            hide: true,
            key: 'entrance_count',
            render: (entrance_count, record) => entrance_count === true ? entrance_count : '-'
        },
        {
            title: t('Кол-во квартир'),
            dataIndex: 'apartment_count',
            sorter: true,
            align: 'center',
            hide: true,
            key: 'apartment_count',
            render: (apartment_count, record) => apartment_count === true ? apartment_count : '-'
        },
        {
            title: t('Наличие электричества'),
            dataIndex: 'is_electricity_network_present',
            sorter: true,
            align: 'center',
            hide: true,
            key: 'is_electricity_network_present',
            render: (is_electricity_network_present, record) => is_electricity_network_present === true ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> : <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
        },
        {
            title: t('Наличие тепловой сети'),
            dataIndex: 'is_heat_network_present',
            sorter: true,
            align: 'center',
            hide: true,
            key: 'is_heat_network_present',
            render: (is_heat_network_present, record) => is_heat_network_present === true ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> : <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
        },
        {
            title: t('Наличие лифта'),
            dataIndex: 'is_elevator_present',
            sorter: true,
            align: 'center',
            hide: true,
            key: 'is_elevator_present',
            render: (is_elevator_present, record) => is_elevator_present === true ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> : <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
        },
        {
            title: t('Наличие почтовых ящиков'),
            dataIndex: 'is_mailboxes_present',
            sorter: true,
            align: 'center',
            hide: true,
            key: 'is_mailboxes_present',
            render: (is_mailboxes_present, record) => is_mailboxes_present === true ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> : <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
        },
        {
            title: t('Создан'),
            key: 'created',
            hide: true,
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            hide: true,
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup size={'small'}>
                <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/real-estate-objects/edit/${id}`}><Button icon={<EditOutlined />} type={'primary'} /></Link>
                </Tooltip>
                <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/real-estate-objects/view/${id}`}><Button icon={<EyeOutlined />} /></Link>
                </Tooltip>
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => onDelete(id)}
                            placement={'left'}
                >
                <Tooltip title={t('Удалить')} placement={'top'}>
                    <Button icon={<DeleteOutlined />} danger/>
                </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    const filters = [
        // {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns'},
        // {name: 'town_distr_id', label: t('Район города'), type: 'select', dict: 'districts', multiple: true},
        // {name: 'street_id', label: t('Улица'), type: 'select', dict: 'streets', multiple: true},
        {name: 'custom', type: 'custom', component: AddressREOSearchForm, span: 24,},
        {name: 'code_name', label: t('ЖК'), type: 'input', span: 4,},
        {name: 'inspector_id', label: t('Контролер'), type: 'select', dict: 'inspector', multiple: true, span: 8,},
        {name: 'sector_id', label: t('Участок'), type: 'select', dict: 'sector',multiple: true, span: 3,},
        {name: 'real_estate_object_type_id', label: t('Тип ОН'), type: 'select', dict: 'real_estate_object_type', span: 4,},
        {name: 'cadastral_number', label: t('РКА '), type: 'input', span: 3,},
        {name: 'index', label: t('Индекс'), type: 'input', span: 2,},
        // {name: 'is_electricity_network_present', label: t('Электричество'), type: 'switch', span: 2,},
        // {name: 'is_heat_network_present', label: t('Тепловые сети'), type: 'switch', span: 2,},
        // {name: 'is_on_crossroad', label: t('Находится на перекрестке'), type: 'switch'},
        // {name: 'has_pdf', label: t('Наличие PDF'), type: 'switch'},
        // {name: 'street_type_id', label: t('Тип'), type: 'select', dict: 'streetTypes', multiple: true},
        // {name: 'code_name', label: t('Код/ФИО'), type: 'input'},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true, span: 4,},
        {name: 'created_at', label: t('Дата создания'), type: 'range', span: 4,},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true, span: 4,},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range', span: 4,},
    ]


    return <>
        <RealEstateObjectsListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
        />
    </>
}
