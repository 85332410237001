import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {
    $editServicesStatus,
    $selectedServices, $serviceTypeEmbeddedOpen, getServicesByIdFx,
    ServicesEditGate, setServiceTypeEmbedded,
    submitEditServicesEv
} from '../../../models/dictionaryServicesModel/index.js';
import StarredTitle from '../../../components/StarredTitle/StarredTitle.jsx';
import {PlusOutlined} from '@ant-design/icons';
import CreateEmbeddedUniversalForm
    from '../../../components/Embedded/CreateEmbeddedUnirversalForm/CreateEmbeddedUniversalForm.jsx';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {filterOption} from "../../../utils/selectUtils.jsx";

export default function EditServices() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedServices)

    const [servicesForm] = Form.useForm()

    const serUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)
    const setEmbedded = useUnit(setServiceTypeEmbedded)
    const embeddedOpen = useUnit($serviceTypeEmbeddedOpen)
    const templateOpts = useStoreMap($dictsSelects, sel => sel.template)
    const algorithmOpts = useStoreMap($dictsSelects, sel => sel.algorithms)
    const srvPayTypeOpts = useStoreMap($dictsSelects, sel => sel.servicePaymentType)
    const srvServiceClassOpts = useStoreMap($dictsSelects, sel => sel.serviceClasses)

    const submit = useUnit(submitEditServicesEv)

    const loading = useUnit(getServicesByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editServicesStatus)
    const genitive_form = t(`breadcrumbs.genitive.services`);


    useEffect(() => {
        if (status) {
            navigate('/dictionaries/services')
        }
    }, [status]);


    useEffect(() => {
        if (selected) {
            servicesForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, [
        'name_kz', 'name_ru', 'short_name_ru', 'short_name_kz', 'epd_order', 'service_unit_id',
        'template_id',
    ])

    console.log('ServicesEdit', changedFields)
    return <>
        <ServicesEditGate id={id}/>
        <Form form={servicesForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[16, 16]}>
                <Col span={12}>
                    <Card title={t('Основные свойства услуги')} size={'small'} style={{height: '100%'}}
                          loading={loading}>
                        <Row gutter={[8, 0]}>
                            <Col span={16}>
                                <Form.Item name={'name_kz'}
                                           label={t(`Название ${genitive_form} (каз)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['name_kz']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'short_name_kz'}
                                           label={t(`Крат. Название ${genitive_form} (каз)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['short_name_kz']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item name={'name_ru'}
                                           label={t(`Название ${genitive_form} (рус)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['name_ru']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'short_name_ru'}
                                           label={t(`Крат. Название ${genitive_form} (рус)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['short_name_ru']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Дополнительные свойства')}/>}
                          size={'small'} style={{height: '100%'}} loading={loading}
                    >
                        <Row gutter={[8, 0]}>
                            <Col span={7}>
                                <Form.Item
                                    name={'service_payment_type_id'}
                                    label={t('Вид платежа')}
                                    rules={[{
                                        required: true,
                                        message: t('Обязательное поле')
                                    }]}
                                >
                                    <Select
                                        options={srvPayTypeOpts}
                                        filterOption={filterOption}
                                        showSearch allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'service_class_id'}
                                    label={t('Класс услуг')}
                                    rules={[{
                                        required: true,
                                        message: t('Обязательное поле')
                                    }]}
                                >
                                    <Select
                                        options={srvServiceClassOpts}
                                        filterOption={filterOption}
                                        showSearch allowClear
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={10}>
                                <Form.Item name={'template_id'}
                                           label={t('Шаблон')}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['template_id']}
                                >
                                    <Select options={templateOpts} showSearch allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name={'epd_order'}
                                           label={t('Порядок ЕПД')}
                                           validateStatus={changedFields['epd_order']}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    servicesForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            servicesForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить')}
                </Button>
            </Space>
        </Row>
    </>
}
