import {useTranslation} from 'react-i18next';
import {useEffect, useMemo, useState} from 'react';
import {useUnit} from 'effector-react';
import {
    $providerServAccsTarifs,
    $provServAccsFilters, $provSrvAccsMap, $provSrvsSelOpts,
    $selectedServAccTarif,
    $selectedSrvAccs, $srvTarifsMap, $srvTarifsOpts,
    addServAccTarifEv,
    applyServAccTarifEv,
    deleteServAccTarifEv,
    resetServAccTarifEv,
    resetSrvAccsForTarifsEv,
    selectServAccTarifEv, setEmbeddedTariffFormEv
} from '../../../../models/dictionaryProvidersModel/index.js';
import dayjs from 'dayjs';
import Form from 'antd/es/form';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import {CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import Popconfirm from 'antd/es/popconfirm';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import Table from 'antd/es/table';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import {filterOption, optionRender} from '../../../../utils/selectUtils.jsx';
import {paginationConfig} from '../../../../utils/paginationConfig.js';
import SrvAccsSearchForm from '../SrvAccsSearchForm.jsx';
import CreateTariffEmbedded from '../CreateTariffEmbedded.jsx';


export default function ProvServAccTariffForm({isView}) {
    const {t} = useTranslation()

    const [srvAccTarifCreateForm] = Form.useForm()
    const [srvAccTarifUpdForm] = Form.useForm()

    const [createMode, setCreateMode] = useState(false)

    const selectedSrvAccs = useUnit($selectedSrvAccs)
    const resetSrvAccs = useUnit(resetSrvAccsForTarifsEv)
    const accFilters = useUnit($provServAccsFilters)

    const serviceOpts = useUnit($provSrvsSelOpts)
    const srvMap = useUnit($provSrvAccsMap)
    const tarifOpts = useUnit($srvTarifsOpts)
    const tarifMap = useUnit($srvTarifsMap)
    const setTarifEmbedded = useUnit(setEmbeddedTariffFormEv)

    const srvAccsTarifs = useUnit($providerServAccsTarifs)
    const selectedAccTarif = useUnit($selectedServAccTarif)
    const selectAccTarif = useUnit(selectServAccTarifEv)
    const resetSelAccTarif = useUnit(resetServAccTarifEv)

    useEffect(() => {
        if (selectedAccTarif) {
            const prepared = {...selectedAccTarif}
            for (const [key, value] of Object.entries(prepared)) {
                if (key.includes('from') || key.includes('to')) {
                    prepared[key] = value ? dayjs(value) : null
                }
            }
            srvAccTarifUpdForm.setFieldsValue(prepared)
        }
    }, [selectedAccTarif]);

    const addAccTarif = useUnit(addServAccTarifEv)
    const changeAccTarif = useUnit(applyServAccTarifEv)
    const deleteAccTarif = useUnit(deleteServAccTarifEv)

    const onCreate = (values) => {
        const data = srvAccTarifCreateForm.getFieldsValue(true)
        const {type, ...payload} = {...values, ...data}
        addAccTarif({type, payload})
        srvAccTarifCreateForm.resetFields()
    }

    const onUpdate = values => {
        changeAccTarif({id: selectedAccTarif.id, payload: values})
        resetSelAccTarif()
        srvAccTarifUpdForm.resetFields()
    }

    const onCancelCreate = () => {
        setCreateMode(false)
        srvAccTarifCreateForm.resetFields()
    }

    const onCancel = () => {
        resetSelAccTarif()
        srvAccTarifUpdForm.resetFields()
    }

    const [dataSource, setDataSource] = useState([])
    const [filterBySrv, setFilterBySrv] = useState(null)

    useEffect(() => {
        if (filterBySrv) {
            setDataSource(srvAccsTarifs.filter(i => i.provider_service_id === filterBySrv))
        } else {
            setDataSource(srvAccsTarifs)
        }
    }, [filterBySrv, srvAccsTarifs])

    useEffect(() => {
        if (srvAccsTarifs.length > 0) {
            resetSrvAccs()
            setCreateMode(false)
        }
    }, [srvAccsTarifs]);

    const tarOpts = useMemo(() => {
        return [
            ...tarifOpts.filter(i => i.provider_service_id === accFilters.provider_service_id),
            {label: 'Создать', value: 'new', emoji: true}
        ]
    }, [tarifOpts, accFilters?.provider_service_id])

    const selTariff = Form.useWatch('tarif_id', srvAccTarifCreateForm)

    useEffect(() => {
        if (createMode) {
            if (selTariff === 'new' && tarOpts.some(i => i.value < 0)) {
                const newTar = tarOpts.findLast(i => i.value < 0)
                if (newTar) {
                    srvAccTarifCreateForm.setFieldsValue({tarif_id: newTar?.value})
                }
            }
        }
    }, [tarOpts, createMode, selTariff])


    const columns = [
        {
            title: t('Услуга'),
            dataIndex: 'account_provider_service_id',
            width: '20%',
            sorter: (a, b) => a.account_provider_service_id - b.account_provider_service_id,
            render: (id) => <MappedDictItem id={srvMap[id]} type={'services'}/>
        },
        {
            title: t('Номер ЛС'),
            width: '10%',
            dataIndex: 'account_id',
            render: (id) => <MappedDictItem id={id} type={'accounts'}/>
        },
        {
            title: t('ФИО'),
            dataIndex: 'account_name_ru',
            render: (nameRu, r) => localStorage.getItem('lang') === 'kz' ? r.account_name_kz : nameRu
        },
        {
            title: t('Тариф'),
            dataIndex: 'tarif_id',
            render: (id) => tarifMap[id]
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            width: '10%',
            render: (id) => <MappedDictItem id={id} type={'town'}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            render: (id) => <MappedDictItem id={id} type={'street'}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
        },
        {
            title: t('Дата начала действия'),
            dataIndex: 'date_from',
            render: (date) => date ? dayjs(date).format('DD.MM.YYYY') : null
        },
        {
            title: createMode ? null : <Tooltip title={t('Добавить')}>
                <Button type={'primary'} icon={<PlusOutlined />} onClick={() => setCreateMode(true)}/>
            </Tooltip>,
            hidden: isView,
            width: '96px',
            align: 'center',
            dataIndex: 'id',
            render: (id) => {
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined/>}
                                onClick={() => selectAccTarif(id)}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={() => deleteAccTarif(id)}
                                placement={'left'}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button icon={<CloseOutlined/>} type={'primary'} danger/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            },
        }
    ]

    return <>
        {!isView && createMode &&
            <Card title={'Добавление Тарифа для ЛС'} size={'small'} style={{marginBottom: '8px'}}>
                <SrvAccsSearchForm />
                <Divider/>
                <Row gutter={16} style={{marginBottom: 8}}>
                    <Col span={6}>
                        <CreateTariffEmbedded srv_id={accFilters?.provider_service_id}/>
                    </Col>
                </Row>
                <Form form={srvAccTarifCreateForm} layout={'vertical'}
                      onFinish={onCreate}
                      size={'small'}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item name={'tarif_id'} label={t('Тариф')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Select options={tarOpts}
                                        optionRender={optionRender}
                                        filterOption={filterOption}
                                        onChange={(v) => {
                                            if (v === 'new') {
                                                setTarifEmbedded(true)
                                            } else {
                                                setTarifEmbedded(false)
                                            }
                                        }}
                                        style={{width: '100%'}}
                                        showSearch
                                        disabled={!accFilters.provider_service_id}
                                        allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name={'date_from'} label={t('Дата начала действия')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name={'rewrite_old'}
                                       label={t('Обновить в уже добавленных ЛС')}
                                       valuePropName={'checked'}
                                       initialValue={false}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify={'space-between'}>
                        <ButtonGroup>
                            <Button type={'primary'}
                                    onClick={() => {
                                        srvAccTarifCreateForm.setFieldsValue({type: 'selected'})
                                        srvAccTarifCreateForm.submit()
                                    }}
                                    disabled={!selectedSrvAccs?.length}
                            >
                                {t('Добавить выделенные')}
                            </Button>
                            <Button
                                onClick={() => {
                                    srvAccTarifCreateForm.setFieldsValue({type: 'all'})
                                    srvAccTarifCreateForm.submit()
                                }}
                            >
                                {t('Добавить все подходящие под фильтр')}
                            </Button>
                        </ButtonGroup>
                        <Button danger type={'primary'} onClick={() => onCancelCreate()}>{t('Отмена')}</Button>
                    </Row>
                </Form>
            </Card>
        }
        <Card title={'Фильтры'} size={'small'}>
            <Row gutter={[16, 0]} style={{alignItems: 'flex-end'}}>
                <Col span={4}>
                    <p style={{marginTop: 0, marginBottom: 4}}>{t('Фильтр по Услуге')}</p>
                    <Select
                        options={serviceOpts}
                        filterOption={filterOption}
                        size={'small'}
                        style={{width: '100%'}}
                        showSearch
                        allowClear
                        value={filterBySrv}
                        onSelect={(id) => setFilterBySrv(id)}
                        onClear={() => setFilterBySrv(null)}
                    />
                </Col>
                <Col span={4}>
                    <Button
                        onClick={() => setFilterBySrv(null)}
                        disabled={!filterBySrv}
                        size={'small'}
                    >
                        {t('Очистить фильтр')}
                    </Button>
                </Col>
            </Row>
        </Card>


        <Row gutter={[16, 16]} style={{marginTop: 8}}>
            <Col span={selectedAccTarif ? 12 : 24}>
                <Card title={'ЛС с тарифами'} size={'small'}>
                    <Table dataSource={dataSource}
                           columns={columns}
                           bordered
                           className={'table-container'}
                           pagination={paginationConfig}
                           size={'small'}
                           rowKey={'id'}
                           rowClassName={(record) => record.id === selectedAccTarif?.id ? 'selectedRowObject' : null}
                    />
                </Card>
            </Col>
            {selectedAccTarif && <Col span={12}>
                <Card title={'Редактирование Услуги по ЛС'} size={'small'}>
                        <Form form={srvAccTarifUpdForm} layout={'vertical'} onFinish={onUpdate} size={'small'}>
                            <Form.Item name={'account_provider_service_id'} label={t('Услуга')}>
                                <Select
                                    options={serviceOpts}
                                    filterOption={filterOption}
                                    style={{width: '100%'}}
                                    showSearch
                                    allowClear
                                    disabled
                                />
                            </Form.Item>
                            <Form.Item name={'account_id'} label={t('Номер ЛС')}>
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item name={'tarif_id'} label={t('Тариф')}>
                                <Select options={tarifOpts}
                                        style={{width: '100%'}}
                                        disabled
                                />
                            </Form.Item>
                            <Form.Item name={'date_from'} label={t('Дата начала действия')}>
                                <DatePicker />
                            </Form.Item>
                            <Row style={{gap: 8, marginTop: 8}}>
                                <Form.Item noStyle>
                                    <Button type={'primary'}
                                            onClick={() => srvAccTarifUpdForm.submit()}
                                    >
                                        {t('Применить')}
                                    </Button>
                                </Form.Item>
                                <Form.Item noStyle>
                                    <Button
                                        onClick={onCancel}
                                        disabled={!selectedAccTarif}
                                    >
                                        {t('Отменить')}
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                </Card>
            </Col>}
        </Row>
    </>
}
