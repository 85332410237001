import Card from 'antd/es/card';
import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import {useStoreMap, useUnit} from 'effector-react';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {filterOption} from '../../../utils/selectUtils.jsx';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {
    $embeddedTariffForm, addServTariffEv,
    setEmbeddedTariffFormEv
} from '../../../models/dictionaryProvidersModel/index.js';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';

export default function CreateTariffEmbedded({srv_id}) {
    const {t} = useTranslation()

    const [tariffForm] = Form.useForm()

    const srvUnitsOpts = useStoreMap($dictsSelects, sel => sel.service_units)
    const open = useUnit($embeddedTariffForm)
    const setEmbedded = useUnit(setEmbeddedTariffFormEv)
    const addTariff = useUnit(addServTariffEv)

    const onSubmit = (values) => {
        addTariff({srv_id, payload: values})
        tariffForm.resetFields()
    }

    const onCancel = () => {
        tariffForm.resetFields()
        setEmbedded(false)
    }


    return !!(open && srv_id) && <Card size={'small'} title={t('Свойства тарифа')}>
        <Form form={tariffForm} layout={'vertical'} size={'small'} onFinish={onSubmit}>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item name={'value'} label={t('Значение тарифа')}>
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'service_unit_id'} label={t('Единица измерения')}>
                        <Select options={srvUnitsOpts}
                                filterOption={filterOption}
                                showSearch
                                allowClear
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <ButtonGroup>
                    <Button type={'primary'} onClick={() => tariffForm.submit()}>
                        {t('Сохранить')}
                    </Button>
                    <Button danger onClick={onCancel}>
                        {t('Отмена')}
                    </Button>
                </ButtonGroup>
            </Form.Item>
        </Form>
    </Card>
}