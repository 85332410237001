import {sample} from "effector";
import {changeHiddenCardsEv, sendSaveMainPageEv} from "./events.js";
import {$hiddenCards, $mainPageData} from "./stores.js";
import {sendSaveMainPageFx} from "./effects.js";

sample({
	source: $hiddenCards,
	clock: changeHiddenCardsEv,
	fn: (hiddenCards, clock) => {
		if(hiddenCards.includes(clock)){
			return hiddenCards.filter(card => card !== clock)
		}
		return [...hiddenCards, clock]
	},
	target: $hiddenCards
})

sample({
	source: $hiddenCards,
	clock: sendSaveMainPageEv,
	fn: (source, clock) => {
		return {
			hiddenCards: source,
			mainPageData: clock
		}
	},
	target: sendSaveMainPageFx
})
