import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const ProviderListGate = createGate()
export const ProviderViewGate = createGate()
export const ProviderEditGate = createGate()
export const ProviderCreateGate = createGate()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
export const setAfterEditActionEv = createEvent()
export const resetAfterEditAttrs = createEvent()

export const setBeneficiaryEv = createEvent()

export const resetLinksOnBenefChangeEv = createEvent()

export const submitCreateProviderEv = createEvent()
export const submitEditProviderEv = createEvent()
export const deleteProviderEv = createEvent()

export const addProvServiceEv = createEvent()
export const selectProvServiceEv = createEvent()
export const resetSelectedProvSrvEv = createEvent()
export const applySrvParamsEv = createEvent()
export const deleteSrvEv = createEvent()

export const addProvBankAccountEv = createEvent()
export const setDefaultBankAccountEv = createEvent()
export const selectProvBankAccountEv = createEvent()
export const resetProvBankAccountsEv = createEvent()
export const applySrvBankAccountEv = createEvent()
export const deleteProvBankAccountEv = createEvent()
export const setBaEmbeddedFormEv = createEvent()

export const addProvAgreementEv = createEvent()
export const selectProvAgreementEv = createEvent()
export const applyProvAgreementEv = createEvent()
export const deleteProvAgreementEv = createEvent()
export const downloadProvAgreementFileEv = createEvent()

export const addServTariffEv = createEvent()
// export const selectServTariffEv = createEvent()
// export const resetServTariffEv = createEvent()
// export const applyServTariffEv = createEvent()
// export const deleteServTariffEv = createEvent()

export const addServPrefEv = createEvent()
export const selectServPrefEv = createEvent()
export const resetServPrefEv = createEvent()
export const applyServPrefEv = createEvent()
export const deleteServPrefEv = createEvent()

export const addServComEv = createEvent()
export const selectServComEv = createEvent()
export const resetServComEv = createEvent()
export const applyServComEv = createEvent()
export const deleteServComEv = createEvent()

export const addServBaREOEv = createEvent()
export const selectServBaREOEv = createEvent()
export const resetServBaREOEv = createEvent()
export const applyServBaREOEv = createEvent()
export const deleteServBaREOEv = createEvent()

export const addServAccEv = createEvent()
export const selectServAccEv = createEvent()
export const resetServAccEv = createEvent()
export const applyServAccEv = createEvent()
export const deleteServAccEv = createEvent()
export const setAccountsFiltersEv = createEvent()
export const getAccountsEv = createEvent()
export const resetAccountsEv = createEvent()
export const selectMultipleAccountsEv = createEvent()

export const addServAccTarifEv = createEvent()
export const selectServAccTarifEv = createEvent()
export const resetServAccTarifEv = createEvent()
export const applyServAccTarifEv = createEvent()
export const deleteServAccTarifEv = createEvent()
export const setSrvAccForTarifsFilterEv = createEvent()
export const resetSrvAccsForTarifsEv = createEvent()
export const applySrvAccForTarifsFiltersEv = createEvent()
export const selectMultipleSrvAccsForTarifsEv = createEvent()
export const setEmbeddedTariffFormEv = createEvent()
