import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import Space from 'antd/es/space';
import {
    $afterCreateOrgTypeAction,
    $createdOrgTypeId,
    submitCreateOrgTypeEv,
    setAfterCreateActionEv,
    resetAfterCreationAttrs, OrgTypeCreateGate
} from '../../../models/dictionaryOrgTypeModel';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';

export default function CreateOrgType() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [orgTypeForm] = Form.useForm()
    const submit = useUnit(submitCreateOrgTypeEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const createdId = useUnit($createdOrgTypeId)
    const action = useUnit($afterCreateOrgTypeAction)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.organization_type`);

    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/organization_type/edit/${createdId}`)
            } else if (action === 'new') {
                orgTypeForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);

    const setAction = useUnit(setAfterCreateActionEv)

    return <>
        <OrgTypeCreateGate />
        <Form form={orgTypeForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>
            <Card title={t('Свойства категории организации')} size={'small'}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    orgTypeForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            orgTypeForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            orgTypeForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
