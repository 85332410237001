import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {
    $createdChiefInspectorId,
    ChiefInspectorListGate,
    submitCreateChiefInspectorEv,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv, ChiefInspectorCreateGate,
} from "../../../models/dictionaryChiefInspectorModel/index.js";
import InspectorsTable from "../../../components/Embedded/InspectorsTable/InspectorsTable.jsx";

export default function CreateChiefInspector() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [inspectorsForm] = Form.useForm()

    const submit = useUnit(submitCreateChiefInspectorEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdChiefInspectorId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const genitive_form = t(`breadcrumbs.genitive.chief-inspector`);



    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/chief-inspector/edit/${createdId}`)
            } else if (action === 'new') {
                inspectorsForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);

    const onSubmit = (values) => {
        values['name_kz'] = values['name_ru']
        return submit(values)
    }

    return <>
        <ChiefInspectorCreateGate />
        <Form form={inspectorsForm} layout={'vertical'} onFinish={onSubmit} size={'small'}>

            <Row justify={'space-between'} gutter={[24, 24]}>

                <Col span={24}>
                    <Card title={<StarredTitle title={t('Свойства Старшего Контролера')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Row>
                            {/*<Col span={6}>*/}
                            {/*    <Form.Item name={'name_kz'}*/}
                            {/*               label={t(`ФИО ${genitive_form} (каз)`)}*/}
                            {/*               rules={[{required: true, message: t('Обязательное поле')}]}*/}
                            {/*    >*/}
                            {/*        <Input/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col span={8}>
                                <Form.Item name={'name_ru'}
                                           label={t(`ФИО ${genitive_form}`)}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
        {/*<Divider orientation={'left'} orientationMargin={0} > asdf</Divider>*/}
        <InspectorsTable />
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    inspectorsForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            inspectorsForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующего')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            inspectorsForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
