import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv, SectorCreateGate, submitCreateSectorEv,
    $createdSectorId
} from '../../../models/dictionarySectorModel/index.js';
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import CreateTownEmbeddedForm from "../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx";
import CreateInspectorEmbeddedForm
    from "../../../components/Embedded/CreateInspectorEmbeddedForm/CreateInspectorEmbeddedForm.jsx";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {
    setCreateInspectorEmbeddedEv,
    setCreateTownEmbeddedEv
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {$createdTownIdModal} from "../../../models/dictionaryDistrictsModel/index.js";
import {$inspectorsByCitySelect, getInspectorsByCityEv} from "../../../models/selectsByParentModel/index.js";

export default function CreateSector() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [sectorForm] = Form.useForm()

    const submit = useUnit(submitCreateSectorEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdSectorId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const townsOpts = useStoreMap($dictsSelects, sel => sel.towns)
    const inspectorOpts = useStoreMap($dictsSelects, sel => sel.inspector)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const setInspectorEmbedded = useUnit(setCreateInspectorEmbeddedEv)
    const newTown = useUnit($createdTownIdModal)

    const inspectorOptions = inspectorOpts.filter(item => item.value !== ' ')
    const inspectorsByTown = useUnit($inspectorsByCitySelect)
    const getInspectorsByTown = useUnit(getInspectorsByCityEv)

    const genitive_form = t(`breadcrumbs.genitive.sector`);

    const townSearched = Form.useWatch('town_id', sectorForm)

    useEffect(() => {
        if (newTown) {
            districtForm.setFieldValue('town_id', newTown)
        }
    }, [newTown])

    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/sector/edit/${createdId}`)
            } else if (action === 'new') {
                sectorForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);

    return <>
        <SectorCreateGate/>
        <Form form={sectorForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              // onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>

                <Col span={8}>
                    <Card title={<StarredTitle title={t('Населенный пункт')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>

                        <Form.Item name={'town_id'}
                                   label={t('Населенный пункт')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select
                                options={[...townsOpts, {label: 'Создать', value: -1, emoji: true}]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setTownEmbedded(true)
                                        getInspectorsByTown(undefined)
                                    } else {
                                        sectorForm.resetFields(['town'])
                                        getInspectorsByTown(v)
                                        setTownEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateTownEmbeddedForm form={sectorForm}/>


                    </Card>
                </Col>
                <Col span={8}>
                    <Card title={<StarredTitle title={t('Контролер')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Form.Item name={'inspector_id'}
                                   label={t('Контролер')}
                        >
                            <Select
                                options={
                                    typeof townSearched === 'number' ?
                                      [...inspectorsByTown, {label: 'Создать', value: -1, emoji: true}]
                                      :
                                      [...inspectorOptions, {label: 'Создать', value: -1, emoji: true},]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setInspectorEmbedded(true)
                                    } else {
                                        sectorForm.resetFields(['inspector'])
                                        setInspectorEmbedded(false)
                                    }
                                }}
                                showSearch
                                defaultValue={null}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateInspectorEmbeddedForm form={sectorForm}/>

                    </Card>

                </Col>


                <Col span={8}>
                    <Card title={t('Свойства участка')} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        {/*<Form.Item name={'name_kz'}*/}
                        {/*           label={t(`Название ${genitive_form} (каз)`)}*/}
                        {/*           rules={[{required: false, message: t('Обязательное поле')}]}*/}
                        {/*>*/}
                        {/*    <Input/>*/}
                        {/*</Form.Item>*/}

                        {/*<Form.Item name={'name_ru'}*/}
                        {/*           label={t(`Название ${genitive_form} (рус)`)}*/}
                        {/*           rules={[{required: false, message: t('Обязательное поле')}]}*/}
                        {/*>*/}
                        {/*    <Input/>*/}
                        {/*</Form.Item>*/}

                        <Form.Item name={'adv_sector'}
                                   label={t('Рекламный сектор')}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>

                </Col>

            </Row>

        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    sectorForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            sectorForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            sectorForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
