import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table'

import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import CreateStTypeEmbeddedForm
    from '../../../components/Embedded/CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';

import {
    BankApiInfoCreateGate, submitCreateBankApiInfoEv,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    $createdBankApiInfoId
} from "../../../models/dictionaryBankApiInfoModel/index.js";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {Select} from "antd";
import {$bankApiAddressList} from "../../../models/dictionaryBankApiAddressModel/index.js";
import IpAddressesTable from "../../../components/Embedded/IpAddressTable/IpAddressesTable.jsx";

export default function CreateBankApiInfo() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [bankApiInfoForm] = Form.useForm()

    const submit = useUnit(submitCreateBankApiInfoEv)

    const loading = useUnit(universalDictEndpointFx.pending)
    const bank_ip = useUnit($bankApiAddressList)

    const createdId = useUnit($createdBankApiInfoId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const {bank} = useStoreMap($dictsSelects, sel => ({
        bank: sel.bank,
    }))

    const columns = [
        {
            width: '5%',
            title: t('Ip'),
            dataIndex: 'ip',
            sorter: (a, b) => a.id - b.id,
            key: 'ip',
        },
    ]



    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/bank-api-info/edit/${createdId}`)
            } else if (action === 'new') {
                bankApiInfoForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <BankApiInfoCreateGate/>
        <Card>
            <Form form={bankApiInfoForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>


                <CreateStTypeEmbeddedForm />
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'bank_id'}
                                   label={t('Банк')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select
                                options={bank}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name={'code'}
                                   label={t('Аббревиатура')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider orientation={'left'} orientationMargin={0}/>

        </Card>
        <Card>
            <IpAddressesTable />
        </Card>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    bankApiInfoForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            bankApiInfoForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            bankApiInfoForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
