import {
    $afterCreateAction,
    $createdAccountId,
    $editAccountStatus,
    $selectedAccount,
    $accountsList,
    $sexEmbedded,
    $nationEmbedded,
    $prefCatEmbedded,
    $consCatEmbedded,
    $selectedAccountReEsObj,
    $providerServiceList,
    $accountsProviderServiceList,
    $accrualsData,
    $paymentsData,
    $paymentsElementsData,
    $selectedProvider,
    $selectedService, $providerService, $editedExternalNumber
} from './stores.js';
import {
    getAccountByIdFx,
    getAccountReEsObjFx,
    getAccountsListFx, getAccrualsListDataFx,
    getProviderServicesFx,
    getProviderSrvAccsFx,
    getPaymentsDataFx, getPaymentsElementsFx,  getProviderServiceFx
} from './effects.js';
import {
    deleteAccountEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    AccountEditGate,
    AccountsListGate,
    AccountViewGate,
    submitCreateAccountEv,
    submitEditAccountEv,
    setSexEmbeddedEv,
    setNationEmbeddedEv,
    setPrefCatEmbeddedEv,
    setConsCatEmbeddedEv,
    AccountCreateGate,
    AccrualsGate,
    PaymentsGate,
    selectProviderEv,
    selectServiceEv, editExternalNumberEv
} from './events.js';
import {sample} from 'effector';
import {
    submitGlobalUpdateEv,
    triggerSuccessOpsEv
} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {
    $createdMissAddresses,
    $createdREO, $selectedAddress,
    $selectedREOFromAddress, resetFiltersEv, resetSelectionEv
} from '../accountsREOSearchModel/index.js';
import queryString from "query-string";

const createAccountCompleteEv = combineEvents({events: {ev: submitCreateAccountEv, response: triggerSuccessOpsEv}, reset: resetAfterCreationAttrs})
const editAccountCompleteEv = combineEvents({ev: submitEditAccountEv, response: triggerSuccessOpsEv})
const deleteAccountCompleteEv = combineEvents({ev: deleteAccountEv, response: triggerSuccessOpsEv})

$accountsList.on(getAccountsListFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(AccountsListGate.close)

$providerService.on(getProviderServiceFx.doneData, (_, payload) => payload)
    .reset(AccountsListGate.close)

$selectedProvider.on(selectProviderEv, (_, payload) => payload)
    .reset(AccountsListGate.close)

$selectedService.on(selectServiceEv, (_, payload) => payload)
    .reset(AccountsListGate.close)

$providerServiceList.on(getProviderServicesFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(AccountsListGate.close)

$accountsProviderServiceList.on(getProviderSrvAccsFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(AccountsListGate.close)


$accrualsData.on(getAccrualsListDataFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(AccountsListGate.close)


$paymentsData.on(getPaymentsDataFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(PaymentsGate.close)
$paymentsElementsData.on(getPaymentsElementsFx.doneData, (_, data) => {
    return data
})
	.reset(PaymentsGate.close)



$selectedAccount.on(getAccountByIdFx.doneData, (_, account) => account)
    .reset([AccountEditGate.close, AccountViewGate.close])

$selectedAccountReEsObj.on(getAccountReEsObjFx.doneData, (_, resobj) => resobj)
    .reset([AccountEditGate.close, AccountViewGate.close])

$createdAccountId.on(createAccountCompleteEv, (_, {response}) => {
    const account = response.find(i => i.type === 'accounts')
    return account ? account?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editAccountStatus.on(editAccountCompleteEv, () => true)
    .reset(AccountEditGate.close)

$sexEmbedded.on(setSexEmbeddedEv, (_, value) => value)
    .reset([AccountCreateGate.close, AccountEditGate.close])

$nationEmbedded.on(setNationEmbeddedEv, (_, value) => value)
    .reset([AccountCreateGate.close, AccountEditGate.close])

$prefCatEmbedded.on(setPrefCatEmbeddedEv, (_, value) => value)
    .reset([AccountCreateGate.close, AccountEditGate.close])

$consCatEmbedded.on(setConsCatEmbeddedEv, (_, value) => value)
    .reset([AccountCreateGate.close, AccountEditGate.close])


sample({
    clock: [AccountEditGate.state.updates, AccountViewGate.state.updates],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: [getAccountByIdFx, getProviderSrvAccsFx]
})


sample({
    source: $editedExternalNumber,
    clock: editExternalNumberEv,
    fn: (state, payload) => {
        const newData = [...state].filter(item => item.id !== payload.id)
        return [...newData, payload]
    },
    target: $editedExternalNumber
})

sample({
    source: $accountsProviderServiceList,
    clock: editExternalNumberEv,
    fn: (state, payload) => {
        let newData = [...state.data].filter(item => item.id !== payload.id)
        const newItem = [...state.data].find(item => item.id === payload.id)
        newItem.external_number = payload.external_number
        newData.push(newItem)
        return {count: state.count, data: newData}
    },
    target: $accountsProviderServiceList
})


sample({
    source: {services: $selectedService, providers: $selectedProvider},
    clock: [$selectedService.updates, $selectedProvider.updates],
    fn: (payload) => {
        const data = {
            provider_id: payload.providers,
            service_id: payload.service,
        }
        return queryString.stringify(data, {skipNull: true, skipEmptyString: true})
    },
    target: getProviderServiceFx
})

sample({
    clock: AccountViewGate.state.updates,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: [getProviderServicesFx, getProviderSrvAccsFx]
})


sample({
    clock: AccrualsGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => {
        return {id: +gate.id, filters: gate.search}
    },
    target:  getAccrualsListDataFx
})


sample({
    clock: PaymentsGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => {
        return {id: +gate.id, filters: gate.search}
    },
    target:  [getPaymentsDataFx, getPaymentsElementsFx]
})


sample({
    clock: AccountsListGate.state.updates,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getAccountsListFx
})

sample({
    source: AccountsListGate.state,
    clock: [createAccountCompleteEv, editAccountCompleteEv, deleteAccountCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getAccountsListFx
})

sample({
    clock: $selectedAccount.updates,
    filter: (acc) => acc && Object.hasOwn(acc, 'real_estate_object_id'),
    fn: (acc) => acc.real_estate_object_id,
    target: getAccountReEsObjFx
})

sample({
    source: {
        selectedAddr: $selectedAddress,
        selectedReo: $selectedREOFromAddress,
        createdReo: $createdREO,
        createdAddress: $createdMissAddresses
    },
    clock: submitCreateAccountEv,
    fn: ({selectedAddr, selectedReo, createdReo, createdAddress}, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        let addrId = selectedAddr ? selectedAddr.id : null
        let reoId = selectedReo ? selectedReo.real_estate_object_id : null

        if (createdAddress.length > 0) {
            actions.push({operation: 'create', type: 'address', value: {...createdAddress[0], id: idCounter}})
            addrId = idCounter
            idCounter--
        }

        if (createdReo) {
            if (createdReo.sector_id < 0) {
                actions.push({operation: 'create', type: 'sector', value: {...createdReo.sector, town_id: createdReo.town_id, id: idCounter}})
                createdReo.sector_id = idCounter
                idCounter--
                delete createdReo.sector
            }
            if (createdReo.real_estate_object_type_id < 0) {
                actions.push({operation: 'create', type: 'real_estate_object_type', value: {...createdReo.real_estate_object_type, id: idCounter}})
                createdReo.real_estate_object_type_id = idCounter
                idCounter--
                delete createdReo.real_estate_object_type
            }
            delete createdReo.town_id
            delete createdReo.town_distr_id
            delete createdReo.street_id
            delete createdReo.house
            delete createdReo.corpus
            delete createdReo.real_estate_object_id
            actions.push({operation: 'create', type: 'real_estate_objects', value: {...createdReo, address_id: addrId, id: idCounter}})
            reoId = idCounter
            idCounter--
        }
        payload.real_estate_object_id = reoId

        actions.push({operation: 'create', type: 'accounts', value: {...payload, id: idCounter}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {current: $selectedAccount, externals: $editedExternalNumber},
    clock: submitEditAccountEv,
    fn: ({current, externals}, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        // FIXME in future, when real estate object embedded is done
        // if (payload.real_estate_object_id < 0) {
        //     actions.push({operation: 'create', type: 'real_estate_object', value: {...payload.real_estate_object, id: idCounter}})
        //     payload.real_estate_object_id = idCounter
        //     idCounter--
        //     delete payload.real_estate_object
        // }
        // if (rsObj && rsObj.id !== current.real_estate_object_id) {
        //     payload.real_estate_object_id = rsObj?.id
        // }

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'accounts', value: {object_id: current.id, ...changedFields}})

        for (const newDist of Object.values(externals)) {
            idCounter--
            actions.push({operation: 'update', type: 'account_provider_service', value: {external_number: newDist.external_number, object_id: newDist.id}})
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteAccountEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'accounts', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [AccountCreateGate.close, AccountEditGate.close, AccountViewGate.close],
    target: [resetSelectionEv, resetFiltersEv]
})

sample({
    clock: createAccountCompleteEv,
    target: resetSelectionEv
})

// $lastCreatedAccount.on(createAccountCompleteEv, (_, data) => {
//     const id = (data?.response || []).filter(x => x.success).map(x => x.value?.id)[0]
//     return id
// }).reset([AccountCreateGate.close, AccountEditGate.close])
