import {combine, createEffect, createEvent, createStore, sample} from 'effector';
import api from '../../../api/api.js';
import queryString from 'query-string';
import {createGate} from 'effector-react';
import {isId} from '../../../utils/helpers.js';
import {$info} from '../../infoModel/index.js';
import {$selectedCorrection} from '../stores.js';
import {
    submitGlobalUpdateEv,
    triggerSuccessOpsEv
} from '../../dictionaryUniversalModel/index.js';
import {getCorrectionByIdFx} from '../effects.js';

export const correctionTypeEnum = {
    new_acc_to_srv: 'add',
    change_existing_params: 'attrs',
    change_tariff: 'tarif',
    change_saldo: 'saldo',
    change_ext_payments: 'ex_payments',
    resume_srv: 'penalty'
}

export const correctionTypeLabelEnum = {
    add: 'присоединение новых ЛС к Услуге',
    attrs: 'изменение параметров уже присоединенных ЛС',
    tarif: 'изменение Тарифа',
    saldo: 'изменение Сальдо',
    ex_payments: 'внесение сторонних платежей',
    penalty: 'возобновление предоставления услуг'
}

export const correctionRequiredKeys = {
    tarif: ['tarif_id', 'tarif_date_from', 'tarif_value', 'tarif_service_unit_id'],
    saldo: ['saldo_end_value'],
    ex_payments: ['ex_payment_value'],
    penalty: ['date_from', 'date_to', 'is_not_accrued', 'is_not_balance_transfer', 'is_not_printed', 'not_printed_from', 'not_printed_to', 'not_accrued_from', 'not_accrued_to', 'not_balance_transfer_from', 'not_balance_transfer_to'],
}

export const afterActionsEnum = {
    single_save: 'single_save',
    single_next: 'single_next',
    single_skip: 'single_skip',
    mass_selected: 'mass_selected',
    mass_all: 'mass_all',
}
const lang = localStorage.getItem('lang') ?? 'ru'
// stores
export const $correctionUpdating = createStore(false)
export const $selectedSrv = createStore(null, {skipVoid: false})
export const $selectedProvider = createStore(null, {skipVoid: false})
export const $provsBySrvRaw = createStore([])
export const $provOpts = combine($provsBySrvRaw, (provs) => provs?.map(i => ({label: `${i.id} ${i[`name_${lang}`]}`, value: i.id})))

export const $correctionType = createStore(null, {skipVoid: false})
export const $correctionPeriod = createStore('current')
export const $editMode = createStore('mass')
export const $pageMode = createStore('edit')

export const $accsTable = createStore({page: 1, limit: 10, sort_order: null, sort_field: null})
export const $accsFilters = createStore({})
export const $foundAccs = createStore({data: [], count: 0})

export const $excludedAccs = createStore([])

export const $selectedAcc = createStore(null)
export const $multiSelectedAccs = createStore([])

export const $srvsByAcc = createStore([])
export const $newSrvsByAcc = createStore([])

export const $accSrvsMap = combine($foundAccs, accs => Object.fromEntries(accs?.data?.map(a => [a.id, a.services])))
export const $srvTarOptsMap = combine($foundAccs, (accs) => {
    if (accs.data?.length > 0) {
        const services = accs?.data?.flatMap(a => a?.services)
        return Object.fromEntries(services?.map(s => [s.service_id, s.tariffs?.map(t => ({label: t.value, value: t.tarif_id}))]))
    } else return {}
})

export const $massSrvTarifsOpts = combine($foundAccs, $selectedProvider, $selectedSrv, (accs, prov, srv) => {
    if (accs?.data?.length > 0 && isId(prov) && isId(srv)) {
        const srvs = accs?.data?.flatMap(a => a?.services?.filter(s => s?.service_id === srv && s?.provider_id === prov))
        return srvs[0]?.tariffs.map(t => ({label: t.value, value: t.tarif_id})) ?? []
    } else return []
})

export const $afterApplyAction = createStore(null)

// effects
export const getAccsFx = createEffect()
    .use(async (filters) => (await api().get(`/correction_aps/account_list?${filters}`)).data)

export const submitTempCorrectionFx = createEffect()
    .use(async payload => (await api().post(`/correction_aps/create_aps`, payload)).data)

// events
export const ProvSrvAccCorrectionGate = createGate()

export const selectProviderEv = createEvent()
export const selectProvSrvEv = createEvent()
export const selectCorrectionTypeEv = createEvent()
export const selectCorrectionPeriodEv = createEvent()

export const setPageModeEv = createEvent()

export const setTableParamsEv = createEvent()
export const searchAccsEv = createEvent()
export const resetAccsEv = createEvent()

export const addExcludedAccEv = createEvent()
export const removeExcludedAccEv = createEvent()

export const setSelectedAccEv = createEvent()
export const setMultiSelectedAccsEv = createEvent()
export const resetSelectedAccEv = createEvent()

export const resetSrvsEv = createEvent()
export const deleteAllTempCorrectionsEv = createEvent()

export const addSrvEv = createEvent()
export const deleteSrvEv = createEvent()

export const setAfterActionEv = createEvent()

export const deleteSingleCorrectionEv = createEvent()
export const submitTempCorrsSingleEv = createEvent()
export const submitSingleAddFromMassEv = createEvent()
export const submitTableFormEv = createEvent()
export const submitTempCorrsMassEv = createEvent()

// handlers
$correctionUpdating.on(submitGlobalUpdateEv, () => true)
    .reset(triggerSuccessOpsEv)

$selectedProvider
    // .on(selectProviderEv, (_, id) => id)
    .on($selectedCorrection.updates, (_, {data}) => data?.provider_id ?? undefined)
    .reset([ProvSrvAccCorrectionGate.close])

$selectedSrv
    // .on(selectProvSrvEv, (_, id) => id)
    .on($selectedCorrection.updates, (_, {data}) => data?.service_id ?? undefined)
    .reset([ProvSrvAccCorrectionGate.close])

$provsBySrvRaw.reset([ProvSrvAccCorrectionGate.close])

$correctionType
    // .on(selectCorrectionTypeEv, (_, type) => type)
    .on($selectedCorrection.updates, (_, {data}) => data?.variant ?? undefined)
    .reset([ProvSrvAccCorrectionGate.close])

$correctionPeriod.on(selectCorrectionPeriodEv, (_, period) => period)
    .reset([ProvSrvAccCorrectionGate.close, selectCorrectionTypeEv, selectProviderEv])

$editMode
    .on($selectedCorrection.updates, (_, {data}) => data?.mass === true ? 'mass' : 'acc')
    .reset(ProvSrvAccCorrectionGate.close)

$pageMode.on(setPageModeEv, (_, mode) => mode)
    .reset([ProvSrvAccCorrectionGate.close])

$accsFilters.on(searchAccsEv, (_, filters) => filters)
    .reset([resetAccsEv, ProvSrvAccCorrectionGate.close])

$accsTable
    .on(setTableParamsEv, (state, payload) => {
        const upd = {...state}
        for (const {key, value} of Object.values(payload)) {
            upd[key] = value
        }
        return upd
    })
    .reset([resetAccsEv, ProvSrvAccCorrectionGate.close])

$foundAccs.on(getAccsFx.doneData, (_, payload) => payload)
    .reset(resetAccsEv)

$excludedAccs.on(addExcludedAccEv, (state, id) => [...state, id])
    .on(removeExcludedAccEv, (state, id) => state.filter(i => i !== id))
    .reset([selectCorrectionPeriodEv, selectCorrectionTypeEv])

$selectedAcc.on(setSelectedAccEv, (_, id) => id)
    .reset([resetSelectedAccEv, resetAccsEv, setTableParamsEv])

$multiSelectedAccs.on(setMultiSelectedAccsEv, (_, ids) => ids)
    .on($excludedAccs.updates, (state, excl) => state.filter(i => !excl.includes(i)))
    .reset([resetAccsEv, selectCorrectionTypeEv, selectCorrectionPeriodEv])

$srvsByAcc.reset([setSelectedAccEv, submitTempCorrectionFx.doneData])
$newSrvsByAcc.reset([setSelectedAccEv, submitTempCorrectionFx.doneData])

$afterApplyAction.on(setAfterActionEv, (_, action) => action)
    .reset(setSelectedAccEv, setMultiSelectedAccsEv, setTableParamsEv)

sample({
    source: {srv: $selectedSrv, provs: $info.map(state => state.provider)},
    clock: [$selectedSrv.updates, $info.updates],
    filter: ({srv}) => !!isId(srv),
    fn: ({srv, provs}) => provs?.filter(s => s?.services?.includes(srv)) ?? [],
    target: $provsBySrvRaw
})

sample({
    source: {table: $accsTable, filters: $accsFilters, correction: $selectedCorrection, corType: $correctionType, period: $correctionPeriod, pageMode: $pageMode},
    clock: [$accsTable.updates, $accsFilters.updates, submitTempCorrectionFx.doneData, $pageMode.updates, $correctionPeriod.updates],
    // filter: ({filters}) => (isId(filters.town_id) && isId(filters.street_id)) || (filters?.account_in && filters.account_in.length > 0),
    filter: ({filters, pageMode}) => pageMode === 'edit' ? (isId(filters.town_id) || (filters?.account_in && filters.account_in.length > 0)) : true,
    fn: ({table, filters, correction, corType, period, pageMode}) => {
        const checked = {...filters}
        if ([correctionTypeEnum.change_tariff, correctionTypeEnum.change_existing_params, correctionTypeEnum.change_ext_payments].includes(corType)) {
            checked['service_accounts_set'] = period
        }
        if (checked?.all_accounts) {
            checked['service_accounts_set'] = period
        }
        delete checked['all_accounts']
        if (pageMode === 'view') {
            checked['have_correction'] = true
        }
        return queryString.stringify({...checked, ...table, correction_id: correction?.data?.id}, {skipEmptyString: true, skipNull: true})
    },
    target: getAccsFx
})

sample({
    source: $accSrvsMap,
    clock: $selectedAcc.updates,
    fn: (srvMap, id) => srvMap[id] ?? [],
    target: $srvsByAcc
})

sample({
    source: {
        selected: $selectedAcc,
        provider: $selectedProvider,
        selectedSrv: $selectedSrv,
        correction: $selectedCorrection,
        corType: $correctionType,
        mode: $editMode
    },
    clock: submitTempCorrsSingleEv,
    filter: ({selected, mode}) => mode === 'acc' && !!selected,
    fn: ({selected, provider, selectedSrv, correction}, payload) => {
        const formValues = payload[selected]
        for (const [key, value] of Object.entries(formValues)) {
            // FIXME add check for excluded keys like 'change_tarif' checkbox
            if (!['tarif_id', 'tarif_value', 'tarif_date_from', 'tarif_service_unit_id', 'provider_service_id'].includes(key)) {
                formValues[`${key}_new`] = value
                delete formValues[key]
            }
        }

        for (const [key, value] of Object.entries(formValues)) {
            if (value === null || value === undefined) {
                delete formValues[key]
            } else if (key === 'tarif_id' && value < 0) {
                delete formValues[key]
            } else if ((key.includes('_from') || key.includes('_to')) && value && dayjs(value).isValid()) {
                formValues[key] = dayjs(value).format()
            }
        }

        const result = {
            correction_id: correction?.data?.id,
            values: [{
                filters: {
                    account_id: selected,
                },
                values: [{
                    provider_id: provider,
                    service_id: selectedSrv,
                    values: formValues
                }]
            }]
        }

        return result
    },
    target: submitTempCorrectionFx
})

sample({
    source: {
        provider: $selectedProvider,
        selectedSrv: $selectedSrv,
        correction: $selectedCorrection,
        corType: $correctionType,
        mode: $editMode
    },
    clock: submitSingleAddFromMassEv,
    filter: ({mode, corType}) => mode === 'mass' && corType === correctionTypeEnum.new_acc_to_srv || corType === correctionTypeEnum.change_existing_params,
    fn: ({provider, selectedSrv, correction}, payload) => {
        const accId = payload.account_id
        const formValues = {...payload}
        delete formValues.account_id
        if (!payload['is_not_accrued']) {
            delete formValues['not_accrued_from']
            delete formValues['not_accrued_to']
        }
        if (!payload['is_not_balance_transfer']) {
            delete formValues['not_balance_transfer_from']
            delete formValues['not_balance_transfer_to']
        }
        if (!payload['is_not_printed']) {
            delete formValues['not_printed_from']
            delete formValues['not_printed_to']
        }

        for (const [key, value] of Object.entries(formValues)) {
            if (['service_id', 'provider_id', 'account_provider_service_id', 'id', 'provider_service_id'].includes(key) || key.endsWith('_old')) {
                delete formValues[key]
                continue
            }
            if (!['tarif_id', 'tarif_value', 'tarif_date_from', 'tarif_service_unit_id'].includes(key) && !key.endsWith('_new')) {
                formValues[`${key}_new`] = value
                delete formValues[key]
            }
        }

        for (const [key, value] of Object.entries(formValues)) {
            if (value === null || value === undefined) {
                delete formValues[key]
            } else if (key === 'tarif_id' && value < 0) {
                delete formValues[key]
            } else if ((key.includes('_from') || key.includes('_to')) && value && dayjs(value).isValid()) {
                formValues[key] = dayjs(value).format()
            }
        }

        const result = {
            correction_id: correction?.data?.id,
            values: [{
                filters: {
                    account_in: accId.toString(),
                },
                values: [{
                    provider_id: provider,
                    service_id: selectedSrv,
                    values: formValues
                }]
            }]
        }

        return result
    },
    target: submitTempCorrectionFx
})

sample({
    source: {
        provider: $selectedProvider,
        selectedSrv: $selectedSrv,
        correction: $selectedCorrection,
        corType: $correctionType,
        mode: $editMode
    },
    clock: submitTableFormEv,
    filter: ({corType}) => [correctionTypeEnum.change_tariff, correctionTypeEnum.change_saldo, correctionTypeEnum.change_ext_payments, correctionTypeEnum.resume_srv].includes(corType),
    fn: ({provider, selectedSrv, correction, corType, mode}, payload) => {
        const payloadCopy = {...payload}
        if (corType !== correctionTypeEnum.resume_srv) {
            const filteredValues = Object.fromEntries(
                Object.entries(payloadCopy).filter(([accountId, accountValues]) => {
                    return correctionRequiredKeys[corType].some(key => accountValues[key] !== undefined);
                })
            );

            if (corType === correctionTypeEnum.change_tariff) {
                for (const acc of Object.keys(filteredValues)) {
                    if (filteredValues[acc]?.tarif_id < 0) {
                        delete filteredValues[acc]['tarif_id']
                    } else if (filteredValues[acc]?.tarif_id && filteredValues[acc]?.tarif_id > 0) {
                        delete filteredValues[acc]['tarif_value']
                        delete filteredValues[acc]['tarif_service_unit_id']
                    }
                }
            }

            const prepped = Object.entries(filteredValues).map(([acc, values]) => {
                return {
                    filters: {
                        account_in: acc,
                    },
                    values: [{
                        provider_id: mode === 'mass' ? provider : values.provider_id,
                        service_id: mode === 'mass' ? selectedSrv : values.service_id,
                        values: mode === 'mass'
                            ? Object.fromEntries(
                                Object.entries(values)
                                    .filter(([key, _value]) => correctionRequiredKeys[corType].includes(key))
                                    .filter(([_key, value]) => (value !== undefined && value !== null))
                                    .map(([key, value]) => {
                                        if (key.endsWith('_from') || key.endsWith('_to') && dayjs(value).isValid()) {
                                            return [key, dayjs(value).format()]
                                        } else return [key, value]
                                    })
                            )
                            : Object.fromEntries(Object.entries(values)
                                .filter(([_key, value]) => (value !== undefined && value !== null))
                                .map(([key, value]) => {
                                    if (key.endsWith('_from') || key.endsWith('_to') && dayjs(value).isValid()) {
                                        return [key, dayjs(value).format()]
                                    } else return [key, value]
                                })
                            )
                    }]
                }
            })

            const result = {
                correction_id: correction?.data?.id,
                values: prepped
            }

            return result
        } else {
            if (!payloadCopy['is_not_accrued']) {
                delete payloadCopy['not_accrued_from']
                delete payloadCopy['not_accrued_to']
            } else if (!payloadCopy['is_not_balance_transfer']) {
                delete payloadCopy['not_balance_transfer_from']
                delete payloadCopy['not_balance_transfer_to']
            } else if (!payloadCopy['is_not_printed']) {
                delete payloadCopy['not_printed_from']
                delete payloadCopy['not_printed_to']
            }

            const filteredValues = Object.fromEntries(
                Object.entries(payloadCopy).filter(([accountId, accountValues]) => {
                    return correctionRequiredKeys[corType].some(key => accountValues[key] !== undefined);
                })
            );


            const prepped = Object.entries(filteredValues).map(([acc, values]) => {
                return {
                    filters: {
                        account_in: acc,
                    },
                    values: [{
                        provider_id: mode === 'mass' ? provider : values.provider_id,
                        service_id: mode === 'mass' ? selectedSrv : values.service_id,
                        values: mode === 'mass'
                            ? Object.fromEntries(Object.entries(values)
                                .filter(([key, value]) => correctionRequiredKeys[corType].includes(key))
                                .map(([key, value]) => {
                                    if (key.endsWith('_from') || key.endsWith('_to') && dayjs(value).isValid()) {
                                        return [key, dayjs(value).format()]
                                    } else return [key, value]
                                })
                            )
                            : Object.fromEntries(Object.entries(values)
                                .filter(([key, value]) => (value !== undefined && value !== null))
                                .map(([key, value]) => {
                                    if (key.endsWith('_from') || key.endsWith('_to') && dayjs(value).isValid()) {
                                        return [key, dayjs(value).format()]
                                    } else return [key, value]
                                })
                            )
                    }]
                }
            })

            const result = {
                correction_id: correction?.data?.id,
                values: prepped
            }
            return result
        }
    },
    target: submitTempCorrectionFx
})

sample({
    source: {
        selectedAccs: $multiSelectedAccs,
        action: $afterApplyAction,
        provider: $selectedProvider,
        selectedSrv: $selectedSrv,
        filters: $accsFilters,
        pagination: $accsTable,
        correction: $selectedCorrection,
        mode: $editMode,
        corType: $correctionType
    },
    clock: submitTempCorrsMassEv,
    filter: ({mode}) => mode === 'mass',
    fn: ({corType, selectedAccs, action, correction, provider, selectedSrv, filters, pagination}, payload) => {
        const filtersChecked = action === afterActionsEnum.mass_all
            ? {...filters}
            : {account_in: selectedAccs.join(', ')}
        for (const [key, value] of Object.entries(filtersChecked)) {
            if (value === null || value === undefined || value === '') {
                delete filtersChecked[key]
            } else if (key === 'street_id' && value) {
                filtersChecked[key] = [value]
            }
        }

        const formValues = {...payload}

        if (corType === correctionTypeEnum.resume_srv || corType === correctionTypeEnum.change_existing_params) {
            if (!formValues['change_period']) {
                delete formValues['date_from']
                delete formValues['date_to']
            }
            if (!formValues['change_not_accrued']) {
                delete formValues['is_not_accrued']
                delete formValues['not_accrued_from']
                delete formValues['not_accrued_to']
            }
            if (!formValues['change_not_balance_transfer']) {
                delete formValues['is_not_balance_transfer']
                delete formValues['not_balance_transfer_from']
                delete formValues['not_balance_transfer_to']
            }
            if (!formValues['change_not_printed']) {
                delete formValues['is_not_printed']
                delete formValues['not_printed_from']
                delete formValues['not_printed_to']
            }
            delete formValues['change_period']
            delete formValues['change_not_accrued']
            delete formValues['change_not_balance_transfer']
            delete formValues['change_not_printed']
        } else if (corType === correctionTypeEnum.change_tariff) {
            if (!formValues['change_tarif']) {
                delete formValues['tarif_id']
                delete formValues['tarif_value']
                delete formValues['tarif_service_unit_id']
            }
            if (!formValues['change_tarif_date_from']) {
                delete formValues['tarif_date_from']
            }
            delete formValues['change_tarif']
            delete formValues['change_tarif_date_from']
        }

        for (const [key, value] of Object.entries(formValues)) {
            if (!['tarif_id', 'tarif_value', 'tarif_date_from', 'tarif_service_unit_id', 'sum_saldo_begin', 'saldo_end_value', 'ex_payment_value', 'correction_value'].includes(key)) {
                formValues[`${key}_new`] = value
                delete formValues[key]
            }
        }

        const srvsPrepped = []

        for (const acc of selectedAccs) {
            const prepped = {
                provider_id: provider,
                service_id: selectedSrv,
                values: {...formValues},
                delete: false,
            }

            for (const [key, value] of Object.entries(prepped.values)) {
                if (value === null || value === undefined) {
                    delete prepped.values[key]
                } else if ((key.includes('_from') || key.includes('_to')) && value && dayjs(value).isValid()) {
                    prepped.values[key] = dayjs(value).format()
                } else if (key === 'tarif_id' && value < 0) {
                    delete prepped.values[key]
                } else if (key === 'sum_saldo_begin') {
                    delete prepped.values[key]
                } else if (key === 'saldo_end_value' && value && prepped.values['correction_value']) {
                    delete prepped.values['correction_value']
                }
            }

            srvsPrepped.push(prepped)
        }
        const result = {
            correction_id: correction?.data?.id,
            ignore_existed: false,
            values: [{
                filters: filtersChecked,
                values: action === afterActionsEnum.mass_all
                    ? [{provider_id: provider, service_id: selectedSrv, values: formValues, delete: false}]
                    : srvsPrepped
            }]
        }

        return result
    },
    target: submitTempCorrectionFx
})

sample({
    source: {correction: $selectedCorrection, mode: $editMode},
    clock: deleteSingleCorrectionEv,
    fn: ({correction, mode}, payload) => {
        return {
            correction_id: correction?.data?.id,
            values: [
                {
                    filters: {account_in: payload.account_id.toString()},
                    values: [{
                        provider_id: mode === 'mass' ? correction?.data?.provider_id : payload.provider_id,
                        service_id: mode === 'mass' ? correction?.data?.service_id : payload.service_id,
                        delete: true
                    }]
                }
            ]
        }
    },
    target: submitTempCorrectionFx
})

sample({
    source: $selectedCorrection,
    clock: deleteAllTempCorrectionsEv,
    fn: (correction) => {
        return {correction_id: correction?.data?.id, clear_all: true, values: []}
    },
    target: submitTempCorrectionFx
})

sample({
    source: {mode: $editMode, correction: $selectedCorrection},
    clock: selectProviderEv,
    filter: ({mode}) => mode === 'mass',
    fn: ({correction}, prov) => {
        return [{operation: 'update', type: 'correction', value: {object_id: correction?.data?.id, provider_id: prov ?? null}}]
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {mode: $editMode, correction: $selectedCorrection},
    clock: selectProvSrvEv,
    filter: ({mode}) => mode === 'mass',
    fn: ({correction}, srv) => {
        const actions = []
        actions.push({operation: 'update', type: 'correction', value: {object_id: correction?.data?.id, service_id: srv ?? null}})
        if (!srv) {
            actions.push({operation: 'update', type: 'correction', value: {object_id: correction?.data?.id, provider_id: null}})
        }
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedCorrection,
    clock: selectCorrectionTypeEv,
    filter: (_, corType) => typeof corType === 'string' && corType?.length > 0,
    fn: (correction, corType) => {
        return [{operation: 'update', type: 'correction', value: {object_id: correction?.data?.id, variant: corType}}]
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: triggerSuccessOpsEv,
    filter: (data) => data[0]?.type === 'correction' && data[0]?.success === true,
    fn: (data) => data[0]?.value?.id,
    target: getCorrectionByIdFx
})

sample({
    source: $selectedCorrection,
    clock: submitTempCorrectionFx.doneData,
    filter: (correction) => isId(correction?.data?.id),
    fn: (correction) => correction?.data?.id,
    target: getCorrectionByIdFx
})