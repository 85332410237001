export const users = [
    {id: 1, firstname: 'Администратор', surname: '', patronymic: '', username: 'admin', role: 'admin'},
    {id: 2, firstname: 'Ермек', surname: 'Исламбек', patronymic: 'Абылайханұлы', username: 'controller1', role: 'supervisor'},
    {id: 3, firstname: 'Иван', surname: 'Иванов', patronymic: 'Васильевич', username: 'controller2', role: 'supervisor'},
]

export const towns = [
    {id: 1, name_kz: 'Астана', name_ru: 'Астана', index: '010000', created_at: '2024-01-01', created_by: 1, updated_at: '2024-01-05', updated_by: 1},
    {id: 2, name_kz: 'Алматы', name_ru: 'Алматы', index: '050000', created_at: '2024-01-02', created_by: 2},
    {id: 3, name_kz: 'Шымкент', name_ru: 'Шымкент', index: '160000', created_at: '2024-01-03', created_by: 3},
]

export const districts = [
    {id: 1, town_id: 1, name_kz: 'Алматы', name_ru: 'Алматы', created_at: '2024-01-01', created_by: 1, updated_at: '2024-01-02', updated_by: 1},
    {id: 2, town_id: 1, name_kz: 'Байконур', name_ru: 'Байконур', created_at: '2024-01-01', created_by: 1, updated_at: '2024-01-02', updated_by: 1},
    {id: 3, town_id: 1, name_kz: 'Нура', name_ru: 'Нура', created_at: '2024-01-01', created_by: 1},
    {id: 4, town_id: 2, name_kz: 'Ауэзов', name_ru: 'Ауэзовский', created_at: '2024-01-01', created_by: 1, updated_at: '2024-01-02', updated_by: 1},
    {id: 5, town_id: 2, name_kz: 'Медеу', name_ru: 'Медеуский', created_at: '2024-01-01', created_by: 1},
    {id: 6, town_id: 3, name_kz: 'Абай', name_ru: 'Абайский', created_at: '2024-01-01', created_by: 1},
    {id: 7, town_id: 3, name_kz: 'Енбекши', name_ru: 'Енбекшинский', created_at: '2024-01-01', created_by: 1}
]

export const streetTypes = [
    {id: 1, short_name_kz: 'к.', name_kz: 'Көше', short_name_ru: 'ул.', name_ru: 'Улица', created_at: '2024-01-01', created_by: 1, updated_at: '2024-01-05', updated_by: 1},
    {id: 2, short_name_kz: 'даңғ.', name_kz: 'Даңғылы', short_name_ru: 'пр.', name_ru: 'Проспект', created_at: '2024-01-01', created_by: 1},
]

export const streets = [
    {id: 1, town_id: 1, street_type_id: 2, name_kz: 'Абай', name_ru: 'Абая', created_at: '2024-01-01', created_by: 1, updated_at: '2024-01-05', updated_by: 1},
    {id: 2, town_id: 2, street_type_id: 2, name_kz: 'Абай', name_ru: 'Абая', created_at: '2024-01-01', created_by: 2, updated_at: '2024-01-05', updated_by: 1},
    {id: 3, town_id: 1, street_type_id: 1, name_kz: 'Достык', name_ru: 'Достык', created_at: '2024-01-01', created_by: 3},
    {id: 4, town_id: 2, street_type_id: 1, name_kz: 'Байтурсынулы', name_ru: 'Байтурсынова', created_at: '2024-01-01', created_by: 1}
]

export const addresses = [
    {id: 1, street_id: 1, house: '22', corpus: '4', is_actual: true, actual_from: '2024-01-01'},
    {id: 2, street_id: 2, house: '54', corpus: null, is_actual: true, actual_from: '2024-01-01'}
]