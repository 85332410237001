import {useUnit} from 'effector-react';
import {
    $accountsList,
    deleteAccountEv,
    getAccountsListFx,
    AccountsListGate
} from '../../models/dictionaryAccountsModel/index.js';
import {useTranslation} from 'react-i18next';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import {
    CheckOutlined, CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined
} from '@ant-design/icons';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import MappedDictItem from '../../components/MappedDictItem/MappedDictItem.jsx';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import Divider from 'antd/es/divider';
import {Link} from 'react-router-dom';
import AccountsListFilters from './components/AccountsListFilters.jsx';
import ButtonGroup from "antd/es/button/button-group";

export default function DictionaryAccountsList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($accountsList)
    const loading = useUnit(getAccountsListFx.pending)
    const deleteAccount = useUnit(deleteAccountEv)
    const {search, exportXlsAction} = searchAndActions()

    const columns = [
        {
            title: t(`Код ЛС`),
            dataIndex: 'id',
            sorter: true,
            // width: '3%',
            key: 'id'
        },
        {
            title: t('ФИО'),
            dataIndex: 'name_ru',
            key: 'fio',
            sorter: true,
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            key: 'town_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town'}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            key: 'town_distr_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            key: 'street_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'street'}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
            key: 'house'
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            key: 'corpus'
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
            key: 'flat'
        },
        {
            title: t('ИИН'),
            dataIndex: 'iin',
            hide: true,
            key: 'iin'
        },
        // {
        //     title: t('ФИО (каз)'),
        //     dataIndex: 'name_kz',
        //     key: 'fio',
        //     sorter: true,
        // },
        {
            title: t('Моб. телефон'),
            dataIndex: 'mobile_phone',
            key: 'mobile_phone'
        },
        {
            title: t('Email расссылка'),
            dataIndex: 'is_email_delivery',
            align: 'center',
            key: 'is_email_delivery',
            render: (value) => value ? <CheckOutlined/> : <CloseOutlined/>
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: t('Телефон'),
            dataIndex: 'phone',
            hide: true,
            key: 'phone'
        },
        {
            title: t('Дата регистрации'),
            dataIndex: 'created_at',
            key: 'created_at',
            hide: true,
            render: (value) => value ? <CustomDates data={value}/> : '-'
        },
        {
            title: t('Код АЭСбыт'),
            dataIndex: 'aesbyt_account',
            hide: true,
            key: 'aesbyt_account'
        },
        {
            title: t('Код энергокомпании'),
            dataIndex: 'energy_account',
            hide: true,
            key: 'energy_account'
        },
        {
            title: t('Общая площадь'),
            dataIndex: 'full_square',
            hide: true,
            key: 'full_square'
        },
        {
            title: t('Жилая площадь'),
            dataIndex: 'living_square',
            hide: true,
            key: 'living_square'
        },
        {
            title: t('Отапливаемая площадь'),
            dataIndex: 'heated_square',
            hide: true,
            key: 'heated_square'
        },
        {
            title: t('Кол-во проживающих'),
            dataIndex: 'people_quantity',
            hide: true,
            key: 'people_quantity'
        },
        {
            title: t('Кол-во комнат'),
            dataIndex: 'room_count',
            hide: true,
            key: 'room_count'
        },
        {
            title: t('Этаж'),
            dataIndex: 'floor',
            hide: true,
            key: 'floor'
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup size={'small'}>
                <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/accounts/edit/${id}`}><Button
                        icon={<EditOutlined/>} type={'primary'}/></Link>
                </Tooltip>
                <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/accounts/view/${id}`}><Button
                        icon={<EyeOutlined/>}/></Link>
                </Tooltip>
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined/>}/>
                </Tooltip>
                {/*<Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}*/}
                {/*            okText={t('Да')}*/}
                {/*            onConfirm={() => deleteAccount(id)}*/}
                {/*            placement={'left'}*/}
                {/*>*/}
                {/*    <Tooltip title={t('Удалить')} placement={'top'}>*/}
                {/*        <Button icon={<DeleteOutlined/>} danger/>*/}
                {/*    </Tooltip>*/}
                {/*</Popconfirm>*/}
            </ButtonGroup>
        }
    ]

    return <>
        <AccountsListGate search={search.toString()}/>
        <AccountsListFilters/>
        <Divider/>
        <DictionaryProTable data={data} count={count} loading={loading} initColumns={columns}
                            exportXlsAction={exportXlsAction}/>
    </>
}
