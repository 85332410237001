import React from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
    $paymentConfirmModal,
    $paymentConfirmModalData,
    closePaymentConfirmModalEv, onConfirmAllEv, onConfirmSelectedEv,
    onConfirmSingleEv
} from "../../../models/paymentModel/index.js";
import Modal from "antd/es/modal";
import Button from "antd/es/button"


export default function PaymentConfirmModal({resetSelected}) {
    const {t} = useTranslation()

    const open = useUnit($paymentConfirmModal)
    const onClose = useUnit(closePaymentConfirmModalEv)
    const payload = useUnit($paymentConfirmModalData)

    const onConfirmSingleEvent = useUnit(onConfirmSingleEv)
    const onConfirmSelectedEvent = useUnit(onConfirmSelectedEv)
    const onConfirmAllEvent = useUnit(onConfirmAllEv)

    console.log('payload', payload)

    const onClick = () => {
        if (payload?.type === 'single') {
            onConfirmSingleEvent(payload?.data)
        } else if (payload?.type === 'selected') {
            onConfirmSelectedEvent(payload?.data)
            resetSelected()
        } else {
            onConfirmAllEvent(payload?.data)
        }
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            footer={
                <>
                    {/*FIXME IF NEED Third Step CONFIRM */}
                    {/*{payload?.type === 'single' ?*/}
                    {/*		<Popconfirm*/}
                    {/*			title={t('Вы точно уверены, что хотите подтвердить?')}*/}
                    {/*			onConfirm={() => onConfirmSingleEvent(payload?.data)}*/}
                    {/*			okText={t('Да')}*/}
                    {/*		>*/}
                    {/*			<Button type={'primary'}>{t('Подтвердить выбранный')}</Button>*/}
                    {/*		</Popconfirm> : payload?.type === 'selected' ? <Popconfirm*/}
                    {/*			title={t('Вы точно уверены, что хотите подтвердить?')}*/}
                    {/*			onConfirm={() => onConfirmSelectedEvent(payload?.data)}*/}
                    {/*			okText={t('Да')}*/}
                    {/*		>*/}
                    {/*			<Button type={'primary'}>{t('Подтвердить выбранные')}</Button>*/}
                    {/*		</Popconfirm> : <Popconfirm*/}
                    {/*			title={t('Вы точно уверены, что хотите подтвердить?')}*/}
                    {/*			onConfirm={() => onConfirmAllEvent(payload?.data)}*/}
                    {/*			okText={t('Да')}*/}
                    {/*		>*/}
                    {/*			<Button type={'primary'}>{t('Подтвердить все')}</Button>*/}
                    {/*		</Popconfirm>}*/}
                    {
                        payload?.type === 'single' ?
                            <Button type={'primary'}
                                    onClick={() => onClick()}>{t('Подтвердить выбранный день')}</Button> :
                            payload?.type === 'selected' ?
                                <Button type={'primary'}
                                        onClick={() => onClick()}>{t('Подтвердить выбранные дни')}</Button> :
                                <Button type={'primary'}
                                        onClick={() => onClick()}>{t('Подтвердить')}</Button>
                    }
                    <Button onClick={onClose}>
                        Отмена
                    </Button>
                </>
            }
            title={'Внимание!'}

        >
            Отменить данное действие не возможно.<br/>
            Вы принимаете на себя ответственность подтверждения в ручном режиме?
        </Modal>
    );
}
