import {useStoreMap, useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {
    $provOpts,
    $selectedProvider,
    $selectedSrv,
    selectProviderEv,
    selectProvSrvEv,
    correctionTypeLabelEnum,
    selectCorrectionTypeEv,
    selectCorrectionPeriodEv,
    $correctionType,
    $correctionPeriod,
    $editMode, correctionTypeEnum, $correctionUpdating, $selectedCorrection
} from '../../../models/dictionaryCorrectionModel/index.js';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Row from 'antd/es/row';
import Radio from 'antd/es/radio';
import {filterOption} from '../../../utils/selectUtils.jsx';

export default function CorrectionTypeSettings() {
    const {t} = useTranslation()
    const loading = useUnit($correctionUpdating)
    const correction = useUnit($selectedCorrection)

    const srvOpts = useStoreMap($dictsSelects, (s) => s.services)
    const provOpts = useUnit($provOpts)
    const selectProv = useUnit(selectProviderEv)
    const selectSrv = useUnit(selectProvSrvEv)
    const selectType = useUnit(selectCorrectionTypeEv)
    const selectPeriod = useUnit(selectCorrectionPeriodEv)

    const editMode = useUnit($editMode)
    const selSrv = useUnit($selectedSrv)
    const selProv = useUnit($selectedProvider)
    const selType = useUnit($correctionType)
    const selPeriod = useUnit($correctionPeriod)

    const corTypeOpts = Object.entries(correctionTypeLabelEnum).map(([key, value]) => ({
        label: t(value),
        value: key
    }))

    useEffect(() => {
        if (editMode === 'mass' && !(selProv && selSrv)) {
            selectType(null)
        }
    }, [selProv, selSrv, editMode])

    return <Card size={'small'} extra={correction?.data?.has_corrections ? t('В корректировку внесены изменения') : null}>
        <Row gutter={[16, 0]}>
            {editMode === 'mass' && <>
                <Col span={4} style={{marginBottom: '16px'}}>
                    <span>{t('Услуга')}</span>
                    <Select
                        disabled={loading || correction?.data?.has_corrections}
                        onChange={selectSrv}
                        options={srvOpts}
                        style={{width: '100%'}}
                        filterOption={filterOption}
                        value={selSrv}
                        showSearch
                        allowClear
                        size={'small'}
                        dropdownStyle={{minWidth: 'max-content'}}
                    />
                </Col>
                <Col span={4} style={{marginBottom: '16px'}}>
                    <span>{t('Поставщик')}</span>
                    <Select disabled={loading || !selSrv || correction?.data?.has_corrections}
                            options={provOpts}
                            dropdownStyle={{minWidth: 'fit-content'}}
                            size={'small'}
                            onChange={selectProv}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            value={selProv}
                    />
                </Col>
            </>}
            <Col span={24} style={{marginBottom: '16px'}}>
                <p style={{margin: 0}}>{t('Тип корректировки')}</p>
                <Radio.Group value={selType}
                             onChange={({target: {value}}) => selectType(value)}
                             size={'small'}
                             disabled={loading || editMode === 'mass' ? (!(selProv && selSrv) || correction?.data?.has_corrections) : correction?.data?.has_corrections}
                >
                    {corTypeOpts.map(({label, value}) =>
                        <Radio.Button key={value} value={value}>{label}</Radio.Button>
                    )}
                </Radio.Group>
            </Col>
            {(selType === correctionTypeEnum.change_existing_params || selType === correctionTypeEnum.change_tariff || selType === correctionTypeEnum.change_ext_payments)
                && <Col span={8}>
                    <p style={{margin: 0}}>{t('Период оказания услуги')}</p>
                    <Radio.Group value={selPeriod}
                                 onChange={({target: {value}}) => selectPeriod(value)}
                                 size={'small'}
                                 disabled={loading || correction?.data?.has_corrections}
                    >
                        <Radio.Button
                            value={'current'}>{t('Текущий рассчетный месяц')}</Radio.Button>
                        <Radio.Button
                            value={'next'}>{t('Будет оказываться в будущем')}</Radio.Button>
                    </Radio.Group>
                </Col>}
        </Row>
    </Card>
}