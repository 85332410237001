import dayjs from "dayjs";
import {sample} from "effector";
import {
	bankApiAuditChangeFilterEv,
	bankApiAuditDownloadFullEv,
	bankApiAuditDownloadShortEv,
	changeServiceInErrorEv,
	closePaymentBankApiAuditModalEv,
	closePaymentConfirmModalEv,
	closePaymentExportModalEv,
	dataPaymentBankApiAuditModalEv,
	dataPaymentConfirmModalEv,
	dataPaymentExportModalEv, exportAppliesErrorEv, exportAppliesTableEv, exportConfirmTableEv, exportExportTableEv,
	exportPaymentAppliesEv,
	exportPaymentConfirmEv, exportPaymentExportEv,
	getExportedPaymentsEv,
	getPaymentByIdEv,
	getPaymentElementsEv,
	getPaymentErrorByIdEv,
	onCancelAppliesSingleEv, onConfirmAllEv,
	onConfirmAppliesSingleEv,
	// onConfirmExportSingleEv,
	onConfirmSelectedEv,
	onConfirmSingleEv,
	onExportToBankSingleEv,
	onPrepareExportSingleEv,
	openPaymentBankApiAuditModalEv,
	openPaymentConfirmModalEv,
	openPaymentExportModalEv,
	PaymentAppiesGate,
	PaymentBankApiGate,
	PaymentExportGate,
	PaymentGate, resetTableErrorEv, TableErrorGate
} from "./events.js";
import {
	cancelAppliesByAllFx,
	confirmAppliesByAllFx,
	confirmByAllFx,
	confirmByIdFx,
	getPaymentAppliesDataFx,
	getPaymentByIdFx,
	getPaymentDataFx,
	getPaymentElementsFx,
	getPaymentErrorByIdFx,
	getPaymentExportDataFx,
	prepareExportByAllFx,
	exportToBankFx,
	changeServiceInErrorFx,
	getExportedPaymentsFx,
	getBankApiAuditFx,
	bankApiAuditDownloadFullFx,
	bankApiAuditDownloadShortFx,
	exportPaymentConfirmFx,
	exportPaymentAppliesFx,
	exportPaymentExportFx,
	exportConfirmTableFx, exportAppliesTableFx, exportAppliesErrorFx, exportExportTableFx
} from "./effects.js";
import {
	$bankApiAuditFiltersSt,
	$bankApiAuditSt,
	$errorSingle,
	$exportedPaymentsSt,
	$paymentAppliesData,
	$paymentBankApiAuditModal,
	$paymentBankApiAuditModalData,
	$paymentConfirmModal,
	$paymentConfirmModalData,
	$paymentData,
	$paymentElementsData,
	$paymentElementsSt,
	$paymentExportData,
	$paymentExportModal,
	$paymentExportModalData,
	$paymentSingle
} from "./stores.js";
import queryString from "query-string";

$paymentData.on(getPaymentDataFx.doneData, (_, data) => data)
	.reset(PaymentGate.close)

$paymentConfirmModal.on(openPaymentConfirmModalEv, () => true)
	.on(closePaymentConfirmModalEv, () => false)
	.reset(PaymentGate.close)

$paymentConfirmModalData.on(dataPaymentConfirmModalEv, (_, data) => data)
	.reset(PaymentGate.close)


$paymentBankApiAuditModal.on(openPaymentBankApiAuditModalEv, () => true)
	.on(closePaymentBankApiAuditModalEv, () => false)
	.reset(PaymentBankApiGate.close)

$paymentBankApiAuditModalData.on(dataPaymentBankApiAuditModalEv, (_, data) => {
	return data
})
	.reset(PaymentBankApiGate.close)

$paymentExportModal.on(openPaymentExportModalEv, () => true)
	.on(closePaymentExportModalEv, () => false)
	.reset(PaymentGate.close)

$paymentExportModalData.on(dataPaymentExportModalEv, (_, data) => data)
	.reset(PaymentGate.close)

$paymentAppliesData.on(getPaymentAppliesDataFx.doneData, (_, data) => data)
	.reset(PaymentGate.close)

$paymentExportData.on(getPaymentExportDataFx.doneData, (_, data) => data)
	.reset(PaymentGate.close)


$paymentElementsData.on(getPaymentElementsFx.doneData, (_, data) => data)
	.reset(PaymentGate.close)

$paymentSingle.on(getPaymentByIdFx.doneData, (_, data) => data)
	.reset(PaymentGate.close)

$errorSingle.on(getPaymentErrorByIdFx.doneData, (_, data) => data)
	.on(resetTableErrorEv, (_, data) => ({data: [], count: 0}))
	.reset(TableErrorGate.close)
	// .reset(resetTableErrorEv)

$paymentElementsSt.on(getPaymentElementsFx.doneData, (_, data) => data)
	.reset(PaymentGate.close)

$exportedPaymentsSt.on(getExportedPaymentsFx.doneData, (_, data) => data)
	.reset(PaymentGate.close)

$bankApiAuditSt.on(getBankApiAuditFx.doneData, (_, data) => data)
	.reset(PaymentBankApiGate.close)

$bankApiAuditFiltersSt.on(bankApiAuditChangeFilterEv, (state, payload) => {
	const result = {...state}
	if (Array.isArray(payload)) {
		for (const change of Object.values(payload)) {
			result[change.key] = change.value
		}
	} else if (!Array.isArray(payload)) {
		result[payload.key] = payload.value
	}
	for (const key in result) {
		if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
			delete result[key]
		}
	}
	return result
})
	.reset(PaymentBankApiGate.close)

sample({
	source: PaymentGate.state,
	clock: [PaymentGate.state, confirmByIdFx.done,  confirmByAllFx.doneData],
	filter: (gate) => Object.hasOwn(gate, 'search'),
	fn: (gate) => {
		return gate.search
	},
	target: getPaymentDataFx
})

sample({
	clock: [confirmByIdFx.done, confirmByAllFx.doneData],
	target: closePaymentConfirmModalEv
})

sample({
	source: PaymentAppiesGate.state,
	clock: [PaymentAppiesGate.state, confirmAppliesByAllFx.doneData, cancelAppliesByAllFx.doneData],
	filter: (gate) => Object.hasOwn(gate, 'search'),
	fn: (gate) => {
		return gate.search
	},
	target: [getPaymentAppliesDataFx, resetTableErrorEv]
})


sample({
	source: PaymentExportGate.state,
	clock: [PaymentExportGate.state, prepareExportByAllFx.doneData, exportToBankFx.doneData],
	filter: (gate) => Object.hasOwn(gate, 'search'),
	fn: (gate) => {
		return gate.search
	},
	target: getPaymentExportDataFx
})



sample({
	source: {gate: PaymentBankApiGate.state, pagination: $bankApiAuditFiltersSt},
	clock: [PaymentBankApiGate.state, $bankApiAuditFiltersSt],
	filter: (payload) => Object.hasOwn(payload.gate, 'search'),
	fn: (payload) => {

		const newFilters = {
			// ...payload.gate.search,
			page: payload.pagination.page,
			limit: payload.pagination.limit,
		}
		// debugger
		const filter = queryString.stringify(newFilters, {skipEmptyString: true, skipNull: true})
		// return queryString.stringify(newFilters, {skipEmptyString: true, skipNull: true})
		return `${payload.gate.search}&${filter}`
	},
	target: getBankApiAuditFx
})

sample({
	clock: [PaymentExportGate.state,  prepareExportByAllFx.doneData, exportToBankFx.doneData],
	target: closePaymentExportModalEv
})


sample({
	clock: onConfirmSingleEv,
	fn: (payload) => {
		return {
			payment_date: dayjs(payload.payment_date).format('YYYY-MM-DD'),
			bank_id: payload.bank_id,
			bank_day: payload.bank_day ? dayjs(payload.bank_day).format('YYYY-MM-DD') : null,
			confirmation_type: "manual"
		}
	},
	target: confirmByIdFx
})

sample({
	clock: onConfirmAppliesSingleEv,
	target: confirmAppliesByAllFx
})

sample({
	clock: onCancelAppliesSingleEv,
	target: cancelAppliesByAllFx
})

sample({
	clock: [onConfirmSelectedEv, onConfirmAllEv],
	fn: (p) => {
		return {
			payment_confirm: p?.payment_confirm?.map(item => {
				return {
					payment_date: dayjs(item.payment_date).format('YYYY-MM-DD'),
					bank_id: item.bank_id,
				}
			}),
			bank_day: p?.bank_day ? dayjs(p.bank_day).format('YYYY-MM-DD') : null,
		}
	},
	target: confirmByAllFx
})

sample({
	clock: getPaymentByIdEv,
	target: getPaymentByIdFx
})

sample({
	clock: getPaymentErrorByIdEv,
	target: getPaymentErrorByIdFx
})


sample({
	clock: onPrepareExportSingleEv,
	target: prepareExportByAllFx
})

sample({
	clock: onExportToBankSingleEv,
	target: exportToBankFx
})

sample({
	clock: getPaymentElementsEv,
	target: getPaymentElementsFx
})


sample({
	clock: changeServiceInErrorEv,
	target: changeServiceInErrorFx
})


sample({
	clock: getExportedPaymentsEv,
	target: getExportedPaymentsFx
})


sample({
	clock: bankApiAuditDownloadFullEv,
	target: bankApiAuditDownloadFullFx
})


sample({
	clock: bankApiAuditDownloadShortEv,
	target: bankApiAuditDownloadShortFx
})


sample({
	clock: exportPaymentConfirmEv,
	target: exportPaymentConfirmFx
})


sample({
	clock: exportPaymentAppliesEv,
	target: exportPaymentAppliesFx
})


sample({
	clock: exportPaymentExportEv,
	target: exportPaymentExportFx
})


sample({
	clock: exportConfirmTableEv,
	target: exportConfirmTableFx
})


sample({
	clock: exportAppliesTableEv,
	target: exportAppliesTableFx
})


sample({
	clock: exportAppliesErrorEv,
	target: exportAppliesErrorFx
})


sample({
	clock: exportExportTableEv,
	target: exportExportTableFx
})
