import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Select from 'antd/es/select';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {
    InspectorsCreateGate,
    submitCreateInspectorsEv,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    $createdInspectorsId, $selectedInspectors
} from '../../../models/dictionaryInspectorsModel/index.js';
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {PlusOutlined} from "@ant-design/icons";
import useFormChanged from "../../../hooks/useFormChanged.js";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {
    setCreateChiefInspectorEmbeddedEv,
    setCreateSectorEmbeddedEv,
    setCreateTownEmbeddedEv
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {$createdTownIdModal} from "../../../models/dictionaryDistrictsModel/index.js";
import CreateTownEmbeddedForm from "../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx";
import SectorsTable from "../../../components/Embedded/SectorsTable/SectorsTable.jsx";
import CreateSectorEmbeddedForm
    from "../../../components/Embedded/CreateSectorEmbeddedForm/CreateSectorEmbeddedForm.jsx";
import CreateChiefInspectorEmbeddedForm
    from "../../../components/Embedded/CreateChiefInspectorEmbeddedForm/CreateChiefInspectorEmbeddedForm.jsx";

export default function CreateInspectors() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [inspectorsForm] = Form.useForm()

    const submit = useUnit(submitCreateInspectorsEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const selected = useUnit($selectedInspectors)


    const createdId = useUnit($createdInspectorsId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const setSectorEmbedded = useUnit(setCreateSectorEmbeddedEv)
    const setChiefInspectorEmbedded = useUnit(setCreateChiefInspectorEmbeddedEv)

    const townsOpts = useStoreMap($dictsSelects, sel => sel.towns)
    const sectorOpts = useStoreMap($dictsSelects, sel => sel.sector)
    const chiefInspectorOpts = useStoreMap($dictsSelects, sel => sel.chief_inspector).filter(item => item.value !== ' ').filter(item => item.value !== null)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const newTown = useUnit($createdTownIdModal)


    const formData = inspectorsForm.getFieldsValue()

    const [check, changedFields] = useFormChanged(selected, ['town_kz','name_kz', 'name_ru'])
    const genitive_form = t(`breadcrumbs.genitive.inspectors`);



    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/inspectors/edit/${createdId}`)
            } else if (action === 'new') {
                inspectorsForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);


    const onSubmit = (values) => {
        values['name_kz'] = values['name_ru']
        return submit(values)
    }

    return <>
        <InspectorsCreateGate/>
        <Form form={inspectorsForm} layout={'vertical'} onFinish={onSubmit} size={'small'}>

            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Населенный пункт')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Form.Item name={'town_id'}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['town_id']}
                        >
                            <Select
                                options={[...townsOpts, {label: 'Создать', value: -1, emoji: true}]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setTownEmbedded(true)
                                    } else {
                                        inspectorsForm.resetFields(['town'])
                                        setTownEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateTownEmbeddedForm form={inspectorsForm}/>

                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Свойства Контролера')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        {/*<Form.Item name={'name_kz'}*/}
                        {/*           label={t(`ФИО ${genitive_form} (каз)`)}*/}
                        {/*           rules={[{required: true, message: t('Обязательное поле')}]}*/}
                        {/*           validateStatus={changedFields['name_kz']}*/}
                        {/*>*/}
                        {/*    <Input/>*/}
                        {/*</Form.Item>*/}
                        <Form.Item name={'name_ru'}
                                   label={t(`ФИО ${genitive_form}`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>
                </Col>

            </Row>


            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Старший контролер')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Form.Item name={'chief_inspector_id'}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['town_id']}
                        >
                            <Select
                                options={[...chiefInspectorOpts, {label: 'Создать', value: -1, emoji: true}]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setChiefInspectorEmbedded(true)
                                    } else {
                                        inspectorsForm.resetFields(['town'])
                                        setChiefInspectorEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateChiefInspectorEmbeddedForm form={inspectorsForm}/>

                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={t('Участок')} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Form.Item name={'sector_id'}
                                   label={'Участок'}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['sector_id']}
                        >
                            <Select
                                options={[...sectorOpts, {label: 'Создать', value: -1, emoji: true}]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setSectorEmbedded(true)
                                    } else {
                                        // realEstateObjectsForm.resetFields(['sector_id'])
                                        setSectorEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateSectorEmbeddedForm form={inspectorsForm}/>

                    </Card>
                </Col>
            </Row>

        </Form>
        {/*<Divider orientation={'left'} orientationMargin={0} > asdf</Divider>*/}
        {/*<SectorsTable*/}

        {/*/>*/}
        {/*<Divider orientation={'left'} orientationMargin={0}/>*/}
        <br/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    inspectorsForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            inspectorsForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            inspectorsForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
