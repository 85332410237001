import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import {Popconfirm} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import sortByUser from '../../utils/sortByUser.js';
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {
    $sectorList, deleteSectorEv,
    getSectorListFx,
    SectorListGate
} from "../../models/dictionarySectorModel/index.js";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";

export default function DictionarySectorList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($sectorList)
    const loading = useUnit(getSectorListFx.pending)
    const onDelete = useUnit(deleteSectorEv)
    // const [search] = useSearchParams()
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.sector`)


    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t('Населеный пункт'),
            dataIndex: 'town_id',
            sorter: true,
            key: 'town_id',
            render: (town_id, record) => <MappedDictItem id={town_id} type={'town'} />
        },
        {
            title: t('Контролер'),
            dataIndex: 'inspector_id',
            sorter: true,
            key: 'inspector_id',
            render: (inspector_id, record) => record.inspector_id === null ? 'Без контролера' : <MappedDictItem id={record.inspector_id} type={'inspector'} />
        },
        {
            title: t('Рекламный сектор'),
            dataIndex: 'adv_sector',
            sorter: true,
            key: 'adv_sector',
            render: (adv_sector, record) => adv_sector
        },
        // {
        //     title: t(`Название ${genitive_form} (каз)`),
        //     dataIndex: 'name_kz',
        //     sorter: true,
        //     key: 'name_kz',
        //     render: (name, record) => <Link to={`/dictionaries/sector/view/${record.id}`}>{`${name}`}</Link>
        // },
        // {
        //     title: t(`Название ${genitive_form} (рус)`),
        //     dataIndex: 'name_ru',
        //     sorter: true,
        //     key: 'name_ru',
        //     render: (name, record) => <Link to={`/dictionaries/sector/view/${record.id}`}>{`${name  }`}</Link>
        // },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/sector/edit/${id}`}><Button icon={<EditOutlined />} type={'primary'} /></Link>
                </Tooltip>
                <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/sector/view/${id}`}><Button icon={<EyeOutlined />} /></Link>
                </Tooltip>
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => onDelete(id)}
                            placement={'left'}
                >
                <Tooltip title={t('Удалить')} placement={'top'}>
                    <Button icon={<DeleteOutlined />} danger/>
                </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код'), type: 'input'},
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns'},
        // {name: 'sector', label: t('Участок'), type: 'select', dict: 'sector'},
        {name: 'inspector_id', label: t('Контролер'), type: 'select', dict: 'inspector', multiple: true},
        // {name: 'street_type_id', label: t('Тип'), type: 'select', dict: 'streetTypes', multiple: true},
        // {name: 'name', label: t('Название'), type: 'input'},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]



    return <>
        <SectorListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
        />
    </>
}
