import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import {
    $selectedAccount, $selectedAccountReEsObjFormatted, AccountViewGate,
    getAccountByIdFx
} from '../../../models/dictionaryAccountsModel/index.js';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';
import CustomDates from '../../../components/CustomDates/CustomDates.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import Tabs from 'antd/es/tabs';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import ProviderServices from "./ProviderServices.jsx";
import Accruals from "./Accruals.jsx";
import Payments from "./Payments.jsx";
import ShowAddressCard from '../../../components/AddressReoSearchForms/ShowAddressCard.jsx';


function DescrItem({label, value}) {
    return <div style={{display: 'flex', flexDirection: 'column', marginBottom: 16}}>
        <span><strong>{label}</strong></span>
        <span>{value && typeof value === 'boolean' ? (value ? 'Да' : 'Нет') : value ?? '-'}</span>
    </div>
}

export default function ViewAccount() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getAccountByIdFx.pending)
    const account = useUnit($selectedAccount)
    const reEsObj = useUnit($selectedAccountReEsObjFormatted)

    const items = <>
        <ShowAddressCard formated={reEsObj} title={t('Объект недвижимости')}/>
        <Divider/>
        <Row justify={'space-between'} gutter={[32, 16]}>
            <Col span={8}>
                <Card title={t('Основная информация')} size={'small'} style={{minHeight: '100%'}}>
                    {/*<DescrItem label={t('ФИО (каз)')} value={account?.name_kz}/>*/}
                    <DescrItem label={t('ФИО')} value={account?.name_ru}/>
                    <DescrItem label={t('Количество проживающих')}
                               value={account?.people_quantity}/>
                    <DescrItem label={t('ИИН')} value={account?.iin}/>
                </Card>
            </Col>
            <Col span={8}>
                <Card title={t('Площади')} size={'small'} style={{minHeight: '100%'}}>
                    <DescrItem label={t('Общая')} value={account?.full_square}/>
                    <DescrItem label={t('Жилая')} value={account?.living_square}/>
                    <DescrItem label={t('Отапливаемая')} value={account?.heated_square}/>
                </Card>
            </Col>
            <Col span={8}>
                <Card title={t('Дополнительная информация')} size={'small'}>
                    <Row justify={'space-between'} gutter={[16, 0]}>
                        <Col span={7}>
                            <DescrItem label={t('Квартира')}/>
                        </Col>
                        <Col span={5}>
                            <DescrItem label={t('Этаж')}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Кол-во комнат')}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Тел.')}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Моб. тел.')}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Email')}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Email рассылка')}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Код АЭСбыт')}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Код энергокомп.')}/>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </>

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={account?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),

            children: <CustomDates data={account?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: account?.updated_by ? <CreatedBy id={account.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: account?.updated_at ? <CustomDates data={account?.updated_at}/> : '-'
        }
    ]

    const tabItems = [
        {
            key: 'common',
            label: t('Общая информация'),
            children: <ViewDictionaryItemCommon customItems={items} itemsDates={itemsDates} id={id}
                                                href={'accounts'}/>
        },
        {
            key: 'services',
            label: t('Услуги поставщиков'),
            children: <ProviderServices isView/>
        },
        {
            key: 'accruals',
            label: t('Начисления'),
            children: <Accruals isView/>
        },
        {
            key: 'payments',
            label: t('Оплаты'),
            children: <Payments isView/>
        },
        {
            key: 'preferences',
            label: t('Льготы'),
            disabled: true,
            children: <span>В разработке</span>
        },
    ]

    const tabItemsDemo = [
        {
            key: 'common',
            label: t('Общая информация'),
            children: <ViewDictionaryItemCommon customItems={items} itemsDates={itemsDates} id={id}
                                                href={'accounts'}/>
        },
        {
            key: 'services',
            label: t('Услуги поставщиков'),
            children: <ProviderServices isView/>
        },
        {
            key: 'accruals',
            label: t('Начисления'),
            children: <Accruals isView/>
        },
        {
            key: 'payments',
            label: t('Оплаты'),
            children: <Payments isView/>
        },
        {
            key: 'preferences',
            label: t('Льготы'),
            disabled: true,
            children: <span>В разработке</span>
        },
    ]

    return <>
        <AccountViewGate id={id}/>
        <Tabs
            items={window.demo_mode === true ? tabItemsDemo : tabItems}
            defaultActiveKey={'common'}
            size={'small'}
            type={'card'}
        />
    </>
}
