import {sample} from 'effector';
import {combineEvents} from 'patronum';
import {$leftSectors, $leftSelected, $rightSelected} from "./stores.js";
import {LeftTableClickEv, RightTableClickEv, SwapSectorGate, SwapSelectedSectorsEv} from "./events.js";
import {getSectorListFx} from "./effects.js";
import {submitGlobalUpdateEv, universalDictEndpointFx} from "../dictionaryUniversalModel/index.js";



// $leftSelected.on(LeftTableClickEv, (_, data) => data)

$leftSectors.on(getSectorListFx.doneData, (state, payload) => payload)
    .reset(SwapSectorGate.close)

$leftSelected.on(LeftTableClickEv, (state, payload) => payload)
$rightSelected.on(RightTableClickEv, (state, payload) => payload)


// sample({
//     clock: SwapSectorGate.state,
//     filter: (gate) => {
//         if (gate.search === '') {
//             return false
//         } else {
//             return true
//         }
//     },
//     fn: (gate) => gate.search,
//     target: getSectorListFx,
// })




sample({
    clock: SwapSelectedSectorsEv,
    fn: (payload) => {
        let actions = []
        console.log('SwapSelectedSectorsEv', payload)
        if (payload.type === 'setNewSector') {
            actions = payload.values.map(
                v => ({
                    operation: 'update',
                    type: 'inspectors',
                    value: {object_id: v.inspector_id, f___sector_id: v.v}
                })
            )
        }
        if (payload.type === 'setNewInspector') {
            actions = payload.values.map(
                v => ({
                    operation: 'update',
                    type: 'sector',
                    value: {object_id: v.sector_id, inspector_id: v.v}
                })
            )
        }
        console.log('actions', actions)
        return actions
    },
    target: submitGlobalUpdateEv,
})


// sample({
//     source: SwapSectorGate.state,
//     clock: universalDictEndpointFx.doneData,
//     fn: (gate) => {
//         return gate.search
//     },
//     target: getSectorListFx,
// })
