import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {$dictsSelects} from '../../../../models/infoModel/index.js';
import Form from 'antd/es/form';
import {
    $massSrvTarifsOpts,
    $srvTarOptsMap
} from '../../../../models/dictionaryCorrectionModel';
import {useMemo} from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import {filterOption, optionRender} from '../../../../utils/selectUtils.jsx';
import Input from 'antd/es/input';
import {isId} from '../../../../utils/helpers.js';
import DatePicker from 'antd/es/date-picker';
import InputNumber from 'antd/es/input-number';
import ActivitySubForm from '../../../DictionaryProviders/components/ActivitySubForm.jsx';

export default function Add({form, isMass, singleFromMass, disabled, fieldPrefix, massEdit}) {
    const {t} = useTranslation()
    const {provOpts, srvOpts} = useStoreMap($dictsSelects, (s) => ({
        provOpts: s.providers,
        srvOpts: s.services
    }))

    // for single account
    const selectedService = Form.useWatch([...fieldPrefix, 'service_id'], form)
    const tarOptsBySrv = useUnit($srvTarOptsMap)
    const tarOptsBySrvFilt = useMemo(() => {
        if (selectedService) {
            return [...tarOptsBySrv[selectedService], {label: t('Создать'), value: -1, emoji: true}]
        } else {
            return [{label: t('Создать'), value: -1, emoji: true}]
        }
    }, [tarOptsBySrv, selectedService])

    // for mass actions
    const selectedTarif = Form.useWatch([...fieldPrefix, 'tarif_id'], form)
    const massSrvTarifOpts = useUnit($massSrvTarifsOpts)
    const tarOptsFilt = useMemo(() => {
        return [...massSrvTarifOpts, {label: t('Создать'), value: -1, emoji: true}]
    }, [massSrvTarifOpts])

    const srvUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)

    return <Row gutter={[16, 0]}>
        <Col span={8}>
            <Form.Item name={[...fieldPrefix, 'service_id']} label={t('Услуга')}
                       hidden={isMass || singleFromMass}
                       rules={isMass || singleFromMass ? [] : [{required: true, message: t('Обязательное поле')}]}
            >
                <Select options={srvOpts}
                        disabled={disabled}
                        style={{width: '100%'}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'provider_id']} label={t('Поставщик')}
                       hidden={isMass || singleFromMass}
                       rules={isMass || singleFromMass ? [] : [{required: true, message: t('Обязательное поле')}]}
            >
                <Select options={provOpts.filter(i => i?.services?.includes(selectedService))}
                        disabled={disabled}
                        style={{width: '100%'}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'tarif_id']} label={t('Тариф')}
                       rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <Select options={(isMass || singleFromMass) ? tarOptsFilt : tarOptsBySrvFilt}
                        disabled={disabled || (!isMass && !singleFromMass && !selectedService)}
                        style={{width: '100%'}}
                        optionRender={optionRender}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
            {selectedTarif === -1 && <>
                <Form.Item name={[...fieldPrefix, 'tarif_value']} label={t('Значение тарифа')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Input style={{width: '100%'}} disabled={disabled}/>
                </Form.Item>
                <Form.Item name={[...fieldPrefix, 'tarif_service_unit_id']} label={t('Единица измерения')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Select options={srvUnitOpts}
                            disabled={disabled}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}/>
                </Form.Item>
            </>}
            <Form.Item name={[...fieldPrefix, 'tarif_date_from']} label={t('Дата начала действия тарифа')}
                       rules={isId(selectedTarif) ? [{required: true, message: t('Обязательное поле')}] : []}
            >
                <DatePicker style={{width: '100%'}} disabled={disabled}/>
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'sum_saldo_begin']} label={t('Нач. сальдо')}
                // rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <Input />
            </Form.Item>
        </Col>
        {!isMass && <Col span={6}>
            <Form.Item name={[...fieldPrefix, 'service_square']} label={t('Обслуживаемая площадь')}>
                <InputNumber step={0.1}
                             style={{width: '100%'}}
                             parser={val => {
                                 if (val.includes(',')) {
                                     val = val.replace(',', '.')
                                 }
                                 return Number(val)
                             }}
                             disabled={disabled}
                             formatter={(val) => val.toString().replace('.', ',')}
                />
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'people_quantity']} label={t('Кол-во проживающих')}>
                <InputNumber min={0} style={{width: '100%'}} disabled={disabled}/>
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'external_number']} label={t('Номер абонента у поставщика')}>
                <Input style={{width: '100%'}} disabled={disabled}/>
            </Form.Item>
        </Col>}
        <Col span={10}>
            <ActivitySubForm type={'2x2'} form={form} prefix={fieldPrefix} disabled={disabled}/>
        </Col>
    </Row>
}