import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react/effector-react.umd';
import {useEffect, useMemo, useState} from 'react';
import {useStoreMap} from "effector-react";

import Col from "antd/es/col";
import dayjs from "dayjs";
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Select from "antd/es/select";
import Row from "antd/es/row";
import Popconfirm from "antd/es/popconfirm";
import Switch from "antd/es/switch";
import Card from "antd/es/card";
import Button from "antd/es/button";

import {
    $uploadModal, $uploadStatus,
    resetUploadModalEv,
    uploadFileEv, uploadFileFx
} from '../../../models/fileUploadModel/index.js';
import {$dictsSelects, $info} from '../../../models/infoModel/index.js';

import UploadComponent from '../../../components/UploadComponent/UploadComponent.jsx';

import * as styles from './UploadModal.module.css'


const monthDict = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
}

const copy_square_options = [
    {
        label: 'Общая площадь',
        value: 'full_square',
    },
    {
        label: 'Жилая площадь',
        value: 'living_square',
    },
    {
        label: 'Отапливаемая площадь',
        value: 'heated_square',
    },
    {
        label: 'Оставить без изменений',
        value: null,
    }
]


export default function UploadModal() {
    const {t} = useTranslation()
    const open = useUnit($uploadModal)
    const onClose = useUnit(resetUploadModalEv)
    const onUpload = useUnit(uploadFileEv)
    const loading = useUnit(uploadFileFx.pending)
    const status = useUnit($uploadStatus)
    const [fileForm] = Form.useForm()
    const curDate = useStoreMap($info, info => info.current_date)

    const providerOpts = useStoreMap($dictsSelects, sel => sel.providers)
    const monthOptions = useMemo(() => {
        const current = dayjs(curDate).get('month')
        const next = dayjs(curDate).add(1, 'month').get('month')
        return [
            {
                label: t(monthDict[current]),
                value: current
            },
            {
                label: t(monthDict[next]),
                value: next
            }
        ]
    }, [curDate])
    const onReset = () => {
        fileForm.resetFields()
        onClose()
    }

    const onSubmit = (values) => {
        const payload = {
            ...values,
            report_date: dayjs().set('date', 1).set('month', values.month).format()
        }
        delete payload.month
        onUpload(payload)
    }

    useEffect(() => {
        if (status) {
            onReset()
        }
    }, [status]);

    const files = Form.useWatch(['files'], fileForm) || []
    const provider_id = Form.useWatch(['provider_id'], fileForm)
    const ids = files.map(f => f.name.replace(/^ОСВ_/, '').replace(/[^0-9]+.*$/g, ''))
    const isIncorrect = ids.map(i => parseInt(i)).some(
        i => isNaN(i) || i !== provider_id
    )
    const statusText = isIncorrect ? t('Код поставщика в имени файла не найден или не совпадает с выбранным поставщиком') : '\u00A0'
    const reAskText = isIncorrect ? <div>
        {t('Код поставщика в имени файла не найден или не совпадает с выбранным поставщиком')}
        <br/>
        {t('Вы уверены, прикрепить файл к выбранному поставщику?')}
    </div> : null
    const onOk = () => fileForm.submit()

    const [openT, setOpenT] = useState(false);
    const confirm = () => {
        setOpenT(false);
        onOk()
    };
    const cancel = () => setOpenT(false)
    const handleOpenChange = (newOpen) => {
        if (!isIncorrect) {
            confirm()
            return;
        }
        setOpenT(newOpen);
    };

    console.log('Files', ids, files, provider_id, isIncorrect)
    return <Modal open={open}
                  width={'80vw'}
                  title={t('Загрузка файла')}
                  onCancel={onReset}
                  onOk={onOk}
                  okButtonProps={{loading: loading}}
                  destroyOnClose
                  footer={[
                      <Button key="back" onClick={onReset}>
                          {t('Отмена')}
                      </Button>,
                      <Popconfirm
                          placement="leftTop"
                          title="Загрузка файла"
                          description={reAskText}
                          open={openT}
                          onOpenChange={handleOpenChange}
                          onConfirm={confirm}
                          onCancel={cancel}
                          okText={t('Да, я уверен')}
                          cancelText={t('Отменить')}
                      >
                          <Button key="submit" type="primary" loading={loading}>
                              {t('Загрузить файл')}
                          </Button>
                      </Popconfirm>,
                  ]}
    >
        <Form
            form={fileForm} onFinish={onSubmit} layout={'vertical'} size={'small'}
            initialValues={{
                month: dayjs(curDate).get('month'),
                copy_quantity: true,
            }}
        >
            <Row justify={"space-between"} gutter={[8, 16]}>
                <Col span={12}>
                    <Form.Item
                        name={'provider_id'} label={t('Поставщик')}
                        rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={providerOpts}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={'month'} label={t('Месяц')}
                        rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={monthOptions}

                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className={styles.warnDiv}>{statusText}</div>
                </Col>
                <Col span={24}>
                    <Card size={'small'} title={t('Обновить данные в ЛС:')}>
                        <Row justify={"space-between"}>
                            <Col span={11}>
                                <Form.Item
                                    name={'copy_square'} label={t('Площадь')}
                                    tooltip={t('Если вы хотите обновить площадь в ЛС, т.е. записать туда данные столбца площади из загружаемого файла, то выберите в какое поле ЛС сохранять изменение')}
                                >
                                    <Select
                                        showSearch
                                        defaultValue={null}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={copy_square_options}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'copy_quantity'} label={t('Количество человек')}
                                    tooltip={t('Если вы хотите обновить кол-во человек в ЛС, т.е. записать туда данные столбца "Количество человек" из загружаемого файла, то выберите вариант "Обновить"')}
                                >
                                    <Switch
                                        checkedChildren={t('Обновить')}
                                        unCheckedChildren={t('Оставить без изменений')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <UploadComponent
                        title={t('Файлы')} isForm formName={'files'} dragger multiple
                    />
                </Col>
            </Row>
        </Form>
    </Modal>
}
