import {
    $emailSubApproveList, approveEmailSubEv,
    EmailSubApproveListGate, rejectEmailSubEv
} from '../../../models/emailSubApproveModel/index.js';
import Card from 'antd/es/card';
import FilterBlock from '../../../components/FiltersBlock/FiltersBlock.jsx';
import Divider from 'antd/es/divider';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import {useTranslation} from 'react-i18next';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';
import {useStoreMap, useUnit} from 'effector-react';
import sortByUser from '../../../utils/sortByUser.js';
import {$infoMaps} from '../../../models/infoModel/index.js';
import dayjs from 'dayjs';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import {useSearchParams} from 'react-router-dom';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

export default function EmailSubApprove() {
    const {t} = useTranslation()
    const {data, count} = useUnit($emailSubApproveList)
    const users = useStoreMap($infoMaps, info => info.usersMap)

    const approve = useUnit(approveEmailSubEv)
    const reject = useUnit(rejectEmailSubEv)

    const [search] = useSearchParams()

    const columns = [
        {
            title: 'Код',
            dataIndex: 'id',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'id',
        },
        {
            title: t('ЛС'),
            dataIndex: 'account_id',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'account_id',
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            sorter: true,
            key: 'email',

        },
        {
            title: t('Включен ли ЛС уже в рассылку'),
            dataIndex: 'already_used',
            sorter: true,
            align: 'center',
            key: 'already_used',
            render: (_, record) => record.already_used ? <span ><CheckOutlined style={{color: 'green'}}/></span> : <span><CloseOutlined style={{color: 'red'}}/></span>
        },
        {
            title: t('Текущий E-mail в ЛС'),
            dataIndex: 'current_email',
            sorter: true,
            key: 'current_email',
        },
        {
            title: t('Кем подана'),
            dataIndex: 'created_by',
            sorter: true,
            render: (applied_by) => <CreatedBy id={applied_by} />
        },
        {
            title: t('Статус'),
            dataIndex: 'approved_status',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'status',
            render: (approved_status) => approved_status === "accepted" ? 'Одобрено' : 'Отклонено'
        },

        {
            title: t('Принял решение'),
            dataIndex: 'approved_by',
            sorter: true,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Дата принятия решения'),
            dataIndex: 'approved_at',
            sorter: true,
            key: 'approved_at',
        },
        {
            key: 'actions',
            dataIndex: 'id',
            align: 'center',
            width: '2%',
            render: (id) => <ButtonGroup>
                <Popconfirm
                    title={t('Вы уверены, что хотите одобрить эту заявку?')}
                    onConfirm={() => approve([id])}
                    okText={t('Да')}
                >
                    <Tooltip title={t('Одобрить')}>
                        <Button type={'primary'} icon={<CheckOutlined />}/>
                    </Tooltip>
                </Popconfirm>
                <Popconfirm
                    title={t('Вы уверены, что хотите отклонить эту заявку?')}
                    onConfirm={() => reject([id])}
                    okText={t('Да')}
                    okButtonProps={{danger: true}}
                >
                    <Tooltip title={t('Отклонить')}>
                        <Button danger icon={<CloseOutlined />}/>
                    </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    const filterItems = [
        {
            label: 'ЛС',
            name: 'account_id',
            type: 'input',
        },
        {
            label: 'Email',
            name: 'email',
            type: 'input',
        },
        {
            label: 'Статус',
            name: 'approved_status',
            type: 'select',
            dict: 'approveStatuses'
        }
    ]

    return <>
        <EmailSubApproveListGate search={search}/>
        <Card>
            <FilterBlock items={filterItems}/>
            <Divider />
            <DictionaryProTable data={data} count={count} initColumns={columns} showActions={false}/>
        </Card>
    </>
}
