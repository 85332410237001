import {useTranslation} from 'react-i18next';
import {
    $correctionType, $excludedAccs,
    $multiSelectedAccs, afterActionsEnum, correctionTypeEnum,
    setAfterActionEv,
    submitTempCorrsMassEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {useUnit} from 'effector-react';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import ServiceCardMass from './ServiceCardMass.jsx';

export default function MassActions() {
    const {t} = useTranslation()
    const [massForm] = Form.useForm()
    const mode = useUnit($correctionType)

    const multiAccs = useUnit($multiSelectedAccs)
    const excludedAccs = useUnit($excludedAccs)

    const setAfterAction = useUnit(setAfterActionEv)

    const submitMultiAccs = useUnit(submitTempCorrsMassEv)

    const onFormSubmit = (action) => {
        setAfterAction(action)
        massForm.submit()
    }

    return <>
        <Form form={massForm} layout={'vertical'}
              onFinish={(values) => submitMultiAccs(values)}
              size={'small'}
              style={{marginTop: 8}}
        >
            <ServiceCardMass form={massForm} isMass massEdit={true}/>
        </Form>
        <Row style={{gap: 8, marginTop: 8}}>
            <Button type={'primary'} disabled={multiAccs?.length === 0} size={'small'} onClick={() => onFormSubmit(afterActionsEnum.mass_selected, mode)}>
                {`${t('Применить для выбранных')}${multiAccs?.length > 0 ? ` (${multiAccs?.length})` : ''}`}
            </Button>
            <Button type={'primary'} size={'small'} ghost onClick={() => onFormSubmit(afterActionsEnum.mass_all, mode)}>
                {t('Применить для всех попавших под фильтр')}
                {excludedAccs?.length > 0 ? ` (${t('Исключено')}: ${excludedAccs?.length})` : ''}
            </Button>
        </Row>
    </>
}