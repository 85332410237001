import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {
    KnpCreateGate,
    submitCreateKnpEv,
    $afterCreateAction,
    $createdKnpId,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
} from "../../../models/dictionaryKnpModel/index.js";
import {InputNumber} from "antd";
import CreateStTypeEmbeddedForm
    from "../../../components/Embedded/CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx";

export default function CreateKnp() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [knpForm] = Form.useForm()

    const submit = useUnit(submitCreateKnpEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdKnpId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.knp`);


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/knp/edit/${createdId}`)
            } else if (action === 'new') {
                knpForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <KnpCreateGate/>
        <Card size={'small'}>
            <Form form={knpForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>
                <Row justify={'space-between'} gutter={[16, 0]}>
                    <Col span={4}>
                        <Form.Item name={'id'}
                                   label={t(`Код ${genitive_form}`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <InputNumber style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item name={'short_name_ru'}
                                   label={t(`Короткое Название ${genitive_form} (рус)`)}

                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item name={'short_name_kz'}
                                   label={t(`Короткое Название ${genitive_form} (каз)`)}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
        <Divider/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    knpForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            knpForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующий')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            knpForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
