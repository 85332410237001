import {
    $afterCreateAction,
    $createdRealEstateObjectsId,
    $editRealEstateObjectsStatus,
    $selectedRealEstateObjects,
    $realEstateObjectsList,
    $accontsList,
    $massReoFormData,
    $generatedReos,
    $massCreateStatus,
    $onCreateList
} from './stores.js';
import {getRealEstateObjectsListFx, getRealEstateObjectsByIdFx, getAccountByIdFx} from './effects.js';
import {
    deleteRealEstateObjectsEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    RealEstateObjectsEditGate,
    RealEstateObjectsListGate,
    RealEstateObjectsViewGate,
    submitCreateRealEstateObjectsEv,
    submitEditRealEstateObjectsEv,
    RealEstateObjectsCreateGate,
    onCreateGroupEv,
    setMassReoFormDataEv, resetMassReoFormDataEv, onCreateListSetFiltersEv
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {
    $createdMissAddresses, $existingReos,
    $missAddrsCreatedStatus,
    $multiSelectedAddresses,
    $selectedAddress, resetCreatedREOEv, resetFiltersEv, resetSelectionEv
} from '../accountsREOSearchModel/index.js';
import {isId} from "../../utils/helpers.js";

const createRealEstateObjectsCompleteEv = combineEvents({events: {ev: submitCreateRealEstateObjectsEv, response: triggerSuccessOpsEv}, reset: RealEstateObjectsCreateGate.state})
const createReoGroupCompleteEv = combineEvents({events: {ev: onCreateGroupEv, response: triggerSuccessOpsEv}, reset: resetAfterCreationAttrs})
const editRealEstateObjectsCompleteEv = combineEvents({ev: submitEditRealEstateObjectsEv, response: triggerSuccessOpsEv})
const deleteRealEstateObjectsCompleteEv = combineEvents({ev: deleteRealEstateObjectsEv, response: triggerSuccessOpsEv})

$onCreateList.on(getRealEstateObjectsListFx.doneData, (_, data) => data)
    .reset(RealEstateObjectsCreateGate.close)

$realEstateObjectsList.on(getRealEstateObjectsListFx.doneData, (_, data) => data)
    .reset(RealEstateObjectsListGate.close)

$selectedRealEstateObjects.on(getRealEstateObjectsByIdFx.doneData, (_, data) => data)
    .reset([RealEstateObjectsEditGate.close, RealEstateObjectsViewGate.close])
// FIXME
$createdRealEstateObjectsId.on(createRealEstateObjectsCompleteEv, (_, {response}) => {
    const realEstateObjects = response.find(i => i.type === 'real_estate_objects')
    return realEstateObjects ? realEstateObjects?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editRealEstateObjectsStatus.on(editRealEstateObjectsCompleteEv, () => true)
    .reset(RealEstateObjectsEditGate.close)


$accontsList.on(getAccountByIdFx.doneData, (_, data) =>  data)

// $addressNoLimit.reset([RealEstateObjectsEditGate.close, RealEstateObjectsCreateGate.close, resetAfterCreationAttrs])

$massReoFormData.on(setMassReoFormDataEv, (_, data) => data)
    .reset([resetAfterCreationAttrs, resetMassReoFormDataEv])

$generatedReos.reset([resetAfterCreationAttrs, resetMassReoFormDataEv])

$massCreateStatus.on(createReoGroupCompleteEv, () => true)
    .reset([RealEstateObjectsCreateGate.close, resetAfterCreationAttrs])

sample({
    clock: [RealEstateObjectsEditGate.state, RealEstateObjectsViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: [getRealEstateObjectsByIdFx, getAccountByIdFx]
})

sample({
    clock: onCreateListSetFiltersEv,
    filter: ({town_id, street_id}) => isId(town_id) && isId(street_id),
    fn: ({town_id, street_id, page, limit, sortField, sortOrder}) => {
        return `town_id=${town_id}&street_id=${street_id}&page=${page}&limit=${limit}&sort_field=${sortField}&sort_order=${sortOrder}`
    },
    target: getRealEstateObjectsListFx
})

sample({
    clock: RealEstateObjectsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getRealEstateObjectsListFx
})

sample({
    source: RealEstateObjectsListGate.state,
    clock: [createRealEstateObjectsCompleteEv, editRealEstateObjectsCompleteEv, deleteRealEstateObjectsCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getRealEstateObjectsListFx
})


sample({
    source: {createdAddrs: $createdMissAddresses, selectedAddr: $selectedAddress},
    clock: submitCreateRealEstateObjectsEv,
    filter: ({createdAddrs, selectedAddr}) => !!(selectedAddr || createdAddrs.length > 0),
    fn: ({createdAddrs, selectedAddr}, payload) => {
        const actions = []
        let idCounter = -1
        let addrId = selectedAddr ? selectedAddr.id : -1
        const townId = selectedAddr?.town_id ?? createdAddrs[0]?.town_id

        payload.name_kz = payload.name_ru

        if (selectedAddr === false && createdAddrs.length > 0) {
            actions.push({operation: 'create', type: 'address', value: {...createdAddrs[0], id: idCounter}})
            addrId = idCounter
            idCounter--
        }

        if (payload.sector_id < 0) {
            const data = {adv_sector: payload.adv_sector, inspector_id: payload.inspector_id, town_id: townId}
            actions.push({operation: 'create', type: 'sector', value: {data, id: idCounter}})
            idCounter--
        }


        if (payload.real_estate_object_type_id === -1) {
            const reoType = {
                name_kz: payload?.real_estate_object_type_name_kz,
                name_ru: payload?.real_estate_object_type_name_ru,
            }
            actions.push({operation: 'create', type: 'real_estate_object_type', value: {...reoType, id: idCounter}})

            payload.real_estate_object_type_id = idCounter
            delete payload.real_estate_object_type_name_kz
            delete payload.real_estate_object_type_name_ru
            idCounter--
        }

        const newData = {
            ...payload,
            is_electricity_network_present: payload.is_electricity_network_present ? payload.is_electricity_network_present : false,
            is_elevator_present: payload.is_elevator_present ? payload.is_elevator_present : false,
            is_heat_network_present: payload.is_heat_network_present ? payload.is_heat_network_present : false,
            is_mailboxes_present: payload.is_mailboxes_present ? payload.is_mailboxes_present : false,
            address_id: addrId,
            cadastral_number: !payload.cadastral_number ? '' : payload.cadastral_number,
            sector_id: (payload.sector_id === ' ' || payload.sector_id === '-') ? null : payload.sector_id,
        }

        actions.push({operation: 'create', type: 'real_estate_objects', value: {...newData, id: idCounter}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {
        reoData: $massReoFormData,
        searchedAddresses: $multiSelectedAddresses,
        createdAddresses: $createdMissAddresses,
        existingReos: $existingReos
    },
    clock: $missAddrsCreatedStatus.updates,
    filter: ({reoData}, status) => status === true && reoData !== null,
    fn: ({reoData, searchedAddresses, createdAddresses, existingReos}) => {
        const result = []
        let idCounter = createdAddresses.length > 0 ? Math.min(...createdAddresses.map(a => a.id)) - 1 : -1

        for (const addr of createdAddresses) {
            result.push({
                ...reoData,
                address_id: addr.id,
                town_id: addr.town_id,
                town_distr_id: addr.town_distr_id ?? null,
                street_id: addr.street_id,
                house: addr.house,
                corpus: addr.corpus ?? null,
                id: idCounter
            })
            idCounter--
        }

        for (const addr of searchedAddresses) {
            if (existingReos.includes(addr.real_estate_object_id)) {
                result.push({
                    ...reoData,
                    address_id: addr.id,
                    town_id: addr.town_id,
                    town_distr_id: addr.town_distr_id ?? null,
                    street_id: addr.street_id,
                    house: addr.house,
                    corpus: addr.corpus ?? null,
                    existing: true
                })
            } else {
                result.push({
                    ...reoData,
                    address_id: addr.id,
                    town_id: addr.town_id,
                    town_distr_id: addr.town_distr_id ?? null,
                    street_id: addr.street_id,
                    house: addr.house,
                    corpus: addr.corpus ?? null,
                    id: idCounter
                })
                idCounter--
            }
        }
        return result
    },
    target: $generatedReos
})

sample({
    source: {generated: $generatedReos, newAddrs: $createdMissAddresses},
    clock: onCreateGroupEv,
    fn: ({generated, newAddrs}) => {
        const actions = []
        const genFiltered = generated.filter(item => !item.existing)
        const reoTypeId = genFiltered[0].real_estate_object_type_id > 0 ? genFiltered[0].real_estate_object_type_id : Math.min(...genFiltered.map(item => item.id)) - 1
        if (reoTypeId < 0) {
            actions.push({operation: 'create', type: 'real_estate_object_type', value: {name_kz: genFiltered[0].reo_type_name_kz, name_ru: genFiltered[0].reo_type_name_ru, id: reoTypeId}})
        }
        for (const address of newAddrs) {
            actions.push({operation: 'create', type: 'address', value: {...address}})
        }
        for (const reo of genFiltered) {
            const checked = {...reo}
            delete checked.town_id
            delete checked.town_distr_id
            delete checked.street_id
            delete checked.house
            delete checked.corpus
            if (reoTypeId < 0) {
                checked.real_estate_object_type_id = reoTypeId
                delete checked.reo_type_name_kz
                delete checked.reo_type_name_ru
            }
            if (!isId(checked.sector_id)) checked.sector_id = null
            actions.push({operation: 'create', type: 'real_estate_objects', value: {...checked}})
        }
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedRealEstateObjects,
    clock: submitEditRealEstateObjectsEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        payload.name_kz = payload.name_ru

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        if (changedFields.cadastral_number === null) changedFields.cadastral_number = ''
        if (changedFields.sector_id === ' ' || changedFields.sector_id === '-') changedFields.sector_id = null

        if (payload.real_estate_object_type_id === -1) {
            const reoType = {
                name_kz: payload?.real_estate_object_type_name_kz,
                name_ru: payload?.real_estate_object_type_name_ru,
            }
            actions.push({operation: 'create', type: 'real_estate_object_type', value: {...reoType, id: idCounter}})

            payload.real_estate_object_type_id = idCounter
            delete payload.real_estate_object_type_name_kz
            delete payload.real_estate_object_type_name_ru

            delete changedFields.real_estate_object_type_name_kz
            delete changedFields.real_estate_object_type_name_ru


            idCounter--
        }

        actions.push({operation: 'update', type: 'real_estate_objects', value: {object_id: current.id, ...changedFields}})


        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteRealEstateObjectsEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'real_estate_objects', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [RealEstateObjectsCreateGate.close, RealEstateObjectsViewGate.close, RealEstateObjectsEditGate.close],
    target: [resetMassReoFormDataEv, resetCreatedREOEv, resetSelectionEv, resetFiltersEv]
})

// sample({
//     clock: submitCreateRealEstateObjectsEv,
//     source: {created: $createdAddress, selected: $selectedAddress, entities: $selectedEntities},
//     fn: (data, payload) => {
//
//         // Логика описана в src/models/dictionaryTownsModel/handlers.js
//         const actions = []
//         let idCounter = -1
//
//         if (data.created !== null) {
//             if (data.created?.streetType !== undefined) {
//                 actions.push({operation: 'create', type: 'street_type', value: {...data.created.streetType, id: idCounter}})
//             }
//             idCounter--
//             if (data.created?.street !== undefined) {
//                 actions.push({operation: 'create', type: 'street', value: {...data.created.street, town_id: data.entities.town_id,  id: idCounter}})
//                 data.created.street_id = idCounter
//                 idCounter--
//             }
//
//             const createdData = {
//                 ...data.created,
//                 street_id: data.created.street_id ? data.created.street_id : idCounter,
//                 is_on_crossroad: false,
//                 actual_from:  data.created.actual_from = dayjs(
//                     data.created.actual_from ? data.created.actual_from : Date.now()
//                 ).startOf('day').format(),
//             }
//             delete createdData?.street
//
//             actions.push({operation: 'create', type: 'address', value: {...createdData, id: idCounter}})
//         }
//
//
//         if (payload.real_estate_object_type_id === -1) {
//             const reoType = {
//                 name_kz: payload?.real_estate_object_type_name_kz,
//                 name_ru: payload?.real_estate_object_type_name_ru,
//             }
//             actions.push({operation: 'create', type: 'real_estate_object_type', value: {...reoType, id: idCounter}})
//
//             payload.real_estate_object_type_id = idCounter
//             delete payload.real_estate_object_type_name_kz
//             delete payload.real_estate_object_type_name_ru
//             idCounter--
//         }
//
//         const newData = {
//             ...payload,
//             is_electricity_network_present: payload.is_electricity_network_present ? payload.is_electricity_network_present : false,
//             is_elevator_present: payload.is_elevator_present ? payload.is_elevator_present : false,
//             is_heat_network_present: payload.is_heat_network_present ? payload.is_heat_network_present : false,
//             is_mailboxes_present: payload.is_mailboxes_present ? payload.is_mailboxes_present : false,
//             address_id: data.selected !== null ? data.selected : idCounter,
//             cadastral_number: !payload.cadastral_number ? '' : payload.cadastral_number,
//             sector_id: (payload.sector_id === ' ' || payload.sector_id === '-') ? null : payload.sector_id,
//         }
//
//         actions.push({operation: 'create', type: 'real_estate_objects', value: {...newData, id: idCounter}})
//         idCounter--
//
//         return actions
//     },
//     target: submitGlobalUpdateEv
// })

// sample({
//     clock: onCreateGroupEv,
//     source: {dataNoLimit: $addressNoLimit, generateType: $generateType},
//     fn: (data, payload) => {
//
//
//         // Логика описана в src/models/dictionaryTownsModel/handlers.js
//         const actions = []
//         let idCounter = -1
//
//
//         // if (payload) {
//         let newItems = payload
//
//         const needTown = payload.filter(item => item.address_id < 0) || []
//
//         if (data.generateType === 'new_address') {
//             if (needTown.length > 0) {
//                 const createTown = needTown.map(item => {
//                     idCounter--
//                     newItems = newItems.map(el => {
//                         if (el.id === item.id) {
//                             return {
//                                 ...el,
//                                 address_id: idCounter
//                             }
//                         } else {
//                             return el
//                         }
//                     })
//                     return {
//                         town_id: item.town_id ? item.town_id : null,
//                         town_distr_id: item.town_distr_id ? item.town_distr_id : null,
//                         street_id: item.street_id ? item.street_id : null,
//                         house: item.house ? item.house : null,
//                         corpus: item.corpus ? item.corpus : null,
//                         is_actual: item.is_actual ? item.is_actual : true,
//                         actual_from: item.actual_from = dayjs(
//                             payload.actual_from ? item.actual_from : Date.now()
//                         ).startOf('day').format(),
//                         id: idCounter
//                     }
//                 })
//                 for (const newDist of Object.values(createTown)) {
//                     actions.push({operation: 'create', type: 'address', value: {...newDist  }})
//                 }
//             }
//             if (newItems.length > 0) {
//                 if (newItems[0].real_estate_object_type_id === -1) {
//                     const reoType = {
//                         name_kz: newItems[0].reo_type_name_kz,
//                         name_ru: newItems[0].reo_type_name_ru,
//                     }
//                     actions.push({operation: 'create', type: 'real_estate_object_type', value: {...reoType, id: idCounter}})
//                 }
//             }
//             const rawItems = newItems.map(item => {
//                 delete item.reo_type_name_kz
//                 delete item.reo_type_name_ru
//                 return {
//                     ...item,
//                     real_estate_object_type_id: item.real_estate_object_type_id === -1 ?  idCounter : item.real_estate_object_type_id
//                 }
//             })
//             idCounter--
//
//             const fileteredNewItems = rawItems.filter(item => item.address_id < 0).map(item => {
//                 delete item.town_distr_id
//                 delete item.corpus
//                 delete item.reo_type_name_kz
//                 delete item.reo_type_name_ru
//                 idCounter--
//                 return {
//                     ...item,
//                     id: idCounter
//                 }
//             })
//             for (const newDist of Object.values(fileteredNewItems)) {
//                 actions.push({operation: 'create', type: 'real_estate_objects', value: {...newDist}})
//             }
//         } else if (data.generateType === 'exist') {
//             if (newItems.length > 0) {
//
//                 if (newItems[0].real_estate_object_type_id === -1) {
//                     const reoType = {
//                         name_kz: newItems[0].reo_type_name_kz,
//                         name_ru: newItems[0].reo_type_name_ru,
//                     }
//                     actions.push({operation: 'create', type: 'real_estate_object_type', value: {...reoType, id: idCounter}})
//                 }
//             }
//             const existNewItems = newItems.map(item => {
//                 delete item.town_distr_id
//                 delete item.corpus
//                 delete item.house
//                 delete item.reo_type_name_kz
//                 delete item.reo_type_name_ru
//                 return {
//                     ...item,
//                     real_estate_object_type_id: item.real_estate_object_type_id === -1 ? idCounter : item.real_estate_object_type_id
//                 }
//             })
//             idCounter--
//
//             for (const newDist of Object.values(existNewItems)) {
//                 actions.push({operation: 'create', type: 'real_estate_objects', value: {...newDist, }})
//             }
//         } else {
//             if (newItems.length > 0) {
//                 if (newItems[0].real_estate_object_type_id === -1) {
//                     const reoType = {
//                         name_kz: newItems[0].reo_type_name_kz,
//                         name_ru: newItems[0].reo_type_name_ru,
//                     }
//                     actions.push({operation: 'create', type: 'real_estate_object_type', value: {...reoType, id: idCounter}})
//                 }
//             }
//             const existFilterNewItems = newItems.map(item => {
//                 delete item.town_distr_id
//                 delete item.corpus
//                 delete item.house
//                 delete item.real_estate_object_id
//                 delete item.reo_type_name_kz
//                 delete item.reo_type_name_ru
//                 return item
//             })
//             idCounter--
//             for (const newDist of Object.values(existFilterNewItems)) {
//                 actions.push({operation: 'create', type: 'real_estate_objects', value: {...newDist, }})
//             }
//         }
//
//         idCounter--
//         return actions
//     },
//     target: submitGlobalUpdateEv
// })
