import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Switch from 'antd/es/switch';
import Divider from 'antd/es/divider';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    AccountEditGate,
    $selectedAccount,
    // getAccountByIdFx,
    submitEditAccountEv,
    $editAccountStatus,
    $selectedAccountReEsObjFormatted,
    // $accountsProviderServiceList,
    // editExternalNumberEv,
    $editedExternalNumber
} from '../../../models/dictionaryAccountsModel/index.js';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import dayjs from 'dayjs';
import useFormChanged from '../../../hooks/useFormChanged.js';
import InputNumber from "antd/es/input-number";
// import {EditOutlined} from "@ant-design/icons";
// import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import ShowAddressCard from '../../../components/AddressReoSearchForms/ShowAddressCard.jsx';
import {binValidator} from "../../../utils/validateBIN.js";
// import ButtonGroup from "antd/es/button/button-group";
// import Tooltip from "antd/es/tooltip";

export default function EditAccount() {
    const {id} = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()

    // const [editMode, setEditMode] = useState(false)
    // const [editData, setEditData] = useState(null)

    const selectedAccount = useUnit($selectedAccount)
    const selectedReSObj = useUnit($selectedAccountReEsObjFormatted)

    // const onEditExternalNumber = useUnit(editExternalNumberEv)


    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const submit = useUnit(submitEditAccountEv)

    const status = useUnit($editAccountStatus)

    // const onOpenEdit = (row) => {
    //     const editData = {
    //         id: row.id,
    //         external_number: row.external_number,
    //     }
    //     setEditMode(true)
    //     setEditData(editData)
    // }
    // const onChangeValue = (v) => {
    //     setEditData({...editData, external_number: v})
    // }
    // const onSaveEdit = () => {
    //     onEditExternalNumber(editData)
    //     setEditMode(false)
    //     setEditData(null)
    // }

    const external = useUnit($editedExternalNumber)
    console.log('external', external)

    // const columns = [
    //     {
    //         title: t('Поставщик'),
    //         dataIndex: 'provider_id',
    //         align: 'center',
    //         key: 'valid',
    //         render: (id) => <MappedDictItem id={id} type={'providers'}/>,
    //     },
    //     {
    //         title: t('Услуга'),
    //         dataIndex: 'service_id',
    //         align: 'center',
    //         key: 'valid',
    //         render: (id) => <MappedDictItem id={id} type={'services'}/>,
    //     },
    //     {
    //         title: t('Номер договора'),
    //         dataIndex: 'external_number',
    //         align: 'center',
    //         key: 'valid',
    //         render: (id) => id ? id : '-',
    //     },
    //     {
    //         title: t('Действия'),
    //         width: '5%',
    //         align: 'center',
    //         dataIndex: 'id',
    //         key: 'actions',
    //         render: (id, row) => <ButtonGroup>
    //             <Tooltip title={t('Редактировать')} placement={'top'}>
    //                 <Button
    //                     onClick={() => onOpenEdit(row)}
    //                     icon={<EditOutlined/>}
    //                     type={'primary'}
    //                 />
    //             </Tooltip>
    //         </ButtonGroup>
    //     }
    // ]

    useEffect(() => {
        if (status) {
            navigate('/dictionaries/accounts')
        }
    }, [status]);

    useEffect(() => {
        if (selectedAccount) {
            const data = {
                ...selectedAccount,
                birthdate: selectedAccount?.birthdate ? dayjs(selectedAccount.birthdate) : null,
                created_at: selectedAccount?.created_at ? dayjs(selectedAccount.created_at) : null
            }
            accountForm.setFieldsValue(data)
        }
    }, [selectedAccount]);

    const [check, changedFields] = useFormChanged(
        selectedAccount,
        [
            'name_kz',
            'name_ru',
            'full_square',
            'living_square',
            'heated_square',
            'people_quantity',
            // 'iin', 'name_ru', 'flat', 'full_square', 'living_square', 'heated_square', 'people_quantity', 'room_count', 'floor', 'phone', 'mobile_phone', 'email', 'is_email_delivery', 'aesbyt_account', 'energy_account',
        ]
    )

    const onSubmit = (values) => {
        for (const [key, value] of Object.entries(values)) {
            if (key === 'birthdate' || key === 'created_at') {
                values[key] = dayjs(value).format('YYYY-MM-DD')
            }
        }
        values['name_kz'] = values['name_ru']
        return submit(values)
    }


    const [accountForm] = Form.useForm()

    // console.log('editData', editData)

    return <>
        <AccountEditGate id={+id}/>
        <ShowAddressCard formated={selectedReSObj} title={t('Объект недвижимости')}/>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Form form={accountForm}
              size={'small'}
              layout={'vertical'}
              onFinish={onSubmit}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row gutter={[8,8]}>
                <Col span={7}>
                    <Card size={'small'} style={{minHeight: '100%'}}>
                        <Row justify={'space-between'} gutter={[8, 0]}>
                            <Col span={24}>
                                <Form.Item name={'name_ru'} label={t('ФИО')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['name_ru']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item
                                    name={'iin'} label={t('ИИН')}
                                    validateStatus={changedFields['iin']}
                                    rules={[
                                        {required: false, message: t('Обязательное поле')},
                                        {validator: (rule, value) => binValidator(rule, value, false)}
                                    ]}
                                >
                                    <Input showCount maxLength={12}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'flat'} label={t('Квартира')}
                                           validateStatus={changedFields['flat']}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card size={'small'} style={{minHeight: '100%'}}>
                        <Row justify={'space-between'} gutter={[8, 0]}>
                            <Col span={8}>
                                <Form.Item name={'full_square'} label={t('Общая пл.')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['full_square']}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                                >
                                    <InputNumber style={{width: '100%'}} decimalSeparator={','}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'living_square'} label={t('Жилая пл.')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['living_square']}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                                >
                                    <InputNumber style={{width: '100%'}} decimalSeparator={','}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'heated_square'} label={t('Отапл. пл.')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['heated_square']}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                                >
                                    <InputNumber style={{width: '100%'}} decimalSeparator={','}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'people_quantity'} label={t('Кол-во прожив.')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                                >
                                    <InputNumber style={{width: '100%'}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'room_count'} label={t('Комнат')}
                                           validateStatus={changedFields['room_count']}>
                                    <InputNumber style={{width: '100%'}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'floor'} label={t('Этаж')}
                                           validateStatus={changedFields['floor']}>
                                    <InputNumber style={{width: '100%'}} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card size={'small'}>
                        <Row gutter={[8,0]}>
                            <Col span={12}>
                                <Form.Item name={'phone'} label={t('Тел.')}
                                           validateStatus={changedFields['phone']}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'mobile_phone'} label={t('Моб. тел.')}
                                           validateStatus={changedFields['mobile_phone']}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'email'} label={t('Email')}
                                           validateStatus={changedFields['email']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'is_email_delivery'} label={t('Email рассылка')}
                                           valuePropName={'checked'}
                                           validateStatus={changedFields['is_email_delivery']}
                                >
                                    <Switch/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card size={'small'}>
                        <Row justify={'space-between'} gutter={[16, 0]}>
                            <Col span={24}>
                                <Form.Item name={'aesbyt_account'} label={t('Код АЭСбыт')}
                                           validateStatus={changedFields['aesbyt_account']}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={'energy_account'} label={t('Код энергокомпании')}
                                           validateStatus={changedFields['energy_account']}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/*<Col span={10}>*/}
            {/*    <Card title={t('Табличка')} size={'small'}>*/}
            {/*        {editMode && <div>*/}
            {/*            <span>Номер договора</span>*/}
            {/*            <br/>*/}
            {/*            <Row justify={"space-between"}>*/}
            {/*                <Input*/}
            {/*                    style={{width: '50%'}}*/}
            {/*                    value={editData.external_number}*/}
            {/*                    onChange={(e) => onChangeValue(e.target.value)}*/}
            {/*                />*/}
            {/*                <Button type={"primary"} onClick={onSaveEdit}>*/}
            {/*                    Сохранить*/}
            {/*                </Button>*/}
            {/*            </Row>*/}
            {/*        </div>}*/}
            {/*        <br/>*/}
            {/*        <Table*/}
            {/*            dataSource={data}*/}
            {/*            columns={columns}*/}
            {/*        />*/}
            {/*    </Card>*/}
            {/*</Col>*/}
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    accountForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            accountForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить')}
                </Button>
            </Space>
        </Row>
    </>
}
