import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $filteredServAccs, $provServAccsFilters, $provSrvsSelOpts,
    $selectedSrvAccs, applySrvAccForTarifsFiltersEv, getProviderSrvAccsForTarifsFx,
    resetSrvAccsForTarifsEv,
    selectMultipleSrvAccsForTarifsEv,
    setSrvAccForTarifsFilterEv
} from '../../../models/dictionaryProvidersModel/index.js';
import Row from 'antd/es/row';
import Table from 'antd/es/table';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Col from 'antd/es/col';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import {filterOption} from '../../../utils/selectUtils.jsx';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import {isId} from '../../../utils/helpers.js';
import AccountSearchForm from "../../../components/AddressReoSearchForms/AccountSearchForm.jsx";

export default function SrvAccsSearchForm() {
    const {t} = useTranslation()

    const loading = useUnit(getProviderSrvAccsForTarifsFx.pending)
    const srvAccsList = useUnit($filteredServAccs)
    const filters = useUnit($provServAccsFilters)
    const setFilters = useUnit(setSrvAccForTarifsFilterEv)
    const applyFilters = useUnit(applySrvAccForTarifsFiltersEv)
    const resetFilters = useUnit(resetSrvAccsForTarifsEv)

    const selectedSrvAccs = useUnit($selectedSrvAccs)
    const setSelectedSrvAccs = useUnit(selectMultipleSrvAccsForTarifsEv)

    const serviceOpts = useUnit($provSrvsSelOpts)

    const [srvAccsForm] = Form.useForm()

    const onReset = () => {
        srvAccsForm.resetFields()
        resetFilters()
    }

    const columns = [
        {
            title: t('Номер ЛС'),
            width: '10%',
            dataIndex: 'account_id',
            render: (id) => <MappedDictItem id={id} type={'accounts'}/>
        },
        {
            title: t('ФИО'),
            dataIndex: 'name_ru',
            render: (name, r) => localStorage.getItem('lang') === 'kz' && r?.name_kz ? r?.name_kz : name
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            width: '10%',
            render: (id) => <MappedDictItem id={id} type={'town'}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            render: (id) => <MappedDictItem id={id} type={'street'}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
        },
    ]

    const account_in = Form.useWatch('account_in', srvAccsForm)
    const iin_name = Form.useWatch('iin_name', srvAccsForm)

    const onValuesChange = (cur) => {
        if (Object.hasOwn(cur, 'town_id')) {
            srvAccsForm.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus', 'flat_in'])
            const vals = srvAccsForm.getFieldsValue()
            setFilters(vals)
        } else {
            setFilters(cur)
        }
    }

    return <>
        <Form form={srvAccsForm}
              size={'small'}
              layout={'vertical'}
              onValuesChange={onValuesChange}
        >
            <Row gutter={[16, 0]}>
                <Col span={10}>
                    <Form.Item name={'provider_service_id'} label={t('Услуга')}>
                        <Select options={serviceOpts} allowClear showSearch
                                filterOption={filterOption}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <AccountSearchForm extForm={srvAccsForm} extButtons onlyItems itemsAsRow/>
                </Col>
                <Col>
                    <ButtonGroup size={'small'}>
                        <Button type={'primary'}
                                loading={loading}
                                disabled={!(isId(filters.street_id) || account_in || iin_name)}
                                onClick={applyFilters}>{t('Найти ЛС')}</Button>
                        <Button onClick={onReset}>{t('Очистить поиск')}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
        <Row style={{marginTop: 8}}>
            <Table className={'table-container'}
                   dataSource={srvAccsList}
                   pagination={paginationConfig}
                   columns={columns}
                   rowKey={'id'}
                   size={'small'}
                   bordered
                   rowSelection={{
                       onChange: (ids) => setSelectedSrvAccs(ids),
                       selectedRowKeys: selectedSrvAccs
                   }}
            />
        </Row>
    </>
}
