import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useStoreMap} from 'effector-react';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import dayjs from 'dayjs';
import queryString from 'query-string';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Collapse from 'antd/es/collapse';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Switch from 'antd/es/switch';
import DatePicker from 'antd/es/date-picker';
import Divider from 'antd/es/divider';
import {filterOption} from '../../../utils/selectUtils.jsx';
import AccountSearchForm from '../../../components/AddressReoSearchForms/AccountSearchForm.jsx';

export default function AccountsListFilters() {
    const {t} = useTranslation()
    const [search, setSearch] = useSearchParams()
    const [form] = Form.useForm()
    const townId = Form.useWatch('town_id', form)

    const selOpts = useStoreMap($dictsSelects, sels => ({
        towns: sels.towns,
        districts: sels.districts,
        streets: sels.streets,
        inspectors: sels.inspector,
        sectors: sels.sectors,
        reoTypes: sels.real_estate_object_type
    }))


    useEffect(() => {
        if (search?.size > 0) {
            const parsed = queryString.parse(search.toString(), {
                parseBooleans: true,
                parseNumbers: true
            })
            for (const [key, value] of Object.entries(parsed)) {
                if (key.split('_').at(-1) === 'from' && typeof value === 'string' && dayjs(value).isValid()) {
                    const actualKey = key.split('_').slice(0, -1).join('_')
                    parsed[actualKey] = [dayjs(value), dayjs(parsed[`${actualKey}_to`])]
                    delete parsed[key]
                }
                if (key === 'inspector') {
                    parsed[key] = Array.isArray(value) ? value.map(item => item === null ? -1 : item) : [value === null ? -1 : value]
                }
            }

            form.setFieldsValue(parsed)
        }
    }, [search])

    const onSubmit = (values) => {
        setSearch(prepareValues(values))
    }

    const onReset = () => {
        form.resetFields()
        setSearch()
    }

    const onValuesChange = (cur, _all) => {
        if (Object.hasOwn(cur, 'town_id')) {
            form.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus', 'flat_in'])
        }
    }


    return <Card size={'small'}>
        <Form layout={'vertical'} form={form} onFinish={onSubmit} size={'small'} onValuesChange={onValuesChange}>
            <AccountSearchForm
                extForm={form} extButtons extOnValChange={onValuesChange} onlyItems itemsAsRow
            />
            <Collapse>
                <CollapsePanel key={'add'} header={t('Дополнительные фильтры')}>
                    <Row gutter={[16, 0]}>
                        {/*<Col span={4}>*/}
                        {/*    <Form.Item name={'name_ru'} label={t('ФИО')}>*/}
                        {/*        <Input/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={4}>
                            <Form.Item name={'phone'} label={t('Телефон')}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'mobile_phone'} label={t('Моб. Телефон')}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'email'} label={t('Email')}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name={'is_email_delivery'} label={t('Email Рассылка')}
                                       valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'created_at'} label={t('Дата регистрации')}>
                                <DatePicker.RangePicker/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 0]}>
                        <Col span={4}>
                            <Form.Item name={'aesbyt_account'} label={t('Код АЭСбыт')}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'energy_account'} label={t('Код энергокомпании')}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 0]}>
                        <Col span={8}>
                            <p style={{color: 'rgba(0, 0, 0, 0.45)', marginTop: 0, marginBottom: 4}}>{t('Общая площадь')}</p>
                            <Row gutter={[16, 0]}>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false}
                                               name={'full_square_from'} label={t('От')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false}
                                               name={'full_square_to'} label={t('До')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <p style={{color: 'rgba(0, 0, 0, 0.45)', marginTop: 0, marginBottom: 4}}>{t('Жилая площадь')}</p>
                            <Row gutter={[16, 0]}>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false}
                                               name={'living_square_from'} label={t('От')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false}
                                               name={'living_square_to'} label={t('До')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <p style={{color: 'rgba(0, 0, 0, 0.45)', marginTop: 0, marginBottom: 4}}>{t('Отапливаемая площадь')}</p>
                            <Row gutter={[16, 0]}>
                                <Col span={8}>
                                    <Form.Item colon={false} name={'heated_square_from'}
                                               layout={'horizontal'} label={t('От')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item colon={false} name={'heated_square_to'}
                                               label={t('До')} layout={'horizontal'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <p style={{color: 'rgba(0, 0, 0, 0.45)', marginTop: 0, marginBottom: 4}}>{t('Кол-во проживающих')}</p>
                            <Row gutter={[16, 0]}>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false}
                                               name={'people_quantity_from'} label={t('От')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false}
                                               name={'people_quantity_to'} label={t('До')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <p style={{color: 'rgba(0, 0, 0, 0.45)', marginTop: 0, marginBottom: 4}}>{t('Кол-во комнат')}</p>
                            <Row gutter={[16, 0]}>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false}
                                               name={'room_count_from'} label={t('От')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false}
                                               name={'room_count_to'} label={t('До')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <p style={{color: 'rgba(0, 0, 0, 0.45)', marginTop: 0, marginBottom: 4}}>{t('Этаж')}</p>
                            <Row gutter={[16, 0]}>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false}
                                               name={'floor_from'} label={t('От')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item layout={'horizontal'} colon={false} name={'floor_to'}
                                               label={t('До')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 0]}>
                        <Col span={4}>
                            <Form.Item name={'sector'} label={t('Участок')} tooltip={townId ? null : t('Выберите город')}>
                                <Select disabled={!townId} mode={'multiple'}
                                        options={townId ? selOpts.sectors.filter(i => i.town_id === townId) : []}
                                        allowClear showSearch filterOption={filterOption}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'inspector'} label={t('Контролер')}>
                                <Select mode={'multiple'}
                                        options={townId ? selOpts.inspectors.filter(i => i.town_id === townId) : selOpts.inspectors}
                                        allowClear showSearch filterOption={filterOption}/>
                            </Form.Item>
                        </Col>
                        {/*<Col span={4}>*/}
                        {/*    <Form.Item name={'real_estate_object_type'} label={'Тип ОН'}>*/}
                        {/*        <Select mode={'multiple'}*/}
                        {/*                options={selOpts.reoTypes}*/}
                        {/*                allowClear showSearch filterOption={filterOption}/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={4}>
                            <Form.Item name={'real_estate_object'} label={'ЖК'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </CollapsePanel>
            </Collapse>
        </Form>
        <Row style={{marginTop: 16}}>
            <Col>
            <ButtonGroup>
                <Button type={'primary'} size={'small'} onClick={() => form.submit()}>{t('Применить')}</Button>
                <Button size={'small'} onClick={onReset}>{t('Сбросить')}</Button>
            </ButtonGroup>
                </Col>
        </Row>
    </Card>
}

function prepareValues(values) {
    for (const [key, value] of Object.entries(values)) {
        if (value === undefined || value === ' ' || value === '') {
            delete values[key]
            continue
        }

        if (!value && value !== false) {
            if (
                key !== 'town_id' && key !== 'sector_id'
                && key !== 'street_id' && key !== 'street_type_id' && key !== 'town_distr_id'
                && key !== 'algorithm_id'
            ) {
                delete values[key]
                continue
            }
        }

        if (key.split('_').at(-1) === 'at' && Array.isArray(value) && value.every(v => dayjs(v).isValid())) {
            values[`${key}_from`] = dayjs(value[0]).format('YYYY-MM-DD')
            values[`${key}_to`] = dayjs(value[1]).format('YYYY-MM-DD')
            delete values[key]
        }
        if (key.split('_').includes('date') && !Array.isArray(value)) {
            values[key] = dayjs(value).format('YYYY-MM-DD')
        }
        if (key === 'name_ru') {
            values[key] = value
            values['name_kz'] = value
        }
    }
    return values
}
