import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {useStoreMap, useUnit} from 'effector-react';
import {$massSrvTarifsOpts} from '../../../../models/dictionaryCorrectionModel/index.js';
import {$dictsSelects} from '../../../../models/infoModel/index.js';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Switch from 'antd/es/switch';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import {optionRender, filterOption} from '../../../../utils/selectUtils.jsx';

export default function TariffMass({form, disabled, fieldPrefix}) {
    const {t} = useTranslation()

    const selectedTarif = Form.useWatch([...fieldPrefix, 'tarif_id'], form)
    const changeTarif = Form.useWatch([...fieldPrefix, 'change_tarif'], form)
    const changeTarifDate = Form.useWatch([...fieldPrefix, 'change_tarif_date_from'], form)
    const massSrvTarifOpts = useUnit($massSrvTarifsOpts)
    const tarOptsFilt = useMemo(() => {
        return [...massSrvTarifOpts, {label: t('Создать'), value: -1, emoji: true}]
    }, [massSrvTarifOpts])

    const srvUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)

    return <Row gutter={[16, 0]}>
        <Col span={8}>
            <Form.Item name={[...fieldPrefix, 'change_tarif']} label={t('Изменить тариф')} layout={'horizontal'} colon={false} valuePropName={'checked'}>
                <Switch />
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'tarif_id']} label={t('Тариф')}
                       rules={changeTarif ? [{required: true, message: t('Обязательное поле')}] : []}
            >
                <Select options={tarOptsFilt}
                        disabled={disabled || !changeTarif}
                        style={{width: '100%'}}
                        optionRender={optionRender}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
            {selectedTarif === -1 && <>
                <Form.Item name={[...fieldPrefix, 'tarif_value']} label={t('Значение тарифа')}
                           rules={changeTarif && selectedTarif < 0 ? [{required: true, message: t('Обязательное поле')}] : []}
                >
                    <Input style={{width: '100%'}} disabled={disabled || !changeTarif}/>
                </Form.Item>
                <Form.Item name={[...fieldPrefix, 'tarif_service_unit_id']} label={t('Единица измерения')}
                           rules={changeTarif && selectedTarif < 0 ? [{required: true, message: t('Обязательное поле')}] : []}
                >
                    <Select options={srvUnitOpts}
                            disabled={disabled || !changeTarif}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}/>
                </Form.Item>
            </>}
        </Col>
        <Col span={8}>
            <Form.Item name={[...fieldPrefix, 'change_tarif_date_from']} label={t('Изменить дату')} layout={'horizontal'} colon={false} valuePropName={'checked'}>
                <Switch />
            </Form.Item>
            <Form.Item name={[...fieldPrefix, 'tarif_date_from']} label={t('Дата начала действия тарифа')}
                       rules={changeTarifDate ? [{required: true, message: t('Обязательное поле')}] : []}
            >
                <DatePicker style={{width: '100%'}} disabled={!changeTarifDate}/>
            </Form.Item>
        </Col>
    </Row>
}