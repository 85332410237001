import TestCorrA from "./tabComponents/TestCorrA.jsx";
import Tabs from "antd/es/tabs";
import TestCorr from "./tabComponents/TestCorr.jsx";

export default function TestProvider() {
    const items = [
        {
            key: '1',
            label: 'Массовый по группе ЛС',
            children: <TestCorr/>
        },
        {
            key: '2',
            label: 'По одному ЛС',
            children: <TestCorrA/>
        },
    ];
    return <Tabs defaultActiveKey="1" items={items}/>;
}
