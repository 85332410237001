import {createStore} from "effector";

export const $paymentData = createStore({data: [], count: 0})
export const $paymentAppliesData = createStore({data: [], count: 0})
export const $paymentExportData = createStore({data: [], count: 0})

export const $paymentElementsData = createStore({data: [], count: 0})

export const $paymentSingle = createStore({data: [], count: 0})
export const $errorSingle = createStore({data: [], count: 0})

export const $paymentConfirmModal = createStore(false)
export const $paymentConfirmModalData = createStore(null)

export const $paymentBankApiAuditModal = createStore(false)
export const $paymentBankApiAuditModalData = createStore({type: null, data: null})

export const $paymentExportModal = createStore(false)
export const $paymentExportModalData = createStore(null)


export const $paymentElementsSt = createStore({data: [], count: 0})
export const $exportedPaymentsSt = createStore({data: [], count: 0})


export const $bankApiAuditSt = createStore({data: [], count: 0})
export const $bankApiAuditFiltersSt = createStore({page: 1, limit: 10})
