import {useTranslation} from 'react-i18next';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Popconfirm} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import {
    $epdTemplateFieldList,
    EPDTemplateFieldListGate,
    deleteEPDTemplateFieldEv,
    getEPDTemplateFieldListFx
} from "../../models/dictionaryEpdTemplateFieldModel/index.js";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {Link} from 'react-router-dom';
import {$epdCellTypeDict, $epdFieldNameDict} from "../../models/infoModel/index.js";


export default function DictionaryEpdTemplateFieldList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($epdTemplateFieldList)
    const loading = useUnit(getEPDTemplateFieldListFx.pending)
    const onDelete = useUnit(deleteEPDTemplateFieldEv)

    const dictCell = useUnit($epdCellTypeDict)
    const dictNameField = useUnit($epdFieldNameDict)

    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.epd-template-fields`)


    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t('Шаблон'),
            dataIndex: 'template_id',
            sorter: true,
            key: 'template_id',
            render: (template_id, record) => <MappedDictItem id={template_id} type={'templates'}/>
        },
        {
            title: t('Название поля'),
            dataIndex: 'field_name',
            sorter: true,
            key: 'field_name',
            render: (name, record) => dictNameField[name] ? dictNameField[name] : name
            // render: (name, record) => <Link to={`/dictionaries/service_epd_accrual_field/view/${record.id}`}>{`${record.firstname}`}</Link>
        },

        {
            title: t('Тип ячейки'),
            dataIndex: 'cell_type',
            sorter: true,
            key: 'cell_type',
            render: (name, record) => dictCell[name] ? dictCell[name] : name
        },


        {
            title: t('Номер столбца'),
            dataIndex: 'column_number',
            sorter: true,
            key: 'column_number',
            // render: (name, record) => <Link to={`/dictionaries/service_epd_accrual_field/view/${record.id}`}>{`${record.firstname}`}</Link>
        },

        {
            title: t('Размер шрифта'),
            dataIndex: 'font_size',
            sorter: true,
            key: 'font_size',
            // render: (name, record) => <Link to={`/dictionaries/service_epd_accrual_field/view/${record.id}`}>{`${record.firstname}`}</Link>
        },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/epd-template-fields/edit/${id}`}><Button icon={<EditOutlined />} type={'primary'} /></Link>
                </Tooltip>
                <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/epd-template-fields/view/${id}`}><Button icon={<EyeOutlined />} /></Link>
                </Tooltip>
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => onDelete(id)}
                            placement={'left'}
                >
                <Tooltip title={t('Удалить')} placement={'top'}>
                    <Button icon={<DeleteOutlined />} danger/>
                </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код/Название'), type: 'input'},
        // {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns', multiple: true},
        // {name: 'street_type_id', label: t('Тип'), type: 'select', dict: 'streetTypes', multiple: true},
        // {name: 'name', label: t('Название'), type: 'input'},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]



    return <>
        <EPDTemplateFieldListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
        />

        {/*<Table dataSource={[...data]}*/}
        {/*       columns={columns}*/}
        {/*       loading={loading}*/}
        {/*       bordered*/}
        {/*       onChange={({current, pageSize}) => onChangePagination(current, pageSize)}*/}
        {/*       pagination={{*/}
        {/*           ...paginationConfig,*/}
        {/*           pageSize: parseInt(limit),*/}
        {/*           current: parseInt(curPage),*/}
        {/*           total: count*/}
        {/*       }}*/}
        {/*       rowClassName={'table-row'}*/}
        {/*       className={'table-container'}*/}
        {/*/>*/}
    </>
}
