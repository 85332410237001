import {useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import Card from 'antd/es/card';
import Table from 'antd/es/table';
import Divider from 'antd/es/divider';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import AddressREOSearchForm from '../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx';
import {$foundReos, searchMultiReosEv, $multiSelectedReos, changeMultiSelectedREOsEv, REOSearchGate} from '../../../models/accountsREOSearchModel/index.js';

export default function ProviderSrvREOSearchForm({fromProvider}) {
    const {t} = useTranslation()


    const data = useUnit($foundReos)

    const selectedMulti = useUnit($multiSelectedReos)
    const setMultiple = useUnit(changeMultiSelectedREOsEv)

    const applyFilters = useUnit(searchMultiReosEv)

    const callApplyFilters = (values) => {
        console.log('ProviderSrvREOSearchForm callApplyFilters', values)
        applyFilters(values)
    }

    // const tableParams = useUnit($tableParams)
    // const setTable = useUnit(setTableParamsEv)
    //
    // const onChangeTable = ({current, pageSize}, filters, {field, order}) => {
    //     const payload = []
    //     if (current !== selectedEntities.page || pageSize !== selectedEntities.limit) {
    //         payload.push({key: 'page', value: current})
    //         payload.push({key: 'limit', value: pageSize})
    //     }
    //
    //     if (field !== selectedEntities.sort_field || order !== selectedEntities.sort_order) {
    //         if (!order) {
    //             payload.push({key: 'sort_field', value: null})
    //             payload.push({key: 'sort_order', value: null})
    //         } else {
    //             payload.push({key: 'sort_field', value: field})
    //             payload.push({
    //                 key: 'sort_order',
    //                 value: order === 'ascend' ? 'asc' : 'desc'
    //             })
    //         }
    //     }
    //     setTable(payload)
    // }

    const columns = [
        // {
        //     title: t('Код ОН'),
        //     dataIndex: 'id',
        //     width: '5%',
        //     key: 'id',
        // },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            key: 'town_id',
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'town'}/>
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            key: 'town_distr_id',
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'town_distr'}/>
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            key: 'street_id',
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'street'}/>
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
            key: 'house'
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            key: 'corpus'
        }
    ]

    return <Card size={'small'} title={t('Объект недвижимости')}>
        <REOSearchGate/>
        <AddressREOSearchForm onSearch={callApplyFilters} houseNonRequired/>
        <Divider/>
        <Table dataSource={data}
               columns={columns}
               bordered
               pagination={paginationConfig}
               // pagination={fromProvider
               //     ? paginationConfig
               //     : {
               //         ...paginationConfig,
               //         total: count,
               //         pageSize: tableParams.limit,
               //         current: tableParams.page
               //     }}
               // onChange={fromProvider ? null : onChangeTable}
               rowKey={'id'}
               rowSelection={fromProvider ? {
                   preserveSelectedRowKeys: true,
                   onChange: (ids) => setMultiple(ids),
                   hideSelectAll: true,
                   selectedRowKeys: selectedMulti
               } : false}
               size={'small'}
        />
    </Card>
}
