import Collapse from 'antd/es/collapse';
import MenuItemCard from './components/MenuItemCard.jsx';
import {useTranslation} from 'react-i18next';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import TESTPAGENotification from '../../components/TESTPAGENotification/TESTPAGENotification.jsx';
import {Link} from "react-router-dom";
import Typography from "antd/es/typography";

export default function Home() {
    const {t} = useTranslation()

    const dictsItems = [
        {
            title: t('Адресный регистр'),
            dicts: [
                {
                    label: t('Населенные пункты'),
                    link: '/dictionaries/town'
                },
                {
                    label: t('Районы'),
                    link: '/dictionaries/town_distr'
                },
                // {
                //     label: t('Налоговые отделения'),
                //     link: '/dictionaries/tax_branch'
                // },
                {
                    label: t('Типы улиц'),
                    link: '/dictionaries/street_type'
                },
                {
                    label: t('Улицы'),
                    link: '/dictionaries/street'
                },
                {
                    label: t('Адреса'),
                    link: '/dictionaries/address'
                },
                {
                    label: t('Тип объектов недвижимости'),
                    link: '/dictionaries/real-estate-object-type',
                },
            ],
            tasks: [
                    {
                        label: t('Назначение адресов на район'),
                        link: '/dictionaries/swap-address'
                    },
                // {
                //     label: t('Переименование улиц'),
                //     link: '/tasks/streets-rename'
                // }
            ]
        },
        {
            title: t('Банковская информация'),
            dicts: [
                {
                    label: t('Банки'),
                    link: '/dictionaries/banks'
                },
                {
                    label: t('Типы расчетных счетов'),
                    link: '/dictionaries/bank-account-type'
                },
                {
                    label: t('КБе'),
                    link: '/dictionaries/kbe'
                },
                {
                    label: t('КБК'),
                    link: '/dictionaries/kbk'
                },
                {
                    label: t('КНП'),
                    link: '/dictionaries/knp'
                },
                {
                    label: t('Комиссия банка'),
                    link: '/dictionaries/bank-commission',
                },
            ],
            tasks: []
        },
        {
            title: t('ЛС и ОН'),
            dicts: [
                {
                    label: t('ЛС'),
                    link: '/dictionaries/accounts'
                },
                {
                    label: t('Объекты недвижимости'),
                    link: '/dictionaries/real-estate-objects'
                },
            ],
            tasks: [
                {
                    label: t('Импорт ЛС'),
                    link: '/upload-accounts',
                },
                {
                    label: t('Импорт обновления ЛС'),
                    link: '/upload-update-accounts',
                },
                {
                    label: t('Импорт ОН'),
                    link: '/upload-real-estate-objects',
                },
            ]
        },
        {
            title: t('Контролеры и Участки'),
            dicts: [
                {
                    label: t('Участки'),
                    link: '/dictionaries/sector'
                },
                {
                    label: t('Старшие контролеры'),
                    link: '/dictionaries/inspectors'
                },
                {
                    label: t('Контролеры'),
                    link: '/dictionaries/chief-inspectors'
                }
            ],
            tasks: [
                {
                    label: t('Назначение контролера на участки'),
                    link: '/dictionaries/swap-sector',
                },
                {
                    label: t('Назначение ОН на участок'),
                    link: '/dictionaries/swap-real-estate-object',
                },
            ]
        },
        {
            title: t('Поставщики, Услуги и Тарифы'),
            dicts: [
                {
                    label: t('Поставщики'),
                    link: '/dictionaries/providers'
                },
                {
                    label: t('Услуги'),
                    link: '/dictionaries/services'
                },
                {
                    label: t('Виды платежей'),
                    link: '/dictionaries/service-payment-type'
                },
                {
                    label: t('Виды деятельности'),
                    link: '/dictionaries/activity-type'
                },
                // {
                //     label: t('Сектора экономики'),
                //     link: '/dictionaries/economy-sector'
                // },
                {
                    label: t('Единицы измерения услуг'),
                    link: '/dictionaries/service-units'
                },
                {
                    label: t('Типы расчета'),
                    link: '/dictionaries/calc-type'
                },
                {
                    label: t('Категории льгот'),
                    link: '/dictionaries/preference-category'
                },
                {
                    label: t('Алгоритмы расчетов'),
                    link: '/dictionaries/algorithm'
                }
            ],
            tasks: [
                    {
                        label: t('Загрузка № договоров с потребителями'),
                        link: '/upload-agreements',
                    },
            ]
        },
    ]

    const adminItems = [
        {
            title: t('Пользователи'),
            dicts: [
                {
                    label: t('Пользователи'),
                    link: '/users'
                },
                {
                    label: t('Роли'),
                    link: '/roles'
                },
                {
                    label: t('Разрешения'),
                    link: '/permissions'
                },
                {
                    label: t('Должности'),
                    link: '/titles'
                },
            ],
            tasks: []
        },
        {
            title: t('Банки'),
            dicts: [
                {
                    label: t('Настройка API банка'),
                    link: '/dictionaries/bank-api-address'
                },
            ],
            tasks: []
        },
        {
            title: t('ЕПД'),
            dicts: [
                {
                    label: t('Поля шаблонов для ЕПД'),
                    link: '/dictionaries/epd-template-fields'
                },
                {
                    label: t('Редактирование столбцов таблицы Услуг'),
                    link: '/services-columns'
                },
            ],
            tasks: []
        },
    ]

    const tasksItems = [
        {
            title: t('ЕПД'),
            dicts: [],
            tasks: [
                {
                    label: t('Email рассылки'),
                    link: '/tasks/email-per-accounts'
                },
                {
                    label: t('Формирование ЕПД'),
                    link: '/tasks/all',
                },
                {
                    label: t('Одобрение Email-рассылок'),
                    link: '/approve-emails'
                },
            ],
        },
        {
            title: t('Начисления'),
            dicts: [
                {
                    label: t('Начисления'),
                    link: '/accruals',
                },
                {
                    label: t('Тестовая страница по сальдо'),
                    link: '/saldo',
                },
            ],
            tasks: [
                {
                    label: t('Загрузка начислений'),
                    link: '/upload'
                },
                {
                    label: t('Переход месяца'),
                    link: '/close-month',
                },
                {
                    label: t('Тестовая страница для запуска пересчетов'),
                    link: '/launch-recalculations',
                },
                // {
                //     label: t('Управление тарифами'),
                //     link: '/tariff-control'
                // },
                // {
                //     label: t('Корректировки'),
                //     link: '/corrections'
                // },
            ],
        },
        {
            title: t('Оплаты'),
            dicts: [],
            tasks: [

            ],
        },
        {
            title: t('Отчеты'),
            dicts: [],
            tasks: [],
        },
    ]

    const notificationsItems = [
        {
            title: t('Уведомления'),
            dicts: [],
            tasks: [],
        },
    ]

    const items = [
        {
            key: 'dicts',
            label: <Typography.Title level={4} style={{margin: '0'}}>{t('Справочники')}</Typography.Title>,
            children: <Row gutter={[24, 24]}>
                {dictsItems.map((i,k) => <Col span={8} key={k}>
                    <MenuItemCard title={i.title} dicts={i.dicts} tasks={i.tasks}/>
                </Col>)}
            </Row>
        },
        {
            key: 'tasks',
            label: <Typography.Title level={4} style={{margin: '0'}}>{t('Задачи')}</Typography.Title>,
            children: <Row gutter={[24, 24]}>
                {tasksItems.map((i,k) => <Col span={8} key={k}>
                    <MenuItemCard title={i.title} dicts={i.dicts} tasks={i.tasks}/>
                </Col>)}
            </Row>
        },
        {
            key: 'admin',
            label: <Typography.Title level={4} style={{margin: '0'}}>{t('Администрирование')}</Typography.Title>,
            children: <Row gutter={[24, 24]}>
                {adminItems.map((i,k) => <Col span={8} key={k}>
                    <MenuItemCard title={i.title} dicts={i.dicts} tasks={i.tasks}/>
                </Col>)}
            </Row>
        },
        {
            key: 'notifications',
            label: <Typography.Title level={4} style={{margin: '0'}}>{t('Уведомления')}</Typography.Title>,
            children: <Row gutter={[24, 24]}>
                {notificationsItems.map((i,k) => <Col span={8} key={k}>
                    <MenuItemCard title={i.title} dicts={i.dicts} tasks={i.tasks}/>
                </Col>)}
            </Row>
        }
    ]

    return <>
        <Collapse size={'small'} items={items} defaultActiveKey={['dicts', 'tasks']} />
        {/*<TESTPAGENotification />*/}
    </>
}
