import {$WsSt, sendMsgEv} from '../../models/webSocketModel/index.js';
import {useWebSocket} from '../../api/WebSocketProvider.jsx';
import {useUnit} from 'effector-react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Menu} from 'antd';
import {Link, useLocation} from 'react-router-dom';
import HomeTwoTone from "@ant-design/icons/es/icons/HomeTwoTone";

export default function NavMenu() {
    const {t} = useTranslation()
    const {pathname} = useLocation()

    const {sendMessage} = useWebSocket()
    const wsStatus = useUnit($WsSt)
    const sub = useUnit(sendMsgEv)

    useEffect(() => {
        const payload = {
            sendMessage: sendMessage,
            payload: {
                object_type: "close_month",
                subscribe: true
            }
        }
        sub(payload)
        return () => sub({
            sendMessage: sendMessage,
            payload: {
                object_type: "close_month",
                subscribe: false
            }
        })
    }, [wsStatus]);

    const items = window.location.href.includes('localhost') || window.location.href.includes('dev') ?
        [
            //FIXME Dev items
            {
                key: '/',
                label: <Link to={'/'}><HomeTwoTone /></Link>
            },
            {
                key: '/accounts_and_real_estate_main',
                label: t('ЛС и ОН'),
                children: [
                    {
                        key: '/dictionaries/accounts',
                        label: <Link to={'/dictionaries/accounts'}>Лицевые счета</Link>
                    },
                    {
                        key: '/upload-accounts',
                        label: <Link to={'/upload-accounts'}>Импорт ЛС</Link>
                    },
                    {
                        key: '/upload-update-accounts',
                        label: <Link to={'/upload-update-accounts'}>Импорт Обновлений ЛС</Link>
                    },
                    // {
                    //     key: '/dictionaries/consumer-category',
                    //     label: <Link to={'/dictionaries/consumer-category'}>{t('Категории потребителей')}</Link>
                    // },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/real-estate-objects',
                        label: <Link
                            to={'/dictionaries/real-estate-objects'}>{t('Объекты недвижимости')}</Link>
                    },
                    {
                        key: '/upload-real-estate-objects',
                        label: <Link
                            to={'/upload-real-estate-objects'}>{t('Импорт ОН')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/chief-inspector',
                        label: <Link
                            to={'/dictionaries/chief-inspector'}>{t('Старшие Контролеры')}</Link>
                    },
                    {
                        key: '/dictionaries/inspectors',
                        label: <Link to={'/dictionaries/inspectors'}>{t('Контролеры')}</Link>
                    },
                    {
                        key: '/dictionaries/sector',
                        label: <Link to={'/dictionaries/sector'}>{t('Участки')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/swap-sector',
                        label: <Link
                            to={'/dictionaries/swap-sector'}>{t('Назначение контролера на участки')}</Link>
                    },
                    {
                        key: '/dictionaries/swap-real-estate-object',
                        label: <Link
                            to={'/dictionaries/swap-real-estate-object'}>{t('Назначение ОН на участок')}</Link>
                    },
                ],
            },
            {
                key: '/providers_and_services_main',
                label: t('Поставщики и услуги'),
                children: [
                    {
                        key: '/dictionaries/providers',
                        label: <Link to={'/dictionaries/providers'}>{t('Поставщики')}</Link>
                    },
                    {
                        key: '/upload-agreements',
                        label: <Link to={'/upload-agreements'}>{t('Загрузка № договоров с потребителями')}</Link>
                    },
                    {type: 'divider'},
                    // {
                    //     key: '/dictionaries/activity-type',
                    //     label: <Link to={'/dictionaries/activity-type'}>{t('Виды деятельности')}</Link>
                    // },
                    {
                        key: '/dictionaries/preference-category',
                        label: <Link
                            to={'/dictionaries/preference-category'}>{t('Категории льгот')}</Link>
                    },
                    // {
                    //     key: '/dictionaries/economy-sector',
                    //     label: <Link
                    //         to={'/dictionaries/economy-sector'}>{t('Сектор экономики')}</Link>
                    // },
                    {
                        key: '/dictionaries/calc-type',
                        label: <Link to={'/dictionaries/calc-type'}>{t('Типы расчетов')}</Link>
                    },
                    {
                        key: '/dictionaries/service-payment-type',
                        label: <Link
                            to={'/dictionaries/service-payment-type'}>{t('Виды платежей')}</Link>
                    },
                    {
                        key: '/dictionaries/organization_type',
                        label: <Link
                            to={'/dictionaries/organization_type'}>{t('Категории организаций')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/services',
                        label: <Link to={'/dictionaries/services'}>{t('Услуги')}</Link>
                    },
                    {
                        key: '/dictionaries/service-classes',
                        label: <Link
                            to={'/dictionaries/service-classes'}>{t('Классы услуг')}</Link>
                    },
                    {
                        key: '/dictionaries/service-units',
                        label: <Link
                            to={'/dictionaries/service-units'}>{t('Единицы измерений услуг')}</Link>
                    },
                    {
                        key: '/dictionaries/algorithm',
                        label: <Link to={'/dictionaries/algorithm'}>{t('Алгоритмы расчетов')}</Link>,
                    }
                ],
            },
            {
                key: '/corr_main',
                label: t('Корректировки'),
                children: [
                    {
                        key: '/corrections',
                        label: <Link to={'/corrections'}>{t('Выбор Корректировки')}</Link>
                    },
                ],
            },
            {
                key: '/accruals_and_payments_main',
                label: t('Начисления и платежи'),
                children: [
                    {
                        key: '/upload',
                        label: <Link to={'/upload'}>{t('Загрузка файлов начислений')}</Link>
                    },
                    {
                        key: '/close-month',
                        label: <Link to={'/close-month'}>{t('Переход месяца')}</Link>,
                    },
                    {type: 'divider'},
                    {
                        key: '/accruals',
                        label: <Link to={'/accruals'}>{t('Начисления')}</Link>
                    },
                    {
                        key: '/payment',
                        label: <Link to={'/payment'}>{t('Распределение платежей')}</Link>
                    },
                    {
                        key: '/launch-recalculations',
                        label: <Link to={'/launch-recalculations'}>{t('Тестовая страница для запуска пересчетов')}</Link>
                    },
                    {
                        key: '/saldo',
                        label: <Link to={'/saldo'}>{t('Тестовая страница по сальдо')}</Link>
                    },
                ],
            },
            {
                key: '/epd_main',
                label: t('ЕПД'),
                children: [
                    // {
                    //     key: '/tasks/pdf-to-controllers',
                    //     label: <Link
                    //         to={'/tasks/pdf-to-controllers'}>{t('PDF по Контролерам')}</Link>
                    // },
                    // {
                    //     key: '/tasks/epd-per-reo',
                    //     label: <Link
                    //         to={'/tasks/epd-per-reo'}>{t('ЕПД по Объектам недвижимости')}</Link>
                    // },
                    {
                        key: '/tasks/all',
                        label: <Link
                            to={'/tasks/all'}>{t('Формирование ЕПД')}</Link>
                    },
                    {
                        key: '/approve-emails',
                        label: <Link to={'/approve-emails'}>{t('Одобрение Email-рассылок')}</Link>
                    },
                    {
                        key: '/tasks/email-per-accounts',
                        label: <Link to={'/tasks/email-per-accounts'}>{t('Email по ЛС')}</Link>
                    },
                ],
            },
            {
                key: '/addresses_main',
                label: t('Адресные данные'),
                children: [
                    {
                        key: '/dictionaries/town',
                        label: <Link to={'/dictionaries/town'}>{t('Населенные пункты')}</Link>
                    },
                    {
                        key: '/dictionaries/town_distr',
                        label: <Link to={'/dictionaries/town_distr'}>{t('Районы города')}</Link>
                    },
                    {
                        key: '/dictionaries/street_type',
                        label: <Link to={'/dictionaries/street_type'}>{t('Типы улиц')}</Link>
                    },
                    {
                        key: '/dictionaries/real-estate-object-type',
                        label: <Link
                            to={'/dictionaries/real-estate-object-type'}>{t('Типы объектов недвижимости')}</Link>
                    },
                    {
                        key: '/dictionaries/street',
                        label: <Link to={'/dictionaries/street'}>{t('Улицы')}</Link>
                    },
                    {
                        key: '/dictionaries/address',
                        label: <Link to={'/dictionaries/address'}>{t('Дома')}</Link>
                    },
                    // {type: 'divider'},
                    // {
                    //     key: '/dictionaries/tax_branch',
                    //     label: <Link
                    //         to={'/dictionaries/tax_branch'}>{t('Отделения налоговой')}</Link>
                    // },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/swap-address',
                        label: <Link
                            to={'/dictionaries/swap-address'}>{t('Назначение адресов на район')}</Link>
                    },
                ],
            },
            {
                key: '/banks_main',
                label: t('Банковские данные'),
                children: [
                    {
                        key: '/dictionaries/banks',
                        label: <Link to={'/dictionaries/banks'}>{t('Банки')}</Link>
                    },
                    {
                        key: '/dictionaries/bank-commission',
                        label: <Link to={'/dictionaries/bank-commission'}>{t('Комиссия банка')}</Link>
                    },
                    {
                        key: '/dictionaries/bank-account-type',
                        label: <Link
                            to={'/dictionaries/bank-account-type'}>{t('Типы расчетных счетов')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/kbe',
                        label: <Link to={'/dictionaries/kbe'}>{t('КБе')}</Link>
                    },
                    {
                        key: '/dictionaries/kbk',
                        label: <Link to={'/dictionaries/kbk'}>{t('КБК')}</Link>
                    },
                    {
                        key: '/dictionaries/knp',
                        label: <Link to={'/dictionaries/knp'}>{t('КНП')}</Link>
                    },
                ],
            },
            {
                key: '/administration_main',
                label: t('Администрирование'),
                children: [
                    {
                        key: '/dictionaries/bank-api-info',
                        label: <Link
                            to={'/dictionaries/bank-api-info'}>{t('Настройка для API с банком')}</Link>
                    },
                    {
                        key: '/admin-services',
                        label: <Link
                            to={'/admin-services'}>{t('Администрирование услуг')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/epd-template-fields',
                        label: <Link
                            to={'/dictionaries/epd-template-fields'}>{t('Поля начислений для ЕПД')}</Link>
                    },
                    {
                        key: '/dictionaries/epd-templates',
                        label: <Link to={'/dictionaries/epd-templates'}>{t('Шаблоны ЕПД')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/role',
                        label: <Link to={'/dictionaries/role'}>{t('Роли')}</Link>
                    },
                    {
                        key: '/dictionaries/permission',
                        label: <Link to={'/dictionaries/permission'}>{t('Разрешения')}</Link>
                    },
                    {
                        key: '/dictionaries/position',
                        label: <Link to={'/dictionaries/position'}>{t('Должности')}</Link>
                    },
                ],
            },
        ] : [
            {
                key: '/',
                label: <Link to={'/'}><HomeTwoTone /></Link>
            },
            {
                key: '/accounts_and_real_estate_main',
                label: t('ЛС и ОН'),
                children: [
                    {
                        key: '/dictionaries/accounts',
                        label: <Link to={'/dictionaries/accounts'}>Лицевые счета</Link>
                    },
                    {
                        key: '/upload-accounts',
                        label: <Link to={'/upload-accounts'}>Импорт ЛС</Link>
                    },
                    {
                        key: '/upload-update-accounts',
                        label: <Link to={'/upload-update-accounts'}>Импорт Обновлений ЛС</Link>
                    },
                    // {
                    //     key: '/dictionaries/consumer-category',
                    //     label: <Link to={'/dictionaries/consumer-category'}>{t('Категории потребителей')}</Link>
                    // },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/real-estate-objects',
                        label: <Link
                            to={'/dictionaries/real-estate-objects'}>{t('Объекты недвижимости')}</Link>
                    },
                    {
                        key: '/upload-real-estate-objects',
                        label: <Link
                            to={'/upload-real-estate-objects'}>{t('Импорт ОН')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/chief-inspector',
                        label: <Link
                            to={'/dictionaries/chief-inspector'}>{t('Старшие Контролеры')}</Link>
                    },
                    {
                        key: '/dictionaries/inspectors',
                        label: <Link to={'/dictionaries/inspectors'}>{t('Контролеры')}</Link>
                    },
                    {
                        key: '/dictionaries/sector',
                        label: <Link to={'/dictionaries/sector'}>{t('Участки')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/swap-sector',
                        label: <Link
                            to={'/dictionaries/swap-sector'}>{t('Назначение контролера на участки')}</Link>
                    },
                    {
                        key: '/dictionaries/swap-real-estate-object',
                        label: <Link
                            to={'/dictionaries/swap-real-estate-object'}>{t('Назначение ОН на участок')}</Link>
                    },
                ],
            },
            {
                key: '/providers_and_services_main',
                label: t('Поставщики и услуги'),
                children: [
                    {
                        key: '/dictionaries/providers',
                        label: <Link to={'/dictionaries/providers'}>{t('Поставщики')}</Link>
                    },
                    {
                        key: '/upload-agreements',
                        label: <Link to={'/upload-agreements'}>{t('Загрузка № договоров с потребителями')}</Link>
                    },
                    {type: 'divider'},
                    // {
                    //     key: '/dictionaries/activity-type',
                    //     label: <Link to={'/dictionaries/activity-type'}>{t('Виды деятельности')}</Link>
                    // },
                    {
                        key: '/dictionaries/preference-category',
                        label: <Link
                            to={'/dictionaries/preference-category'}>{t('Категории льгот')}</Link>
                    },
                    // {
                    //     key: '/dictionaries/economy-sector',
                    //     label: <Link
                    //         to={'/dictionaries/economy-sector'}>{t('Сектор экономики')}</Link>
                    // },
                    {
                        key: '/dictionaries/calc-type',
                        label: <Link to={'/dictionaries/calc-type'}>{t('Типы расчетов')}</Link>
                    },
                    {
                        key: '/dictionaries/service-payment-type',
                        label: <Link
                            to={'/dictionaries/service-payment-type'}>{t('Виды платежей')}</Link>
                    },
                    {
                        key: '/dictionaries/organization_type',
                        label: <Link
                            to={'/dictionaries/organization_type'}>{t('Категории организаций')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/services',
                        label: <Link to={'/dictionaries/services'}>{t('Услуги')}</Link>
                    },
                    {
                        key: '/dictionaries/service-classes',
                        label: <Link
                            to={'/dictionaries/service-classes'}>{t('Классы услуг')}</Link>
                    },
                    {
                        key: '/dictionaries/service-units',
                        label: <Link
                            to={'/dictionaries/service-units'}>{t('Единицы измерений услуг')}</Link>
                    },
                    {
                        key: '/dictionaries/algorithm',
                        label: <Link to={'/dictionaries/algorithm'}>{t('Алгоритмы расчетов')}</Link>,
                    }
                ],
            },
            {
                key: '/accruals_and_payments_main',
                label: t('Начисления и платежи'),
                children: [
                    {
                        key: '/upload',
                        label: <Link to={'/upload'}>{t('Загрузка файлов начислений')}</Link>
                    },
                    {
                        key: '/close-month',
                        label: <Link to={'/close-month'}>{t('Переход месяца')}</Link>,
                    },
                    {type: 'divider'},
                    {
                        key: '/accruals',
                        label: <Link to={'/accruals'}>{t('Начисления')}</Link>
                    },
                    {
                        key: '/payment',
                        label: <Link to={'/payment'}>{t('Распределение платежей')}</Link>
                    },
                    {
                        key: '/launch-recalculations',
                        label: <Link to={'/launch-recalculations'}>{t('Тестовая страница для запуска пересчетов')}</Link>
                    },
                    {
                        key: '/saldo',
                        label: <Link to={'/saldo'}>{t('Тестовая страница по сальдо')}</Link>
                    },
                ],
            },
            {
                key: '/epd_main',
                label: t('ЕПД'),
                children: [
                    // {
                    //     key: '/tasks/pdf-to-controllers',
                    //     label: <Link
                    //         to={'/tasks/pdf-to-controllers'}>{t('PDF по Контролерам')}</Link>
                    // },
                    // {
                    //     key: '/tasks/epd-per-reo',
                    //     label: <Link
                    //         to={'/tasks/epd-per-reo'}>{t('ЕПД по Объектам недвижимости')}</Link>
                    // },
                    {
                        key: '/tasks/all',
                        label: <Link
                            to={'/tasks/all'}>{t('Формирование ЕПД')}</Link>
                    },
                    {
                        key: '/approve-emails',
                        label: <Link to={'/approve-emails'}>{t('Одобрение Email-рассылок')}</Link>
                    },
                    {
                        key: '/tasks/email-per-accounts',
                        label: <Link to={'/tasks/email-per-accounts'}>{t('Email по ЛС')}</Link>
                    },
                ],
            },
            {
                key: '/addresses_main',
                label: t('Адресные данные'),
                children: [
                    {
                        key: '/dictionaries/town',
                        label: <Link to={'/dictionaries/town'}>{t('Населенные пункты')}</Link>
                    },
                    {
                        key: '/dictionaries/town_distr',
                        label: <Link to={'/dictionaries/town_distr'}>{t('Районы города')}</Link>
                    },
                    {
                        key: '/dictionaries/street_type',
                        label: <Link to={'/dictionaries/street_type'}>{t('Типы улиц')}</Link>
                    },
                    {
                        key: '/dictionaries/real-estate-object-type',
                        label: <Link
                            to={'/dictionaries/real-estate-object-type'}>{t('Типы объектов недвижимости')}</Link>
                    },
                    {
                        key: '/dictionaries/street',
                        label: <Link to={'/dictionaries/street'}>{t('Улицы')}</Link>
                    },
                    {
                        key: '/dictionaries/address',
                        label: <Link to={'/dictionaries/address'}>{t('Дома')}</Link>
                    },
                    // {type: 'divider'},
                    // {
                    //     key: '/dictionaries/tax_branch',
                    //     label: <Link
                    //         to={'/dictionaries/tax_branch'}>{t('Отделения налоговой')}</Link>
                    // },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/swap-address',
                        label: <Link
                            to={'/dictionaries/swap-address'}>{t('Назначение адресов на район')}</Link>
                    },
                ],
            },
            {
                key: '/banks_main',
                label: t('Банковские данные'),
                children: [
                    {
                        key: '/dictionaries/banks',
                        label: <Link to={'/dictionaries/banks'}>{t('Банки')}</Link>
                    },
                    {
                        key: '/dictionaries/bank-commission',
                        label: <Link to={'/dictionaries/bank-commission'}>{t('Комиссия банка')}</Link>
                    },
                    {
                        key: '/dictionaries/bank-account-type',
                        label: <Link
                            to={'/dictionaries/bank-account-type'}>{t('Типы расчетных счетов')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/kbe',
                        label: <Link to={'/dictionaries/kbe'}>{t('КБе')}</Link>
                    },
                    {
                        key: '/dictionaries/kbk',
                        label: <Link to={'/dictionaries/kbk'}>{t('КБК')}</Link>
                    },
                    {
                        key: '/dictionaries/knp',
                        label: <Link to={'/dictionaries/knp'}>{t('КНП')}</Link>
                    },
                ],
            },
            {
                key: '/administration_main',
                label: t('Администрирование'),
                children: [
                    {
                        key: '/dictionaries/bank-api-info',
                        label: <Link
                            to={'/dictionaries/bank-api-info'}>{t('Настройка для API с банком')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/epd-template-fields',
                        label: <Link
                            to={'/dictionaries/epd-template-fields'}>{t('Поля начислений для ЕПД')}</Link>
                    },
                    {
                        key: '/dictionaries/epd-templates',
                        label: <Link to={'/dictionaries/epd-templates'}>{t('Шаблоны ЕПД')}</Link>
                    },
                    {type: 'divider'},
                    {
                        key: '/dictionaries/role',
                        label: <Link to={'/dictionaries/role'}>{t('Роли')}</Link>
                    },
                    {
                        key: '/dictionaries/permission',
                        label: <Link to={'/dictionaries/permission'}>{t('Разрешения')}</Link>
                    },
                    {
                        key: '/dictionaries/position',
                        label: <Link to={'/dictionaries/position'}>{t('Должности')}</Link>
                    },
                ],
            },
        ]

    return <Menu mode={'horizontal'}
                 items={items}
                 style={{width: '100%'}}
                 selectedKeys={[pathname]}
                 theme={'dark'}
    />
}
