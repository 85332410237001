import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Select from 'antd/es/select';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {
    CheckCircleOutlined, CheckOutlined,
    CloseCircleOutlined, CloseOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {
    setCreateRealEstateObjTypeEmbeddedEv, setCreateSectorEmbeddedEv,
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {
    RealEstateObjectsCreateGate,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    setMassReoFormDataEv,
    $generatedReos,
    resetMassReoFormDataEv,
    $massCreateStatus,
} from '../../../models/dictionaryRealEstateObjectsModel/index.js';
import CreateSectorEmbeddedForm
    from "../../../components/Embedded/CreateSectorEmbeddedForm/CreateSectorEmbeddedForm.jsx";
import {InputNumber, Popconfirm, Switch} from "antd";
import {
    onCreateGroupEv
} from "../../../models/dictionaryRealEstateObjectsModel/index.js";
import CreateRealEstateObjTypeEmbeddedForm
    from "../../../components/Embedded/CreateRealEstateObjTypeEmbeddedForm/CreateRealEstateObjTypeEmbeddedForm.jsx";
import Table from "antd/es/table";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import AddressREOSearchForm
    from '../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx';
import {
    resetSelectionEv,
    searchMultiAddressesEv
} from '../../../models/accountsREOSearchModel/index.js';
import {filterOption, optionRender} from '../../../utils/selectUtils.jsx';
import Tooltip from 'antd/es/tooltip';
import {isId} from '../../../utils/helpers.js';
import * as styles from './CreateGroupREO.module.css'


export default function CreateGroupREO() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const resetAddresses = useUnit(resetSelectionEv)
    const resetReoForm = useUnit(resetMassReoFormDataEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const createStatus = useUnit($massCreateStatus)

    useEffect(() => {
        if (createStatus) {
            if (action === 'list') {
                realEstateObjectsForm.resetFields()
                resetReoForm()
                resetAddresses()
                resetAttrs()
                navigate(`/dictionaries/real-estate-objects`)
            } else if (action === 'new') {
                realEstateObjectsForm.resetFields()
                resetReoForm()
                resetAddresses()
                resetAttrs()
            }
        }
    }, [createStatus])

    const [addressForm] = Form.useForm()
    const [realEstateObjectsForm] = Form.useForm()

    const loading = useUnit(universalDictEndpointFx.pending)

    const houses = Form.useWatch('house_in', addressForm)

    const searchAddresses = useUnit(searchMultiAddressesEv)

    const sectorOpts = useStoreMap($dictsSelects, sel => sel.sector)
    const realEstateObjectTypesOpts = useStoreMap($dictsSelects, sel => sel.real_estate_object_type)

    const setRESFormData = useUnit(setMassReoFormDataEv)

    const setSectorEmbedded = useUnit(setCreateSectorEmbeddedEv)
    const setRealEstateObjTypeEmbedded = useUnit(setCreateRealEstateObjTypeEmbeddedEv)

    const generatedReos = useUnit($generatedReos)

    const onCreateGroup = useUnit(onCreateGroupEv)

    const columns = [
        {
            title: t('Существует'),
            dataIndex: 'existing',
            render: exist => exist ? <CheckOutlined/> : <CloseOutlined/>
        },
        {
            width: 200,
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            sorter: true,
            key: 'town_id',
            render: (id) => isId(id) ? <MappedDictItem id={id} type={'town'}/> : '-'
        },
        {
            width: 200,
            title: t('Район'),
            dataIndex: 'town_distr_id',
            sorter: true,
            key: 'town_distr_id',
            render: (id) => isId(id) ? <MappedDictItem id={id} type={'town_distr'}/> : '-'
        },
        {
            width: 200,
            title: t('Улицы'),
            dataIndex: 'street_id',
            sorter: true,
            key: 'street_id',
            render: (id) => isId(id) ? <MappedDictItem id={id} type={'street'}/> : '-'
        },
        {
            width: 200,
            title: t('Дом'),
            dataIndex: 'house',
            sorter: true,
            key: 'house',
        },
        {
            width: 200,
            title: t('Корпус'),
            dataIndex: 'corpus',
            sorter: true,
            key: 'corpus',
        },

        {
            width: 200,
            title: t('Участок'),
            dataIndex: 'sector_id',
            sorter: true,
            key: 'sector_id',
            render: (id) => isId(id) ? <MappedDictItem id={id} type={'sector'}/> : '-'
        },
        {
            width: 200,
            title: t('Тип ОН'),
            dataIndex: 'real_estate_object_type_id',
            sorter: true,
            key: 'real_estate_object_type_id',
            render: (id, record) => {
                const lang = localStorage.getItem('lang') ?? 'ru'
                return isId(id)
                    ? <MappedDictItem id={id} type={'real_estate_object_type'}/>
                    : record.formData[`real_estate_object_type_name_${lang}`]
            }
        },
        {
            width: 200,
            title: t('Кол-во Этажей'),
            dataIndex: 'floor_count',
            sorter: true,
            key: 'floor_count',
        },
        {
            width: 200,
            title: t('Кол-во Подъездов'),
            dataIndex: 'entrance_count',
            sorter: true,
            key: 'entrance_count',
        },
        {
            width: 200,
            title: t('Кол-во Квартир'),
            dataIndex: 'apartment_count',
            sorter: true,
            key: 'apartment_count',
        },

        {
            width: 200,
            title: t('Электричество'),
            dataIndex: 'is_electricity_network_present',
            sorter: true,
            key: 'is_electricity_network_present',
            render: (is_electricity_network_present) => is_electricity_network_present === true ?
                <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> :
                <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>

        },
        {
            width: 200,
            title: t('Тепловая сеть'),
            dataIndex: 'is_heat_network_present',
            sorter: true,
            key: 'is_heat_network_present',
            render: (is_heat_network_present) => is_heat_network_present === true ?
                <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> :
                <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
        },
        {
            width: 200,
            title: t('Лифт'),
            dataIndex: 'is_elevator_present',
            sorter: true,
            key: 'is_elevator_present',
            render: (is_elevator_present) => is_elevator_present === true ?
                <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> :
                <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
        },
        {
            width: 200,
            title: t('Почтовые ящики'),
            dataIndex: 'is_mailboxes_present',
            sorter: true,
            key: 'is_mailboxes_present',
            render: (is_mailboxes_present) => is_mailboxes_present === true ?
                <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> :
                <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
        }
    ]


    const generateData = (reoValues) => {
        resetAddresses()
        const addressData = addressForm.getFieldsValue()
        setRESFormData(reoValues)
        searchAddresses(addressData)
    }


    const onCreate = () => {
        onCreateGroup()
    }

    const onClearAll = () => {
        resetAddresses()
        resetReoForm()
        realEstateObjectsForm.resetFields()
    }


    return <>
        <RealEstateObjectsCreateGate/>
        <AddressREOSearchForm isCard
                              itemsAsRow
                              extForm={addressForm}
                              extButtons
                              cardTitle={t('Адреса')}
        />
        <Divider/>
        <Form
            form={realEstateObjectsForm}
            layout={'vertical'}
            onFinish={(values) => generateData(values)}
            size={'small'}
        >
            <Row justify={'space-between'} gutter={[8, 8]}>
                <Col span={4}>
                    <Card title={<StarredTitle title={t('Тип объекта недвижимости')}/>}
                          style={{height: '100%'}}
                          size={'small'}>
                        <Form.Item name={'real_estate_object_type_id'}
                                   rules={[{
                                       required: true,
                                       message: t('Обязательное поле')
                                   }]}
                        >
                            <Select
                                options={[...realEstateObjectTypesOpts, {
                                    label: 'Создать',
                                    value: -1,
                                    emoji: true
                                }]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setRealEstateObjTypeEmbedded(true)
                                    } else {
                                        // realEstateObjectsForm.resetFields(['sector_id'])
                                        setRealEstateObjTypeEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={filterOption}
                                optionRender={optionRender}
                            />
                        </Form.Item>
                        <CreateRealEstateObjTypeEmbeddedForm
                            form={realEstateObjectsForm} inCol={true}/>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title={t('Участок')} style={{height: '100%'}}
                          size={'small'}>
                        <Form.Item name={'sector_id'}
                            // label={'Участок'}
                                   rules={[{
                                       required: false,
                                       message: t('Обязательное поле')
                                   }]}
                        >
                            <Select
                                options={[...sectorOpts, {
                                    label: 'Создать',
                                    value: -1,
                                    emoji: true
                                }]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setSectorEmbedded(true)
                                    } else {
                                        // realEstateObjectsForm.resetFields(['sector_id'])
                                        setSectorEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                                <span role="img" aria-label={option.data.label}>
                                                  {option?.data?.emoji ?
                                                      <PlusOutlined
                                                          style={{color: '#1677ff'}}/> : ''}
                                                </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateSectorEmbeddedForm form={realEstateObjectsForm}
                                                  inCol={true}/>
                    </Card>
                </Col>
                <Col span={7}>
                    <Card size={'small'} className={styles.reoOptsCard} loading={loading}>
                        <Row gutter={[8, 8]}>
                            <Col span={8}>
                                <Form.Item name={'floor_count'}
                                           label={'Этажей'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'entrance_count'}
                                           label={'Подъездов'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'apartment_count'}
                                           label={'Квартир'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={10}>
                    <Card size={'small'} className={styles.reoOptsCard} loading={loading}>
                        <Row gutter={[8, 0]}>
                            <Col span={6}>
                                <Form.Item name={'is_electricity_network_present'}
                                           label={'Электричество'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Switch
                                        defaultChecked={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'is_heat_network_present'}
                                           label={'Тепловая сеть'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name={'is_elevator_present'}
                                           label={'Лифт'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'is_mailboxes_present'}
                                           label={'Почтовые ящики'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={6}>
                    <Tooltip title={!houses || houses?.length < 1 ? t('Укажите дома') : null}
                             placement={'left'}>
                        <Button type={'primary'} style={{width: '100%'}}
                                disabled={!houses || houses?.length < 1}
                                onClick={realEstateObjectsForm.submit}
                        >
                            Сгенерировать
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Card title={
            <Row justify={"space-between"} align={'middle'}>
                <p>{t('Сгенерированные данные')}</p>
                <Button danger onClick={onClearAll}>{t('Очистить все')}</Button>
            </Row>
        }>
            <div style={{position: 'relative'}}>
                <Table
                    columns={columns}
                    dataSource={generatedReos}
                    size={'small'}
                    bordered
                    style={{
                        width: '100%',
                        overflow: 'auto'
                    }}
                    rowClassName={record => record.existing ? 'existing-row' : null}
                    rowKey={'id'}
                />
            </div>


        </Card>
        <br/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    realEstateObjectsForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Tooltip
                    title={generatedReos.length === 0 ? t('Необходимо сгенерировать ОН') : generatedReos.every(i => i?.existing) ? t('Все сгенерированные ОН уже существуют') : null}>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('new')
                                onCreate()
                            }}
                            ghost
                            type={'primary'}
                            disabled={generatedReos.length === 0 || generatedReos.every(i => i?.existing)}
                    >
                        {t('Сохранить и создать ещё')}
                    </Button>
                </Tooltip>
                <Tooltip
                    title={generatedReos.length === 0 ? t('Необходимо сгенерировать ОН') : generatedReos.every(i => i?.existing) ? t('Все сгенерированные ОН уже существуют') : null}>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('list')
                                onCreate()
                            }}
                            type={'primary'}
                            disabled={generatedReos.length === 0 || generatedReos.every(i => i?.existing)}
                    >
                        {t('Сохранить и вернуться к списку')}
                    </Button>
                </Tooltip>
            </Space>
        </Row>
    </>
}
