import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import StarredTitle from '../../../components/StarredTitle/StarredTitle.jsx';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel';
import {
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ServicesCreateGate,
    submitCreateServicesEv,
    $createdServicesId,
    setServiceTypeEmbedded,
    $serviceTypeEmbeddedOpen
} from '../../../models/dictionaryServicesModel';
import CreateEmbeddedUniversalForm
    from '../../../components/Embedded/CreateEmbeddedUnirversalForm/CreateEmbeddedUniversalForm.jsx';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {filterOption} from "../../../utils/selectUtils.jsx";


export default function CreateServices() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [servicesForm] = Form.useForm()

    const submit = useUnit(submitCreateServicesEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const serUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)
    const templateOpts = useStoreMap($dictsSelects, sel => sel.template)
    const algorithmOpts = useStoreMap($dictsSelects, sel => sel.algorithms)
    const srvPayTypeOpts = useStoreMap($dictsSelects, sel => sel.servicePaymentType)
    const srvServiceClassOpts = useStoreMap($dictsSelects, sel => sel.serviceClasses)

    const setEmbedded = useUnit(setServiceTypeEmbedded)
    const embeddedOpen = useUnit($serviceTypeEmbeddedOpen)


    const createdId = useUnit($createdServicesId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.services`);


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/services/edit/${createdId}`)
            } else if (action === 'new') {
                servicesForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);

    return <>
        <ServicesCreateGate/>
        <Form form={servicesForm} layout={'vertical'} onFinish={submit} size={'small'}>
            <Row justify={'space-between'} gutter={[16, 16]}>
                <Col span={12}>
                    <Card title={t('Основные свойства услуги')} size={'small'}
                          style={{height: '100%'}}>
                        <Row gutter={[8, 0]}>
                            <Col span={16}>
                                <Form.Item name={'name_ru'}
                                           label={t(`Название ${genitive_form} (рус)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'short_name_ru'}
                                           label={t(`Крат. Название (рус)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item name={'name_kz'}
                                           label={t(`Название ${genitive_form} (каз)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'short_name_kz'}
                                           label={t(`Крат. Название (каз)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Дополнительные свойства')}/>}
                          size={'small'} style={{height: '100%'}}>
                        <Row gutter={[8, 0]}>
                            <Col span={7}>
                                <Form.Item
                                    name={'service_payment_type_id'}
                                    label={t('Вид платежа')}
                                    rules={[{
                                        required: true,
                                        message: t('Обязательное поле')
                                    }]}
                                >
                                    <Select
                                        options={srvPayTypeOpts}
                                        filterOption={filterOption}
                                        showSearch allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'service_class_id'}
                                    label={t('Класс услуг')}
                                    rules={[{
                                        required: true,
                                        message: t('Обязательное поле')
                                    }]}
                                >
                                    <Select
                                        options={srvServiceClassOpts}
                                        filterOption={filterOption}
                                        showSearch allowClear
                                    />
                                </Form.Item>
                            </Col>


                            <Col span={10}>
                                <Form.Item name={'template_id'}
                                           label={t('Шаблон')}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Select options={templateOpts} showSearch allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name={'epd_order'}
                                           label={t('Порядок ЕПД')}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    servicesForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            servicesForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            servicesForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
