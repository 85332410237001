import {useTranslation} from 'react-i18next'
import Col from "antd/es/col"
import Row from "antd/es/row"
import Button from "antd/es/button"
import {Form, Select} from "antd"
import {useEffect, useState} from "react"
import {useStoreMap, useUnit} from "effector-react"

import {$dictsSelects, $info, $infoMaps} from "../../models/infoModel"
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx"
import {
    SwapSectorGate, SwapSelectedSectorsEv
} from "../../models/swapSectorModel"
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx"
import {searchAndActions} from "../../utils/searchAndActions.js"
import Table from "antd/es/table"
import {filterOption} from "../../utils/selectUtils.jsx"
import {isId} from "../../utils/helpers.js"
import ButtonGroup from "antd/es/button/button-group";
import Tabs from "antd/es/tabs";

const defaultFilter = {
    town_id: null,
    filteredInspectors: [],
    filteredSectors: [],
    noSector: false,
    noInspector: false,
}


export default function SwapSector() {
    const {t} = useTranslation()
    const [form] = Form.useForm()
    const town_id = Form.useWatch('town_id', form)
    const [filterValues, setFilterValues] = useState(defaultFilter)
    const {search} = searchAndActions()
    const checkTown = isId(town_id)

    const filteredInspectorsAll = Form.useWatch('_inspector_id', form) || []
    const filteredInspectors = filteredInspectorsAll.filter(o => o !== 'no_sector')
    const filteredSectorsAll = Form.useWatch('_sector_id', form) || []
    const filteredSectors = filteredSectorsAll.filter(o => o !== 'no_inspector')
    const noSector = filteredInspectorsAll.includes('no_sector')
    const noInspector = filteredSectorsAll.includes('no_inspector')

    const {aSectors} = useStoreMap($info, sel => ({
        aSectors: sel.sector,
    }))
    const {inspectorOpts} = useStoreMap($dictsSelects, sel => ({
        inspectorOpts: sel.inspector,
    }))

    const filterInspectors = [
        {label: t('Без Участка'), value: 'no_sector'},
        ...(
            inspectorOpts.filter(
                o => o.town_id === town_id && (o.value !== ' ' && o.value !== '-')
            )
        )
    ]
    const filterSectors = [
        {label: t('Без Контролера'), value: 'no_inspector'},
        ...(
            aSectors.filter(
                o => o.town_id === town_id && (o.value !== ' ' && o.value !== '-')
                    && (
                        filteredInspectors.includes(o.inspector_id) ||
                        noSector || filteredInspectors.length === 0
                    )
            ).map(o => ({label: o.id, value: o.id}))
        )
    ]

    const onSubmit = () => {
        setFilterValues({
            town_id,
            filteredInspectors,
            filteredSectors,
            noSector,
            noInspector,
        })
    }
    const onReset = () => {
        setFilterValues(defaultFilter)
    }

    const filters = [
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns',},
        {
            name: '_inspector_id',
            label: t('Контролер'),
            type: 'select',
            // dict: 'inspector',
            multiple: true,
            disabled: !checkTown,
            options: filterInspectors,
        },
        {
            name: '_sector_id',
            label: t('Участок'),
            type: 'select',
            // dict: 'sector',
            multiple: true,
            disabled: !checkTown,
            options: filterSectors,
        },
    ]

    console.log('bla', town_id)
    return <div id={"SwapSector"}>
        <SwapSectorGate search={search.toString()}/>
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <FilterBlock
                    items={filters} extForm={form} onSubmitHook={onSubmit}
                    onResetHook={onReset}
                />
            </Col>
            <Col span={24}>
                <Tabs
                    // onChange={onChangeTab}
                    type="card"
                    size={'small'}
                    items={[
                        {
                            label: t('Смена участка'),
                            key: 'sector',
                            children: <SectorsTable {...filterValues}/>,
                        },
                        {
                            label: t('Смена контролера'),
                            key: 'inspector',
                            children: <InspectorsTable {...filterValues}/>,
                        },
                    ]}
                />

            </Col>
        </Row>
    </div>
}

function InspectorsTable(props) {
    const {town_id, filteredInspectors, filteredSectors, noSector, noInspector} = props
    const {t} = useTranslation()
    const [data, setData] = useState([])
    const updateSectorInspector = useUnit(SwapSelectedSectorsEv)
    const [changes, setChanges] = useState({})
    const {aSectors, aInspectors} = useStoreMap($info, sel => ({
        aSectors: sel.sector,
        aInspectors: sel.inspector,
    }))
    const {oInspectors} = useStoreMap($infoMaps, sel => ({
        oInspectors: sel.inspectorsMap,
    }))
    const inspectorSector = Object.fromEntries(aSectors.map(s => [s.inspector_id, s.id]))
    const changedSectors = Object.values(changes).map(
        c => c.v
    ).filter(i => isId(i))

    const newSectorOpts = [
        {label: t('Без Участка'), value: null},
        ...aSectors.filter(
            s => s.town_id === town_id && !changedSectors.includes(s.id)
        ).map(s => {
            const i = oInspectors[s.inspector_id] || null
            return {
                value: s.id,
                label: i ?
                    `${s.id} (${i.chief_inspector_id}-${i.id} ${i.name})` :
                    `${s.id} (${t('Без Контролера')})`
            }
        })
    ]

    const updateData = () => {
        const dataNoSector = (noSector ? aInspectors.filter(
            o => !isId(inspectorSector[o.id]) && (o.town_id === town_id)
        ) : []).map(o => ({
            sector_id: null,
            inspector_id: o.id,
            id: `null-${o.id}`,
        }))
        const dataF = aInspectors.filter(o => (
            (filteredInspectors.includes(o.id) || (filteredInspectors.length === 0 && !noSector)) &&
            (filteredSectors.includes(inspectorSector[o.id]) || (filteredSectors.length === 0 && !noInspector)) &&
            (o.town_id === town_id)
        )).map(o => ({
            sector_id: inspectorSector[o.id] || null,
            inspector_id: o.id,
            id: `${inspectorSector[o.id] || null}-${o.id}`,
        }))
        const newData = Object.values(
            Object.fromEntries(
                [...dataNoSector, ...dataF].map(o => [o.id, o])
            )
        )

        console.log('Data', aSectors, props, dataNoSector, dataF)
        setData(newData)
    }

    useEffect(() => {
        updateData()
    }, [aSectors, aInspectors]);

    useEffect(() => {
        updateData()
    }, [town_id, filteredInspectors, filteredSectors]);

    useEffect(() => {
        setChanges({})
    }, [town_id]);

    const onChange = (r, v) => {
        const id = r.id
        console.log(id, v)
        const newChanges = {...changes}
        newChanges[id] = {...r, v}
        setChanges(newChanges)
    }

    const handleOk = () => {
        updateSectorInspector({
            type: 'setNewSector',
            values: Object.values(changes),
        })
    }
    const onReset = () => {
        setChanges({})
    }

    console.log('Changes', changes, town_id, filteredInspectors, filteredSectors)
    console.log('SOPTS', changedSectors, newSectorOpts)
    const leftColumns = [
        {
            title: 'Контролер',
            dataIndex: 'inspector_id',
            sorter: true,
            key: 'inspector_id',
            render: (inspector_id) => {
                return inspector_id === '-' ? 'Не назначен' :
                    <MappedDictItem id={inspector_id} type={'inspector'}/>
            },
            width: '40%',
        },
        {
            title: 'Старый Участок',
            dataIndex: 'sector_id',
            sorter: true,
            key: 'sector_id',
            render: (id) => {
                return <MappedDictItem id={id} type={'sector'} link={false}/>
            },
            width: '10%',
        },
        {
            title: 'Новый Участок',
            dataIndex: 'new_sector_id',
            sorter: true,
            key: 'new_sector_id',
            render: (id, r) => {
                return <Select
                    filterOption={filterOption}
                    allowClear
                    showSearch
                    size={'small'}
                    onChange={(v) => onChange(r, v)}
                    options={newSectorOpts}
                    style={{width: '100%'}}
                    value={changes[r.id]?.v}
                />
            },
            width: '50%',
        },
    ]

    return <Row gutter={[8, 8]}>
        <Col span={24}>
            <Table
                size={'small'}
                rowKey={'id'}
                columns={leftColumns}
                dataSource={data}
                pagination={false}
                scroll={{y: 55 * 10}}
            />
        </Col>
        <Col span={24}>
            <ButtonGroup size={'small'}>
                <Button type={'primary'} onClick={handleOk} size={'small'}>
                    {t('Сохранить изменения контролеров')}
                </Button>
                <Button onClick={onReset} size={'small'}>
                    {t('Очистить изменения')}
                </Button>
            </ButtonGroup>
        </Col>
    </Row>
}

function SectorsTable(props) {
    const lang = localStorage.getItem('lang') ?? 'ru'
    const {town_id, filteredInspectors, filteredSectors, noSector, noInspector} = props
    const {t} = useTranslation()
    const [data, setData] = useState([])
    const updateSectorInspector = useUnit(SwapSelectedSectorsEv)
    const [changes, setChanges] = useState({})
    const {aSectors, aInspectors} = useStoreMap($info, sel => ({
        aSectors: sel.sector,
        aInspectors: sel.inspector,
    }))
    const inspectorSector = Object.fromEntries(aSectors.map(s => [s.inspector_id, s.id]))
    // const changedInspectors = Object.values(changes).map(
    //     c => c.v
    // ).filter(i => isId(i))

    const newInspectorOpts = [
        {label: t('Без Контролера'), value: null},
        ...aInspectors.filter(
            s => s.town_id === town_id //&& !changedInspectors.includes(s.id)
        ).map(i => {
            const s = inspectorSector[i.id] || null
            return {
                value: i.id,
                label: `${i.chief_inspector_id}-${i.id} ${i['name_' + lang]} (${s ? s : t("Без участка")})`
            }
        })
    ]

    const updateData = () => {
        const dataNoInspector = (noInspector ? aSectors.filter(
            o => o.inspector_id === null && (o.town_id === town_id)
        ) : []).map(o => ({
            sector_id: o.id,
            inspector_id: null,
            id: `${o.id}-null`,
        }))
        const dataF = aSectors.filter(o => (
            (filteredInspectors.includes(o.inspector_id) || (filteredInspectors.length === 0 && !noSector)) &&
            (filteredSectors.includes(o.id) || (filteredSectors.length === 0 && !noInspector)) &&
            (o.town_id === town_id)
        )).map(o => ({
            sector_id: o.id || null,
            inspector_id: o.inspector_id,
            id: `${o.id}-${o.inspector_id || null}`,
        }))
        const newData = Object.values(
            Object.fromEntries(
                [...dataNoInspector, ...dataF].map(o => [o.id, o])
            )
        )

        console.log('Data', aSectors, props, dataNoInspector, dataF)
        setData(newData)
    }

    useEffect(() => {
        updateData()
    }, [aSectors, aInspectors]);

    useEffect(() => {
        updateData()
    }, [town_id, filteredInspectors, filteredSectors]);

    useEffect(() => {
        setChanges({})
    }, [town_id]);

    useEffect(() => {
        setChanges({})
    }, [isId(town_id)]);

    const onChange = (r, v) => {
        const id = r.id
        console.log(id, v)
        const newChanges = {...changes}
        newChanges[id] = {...r, v}
        setChanges(newChanges)
    }

    const handleOk = () => {
        updateSectorInspector({
            type: 'setNewInspector',
            values: Object.values(changes),
        })
    }
    const onReset = () => {
        setChanges({})
    }

    // console.log('Changes', changes, town_id, filteredInspectors, filteredSectors, data)
    // console.log('SOPTS', changedInspectors, newInspectorOpts)
    const leftColumns = [
        {
            title: 'Участок',
            dataIndex: 'sector_id',
            sorter: true,
            key: 'sector_id',
            render: (id) => {
                return <MappedDictItem id={id} type={'sector'} link={false}/>
            },
            width: '10%',
        },
        {
            title: 'Старый Контролер',
            dataIndex: 'inspector_id',
            sorter: true,
            key: 'inspector_id',
            render: (inspector_id) => {
                return inspector_id === '-' ? 'Не назначен' :
                    <MappedDictItem id={inspector_id} type={'inspector'}/>
            },
            width: '40%',
        },
        {
            title: 'Новый Контролер',
            dataIndex: 'new_inspector_id',
            sorter: true,
            key: 'new_inspector_id',
            render: (_, r) => {
                return <Select
                    filterOption={filterOption}
                    allowClear
                    showSearch
                    size={'small'}
                    onChange={(v) => onChange(r, v)}
                    options={newInspectorOpts}
                    style={{width: '100%'}}
                    value={changes[r.id]?.v}
                />
            },
            width: '50%',
        },
    ]

    return <Row gutter={[8, 8]}>
        <Col span={24}>
            <Table
                size={'small'}
                rowKey={'id'}
                columns={leftColumns}
                dataSource={data}
                pagination={false}
                scroll={{y: 55 * 10}}
            />
        </Col>
        <Col span={24}>
            <ButtonGroup size={'small'}>
                <Button type={'primary'} onClick={handleOk} size={'small'}>
                    {t('Сохранить изменения контролеров')}
                </Button>
                <Button onClick={onReset} size={'small'}>
                    {t('Очистить изменения')}
                </Button>
            </ButtonGroup>
        </Col>
    </Row>
}
