import {sample} from 'effector';
import {combineEvents} from 'patronum';
import {
    $addressList, $checkTown,
    $districtsOptions,
    $leftSectors,
    $leftSelected,
    $rightSelected, $selectedAddress,
    $streetsOptions, $addressPagination, $addressFilters
} from "./stores.js";
import {
    getAddressEv,
    LeftTableClickEv, onSwapEv,
    RightTableClickEv, selectAddressEv,
    selectTownEv, setPaginationEv,
    SwapSectorGate,
    SwapSelectedSectorsEv,
} from "./events.js";
import {getAddressFx, getDistrictsSelectFx, getSectorListFx, getSelectsForAddressFx} from "./effects.js";
import {submitGlobalUpdateEv, universalDictEndpointFx} from "../dictionaryUniversalModel/index.js";




$leftSectors.on(getSectorListFx.doneData, (state, payload) => payload)
    .reset(SwapSectorGate.close)

$leftSelected.on(LeftTableClickEv, (state, payload) => payload)
$rightSelected.on(RightTableClickEv, (state, payload) => payload)

$districtsOptions.on(getDistrictsSelectFx.doneData, (state, payload) => payload.data)
$streetsOptions.on(getSelectsForAddressFx.doneData, (state, payload) => payload)

$addressList.on(getAddressFx.doneData, (state, payload) => payload)
$selectedAddress.on(selectAddressEv, (state, payload) => payload)
$addressFilters.on(getAddressEv, (state, payload) => payload)


sample({
    clock: setPaginationEv,
    fn: (pagination ) => {
        const data = {
            ...pagination,
            page: pagination.current
        }
        return data
    },
    target: $addressPagination
})


sample({
    clock: SwapSectorGate.state,
    filter: (gate) => {
        if (gate.search === '') {
            return false
        } else {
            return true
        }
    },
    fn: (gate) => gate.search,
    target: getSectorListFx,
})


sample({
    clock: selectTownEv,
    source: {filter: $addressFilters, pagination: $addressPagination},
    filter: (data, id) => {
        return !(id === null || id === undefined || id === '' || id === ' ');
    },
    fn: (data, town_id) => {
        if (town_id === null) return false

        const newFilter = `town_id=${town_id}&limit=${data.pagination.limit }` + `&page=${data.pagination.page}`
        return newFilter
    },
    target: [getDistrictsSelectFx, getSelectsForAddressFx],
})


sample({
    clock: selectTownEv,
    fn: (id) => {
        return typeof id === 'number' ? id : undefined
    },
    target: $checkTown,
})


sample({
    clock: [getAddressEv, $addressPagination.updates, universalDictEndpointFx.doneData],
    source: {filter: $addressFilters, pagination: $addressPagination},
    fn: (data) => {
        const newFilter = data.filter + `&limit=${data.pagination.limit }` + `&page=${data.pagination.page}`
        return newFilter
    },
    target: getAddressFx,
})


sample({
    clock: onSwapEv,
    source: $selectedAddress,
    fn: (payload, data, a, b, c) => {
        console.log(a,b,c)
        const actions = []
        const updatedAddress = data.selected.map(item => {
            return {
                object_id: item,
                town_distr_id: data.district
            }
        })

        for (const newDist of Object.values(updatedAddress)) {
            actions.push({operation: 'update', type: 'address', value: {...newDist, }})
        }
        return actions
    },
    target: submitGlobalUpdateEv,
})


sample({
    clock: SwapSelectedSectorsEv,
    fn: (payload) => {
        const actions = []
        const updatedSectors = payload.sectors.map(item => {
            return {
                object_id: item.id,
                inspector_id: payload.newInspector === '-' ? null : payload.newInspector
            }
        })


        for (const newDist of Object.values(updatedSectors)) {
            actions.push({operation: 'update', type: 'sector', value: {...newDist, }})
        }
        return actions
    },
    target: submitGlobalUpdateEv,
})


sample({
    source: SwapSectorGate.state,
    clock: universalDictEndpointFx.doneData,
    fn: (gate) => {
        return gate.search
    },
    target: getSectorListFx,
})
