import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import DatePicker from 'antd/es/date-picker';
import {
    $afterCreateAction, $chosenMainAddress,
    $createdAddressId,
    AddressCreateGate,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    submitCreateAddressEv
} from '../../../models/dictionaryAddressesModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import dayjs from 'dayjs';
import {PlusOutlined} from '@ant-design/icons';
import {
    setCreateDistrictEmbeddedEv,
    setCreateStreetEmbeddedEv, setCreateTownEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import CreateStreetEmbeddedForm
    from '../../../components/Embedded/CreateStreetEmbeddedForm/CreateStreetEmbeddedForm.jsx';
import {
    $distrsByCitySelect,
    $streetsByCitySelect, getDistrsByCityEv,
    getStreetsByCityEv
} from '../../../models/selectsByParentModel/index.js';
import CreateTownEmbeddedForm
    from '../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import CreateDistrictEmbeddedForm
    from "../../../components/Embedded/CreateDistrictEmbeddedForm/CreateDistrictEmbeddedForm.jsx";
import SecondaryAddressTable from "./SecondaryAddressTable.jsx";
import SelectMainAddress from "./SelectMainAddress.jsx";

export default function CreateAddress() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [addressForm] = Form.useForm()

    const submit = useUnit(submitCreateAddressEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const {townOpts, distOpts, streetOpts} = useStoreMap($dictsSelects, sel => ({
        townOpts: sel.towns,
        distOpts: sel.districts,
        streetOpts: sel.streets
    }))

    const getStByTown = useUnit(getStreetsByCityEv)
    const getDistrsTown = useUnit(getDistrsByCityEv)

    const streetsByTown = useUnit($streetsByCitySelect)
    const distrsByTown = useUnit($distrsByCitySelect)
    const setStreetEmbedded = useUnit(setCreateStreetEmbeddedEv)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const setDistEmbedded = useUnit(setCreateDistrictEmbeddedEv)

    const createdId = useUnit($createdAddressId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const chosenMainAddress = useUnit($chosenMainAddress)
    const [isMain, setIsMain] = useState(true)
    const toggleIsMain = () => {
        setIsMain(!isMain)
    }

    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/address/edit/${createdId}`)
            } else if (action === 'new') {
                // addressForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);

    const townSearched = Form.useWatch('town_id', addressForm)
    const distrId = Form.useWatch('town_distr_id', addressForm)
    // const streetId = Form.useWatch('street_id', addressForm)

    return <>
        <AddressCreateGate/>
        <Form form={addressForm} layout={'vertical'}
              size={'small'}
              onFinish={(v) => {
                  const payload = {
                      ...v,
                      actual_from: dayjs(v.actual_from).format('YYYY-MM-DD'),
                  }
                  submit(payload)
              }}
              initialValues={{
                  actual_from: dayjs(new Date()),
              }}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={12}>
                    <Card title={t('Основные свойства')} style={{height: '100%'}} size={'small'}>

                        <Form.Item name={'town_id'} label={t('Населенный пункт')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select allowClear
                                    options={[...townOpts, {label: 'Создать', value: -1, emoji: true}]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setTownEmbedded(true)
                                            getStByTown(undefined)
                                            getDistrsTown(undefined)
                                            // addressForm.setFieldValue('street_id', v)
                                            setStreetEmbedded(true)
                                            setDistEmbedded(true)
                                        } else {
                                            setTownEmbedded(false)
                                            getStByTown(v)
                                            getDistrsTown(v)
                                            setStreetEmbedded(false)
                                            setDistEmbedded(false)
                                            addressForm.resetFields(['town_distr_id', 'street', 'street_id'])
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                      (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                      <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                            <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                          {
                                              option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                          }
                                      </Space>
                                    )}
                            />
                        </Form.Item>
                        <CreateTownEmbeddedForm/>
                        <Form.Item name={'town_distr_id'} label={t('Район')}
                                   rules={distrId < 0 ? [{required: false, message: t('Обязательное поле')}] : null}
                        >
                            <Select allowClear
                                    options={typeof townSearched === 'number' && townSearched >= 0 ? [...distrsByTown, {
                                        label: 'Создать',
                                        value: -1,
                                        emoji: true
                                    }] : [...distOpts, {
                                        label: 'Создать',
                                        value: -1,
                                        emoji: true
                                    }]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setDistEmbedded(true)
                                        } else {
                                            addressForm.resetFields(['district'])
                                            setDistEmbedded(false)
                                        }
                                    }}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                      <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                            <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                          {
                                              option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                          }
                                      </Space>
                                    )}
                                    showSearch
                                    disabled={!(typeof addressForm.getFieldValue('town_id') === 'number') || !isMain}
                            />
                        </Form.Item>
                        <CreateDistrictEmbeddedForm/>
                        <Form.Item name={'street_id'} label={t('Улица')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}>
                            <Select allowClear
                                    options={typeof townSearched === 'number' && townSearched >= 0 ? [...streetsByTown, {
                                        label: 'Создать',
                                        value: -1,
                                        emoji: true
                                    }] : [...streetOpts, {
                                        label: 'Создать',
                                        value: -1,
                                        emoji: true
                                    }]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setStreetEmbedded(true)
                                        } else {
                                            addressForm.resetFields(['street', 'house', 'corpus'])
                                            setStreetEmbedded(false)
                                        }
                                    }}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                      <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                            <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                          {
                                              option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                          }
                                      </Space>
                                    )}
                                    showSearch
                                    disabled={!(typeof addressForm.getFieldValue('town_id') === 'number')}
                            />
                        </Form.Item>
                        <CreateStreetEmbeddedForm form={addressForm} searchedTown={townSearched}/>
                        <Row justify={"space-between"}>
                            <Col span={11}>
                                <Form.Item name={'house'}
                                           label={t('Номер дома')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Input
                                      // disabled={!addressForm.getFieldValue('street_id')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item name={'corpus'} label={t('Номер корпуса')}>
                                    <Input
                                      // disabled={!addressForm.getFieldValue('street_id')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{marginBottom: '24px'}}>
                            <label>{t('Основной адрес: ')}</label>
                            <Switch value={isMain} onChange={toggleIsMain}/>
                        </div>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={t('Свойства адреса')} size={'small'} style={{height: '100%'}}>
                        <Form.Item name={'index'}
                                   label={t('Индекс')}
                          // rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input disabled={!isMain}/>
                        </Form.Item>
                        <Form.Item name={'is_on_crossroad'}
                                   label={t('Находится на перекрестке')}
                          // rules={[{required: true, message: t('Обязательное поле')}]}
                                   valuePropName={'checked'}

                        >
                            <Switch/>
                        </Form.Item>
                        <Form.Item name={'is_actual'}
                                   label={t('Актуален')}
                          // rules={[{required: true, message: t('Обязательное поле')}]}
                                   valuePropName={'checked'}

                        >
                            <Switch value={'checked'}/>
                        </Form.Item>
                        <Form.Item name={'actual_from'}
                                   label={t('Дата начала актуальности')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <DatePicker style={{width: '100%'}}/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Card title={isMain ? t('Выбор вспомогательных адресов') : t('Основной адрес')} size={'small'}>
            {
                isMain ?
                  <SecondaryAddressTable
                    chosenTown={addressForm.getFieldValue('town_id')}
                    chosenDistr={addressForm.getFieldValue('town_distr_id')}
                  />
                  :
                  <SelectMainAddress
                    chosenTown={addressForm.getFieldValue('town_id')}
                    chosenDistr={addressForm.getFieldValue('town_distr_id')}
                  />
            }
        </Card>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    addressForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            addressForm.submit()
                        }}
                        type={'primary'}
                        ghost
                        disabled={!isMain && !chosenMainAddress}
                >
                    {t('Сохранить и создать следующий')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            addressForm.submit()
                        }}
                        type={'primary'}
                        disabled={!isMain && !chosenMainAddress}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
