import {useTranslation} from 'react-i18next';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    CheckOutlined, CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {useStoreMap, useUnit} from 'effector-react';

import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link} from 'react-router-dom';
import {Popconfirm} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import {
    $banksList,
    BanksListGate, deleteBanksEv, getBanksListFx,

} from "../../models/dictionaryBanksModel/index.js";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {$infoMaps} from "../../models/infoModel/index.js";

export default function DictionaryBanksList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($banksList)
    const loading = useUnit(getBanksListFx.pending)
    const onDelete = useUnit(deleteBanksEv)
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.banks`)
    const commissionMap = useStoreMap($infoMaps, maps => maps.bankCommissionMap)

    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            width: '5%',
            title: t(`№ отделения`),
            dataIndex: 'branch',
            sorter: true,
            key: 'branch',
        },
        {
            title: t(`Название ${genitive_form} (рус)`),
            dataIndex: 'name_ru',
            sorter: true,
            key: 'name_ru',
            render: (name, record) => <Link to={`/dictionaries/banks/view/${record.id}`}>{`${name}`}</Link>
        },
        {
            title: t(`Название ${genitive_form} (каз)`),
            dataIndex: 'name_kz',
            sorter: true,
            key: 'name_kz',
            render: (name, record) => <Link to={`/dictionaries/banks/view/${record.id}`}>{`${name}`}</Link>
        },
        {
            title: t('БИК'),
            dataIndex: 'bik',
            sorter: true,
            key: 'bik',
        },
        {
            title: t('БИН'),
            dataIndex: 'bank_bin_iin',
            sorter: true,
            key: 'bank_bin_iin',
        },

        {
            title: t('Наличие договора'),
            dataIndex: 'is_contract_signed',
            sorter: true,
            key: 'is_contract_signed',
            render: (v, r) => {
                return r.is_contract_signed ? <CheckOutlined/> : <CloseCircleOutlined/>
            }
        },
        {
            title: t('Отделение'),
            dataIndex: 'branch',
            sorter: true,
            key: 'branch    ',
        },

        {
            title: t('Адрес'),
            dataIndex: 'address',
            sorter: true,
            key: 'address',
            render: (address) => {
               // FIXME Need id ? and link
                return address

            }
        },

        {
            title: t('Комиссия / Возмещение в ЕРЦ'),
            dataIndex: 'bank_commission_id',
            sorter: true,
            key: 'bank_commission_id',
            // FIXME HON
            // render: (bank_commission_id, record) => bank_commission_id === null ? '- / -' :  <Link to={`/dictionaries/bank_account/view/${bank_commission_id}`}>{`Комиссия / Возмещение в ЕРЦ`}</Link>
            render: (bank_commission_id, record) => {
                // debugger
                if (bank_commission_id === null || bank_commission_id === '-') {
                    return '- / -'

                } else {
                    return <Link to={`/dictionaries/bank-commission/view/${bank_commission_id}`}>{commissionMap[bank_commission_id]}</Link>
                }

            }
        },

        {
            title: t('Договор'),
            dataIndex: 'agreement',
            sorter: true,
            key: 'agreement',
            render: (agreement, record) => <Link to={`/dictionaries/banks/view/${record.id}`}>{`${agreement}`}</Link>
        },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/banks/edit/${id}`}><Button icon={<EditOutlined />} type={'primary'} /></Link>
                </Tooltip>
                <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/banks/view/${id}`}><Button icon={<EyeOutlined />} /></Link>
                </Tooltip>
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => onDelete(id)}
                            placement={'left'}
                >
                <Tooltip title={t('Удалить')} placement={'top'}>
                    <Button icon={<DeleteOutlined />} danger/>
                </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    // banks/&code_name=1&sort_order=asc&ignore_limit=false

    const filters = [
        {name: 'code_name', label: t('Код/Название'), type: 'input'},
        // {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns', multiple: true},
        // {name: 'street_type_id', label: t('Тип'), type: 'select', dict: 'streetTypes', multiple: true},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]



    return <>
        <BanksListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
        />
    </>
}
