import {useStoreMap, useUnit} from 'effector-react';
import {
    $correctionPeriod,
    $massSrvTarifsOpts
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {filterOption, optionRender} from '../../../../utils/selectUtils.jsx';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';
import Row from 'antd/es/row';
import Input from 'antd/es/input';
import {$dictsSelects} from '../../../../models/infoModel/index.js';

export default function TarifForTable({form, fieldPrefix, srv, cor}) {
    const {t} = useTranslation()
    const selPeriod = useUnit($correctionPeriod)

    const massSrvTarifOpts = useUnit($massSrvTarifsOpts)
    const tarifOpts = useMemo(() => {
        return [...massSrvTarifOpts, {label: t('Создать'), value: -1, emoji: true}]
    }, [massSrvTarifOpts])

    const srvUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)

    const selTarif = Form.useWatch([...fieldPrefix, 'tarif_id'], form)

    useEffect(() => {
        if (form && cor) {
            form.setFieldValue([...fieldPrefix, 'tarif_id'], cor.tarif_id)
            form.setFieldValue([...fieldPrefix, 'tarif_date_from'], dayjs(cor.tarif_date_from).isValid() ? dayjs(cor.tarif_date_from) : null)
        } else if (form && srv) {
            const tarif = selPeriod === 'current'
                ? srv?.tariffs
                    ?.toSorted((a, b) => dayjs(b.date_from).unix() - dayjs(a.date_from).unix())
                    ?.find(t => dayjs(t.date_from).isBefore(dayjs(dayjs().endOf('month'))))
                : srv?.tariffs
                    ?.toSorted((a, b) => dayjs(b.date_from).unix() - dayjs(a.date_from).unix())
                    ?.find(t => dayjs(t.date_from).isAfter(dayjs(dayjs(dayjs().endOf('month')).endOf('month'))))
            form.setFieldValue([...fieldPrefix, 'tarif_id'], tarif?.tarif_id)
            form.setFieldValue([...fieldPrefix, 'tarif_date_from'], dayjs(tarif?.date_from).isValid() ? dayjs(tarif?.date_from) : null)
        }
    }, [srv, cor]);

    // const tarif = selPeriod === 'current'
    //     ? srv?.tariffs
    //         ?.toSorted((a, b) => dayjs(b.date_from).unix() - dayjs(a.date_from).unix())
    //         ?.find(t => dayjs(t.date_from).isBefore(dayjs(dayjs().endOf('month'))))
    //     : srv?.tariffs
    //         ?.toSorted((a, b) => dayjs(b.date_from).unix() - dayjs(a.date_from).unix())
    //         ?.find(t => dayjs(t.date_from).isAfter(dayjs(dayjs(dayjs().endOf('month')).endOf('month'))))
    // const initTarif = massSrvTarifOpts?.find(opt => opt?.value === tarif?.tarif_id)?.label ?? '-'
    // const initDate = tarif && tarif?.date_from ? dayjs(tarif?.date_from)?.format('DD.MM.YYYY') : null

    return <Row gutter={[8, 0]}>
        <Col span={12}>
            {/*<p style={{margin: 0}}>{initTarif ?? '-'}</p>*/}
            <Form.Item name={[...fieldPrefix, 'tarif_id']}
                // rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <Select options={tarifOpts}
                        placeholder={t('Тариф')}
                        style={{width: '100%'}}
                        optionRender={optionRender}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        dropdownStyle={{minWidth: 'max-content'}}
                />
            </Form.Item>
        </Col>
        <Col span={12}>
            {/*<p style={{margin: 0}}>{initDate ?? '-'}</p>*/}
            <Form.Item name={[...fieldPrefix, 'tarif_date_from']}
                // rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <DatePicker style={{width: '100%'}}/>
            </Form.Item>
        </Col>
        {selTarif === -1 && <>
            <Col span={12}>
                <Form.Item name={[...fieldPrefix, 'tarif_value']} label={t('Значение тарифа')}
                           rules={selTarif < 0 ? [{required: true, message: t('Обязательное поле')}] : []}
                >
                    <Input style={{width: '100%'}} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={[...fieldPrefix, 'tarif_service_unit_id']} label={t('Единица измерения')}
                           rules={selTarif < 0 ? [{required: true, message: t('Обязательное поле')}] : []}
                >
                    <Select options={srvUnitOpts}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}/>
                </Form.Item>
            </Col>
        </>}
    </Row>
}