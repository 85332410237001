import {useMemo} from 'react';
import {useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import {
    $correctionType, correctionTypeEnum, submitSingleAddFromMassEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import Card from 'antd/es/card';
import Button from 'antd/es/button';
import Add from '../components/Add.jsx';
import Edit from '../components/Edit.jsx';
import TariffMass from '../components/TarifMass.jsx';
import SaldoMass from '../components/SaldoMass.jsx';
import ActivitySubForm from '../../../DictionaryProviders/components/ActivitySubForm.jsx';
import ExtPaymentsMass from '../components/ExtPaymentsMass.jsx';

export default function ServiceCardMass({cardKey, isMass, singleFromMass, disabled, account_id, provider_id, srv_id, form, massEdit}) {
    const {t} = useTranslation()

    const type = useUnit($correctionType)

    const submitSingleAccFromMassMode = useUnit(submitSingleAddFromMassEv)

    const fieldPrefix = useMemo(() => {
        return isMass && !singleFromMass ? [] : [account_id?.toString(), `${provider_id?.toString()}#${srv_id?.toString()}`]
    }, [isMass])


    const onSubmitSingle = async () => {
        try {
            const values = await form.validateFields(fieldPrefix, {recursive: true})
            submitSingleAccFromMassMode({...values[account_id][`${provider_id?.toString()}#${srv_id?.toString()}`], account_id})
        } catch (e) {
            console.log(e)
        }
    }


    const items = useMemo(() => {
        return {
            [correctionTypeEnum.new_acc_to_srv]: <Add form={form} isMass={isMass} singleFromMass={singleFromMass} disabled={disabled} fieldPrefix={fieldPrefix} massEdit={massEdit}/>,
            [correctionTypeEnum.change_existing_params]: <Edit form={form} isMass={isMass} disabled={disabled} fieldPrefix={fieldPrefix} massEdit={massEdit}/>,
            [correctionTypeEnum.change_tariff]: <TariffMass form={form} fieldPrefix={fieldPrefix}/>,
            [correctionTypeEnum.resume_srv]: <ActivitySubForm horizontal form={form} prefix={fieldPrefix} disabled={disabled} disableChecks={massEdit}/>,
            [correctionTypeEnum.change_saldo]: <SaldoMass fieldPrefix={fieldPrefix}/>,
            [correctionTypeEnum.change_ext_payments]: <ExtPaymentsMass fieldPrefix={fieldPrefix}/>,
        }
    }, [])

    return <Card key={cardKey} size={'small'} style={{minHeight: '100%', width: '100%'}}>
        {items[type]}
        {singleFromMass && <Button type={'primary'} size={'small'} onClick={() => onSubmitSingle()}>
            {t('Внести изменения в список изменений')}
        </Button>}
    </Card>
}