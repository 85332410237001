import {
    $correctionList,
    $selectedCorrection,
    $createdCorrectionId, $finalAction, $redirectPath
} from './stores.js';
import {
    getCorrectionListFx,
    getCorrectionByIdFx,
    finishCorrectionFx, cancelCorrectionFx, createCorrectionFx, deleteCorrectionFx,
} from './effects.js';
import {
    CorrectionListGate,
    CorrectionViewGate,
    cancelCorrectionEv,
    createCorrectionEv,
    finishCorrectionEv,
    resetCreatedCorrectionEv, setFinalActionEv, resetFinalActionEv, deleteCorrectionEv,
} from './events.js';
import {sample} from 'effector';
import {ProvSrvAccCorrectionGate} from './provServAcc/index.js';

$correctionList.on(getCorrectionListFx.doneData, (_, data) => data)
    .reset(CorrectionListGate.close)

$selectedCorrection.on(getCorrectionByIdFx.doneData, (_, data) => data)
    .reset([ProvSrvAccCorrectionGate.close, CorrectionViewGate.close])

$createdCorrectionId.on(createCorrectionFx.doneData, (_, res) => res)
    .reset([resetCreatedCorrectionEv])

$finalAction.on(setFinalActionEv, (_, data) => data)
    .reset(resetFinalActionEv)

sample({
    clock: CorrectionListGate.close,
    target: resetCreatedCorrectionEv
})

sample({
    selected: $selectedCorrection,
    clock: ProvSrvAccCorrectionGate.state,
    filter: (selected, gate) => Object.hasOwn(gate, 'id'),
    fn: (selected, gate) => {
        return +gate.id
    },
    target: getCorrectionByIdFx
})

sample({
    source: CorrectionListGate.state,
    clock: [CorrectionListGate.state, deleteCorrectionFx.doneData],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getCorrectionListFx
})

sample({
    clock: createCorrectionEv,
    fn: (payload) => {
        const checked = {...payload}
        if (!checked.object_type) {
            checked.object_type = 'account_provider_service'
        }
        if (!checked.variant) {
            checked.variant = 'add'
        }
        const curUrl = window.location.pathname
        localStorage.setItem('corFrom', curUrl)
        return checked
    },
    target: createCorrectionFx
})


sample({
    source: $selectedCorrection,
    clock: finishCorrectionEv,
    fn: (selected) =>  selected.data.id,
    target: finishCorrectionFx
})

sample({
    clock: deleteCorrectionEv,
    target: deleteCorrectionFx
})


sample({
    source: $selectedCorrection,
    clock: cancelCorrectionEv,
    fn: (selected) => selected && selected?.data?.id,
    target: cancelCorrectionFx
})

sample({
    source: $finalAction,
    clock: [finishCorrectionFx.doneData, cancelCorrectionFx.doneData],
    filter: (afterAction) => afterAction && afterAction === 'close',
    fn: () => {
        return localStorage.getItem('corFrom') ?? '/corrections'
    },
    target: $redirectPath
})

sample({
    source: {current: $selectedCorrection, finalAction: $finalAction},
    clock: finishCorrectionFx.doneData,
    filter: ({finalAction}) => finalAction && finalAction === 'resume',
    fn: ({current}) => {
        const payload = {
            mass: current?.data?.mass,
            object_type: current?.data?.object_type,
            variant: current?.data?.variant
        }
        if (current?.data?.mass === true) {
            payload['service_id'] = current?.data?.service_id
            payload['provider_id'] = current?.data?.provider_id
        }
        return payload
    },
    target: createCorrectionFx
})
