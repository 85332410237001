import {WebSocketProvider} from './api/WebSocketProvider.jsx';
import {useEffect} from 'react';
import {AppGate} from './models/infoModel/index.js';
import {useUnit} from 'effector-react';
import {$authStatus} from './models/authModel/index.js';
import {Outlet, useNavigate} from 'react-router-dom';
import {Layout} from 'antd';
import StickyBox from "react-sticky-box";
import NavMenu from './components/NavMenu/NavMenu.jsx';
import './App.css'
import BreadcrumbsGlobal from './components/BreadcrumbsGlobal/BreadcrumbsGlobal.jsx';
import ProfileDropdown from './components/ProfileDropdown/ProfileDropdown.jsx';
import CloseMonthBlockingModal
    from './components/CloseMonthBlockingModal/CloseMonthBlockingModal.jsx';


function App() {
    const auth = useUnit($authStatus)
    const navigate = useNavigate()

    window.demo_mode = window.location.href.includes('test.astana-erc.kz') ? true : false;

    useEffect(() => {
        if (!auth) {
            navigate('/login')
        }
    }, [auth])

    return (
        <>
            <WebSocketProvider>
                <AppGate/>
                <Layout className={'container-global'}>
                    <Layout.Header className={'container-header'}>
                        <NavMenu/>
                        <ProfileDropdown/>
                    </Layout.Header>
                    <Layout.Content className={'container-content'}>
                        <StickyBox offsetTop={-16} style={{zIndex: 11}}>
                            <BreadcrumbsGlobal/>
                        </StickyBox>
                        <Outlet/>
                    </Layout.Content>
                </Layout>
                <CloseMonthBlockingModal/>
            </WebSocketProvider>
        </>
    )
}

export default App
