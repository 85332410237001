import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Select from 'antd/es/select';
import PlusOutlined from '@ant-design/icons/es/icons/PlusOutlined';
import {
    $afterCreateAction,
    $createdStreetId,
    StreetCreateGate,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    submitCreateStreetEv
} from '../../../models/dictionaryStreetsModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {
    setCreateTownEmbeddedEv,
    setCreateStTypeEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import CreateTownEmbeddedForm
    from '../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import CreateStTypeEmbeddedForm
    from '../../../components/Embedded/CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';
import AddressesTable from '../../../components/Embedded/AddressesTable/AddressesTable.jsx';
import Switch from "antd/es/switch";
import DatePicker from "antd/es/date-picker";
import dayjs from "dayjs";
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";

export default function CreateStreet() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [streetForm] = Form.useForm()

    const submit = useUnit(submitCreateStreetEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const {townsOpts, stTypesOpts} = useStoreMap($dictsSelects, sel => ({
        townsOpts: sel.towns,
        stTypesOpts: sel.streetTypes
    }))

    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const setStreetTypeEmbedded = useUnit(setCreateStTypeEmbeddedEv)

    const createdId = useUnit($createdStreetId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.street`)


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/street/edit/${createdId}`)
            } else if (action === 'new') {
                streetForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <StreetCreateGate/>
        <Form
            form={streetForm} layout={'vertical'} onFinish={(v) => submit(v)}
            initialValues={{
                actual_from: dayjs(new Date()),
            }}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={6}>
                    <Card title={<StarredTitle title={t('Населенный пункт')}/>} style={{height: '100%'}} size={'small'}>
                        <Form.Item name={'town_id'}
                                   rules={[{required: true, message: t('Обязательное поле')}]}>
                            <Select
                                showSearch
                                options={
                                    [
                                        ...townsOpts,
                                        {label: 'Создать', value: -1, emoji: true}
                                    ]
                                }
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}

                                allowClear

                                onChange={(v) => {
                                    if (v === -1) {
                                        setTownEmbedded(true)
                                    } else {
                                        streetForm.resetFields(['town'])
                                        setTownEmbedded(false)
                                    }
                                }}
                            />
                        </Form.Item>
                        <CreateTownEmbeddedForm/>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title={<StarredTitle title={t('Тип улицы')}/>} style={{height: '100%'}} size={'small'}>
                        <Form.Item name={'street_type_id'}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select options={[...stTypesOpts, {
                                label: 'Создать',
                                value: -1,
                                emoji: true
                            }]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setStreetTypeEmbedded(true)
                                        } else {
                                            streetForm.resetFields(['streetType'])
                                            setStreetTypeEmbedded(false)
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                        <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                            {
                                                option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                            }
                                        </Space>
                                    )}
                            />
                        </Form.Item>
                        <CreateStTypeEmbeddedForm/>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title={t('Свойства улицы')} style={{height: '100%'}} size={'small'}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title={t('Актуальность')} style={{height: '100%'}} size={'small'}>
                        <Form.Item
                            name={'is_actual'}
                            label={t('Актуален')}
                            valuePropName={'checked'}

                        >
                            <Switch value={'checked'}/>
                        </Form.Item>
                        <Form.Item
                            name={'actual_from'}
                            label={t('Дата начала актуальности')}
                            rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <DatePicker style={{width: '100%'}}/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
        <Card title={t('Адреса')} size={'small'} style={{marginTop: 16}}>
            <AddressesTable/>
        </Card>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    streetForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            streetForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            streetForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
