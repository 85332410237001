import Form from "antd/es/form";
import Card from "antd/es/card";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Select from "antd/es/select";
import {Checkbox, Segmented} from "antd";
import AccountSearchForm from "../../../../components/AddressReoSearchForms/AccountSearchForm.jsx";
import Switch from "antd/es/switch";
import Space from "antd/es/space";
import Button from "antd/es/button";
import Table from "antd/es/table";
import {paginationConfig} from "../../../../utils/paginationConfig.js";
import InputNumber from "antd/es/input-number";
import ActivitySubForm2 from "../ActivitySubForm2.jsx";
import DatePicker from "antd/es/date-picker";
import {useTranslation} from "react-i18next";
import Divider from "antd/es/divider";
import ActivitySubForm from "../ActivitySubForm.jsx";
import {useState} from "react";

export default function TestCorr() {
    const [form] = Form.useForm()
    const serviceDefaults = {
        s: 45.56,
        q: 3,
        num: 2342342,
        saldo: 1000.0,
        fin_saldo: 1200,
        st_sald: 1000,
        corr: 100,
        accr: 1100,
        pay: 1000,
    }
    const accounts = [{
        account_id: 1,
        name: 'Иванов И.И.',
        street: '1 ул. Петрова',
        town: '0 Астана',
        house: '3',
        corpus: '',
        flat: '1',
        fin_saldo: 1200,
        st_sald: 1000,
        corr: 100,
        accr: 1100,
        pay: 1000,
    }, {
        account_id: 2,
        name: 'Петров И.И.',
        street: '1 ул. Петрова',
        town: '0 Астана',
        house: '3',
        corpus: '',
        flat: '2',
        fin_saldo: 1200,
        st_sald: 1000,
        corr: 100,
        accr: 1100,
        pay: 1000,

    }, {
        account_id: 3,
        name: 'Сидоров И.И.',
        street: '1 ул. Петрова',
        town: '0 Астана',
        house: '3',
        corpus: '',
        flat: '3'
    }, {
        account_id: 4,
        name: 'Федоров И.И.',
        street: '1 ул. Петрова',
        town: '0 Астана',
        house: '3',
        corpus: '',
        flat: '4',
        fin_saldo: 1200,
        st_sald: 1000,
        corr: 100,
        accr: 1100,
        pay: 1000,

    }, {
        account_id: 5,
        name: 'Михайлов И.И.',
        street: '1 ул. Петрова',
        town: '0 Астана',
        house: '3',
        corpus: '',
        flat: '5',
        fin_saldo: 1200,
        st_sald: 1000,
        corr: 100,
        accr: 1100,
        pay: 1000,

    }, {
        account_id: 6,
        name: 'Дмитриев И.И.',
        street: '1 ул. Петрова',
        town: '0 Астана',
        house: '3',
        corpus: '',
        flat: '6',
        fin_saldo: 1200,
        st_sald: 1000,
        corr: 100,
        accr: 1100,
        pay: 1000,

    },]

    const [regime, setRegime] = useState('Режим внесения изменений')
    const onChangeRegime = (v) => {
        setRegime(v)
    }

    const [val, setVal] = useState(1)
    const onChange = (v) => {
        console.log('EEE', v)
        if (v === "Присоединение новых ЛС к Услуге") v = 1
        if (v === "Изменение параметров уже присоединенных ЛС") v = 2
        if (v === "Изменение Тарифа") v = 3
        if (v === "Изменение Сальдо") v = 4
        if (v === "Внешние платежи") v = 5
        if (v === "Возобновление предоставления услуги") v = 6
        setVal(v)
    }
    console.log('XXX', val)
    const PMass = [
        Col,
        PSANewMass, PSAEditMass, PSATarifMass, PSASaldoMass, PSAPayMass, PSARestoreMass
    ][val]

    const PTab = [
        Col,
        PSANewTable, PSAEditTable, PSATarifTable, PSASaldoTable, PSAPayTable, PSARestoreTable
    ][val]

    return <Form
        layout={'vertical'} form={form} size={'small'}
        name={'xxx'}
        initialValues={serviceDefaults}
        preserve={true}
    >
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Card size={'small'} title={'Параметры корректировки'}>
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Form.Item
                                name={['provider_id']}
                                label={'Поставщик'}
                            >
                                <Select
                                    size={'small'}
                                    options={[]}
                                    style={{width: '100%', textAlign: 'left'}}
                                    showSearch
                                    allowClear
                                    disabled={false}
                                    placeholder={'Поставщик'}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['service_id']}
                                label={'Услуга'}
                            >
                                <Select
                                    size={'small'}
                                    options={[]}
                                    style={{width: '100%', textAlign: 'left'}}
                                    showSearch
                                    allowClear
                                    disabled={false}
                                    placeholder={'Услуга'}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={['type']}
                                label={'Тип корректировки'}
                            >
                                <Segmented
                                    size={'small'}
                                    options={["Присоединение новых ЛС к Услуге", "Изменение параметров уже присоединенных ЛС", "Изменение Тарифа", "Изменение Сальдо", "Внешние платежи", "Возобновление предоставления услуги"]}
                                    onChange={onChange}
                                />
                            </Form.Item>
                        </Col>
                        {val === 2 || val === 3 || val === 5 ?
                            <Col span={24}>
                                <Form.Item
                                    name={['period']}
                                    label={'Период оказания услуги'}
                                >
                                    <Segmented
                                        size={'small'}
                                        options={["Текущий расчетный месяц", "Будет оказываться в будущем",]}
                                    />
                                </Form.Item>
                            </Col> : null}
                    </Row>
                </Card>
            </Col>
            <Col span={24}>
                <Divider/>
                <Card size={'small'}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <AccountSearchForm
                                extForm={form} extButtons onlyItems itemsAsRow
                            />
                        </Col>
                        {val !== 1 ?
                            <Col span={24}>
                                <Form.Item
                                    name={['all']}
                                    label={'Все ЛС прикрепленные к услуге поставщика'}
                                >
                                    <Switch/>
                                </Form.Item>
                            </Col> : null}
                        <Button>Найти</Button>
                    </Row>
                </Card>
            </Col>
            <Col span={24}>
                <Card size={'small'} title={'Массовые изменения'}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <PMass form={form}/>
                        </Col>
                        <Col span={24}>
                            <Space>
                                <Button size={'small'}>
                                    Применить к выделенным
                                </Button>
                                <Button size={'small'}>
                                    Применить ко всем попавшим под фильтр
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={24}>
                <PTab accounts={accounts} form={form}/>
            </Col>
            <Col span={24}>
                <Card size={'small'}>
                    <Row gutter={[8, 8]}>
                        <Col span={8}>
                            <Segmented
                                onChange={onChangeRegime}
                                size={'small'}
                                options={["Режим внесения изменений", "Режим просмотра изменений",]}
                            />
                        </Col>
                        {regime === 'Режим просмотра изменений' ?
                        <Col span={4}>
                            <Button size={'small'} danger>
                                Очистить список изменений
                            </Button>
                        </Col> : null }
                    </Row>
                </Card>
            </Col>
            <Col span={24}>
                <Divider/>
                <Space>
                    <Button size={'small'} danger>
                        Отменить и закрыть
                    </Button>
                    <Button size={'small'} type={'primary'}>
                        Сохранить и продолжить корректировку
                    </Button>
                    <Button size={'small'}>
                        Сохранить и закрыть
                    </Button>
                </Space>
            </Col>
        </Row>
    </Form>
}

function PSANewMass({form}) {
    const {t} = useTranslation()

    return <Row gutter={[8, 8]}>
        <Col span={14}>
            <Row gutter={[8, 8]}>
                <Col span={4}>
                    <Card extra={<Checkbox size={'small'} checked/>} title={('Площадь')}>
                        <Form.Item name={'s'}>
                            <InputNumber
                                step={0.1}
                                style={{width: '100%'}}
                                parser={val => {
                                    if (val.includes(',')) {
                                        val = val.replace(',', '.')
                                    }
                                    return Number(val)
                                }}
                                formatter={(val) => val.toString().replace('.', ',')}
                            />
                        </Form.Item>
                    </Card>
                </Col>
                {/*<Col span={3}>*/}
                {/*    <Card extra={<Checkbox size={'small'} checked/>} title={('Кол-во')}>*/}
                {/*        <Form.Item name={'q'}>*/}
                {/*            <InputNumber min={0} style={{width: '100%'}}/>*/}
                {/*        </Form.Item>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                <Col span={4}>
                    <Card extra={<Checkbox size={'small'} checked/>} title={('Нач. сальдо')}>
                        <Form.Item name={'saldo'}>
                            <InputNumber
                                step={0.1}
                                style={{width: '100%'}}
                                parser={val => {
                                    if (val.includes(',')) {
                                        val = val.replace(',', '.')
                                    }
                                    return Number(val)
                                }}
                                formatter={(val) => val.toString().replace('.', ',')}
                            />
                        </Form.Item>
                    </Card>
                </Col>
                <Col span={5}>
                    <Card extra={<Checkbox size={'small'}/>} title={t('Тариф')}>
                        <Form.Item name={'tarif_id'}>
                            <Select options={[]}
                                    placeholder={'Тариф'}
                                    style={{width: '100%'}}
                                    showSearch
                                    allowClear
                            />
                        </Form.Item>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card extra={<Checkbox size={'small'}/>} title={t('Дата тарифа')}>
                        <Form.Item name={'date_from'}>
                            <DatePicker/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Col>
        <Col span={10}>
            <Card extra={<Checkbox size={'small'}/>}>
                <ActivitySubForm2 form={form}/>
            </Card>
        </Col>
    </Row>
}

function PSAEditMass({form}) {
    const {t} = useTranslation()

    return <Row gutter={[8, 8]}>
        <Col span={8}>
            <Row gutter={[8, 8]}>
                <Col span={8}>
                    <Card extra={<Checkbox size={'small'} checked/>} title={('Площадь')}>
                        <Form.Item name={'s'}>
                            <InputNumber
                                step={0.1}
                                style={{width: '100%'}}
                                parser={val => {
                                    if (val.includes(',')) {
                                        val = val.replace(',', '.')
                                    }
                                    return Number(val)
                                }}
                                formatter={(val) => val.toString().replace('.', ',')}
                            />
                        </Form.Item>
                    </Card>
                </Col>
                {/*<Col span={8}>*/}
                {/*    <Card extra={<Checkbox size={'small'} checked/>} title={('Кол-во')}>*/}
                {/*        <Form.Item name={'q'}>*/}
                {/*            <InputNumber min={0} style={{width: '100%'}}/>*/}
                {/*        </Form.Item>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
            </Row>
        </Col>
        <Col span={16}>
            <Card extra={<Checkbox size={'small'}/>}>
                <ActivitySubForm form={form}/>
            </Card>
        </Col>
    </Row>
}

function PSATarifMass({form}) {
    const {t} = useTranslation()

    return <Row gutter={[8, 8]}>
        <Col span={5}>
            <Card extra={<Checkbox size={'small'}/>} title={t('Тариф')}>
                <Form.Item name={'tarif_id'}>
                    <Select options={[]}
                            placeholder={'Тариф'}
                            style={{width: '100%'}}
                            showSearch
                            allowClear
                    />
                </Form.Item>
            </Card>
        </Col>
        <Col span={4}>
            <Card extra={<Checkbox size={'small'}/>} title={t('Дата тарифа')}>
                <Form.Item name={'date_from'}>
                    <DatePicker/>
                </Form.Item>
            </Card>
        </Col>
    </Row>
}

function PSASaldoMass({form}) {
    const {t} = useTranslation()

    return <Row gutter={[8, 8]}>
        <Col span={8}>
            <Row gutter={[8, 8]}>
                <Col span={8}>
                    <Card extra={<Checkbox size={'small'} checked/>} title={('Корректировка')}>
                        <Form.Item name={'s'}>
                            <InputNumber
                                step={0.1}
                                style={{width: '100%'}}
                                parser={val => {
                                    if (val.includes(',')) {
                                        val = val.replace(',', '.')
                                    }
                                    return Number(val)
                                }}
                                formatter={(val) => val.toString().replace('.', ',')}
                            />
                        </Form.Item>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card extra={<Checkbox size={'small'}/>} title={('Конечное сальдо')}>
                        <Form.Item name={'q'}>
                            <InputNumber min={0} style={{width: '100%'}}/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Col>
    </Row>
}

function PSAPayMass({form}) {
    const {t} = useTranslation()

    return <Row gutter={[8, 8]}>
        <Col span={5}>
            <Form.Item name={'tarif_id'} label={t('Сумма')}>
                <InputNumber placeholder={'Сумма'} style={{width: '100%'}}/>
            </Form.Item>
        </Col>
        {/*<Col span={4}>*/}
        {/*    <Form.Item name={'date_from'} label={t('Дата платежа')}>*/}
        {/*        <DatePicker/>*/}
        {/*    </Form.Item>*/}
        {/*</Col>*/}
    </Row>
}

function PSARestoreMass({form}) {
    const {t} = useTranslation()

    return <Row gutter={[8, 8]}>
        <Col span={16}>
            <ActivitySubForm form={form}/>
        </Col>
    </Row>
}

function PSANewTable({accounts, form}) {
    const {t} = useTranslation()

    const columns = [
        {
            title: 'Номер ЛС', // width: '10%',
            dataIndex: 'account_id', render: (id) => id
        }, {
            title: 'ФИО', // width: '10%',
            dataIndex: 'name', render: (id) => id
        }, {
            title: 'Нас. пункт', // width: '10%',
            dataIndex: 'town', render: (id) => id
        }, {
            title: 'Улица', // width: '10%',
            dataIndex: 'street', render: (id) => id
        }, {
            title: 'Дом', // width: '10%',
            dataIndex: 'house', render: (id) => id
        }, {
            title: 'Корпус', // width: '10%',
            dataIndex: 'corpus', render: (id) => id
        },
        {
            title: 'Кв.', // width: '10%',
            dataIndex: 'flat', render: (id) => id
        },
        {
            // title: 'Кв.',
            width: '100px',
            dataIndex: 'flat', render: (_) => <Button size={'small'} danger>Исключить</Button>
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }, // getCheckboxProps: (record) => ({
        //     disabled: record.name === 'Disabled User',
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };

    return <Table
        dataSource={accounts}
        columns={columns}
        bordered
        className={'table-container'}
        pagination={paginationConfig}
        size={'small'}
        rowKey={'account_id'}
        rowSelection={{
            type: 'checkbox', ...rowSelection,
        }}
        expandable={{
            expandedRowRender: (_) => (<>
                <Row gutter={[8, 8]}>
                    <Col span={14}>
                        <Row gutter={[8, 8]}>
                            <Col span={4}>
                                <Form.Item name={'s'} label={('Площадь')}>
                                    <InputNumber
                                        step={0.1}
                                        style={{width: '100%'}}
                                        parser={val => {
                                            if (val.includes(',')) {
                                                val = val.replace(',', '.')
                                            }
                                            return Number(val)
                                        }}
                                        formatter={(val) => val.toString().replace('.', ',')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item name={'q'} label={('Кол-во')}>
                                    <InputNumber min={0} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name={'n'} label={('№ дог./ИИН')}>
                                    <InputNumber min={0} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name={'saldo'} label={('Нач. сальдо')}>
                                    <InputNumber
                                        step={0.1}
                                        style={{width: '100%'}}
                                        parser={val => {
                                            if (val.includes(',')) {
                                                val = val.replace(',', '.')
                                            }
                                            return Number(val)
                                        }}
                                        formatter={(val) => val.toString().replace('.', ',')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name={'tarif_id'} label={t('Тариф')}>
                                    <Select options={[]}
                                            placeholder={'Тариф'}
                                            style={{width: '100%'}}
                                            showSearch
                                            allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name={'date_from'} label={t('Дата тарифа')}>
                                    <DatePicker/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={10}>
                        <ActivitySubForm2 form={form}/>
                    </Col>
                    <Col span={24} style={{marginBottom: '16px'}}>
                        <Button size={'small'}>Применить</Button>
                    </Col>
                </Row>
            </>), defaultExpandAllRows: true,
        }}
    />
}

function PSAEditTable({accounts, form}) {
    const {t} = useTranslation()

    const columns = [{
        title: 'Номер ЛС', // width: '10%',
        dataIndex: 'account_id', render: (id) => id
    }, {
        title: 'ФИО', // width: '10%',
        dataIndex: 'name', render: (id) => id
    }, {
        title: 'Нас. пункт', // width: '10%',
        dataIndex: 'town', render: (id) => id
    }, {
        title: 'Улица', // width: '10%',
        dataIndex: 'street', render: (id) => id
    }, {
        title: 'Дом', // width: '10%',
        dataIndex: 'house', render: (id) => id
    }, {
        title: 'Корпус', // width: '10%',
        dataIndex: 'corpus', render: (id) => id
    }, {
        title: 'Кв.', // width: '10%',
        dataIndex: 'flat', render: (id) => id
    }, {
        // title: 'Кв.',
        width: '100px',
        dataIndex: 'flat', render: (_) => <Button size={'small'} danger>Исключить</Button>
    },]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }, // getCheckboxProps: (record) => ({
        //     disabled: record.name === 'Disabled User',
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };

    return <Table
        dataSource={accounts}
        columns={columns}
        bordered
        className={'table-container'}
        pagination={paginationConfig}
        size={'small'}
        rowKey={'account_id'}
        rowSelection={{
            type: 'checkbox', ...rowSelection,
        }}
        expandable={{
            expandedRowRender: (_) => (<>
                <Row gutter={[8, 8]}>
                    <Col span={8}>
                        <Row gutter={[8, 8]}>
                            <Col span={8}>
                                <Form.Item name={'s'} label={('Площадь')}>
                                    <InputNumber
                                        step={0.1}
                                        style={{width: '100%'}}
                                        parser={val => {
                                            if (val.includes(',')) {
                                                val = val.replace(',', '.')
                                            }
                                            return Number(val)
                                        }}
                                        formatter={(val) => val.toString().replace('.', ',')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'q'} label={('Кол-во')}>
                                    <InputNumber min={0} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'n'} label={('№ дог./ИИН')}>
                                    <InputNumber min={0} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={16}>
                        <ActivitySubForm form={form}/>
                    </Col>
                    <Col span={24} style={{marginBottom: '16px'}}>
                        <Button size={'small'}>Применить</Button>
                    </Col>
                </Row>
            </>), defaultExpandAllRows: true,
        }}
    />
}

function PSATarifTable({accounts, form}) {
    const {t} = useTranslation()

    const columns = [{
        title: 'Номер ЛС', // width: '10%',
        dataIndex: 'account_id', render: (id) => id
    }, {
        title: 'ФИО', // width: '10%',
        dataIndex: 'name', render: (id) => id
    }, {
        title: 'Нас. пункт', // width: '10%',
        dataIndex: 'town', render: (id) => id
    }, {
        title: 'Улица', // width: '10%',
        dataIndex: 'street', render: (id) => id
    }, {
        title: 'Дом', // width: '10%',
        dataIndex: 'house', render: (id) => id
    }, {
        title: 'Корпус', // width: '10%',
        dataIndex: 'corpus', render: (id) => id
    }, {
        title: 'Кв.', // width: '10%',
        dataIndex: 'flat', render: (id) => id
    }, {
        title: 'Тариф', width: '300px', dataIndex: 'tarif', render: (id) => <Row gutter={[8, 0]}>
            <Col span={24}>
                1 000.00 тг/чел
            </Col>
            <Col span={12}>
                <Form.Item name={'tarif_id'} style={{marginBottom: 0}}>
                    <Select options={[]}
                            placeholder={'Тариф'}
                            style={{width: '100%'}}
                            showSearch
                            allowClear
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={'date_from'} style={{marginBottom: 0}}>
                    <DatePicker/>
                </Form.Item>
            </Col>
        </Row>
    }, {
        // title: 'Кв.',
        width: '100px',
        dataIndex: 'flat',
        render: (_) => <Button size={'small'} danger>Исключить</Button>
    },]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }, // getCheckboxProps: (record) => ({
        //     disabled: record.name === 'Disabled User',
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };

    const paginationConfig = {
        defaultPageSize: 10,
        defaultCurrent: 1,
        pageSizeOptions: [10, 20, 100],
        showSizeChanger: true,
        position: ['bottomCenter'],
    }

    return <Table
        dataSource={accounts}
        columns={columns}
        bordered
        className={'table-container'}
        pagination={paginationConfig}
        size={'small'}
        rowKey={'account_id'}
        rowSelection={{
            type: 'checkbox', ...rowSelection,
        }}
        footer={() => <Button size={'small'}>Применить изменения в таблице</Button>}
    />
}

function PSASaldoTable({accounts, form}) {
    const {t} = useTranslation()

    const columns = [{
        title: 'Номер ЛС', // width: '10%',
        dataIndex: 'account_id', render: (id) => id
    }, {
        title: 'ФИО', // width: '10%',
        dataIndex: 'name', render: (id) => id
    }, {
        title: 'Нас. пункт', // width: '10%',
        dataIndex: 'town', render: (id) => id
    }, {
        title: 'Улица', // width: '10%',
        dataIndex: 'street', render: (id) => id
    }, {
        title: 'Дом', // width: '10%',
        dataIndex: 'house', render: (id) => id
    }, {
        title: 'Корпус', // width: '10%',
        dataIndex: 'corpus', render: (id) => id
    }, {
        title: 'Кв.', // width: '10%',
        dataIndex: 'flat', render: (id) => id
    }, {
        title: 'Нач. сальдо', width: '100px', dataIndex: 'st_sald', render: (id) => id
    }, {
        title: 'Корректировка',
        width: '100px',
        dataIndex: 'corr',
        render: (id) => <Form.Item name={'corr'} style={{marginBottom: 0}}>
            <InputNumber
                step={0.1}
                style={{width: '100%'}}
                parser={val => {
                    if (val.includes(',')) {
                        val = val.replace(',', '.')
                    }
                    return Number(val)
                }}
                formatter={(val) => val.toString().replace('.', ',')}
            />
        </Form.Item>
    }, {
        title: 'Начилс.', width: '100px', dataIndex: 'accr', render: (id) => id
    }, {
        title: 'Оплаты', width: '100px', dataIndex: 'pay', render: (id) => id
    }, {
        title: 'Кон. сальдо',
        width: '100px',
        dataIndex: 'fin_saldo',
        render: (id) => <Form.Item name={'fin_saldo'} style={{marginBottom: 0}}>
            <InputNumber min={0} style={{width: '100%'}}/>
        </Form.Item>
    }, {
        // title: 'Кв.',
        width: '100px',
        dataIndex: 'flat',
        render: (_) => <Button size={'small'} danger>Исключить</Button>
    },

    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }, // getCheckboxProps: (record) => ({
        //     disabled: record.name === 'Disabled User',
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };

    return <Table
        dataSource={accounts}
        columns={columns}
        bordered
        className={'table-container'}
        pagination={paginationConfig}
        size={'small'}
        rowKey={'account_id'}
        rowSelection={{
            type: 'checkbox', ...rowSelection,
        }}
        footer={() => <Button size={'small'}>Применить изменения в таблице</Button>}
    />
}

function PSAPayTable({accounts, form}) {
    const {t} = useTranslation()

    const columns = [{
        title: 'Номер ЛС', // width: '10%',
        dataIndex: 'account_id', render: (id) => id
    }, {
        title: 'ФИО', // width: '10%',
        dataIndex: 'name', render: (id) => id
    }, {
        title: 'Нас. пункт', // width: '10%',
        dataIndex: 'town', render: (id) => id
    }, {
        title: 'Улица', // width: '10%',
        dataIndex: 'street', render: (id) => id
    }, {
        title: 'Дом', // width: '10%',
        dataIndex: 'house', render: (id) => id
    }, {
        title: 'Корпус', // width: '10%',
        dataIndex: 'corpus', render: (id) => id
    }, {
        title: 'Кв.', // width: '10%',
        dataIndex: 'flat', render: (id) => id
    }, {
        title: 'Платежи', width: '300px', dataIndex: 'tarif', render: (id) => <Row gutter={[8, 0]}>
            <Col span={24}>
                6 000.00 тг
            </Col>
            <Col span={12}>
                <Form.Item name={'tarif_id'} style={{marginBottom: 0}}>
                    <InputNumber placeholder={'Сумма'} style={{width: '100%'}}/>
                </Form.Item>
            </Col>
            {/*<Col span={12}>*/}
            {/*    <Form.Item name={'date_from'} style={{marginBottom: 0}}>*/}
            {/*        <DatePicker placeholder={"Дата платежа"}/>*/}
            {/*    </Form.Item>*/}
            {/*</Col>*/}
        </Row>
    }, {
        // title: 'Кв.',
        width: '100px',
        dataIndex: 'flat',
        render: (_) => <Button size={'small'} danger>Исключить</Button>
    },]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }, // getCheckboxProps: (record) => ({
        //     disabled: record.name === 'Disabled User',
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };

    return <Table
        dataSource={accounts}
        columns={columns}
        bordered
        className={'table-container'}
        pagination={paginationConfig}
        size={'small'}
        rowKey={'account_id'}
        rowSelection={{
            type: 'checkbox', ...rowSelection,
        }}
        footer={() => <Button size={'small'}>Применить изменения в таблице</Button>}
    />
}

function PSARestoreTable({accounts, form}) {
    const {t} = useTranslation()

    const columns = [
        {
            title: 'Номер ЛС', // width: '10%',
            dataIndex: 'account_id', render: (id) => id
        }, {
            title: 'ФИО', // width: '10%',
            dataIndex: 'name', render: (id) => id
        }, {
            title: 'Нас. пункт', // width: '10%',
            dataIndex: 'town', render: (id) => id
        }, {
            title: 'Улица', // width: '10%',
            dataIndex: 'street', render: (id) => id
        }, {
            title: 'Дом', // width: '10%',
            dataIndex: 'house', render: (id) => id
        }, {
            title: 'Корпус', // width: '10%',
            dataIndex: 'corpus', render: (id) => id
        },
        {
            title: 'Кв.', // width: '10%',
            dataIndex: 'flat', render: (id) => id
        },
        {
            // title: 'Кв.',
            width: '550px',
            dataIndex: 'flat', render: (id) => <ActivitySubForm2 form={form}/>
        },
        {
            // title: 'Кв.',
            width: '100px',
            dataIndex: 'flat', render: (_) => <Button size={'small'} danger>Исключить</Button>
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }, // getCheckboxProps: (record) => ({
        //     disabled: record.name === 'Disabled User',
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };

    return <Table
        dataSource={accounts}
        columns={columns}
        bordered
        className={'table-container'}
        pagination={paginationConfig}
        size={'small'}
        rowKey={'account_id'}
        rowSelection={{
            type: 'checkbox', ...rowSelection,
        }}
        footer={() => <Button size={'small'}>Применить изменения в таблице</Button>}
    />
}
