import React from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {$paymentsData, getPaymentsDataFx, PaymentsGate} from "../../models/paymentsModel/index.js";
import {searchAndActions} from "../../utils/searchAndActions.js";
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import Table from "antd/es/table";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import InnerTable from "./components/InnerTable.jsx";

const options = [
	{
		label: 'True',
		value: true,
	},
	{
		label: 'False',
		value: false,
	},
	{
		label: 'undefined',
		value: undefined,
	}
]

function PaymentsPage() {
	const {t} = useTranslation()
	const {data, count} = useUnit($paymentsData)
	const {search} = searchAndActions()
	const loading = useUnit(getPaymentsDataFx.pending)

	const columns = [
		{
			title: t('report_date'),
			dataIndex: 'report_date',
			key: 'report_date',
		},
		{
			title: t('epd_id'),
			dataIndex: 'epd_id',
			key: 'epd_id',
		},
		{
			title: t('invoice'),
			dataIndex: 'invoice',
			key: 'invoice',
		},
		{
			title: t('account_id'),
			dataIndex: 'account_id',
			key: 'account_id',
		},
		{
			title: t('bank_payment_date'),
			dataIndex: 'bank_payment_date',
			key: 'bank_payment_date',
		},
		{
			title: t('sysref'),
			dataIndex: 'sysref',
			key: 'sysref',
		},
		{
			title: t('Банк'),
			dataIndex: 'bank_id',
			key: 'bank_id',
			render: (bank_id) => <MappedDictItem id={bank_id} type={'bank'} link={false}/>,
		},
		{
			title: t('sysbankref'),
			dataIndex: 'sysbankref',
			key: 'sysbankref',
		},
		{
			title: t('is_confirmed'),
			dataIndex: 'is_confirmed',
			key: 'is_confirmed',
			render: (is_confirmed) => (
				is_confirmed ?
					<CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
					:
					<CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
			)
		},
		{
			title: t('is_rollback'),
			dataIndex: 'is_rollback',
			key: 'is_rollback',
			render: (is_rollback) => (
				is_rollback ?
					<CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
					:
					<CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
			)
		},
		{
			title: t('erc_confirm_date'),
			dataIndex: 'erc_confirm_date',
			key: 'erc_confirm_date',
		},
		{
			title: t('rollback_date'),
			dataIndex: 'rollback_date',
			key: 'rollback_date',
		}
	]

	const filters = [
		{name: 'sysref', label: t('sysref'), type: 'input'},
		{name: 'bank_id', label: t('bank_id'), type: 'select', dict: 'bank'},
		{name: 'sysbankref', label: t('sysbankref'), type: 'input'},
		{name: 'is_confirmed', label: t('подтвержден'), type: 'select', options},
		{name: 'is_rollback', label: t('откачен'), type: 'select', options},
		{name: 'code_name', label: t('code_name'), type: 'input'},
		{name: 'report_date', label: t('report_date'), type: 'date'},
		{name: 'bank_payment_date_from', label: t('bank_payment_date_from'), type: 'date'},
		{name: 'bank_payment_date_to', label: t('bank_payment_date_to'), type: 'date'},
		{name: 'erc_confirm_date_from', label: t('erc_confirm_date_from'), type: 'date'},
		{name: 'erc_confirm_date_to', label: t('erc_confirm_date_to'), type: 'date'},
		{name: 'rollback_date_from', label: t('rollback_date_from'), type: 'date'},
		{name: 'rollback_date_to', label: t('rollback_date_to'), type: 'date'},
		{name: 'service_id', label: t('service_id'), type: 'select', dict: 'services'},
	]

	return <>
		<PaymentsGate search={search.toString()}/>

		<FilterBlock items={filters}/>
		<DictionaryProTable
			data={data}
			count={count}
			initColumns={columns}
			loading={loading}
			showActions={false}
			expandable={{
				expandedRowRender: (record) => (
					<InnerTable id={record.id}/>
				)
			}}
		/>
	</>
}

export default PaymentsPage;
