import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import {$selectedServices, getServicesByIdFx, ServicesViewGate} from "../../../models/dictionaryServicesModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";

export default function ViewServices() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getServicesByIdFx.pending)
    const data = useUnit($selectedServices)
    const genitive_form = t(`breadcrumbs.genitive.services`);

    const items = [
        {
            key: 'name_kz',
            label: t(`Название ${genitive_form} (каз)`),
            children: data?.name_kz
        },
        {
            key: 'name_ru',
            label: t(`Название ${genitive_form} (рус)`),
            children: data?.name_ru
        },
        {
            key: 'short_name_kz',
            label: t(`Короткое Название ${genitive_form} (каз)`),
            children: data?.short_name_kz
        },
        {
            key: 'short_name_ru',
            label: t(`Короткое Название ${genitive_form} (рус)`),
            children: data?.short_name_ru
        },
        {
            key: 'service_payment_type_id',
            label: t('Вид платежа'),
            children: <MappedDictItem
                id={data?.service_payment_type_id} type={'service-payment-type'}
            />
        },
        {
            key: 'service_class_id',
            label: t('Класс услуг'),
            children: <MappedDictItem id={data?.service_class_id} type={'service-classes'}/>,
        },
        {
            key: 'service_payment_type_id',
            label: t('Вид платежа'),
            children: <MappedDictItem id={data?.service_payment_type_id} type={'service-payment-type'}/>,
        },
        {
            key: 'epd_order',
            label: t('Порядок ЕПД'),
            children: data?.epd_order
        },
        {
            key: 'template_id',
            label: t('Шаблон'),
            children: <MappedDictItem id={data?.template_id} type={'templates'}/>
        },

    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <ServicesViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр Услуги')}</span> <MappedDictItem id={id} type={'services'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'services'}/>
        </Card>
    </>
}
