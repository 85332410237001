import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const AccountsListGate = createGate()
export const AccountViewGate = createGate()
export const AccountEditGate = createGate()
export const AccountCreateGate = createGate()

export const AccrualsGate = createGate()
export const PaymentsGate = createGate()

export const submitCreateAccountEv = createEvent()
export const submitEditAccountEv = createEvent()
export const deleteAccountEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()

export const selectProviderEv = createEvent()
export const selectServiceEv = createEvent()
export const editExternalNumberEv = createEvent()

export const setSexEmbeddedEv = createEvent()
export const setNationEmbeddedEv = createEvent()
export const setPrefCatEmbeddedEv = createEvent()
export const setConsCatEmbeddedEv = createEvent()
