import {useTranslation} from 'react-i18next';
import Col from 'antd/es/col';
import InputNumber from 'antd/es/input-number';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import {useEffect} from 'react';

export default function ExtPaymentsForTable({form, fieldPrefix, srv, existCorr}) {
    const {t} = useTranslation()

    useEffect(() => {
        if (existCorr && srv && form) {
            const {sum_saldo_begin, correction_value, ex_payment_value, saldo_end_value} = existCorr
            const {accrual, payments} = srv

            form.setFieldValue([...fieldPrefix, 'sum_saldo_begin'], sum_saldo_begin ?? 0)
            form.setFieldValue([...fieldPrefix, 'correction_value'], correction_value ?? 0)
            form.setFieldValue([...fieldPrefix, 'accrual'], accrual ?? 0)
            form.setFieldValue([...fieldPrefix, 'payments'], payments ?? 0)
            form.setFieldValue([...fieldPrefix, 'ex_payment_value'], ex_payment_value ?? 0)
            form.setFieldValue([...fieldPrefix, 'saldo_end_value'], saldo_end_value ?? 0)
        } else if (srv && form) {
            const {ex_payments, accrual, payments} = srv
            if (accrual !== null) {
                form.setFieldValue([...fieldPrefix, 'accrual'], accrual?.sum_calc ?? 0)
                form.setFieldValue([...fieldPrefix, 'sum_saldo_begin'], accrual?.sum_saldo_begin ?? 0)
                form.setFieldValue([...fieldPrefix, 'correction_value'], accrual?.sum_recalc ?? 0)
                form.setFieldValue([...fieldPrefix, 'saldo_end_value'], accrual?.sum_saldo_end ?? 0)
            }

            form.setFieldValue([...fieldPrefix, 'ex_payment_value'], ex_payments)
            form.setFieldValue([...fieldPrefix, 'payments'], payments)
        }
    }, [srv, existCorr]);

    const beginSaldo = Form.useWatch([...fieldPrefix, 'sum_saldo_begin'], form) ?? 0
    const accrualVal = Form.useWatch([...fieldPrefix, 'accrual'], form) ?? 0
    const paymentsVal = Form.useWatch([...fieldPrefix, 'payments'], form) ?? 0
    const correctionVal = Form.useWatch([...fieldPrefix, 'correction_value'], form) ?? 0

    return <Row>
        <Col span={24}>
            <Row justify={'space-between'} align={'bottom'}>
                <Col span={4}>
                    <Form.Item name={[...fieldPrefix, 'sum_saldo_begin']} label={t('Нач. сальдо')}>
                        <InputNumber placeholder={'-'} disabled style={{border: 'none', backgroundColor: 'transparent', color: 'rgba(0, 0, 0, 0.88)', width: '100%'}}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={[...fieldPrefix, 'correction_value']} label={t('Корректировка')}>
                        <InputNumber placeholder={'-'} disabled style={{border: 'none', backgroundColor: 'transparent', color: 'rgba(0, 0, 0, 0.88)', width: '100%'}}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={[...fieldPrefix, 'accrual']} label={t('Начисления')}>
                        <InputNumber placeholder={'-'} disabled style={{border: 'none', backgroundColor: 'transparent', color: 'rgba(0, 0, 0, 0.88)', width: '100%'}}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={[...fieldPrefix, 'payments']} label={t('Оплаты')}>
                        <InputNumber placeholder={'-'} disabled style={{border: 'none', backgroundColor: 'transparent', color: 'rgba(0, 0, 0, 0.88)', width: '100%'}}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={[...fieldPrefix, 'ex_payment_value']} label={t('Сторон. платеж')}>
                        <InputNumber style={{width: '80%'}}
                                     onBlur={({target}) =>
                                         form.setFieldValue(
                                             [...fieldPrefix, 'saldo_end_value'],
                                             (Number(beginSaldo ?? 0) + Number(accrualVal ?? 0) + Number(correctionVal ?? 0) - Number(paymentsVal ?? 0) - Number(target.value ?? 0))
                                         )}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={[...fieldPrefix, 'saldo_end_value']} label={t('Кон. сальдо')}>
                        <InputNumber placeholder={'-'} disabled style={{border: 'none', backgroundColor: 'transparent', color: 'rgba(0, 0, 0, 0.88)', width: '100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Col>
    </Row>
}