import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useNavigate, useParams} from 'react-router-dom';
import StickyBox from 'react-sticky-box'
import {
    $afterEditAction,
    $embeddedBaForm,
    $provAgreementsFiles, $providerDefaultBankAccount,
    $providerEditStatus, $selectedProvider,
    getProviderByIdFx,
    ProviderEditGate, resetAfterEditAttrs, setAfterEditActionEv, setBaEmbeddedFormEv
} from '../../../models/dictionaryProvidersModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import Tabs from 'antd/es/tabs';
import ProviderMainForm from './tabComponents/ProviderMainForm.jsx';
import ProvServForm from './tabComponents/ProvServForm2.jsx';
import ProvServAccForm from './tabComponents/ProvServAccForm.jsx';
import ProvServAccTariffForm from './tabComponents/ProvServAccTariffForm.jsx';
import ProvServREOForm from './tabComponents/ProvServREOForm.jsx';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Form from 'antd/es/form';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import Skeleton from 'antd/es/skeleton';
import Tooltip from 'antd/es/tooltip';
import {HomeOutlined} from "@ant-design/icons";

export default function EditProvider() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id} = useParams()

    const selectedProvider = useUnit($selectedProvider)

    const [providerForm] = Form.useForm()
    const loading = useUnit(getProviderByIdFx.pending)
    const editLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($providerEditStatus)
    const filesToUpload = useUnit($provAgreementsFiles)

    const defaultBa = useUnit($providerDefaultBankAccount)
    const embeddedOpen = useUnit($embeddedBaForm)
    const setEmbeddedBA = useUnit(setBaEmbeddedFormEv)

    const action = useUnit($afterEditAction)
    const setAction = useUnit(setAfterEditActionEv)
    const resetAttrs = useUnit(resetAfterEditAttrs)

    const [activeTab, setActiveTab] = useState('main')

    useEffect(() => {
        if (status && filesToUpload.length === 0) {
            if (action === 'list') {
                providerForm.resetFields()
                navigate(`/dictionaries/providers`)
            }
            resetAttrs()
        }
    }, [status, action, filesToUpload]);

    const tabs = [
        {
            key: 'main',
            label: t('Основная информация'),
            children: <ProviderMainForm form={providerForm} edit={true}/>
        },
        {
            key: 'provServ',
            label: t('Услуги и Тарифы'),
            disabled: selectedProvider?.is_beneficiary,
            children: <ProvServForm />
        },
        // {
        //     key: 'provServTariff',
        //     disabled: selectedProvider?.is_beneficiary,
        //     label: t('Тарифы'),
        //     children: <ProvServTariffForm />
        // },
        {
            key: 'provServAcc',
            disabled: selectedProvider?.is_beneficiary,
            label: t('ЛС'),
            children: <ProvServAccForm />
        },
        {
            key: 'provServAccTariff',
            disabled: selectedProvider?.is_beneficiary,
            label: t('Тарифы ЛС'),
            children: <ProvServAccTariffForm />
        },
        {
            key: 'provServReo',
            disabled: selectedProvider?.is_beneficiary,
            label: <HomeOutlined />,
            children: <ProvServREOForm />
        },
        // {
        //     key: 'provServCommission',
        //     disabled: selectedProvider?.is_beneficiary,
        //     label: t('Комиссии банков'),
        //     children: <ProvServCommissionForm/>
        // },
        // {
        //     key: 'provServPrefs',
        //     disabled: selectedProvider?.is_beneficiary,
        //     label: t('Льготы'),
        //     children: <ProvServPrefsForm/>
        // },
    ]

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox offsetTop={24} style={{zIndex: 11}}>
            <DefaultTabBar
                {...props}
                style={{
                    background: '#f5f5f5',
                    borderBottom: '1px solid #ffffff',
                    padding: '0 32px',
                    margin: '0 -32px 8px',
                }}
            />
        </StickyBox>
    )

    return <>
        {status === false && <ProviderEditGate id={+id}/>}
        {loading
            ? <Skeleton />
            : <Tabs defaultActiveKey="main" activeKey={activeTab} items={tabs} type={'card'}
                    onChange={(tabKey) => {
                        if (embeddedOpen) {
                            setEmbeddedBA(false)
                        }
                        setActiveTab(tabKey)
                    }}
                    renderTabBar={renderTabBar}
        />}
        <Divider />
        <Row>
            <Space>
                <Button danger onClick={() => {
                    providerForm.resetFields()
                    navigate('/dictionaries/providers')
                }}
                >
                    {t('Отмена')}
                </Button>
                <Tooltip title={!defaultBa ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={editLoading}
                            onClick={() => {
                                setAction('edit')
                                providerForm.submit()
                            }}
                            type={'primary'}
                            disabled={!defaultBa}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                </Tooltip>
                <Tooltip title={!defaultBa ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={editLoading}
                            onClick={() => {
                                setAction('list')
                                providerForm.submit()
                            }}
                            disabled={!defaultBa}
                    >
                        {t('Сохранить и перейти к списку')}
                    </Button>
                </Tooltip>
            </Space>
        </Row>
    </>
}
