import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import DatePicker from 'antd/es/date-picker';
import Switch from 'antd/es/switch';
import Card from 'antd/es/card';
import {useTranslation} from 'react-i18next';
import * as styles from './ActivitySubForm.module.css'

export default function ActivitySubForm2(props) {
    const {
        form, splitRows, horizontal, prefix = [],
        type = '3h', disabled = false
    } = props
    const {t} = useTranslation()
    const itemLayout = horizontal ? 'horizontal' : 'vertical'
    const isNotAccrued = Form.useWatch([...prefix, 'is_not_accrued'], form)
    const isNotBalanceTransfer = Form.useWatch([...prefix, 'is_not_balance_transfer'], form)
    const isNotPrinted = Form.useWatch([...prefix, 'is_not_printed'], form)

    const switchIsNotAccrued = (is_not_accrued) => {
        if (is_not_accrued === false) {
            form.setFieldValue([...prefix, 'is_not_balance_transfer'], false)
        }
    }

    const switchIsNotBalanceTransfer = (is_not_balance_transfer) => {
        if (is_not_balance_transfer === true) {
            form.setFieldValue([...prefix, 'is_not_accrued'], true)
        }
    }

    const cPeriod = <Card title={t('Оказание услуг')} size={'small'}>
        <Row gutter={[8, 0]}>
            <Col span={24}>
                <Form.Item
                    name={[...prefix, 'date_from']} label={null} layout={itemLayout}
                >
                    <DatePicker
                        disabled={disabled}
                        style={{width: '100%'}}
                        placeholder={t('с')}
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name={[...prefix, 'date_to']} label={null} layout={itemLayout}
                >
                    <DatePicker
                        disabled={disabled}
                        style={{width: '100%'}}
                        placeholder={t('до')}
                    />
                </Form.Item>
            </Col>
        </Row>
    </Card>

    const nNonAccrued = [...prefix, 'is_not_accrued']
    const vNonAccrued = true//Form.useWatch(nNonAccrued, form)
    const cNonAccrued = <Card
        size={'small'} title={
        <Form.Item
            name={nNonAccrued} label={t('Не начисл')}
            valuePropName={'checked'}
            initialValue={false}
            colon={false}
            layout={'horizontal'}
            className={styles.horizontalSwitchHeader}
        >
            <Switch onChange={switchIsNotAccrued} disabled={disabled}/>
        </Form.Item>
    }
    >
        {vNonAccrued ? <Row gutter={[8, 0]}>
            <Col span={24}>
                <Form.Item
                    name={[...prefix, 'not_accrued_from']} label={null}
                    layout={itemLayout}
                >
                    <DatePicker.MonthPicker
                        style={{width: '100%'}} disabled={!isNotAccrued || disabled}
                        placeholder={t('с')}
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name={[...prefix, 'not_accrued_to']} label={null}
                    layout={itemLayout}
                >
                    <DatePicker.MonthPicker
                        style={{width: '100%'}} disabled={!isNotAccrued || disabled}
                        placeholder={t('до')}
                    />
                </Form.Item>
            </Col>
        </Row> : null}
    </Card>

    const nNonTransfer = [...prefix, 'is_not_balance_transfer']
    const vNonTransfer = true//Form.useWatch(nNonTransfer, form)
    const cNonTransfer = <Card
        size={'small'} title={<Form.Item
        name={nNonTransfer}
        label={t('Не перев')}
        valuePropName={'checked'}
        initialValue={false}
        layout={'horizontal'}
        colon={false}
        className={styles.horizontalSwitchHeader}
    >
        <Switch onChange={switchIsNotBalanceTransfer} disabled={disabled}/>
    </Form.Item>}
    >
        {vNonTransfer ? <Row gutter={[8, 0]}>
            <Col span={24}>
                <Form.Item
                    name={[...prefix, 'not_balance_transfer_from']} label={null}
                    layout={itemLayout}
                >
                    <DatePicker.MonthPicker
                        style={{width: '100%'}} disabled={!isNotBalanceTransfer || disabled}
                        placeholder={t('с')}
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name={[...prefix, 'not_balance_transfer_to']} label={null}
                    layout={itemLayout}
                >
                    <DatePicker.MonthPicker
                        style={{width: '100%'}} disabled={!isNotBalanceTransfer || disabled}
                        placeholder={t('до')}
                    />
                </Form.Item>
            </Col>
        </Row> : null}
    </Card>

    const nNonPrint = [...prefix, 'is_not_printed']
    const vNonPrint = true//Form.useWatch(nNonPrint, form)
    const cNonPrint = <Card
        size={'small'} title={<Form.Item
        name={nNonPrint}
        label={t('Не печат')}
        valuePropName={'checked'}
        initialValue={false}
        layout={'horizontal'}
        colon={false}
        className={styles.horizontalSwitchHeader}
    >
        <Switch disabled={disabled}/>
    </Form.Item>}
    >
        {vNonPrint ? <Row gutter={[8, 0]}>
            <Col span={24}>
                <Form.Item
                    name={[...prefix, 'not_printed_from']} label={null}
                    layout={itemLayout}
                >
                    <DatePicker.MonthPicker
                        style={{width: '100%'}} disabled={!isNotPrinted || disabled}
                        placeholder={t('с')}
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name={[...prefix, 'not_printed_to']} label={null}
                    layout={itemLayout}
                >
                    <DatePicker.MonthPicker
                        style={{width: '100%'}} disabled={!isNotPrinted || disabled}
                        placeholder={t('до')}
                    />
                </Form.Item>
            </Col>
        </Row> : null}
    </Card>

    if (type === '1x3') return <Row gutter={[8, 8]}>
        <Col span={12}>
            <Col span={24}>
                {cPeriod}
            </Col>
        </Col>
        <Col span={12}>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    {cNonAccrued}
                </Col>
                <Col span={24}>
                    {cNonTransfer}
                </Col>
                <Col span={24}>
                    {cNonPrint}
                </Col>
            </Row>
        </Col>
    </Row>

    if (type === '2x2') return <Row gutter={[8, 8]} className={styles.type2x2}>
        <Col span={12}>
            {cPeriod}
        </Col>
        <Col span={12}>
            {cNonAccrued}
        </Col>
        <Col span={12}>
            {cNonTransfer}
        </Col>
        <Col span={12}>
            {cNonPrint}
        </Col>
    </Row>

        if (type === '1x4') return <Row gutter={[8, 8]} className={styles.type2x2}>
        <Col span={24}>
            {cPeriod}
        </Col>
        <Col span={24}>
            {cNonAccrued}
        </Col>
        <Col span={24}>
            {cNonTransfer}
        </Col>
        <Col span={24}>
            {cNonPrint}
        </Col>
    </Row>

    return <Row
        gutter={[8, 8]}
        className={styles.simpleRow}
        wrap={true}
    >
        <Col span={splitRows ? 12 : 6}>
            {cPeriod}
        </Col>
        <Col span={splitRows ? 12 : 6}>
            {cNonAccrued}
        </Col>
        <Col span={splitRows ? 12 : 6}>
            {cNonTransfer}
        </Col>
        <Col span={splitRows ? 12 : 6}>
            {cNonPrint}
        </Col>
    </Row>
}
