import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    CheckCircleOutlined, CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';

import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link} from 'react-router-dom';
import {Popconfirm} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import sortByUser from '../../utils/sortByUser.js';


import {
    $servicesList,
    deleteServicesEv,
    getServicesListFx,
    ServicesListGate
} from "../../models/dictionaryServicesModel/index.js";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import MappedDictItem from '../../components/MappedDictItem/MappedDictItem.jsx';

export default function DictionaryServicesList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($servicesList)
    const loading = useUnit(getServicesListFx.pending)
    const onDelete = useUnit(deleteServicesEv)
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.services`)

    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t(`Название ${genitive_form} (рус)`),
            dataIndex: 'name_ru',
            sorter: true,
            key: 'name_ru',
            render: (short_name, record) => <Link to={`/dictionaries/services/view/${record.id}`}>{`${short_name}`}</Link>
        },
        {
            title: t(`Название ${genitive_form} (каз)`),
            dataIndex: 'name_kz',
            sorter: true,
            key: 'name_kz',
            render: (name, record) => <Link to={`/dictionaries/services/view/${record.id}`}>{`${name}`}</Link>
        },

        {
            title: t(`Короткое Название ${genitive_form} (каз)`),
            dataIndex: 'short_name_kz',
            sorter: true,
            key: 'short_name_kz',
            render: (short_name, record) => <Link to={`/dictionaries/services/view/${record.id}`}>{`${short_name}`}</Link>
        },
        {
            title: t(`Короткое Название ${genitive_form} (рус)`),
            dataIndex: 'short_name_ru',
            sorter: true,
            key: 'name_ru',
            render: (name, record) => <Link to={`/dictionaries/services/view/${record.id}`}>{`${name}`}</Link>
        },
        {
            title: t('Класс услуги'),
            dataIndex: 'service_class_id',
            sorter: true,
            key: 'service_class_id',
            render: (id) => <MappedDictItem id={id} type={'service-classes'}/>
        },
        {
            title: t('Вид платежа'),
            dataIndex: 'service_payment_type_id',
            sorter: true,
            key: 'service_payment_type_id',
            render: (id) => <MappedDictItem id={id} type={'service-payment-type'}/>
        },
        {
            title: t('Порядок ЕПД'),
            dataIndex: 'epd_order',
            sorter: true,
            key: 'epd_order',
        },

        {
            title: t('Шаблон'),
            dataIndex: 'template_id',
            sorter: true,
            key: 'template_id',
            render: (template_id) => <MappedDictItem id={template_id} type={'templates'}/>
        },
        // {
        //     title: t('Услуга по счетчику'),
        //     dataIndex: 'is_counter_service',
        //     sorter: true,
        //     key: 'is_counter_service',
        //     render: (is_counter_service) => is_counter_service === true ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> : is_counter_service === false ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> : '-'
        //
        // },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/services/edit/${id}`}><Button icon={<EditOutlined />} type={'primary'} /></Link>
                </Tooltip>
                <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/services/view/${id}`}><Button icon={<EyeOutlined />} /></Link>
                </Tooltip>
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => onDelete(id)}
                            placement={'left'}
                >
                <Tooltip title={t('Удалить')} placement={'top'}>
                    <Button icon={<DeleteOutlined />} danger/>
                </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код/Название'), type: 'input'},
        // {name: 'service_unit_id', label: t('Единица измерения'), type: 'select', dict: 'service_units', multiple: true},
        {name: 'template_id', label: t('Шаблон ЕПД'), type: 'select', dict: 'template', multiple: true},
        // {name: 'algorithm_id', label: t('Алгоритм расчета'), type: 'select', dict: 'algorithms', multiple: true},
        // {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns', multiple: true},
        // {name: 'street_type_id', label: t('Тип'), type: 'select', dict: 'streetTypes', multiple: true},
        // {name: 'name', label: t('Название'), type: 'input'},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]



    return <>
        <ServicesListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
        />
    </>
}
