import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $embeddedBaForm,
    $providerBankAccounts,
    $providerDefaultBankAccount,
    $selectedBankAccount,
    addProvBankAccountEv,
    applySrvBankAccountEv,
    deleteProvBankAccountEv,
    selectProvBankAccountEv, setBaEmbeddedFormEv,
    setDefaultBankAccountEv
} from '../../../models/dictionaryProvidersModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {filterOption} from '../../../utils/selectUtils.jsx';
import Card from 'antd/es/card';
import Table from 'antd/es/table';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import {
    ApiOutlined,
    CheckOutlined, DeleteOutlined, EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined
} from '@ant-design/icons';
import Switch from 'antd/es/switch';
import {useEffect, useState} from 'react';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';

function isDefault (id, defaultBa) {
    return defaultBa?.bank_account_id === id
}

export default function ProviderBankAccounts(props) {
    const {isView, beneficiaryId, embedded, externalForm, nonHeight} = props
    const {t} = useTranslation()

    const [baForm] = Form.useForm()
    const embeddedOpen = useUnit($embeddedBaForm)
    const setEmbedded = useUnit(setBaEmbeddedFormEv)

    const data = useUnit($providerBankAccounts)
    const defaultAcc = useUnit($providerDefaultBankAccount)

    const selectedBa = useUnit($selectedBankAccount)

    const addAcc = useUnit(addProvBankAccountEv)
    const setAsDefault = useUnit(setDefaultBankAccountEv)
    const selectBa = useUnit(selectProvBankAccountEv)
    const changeBa = useUnit(applySrvBankAccountEv)
    const deleteBa = useUnit(deleteProvBankAccountEv)

    const [add, setAdd] = useState(false)
    const [isDuplicate, setIsDuplicate] = useState(false)

    useEffect(() => {
        if (selectedBa) {
            setAdd(true)
            baForm.setFieldsValue(selectedBa)
            if (selectedBa?.id === defaultAcc?.bank_account_id) {
                baForm.setFieldValue('is_new_default', true)
            }
        }
    }, [selectedBa]);

    useEffect(() => {
        if (embedded && embeddedOpen) {
            setAdd(true)
        } else if (embedded && !embeddedOpen) {
            baForm.resetFields()
            setAdd(false)
        }
    }, [embeddedOpen, embedded])

    const onSubmit = (values) => {
        if (selectedBa) {
            if (selectedBa.id === defaultAcc?.bank_account_id && values?.is_new_default === true) {
                delete values?.is_new_default
            }
            changeBa({id: selectedBa.id, ...values})
        } else {
            addAcc(values)
        }
        baForm.resetFields()
        setAdd(false)
    }

    const onCancelAdd = () => {
        baForm.resetFields()
        selectBa(null)
        setAdd(false)
        if (embedded && embeddedOpen) {
            setEmbedded(false)
            externalForm?.setFieldsValue({bank_account_id: undefined})
        }
    }

    const onAdd = () => {
        setAdd(true)
        if (data.length === 0 && !defaultAcc) {
            baForm.setFieldValue('is_new_default', true)
        }
    }

    const validateDuplicates = (changed, allVals) => {
        if (Object.hasOwn(allVals, 'bank_id') && Object.hasOwn(allVals, 'account_number') && Object.hasOwn(allVals, 'bank_account_type_id')) {
            let duplicate = false
            for (const ba of data) {
                if (ba.account_number === allVals.account_number && ba.bank_id === allVals.bank_id && ba.bank_account_type_id === allVals.bank_account_type_id) {
                    duplicate = true
                    break
                }
            }
            if (duplicate) {
                setIsDuplicate(true)
            } else if (isDuplicate && !duplicate) {
                setIsDuplicate(false)
            }
        }
    }


    const {bankOpts, bankAccTypeOpts} = useStoreMap($dictsSelects, sel => ({
        bankOpts: sel.bank,
        bankAccTypeOpts: sel.bank_account_types
    }))

    const columns = [
        {
            title: t('Банк'),
            dataIndex: 'bank_id',
            key: 'bank_id',
            render: (bank_id) => <MappedDictItem id={bank_id} type={'bank'}/>
        },
        {
            title: t('Тип р/с'),
            dataIndex: 'bank_account_type_id',
            key: 'bank_account_type_id',
            render: (bank_account_type_id) => <MappedDictItem id={bank_account_type_id} type={'bank-account-type'}/>
        },
        {
            title: t('Номер счета'),
            dataIndex: 'account_number',
            key: 'account_number'
        },
        {
            title: <Tooltip title={t('По умолчанию')}>
                <ApiOutlined />
            </Tooltip>,
            dataIndex: 'id',
            key: 'is_default',
            width: '15%',
            align: 'center',
            render: id => isDefault(id, defaultAcc) ? <CheckOutlined /> : null
        },
        {
            title: <Tooltip title={beneficiaryId ? t('Выбран бенефициар') : t('Добавить')}>
                <Button icon={<PlusOutlined />} type={'primary'} disabled={beneficiaryId} onClick={onAdd} />
            </Tooltip>,
            hidden: isView,
            width: '5%',
            key: 'actions',
            dataIndex: 'id',
            align: 'center',
            render: (id) => <ButtonGroup>
                {isDefault(id, defaultAcc) ? null : <Tooltip title={t('Сделать счетом по умолчанию')}>
                    <Button icon={<ExclamationCircleOutlined/>} onClick={() => setAsDefault(id)}/>
                </Tooltip>}
                <Tooltip title={t('Редактировать')}>
                    <Button onClick={() => selectBa(id)} icon={<EditOutlined />} disabled={beneficiaryId}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись')}
                            okText={t('Да')}
                            onConfirm={() => deleteBa(id)}
                            placement={'left'}
                            disabled={isDefault(id, defaultAcc) || beneficiaryId}
                >
                    <Tooltip title={t('Удалить')}>
                        <Button danger icon={<DeleteOutlined />} disabled={isDefault(id, defaultAcc) || beneficiaryId}/>
                    </Tooltip>
                </Popconfirm>
                </ButtonGroup>
        }
    ]

    const style = nonHeight ? {} : {minHeight: '100%'}
    return <Card title={t('Расчетные счета')} size={'small'} style={style}>
        {add && <Form layout={'vertical'} form={baForm} onFinish={onSubmit} onValuesChange={(changed, allVals) => validateDuplicates(changed, allVals)} size={'small'}>
            <Row gutter={[16, 16]} style={{alignItems: 'flex-end'}}>
                <Col span={8}>
                    <Form.Item name={'bank_id'} label={t('Банк')}
                               rules={[{required: true, message: t('Обязательное поле')}]}>
                        <Select options={bankOpts} filterOption={filterOption} showSearch
                                allowClear/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'bank_account_type_id'} label={t('Тип расчетного счета')}
                               rules={[{required: true, message: t('Обязательное поле')}]}>
                        <Select options={bankAccTypeOpts} filterOption={filterOption} showSearch
                                allowClear/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'account_number'} label={t('Номер счета')}
                               validateTrigger={'onBlur'}
                               normalize={value => value.toUpperCase()}
                               rules={[
                                   {required: true, message: t('Обязательное поле')},
                                   {pattern: /^[A-Z0-9]{20}$/, message: t('20 символов, только латинские буквы и цифры')},
                                   // {pattern: /^KZ\d{2}\s?\d{3}\s?\d{13}$/, message: t('Номер счета должен состоять из 20 символов')}
                               ]}>
                        <Input showCount maxLength={20}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{alignItems: 'flex-end'}}>
                {!embedded ? <Col span={16}>
                    <Form.Item name={'is_new_default'} label={t('Счет по умолчанию')}
                               valuePropName={'checked'}
                    >
                        <Switch />
                    </Form.Item>
                </Col> : null}
                <Col span={2}>
                    <Form.Item>
                        <ButtonGroup>
                            <Tooltip title={isDuplicate ? t('Такой счет уже существует') : null}>
                            <Button type={'primary'}
                                    onClick={() => baForm.submit()}
                                    disabled={isDuplicate}
                            >
                                {selectedBa ? t('Сохранить') : t('Добавить')}
                            </Button>
                            </Tooltip>
                            <Button danger onClick={onCancelAdd}>{t('Отмена')}</Button>
                        </ButtonGroup>
                    </Form.Item>
                </Col>
            </Row>
        </Form>}
        {!embedded && <Table
            dataSource={data} columns={columns} bordered pagination={false} size={'small'}
            rowKey={(r) => `${r.account_number}:${r.bank_account_type_id}:${r.bank_id}:${r.id}:${r.provider_id}`}
        />}
    </Card>
}
