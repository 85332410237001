import dayjs from 'dayjs';
import {isId} from "../../utils/helpers.js";
import {typeOf} from "mathjs";

const allowedKeys = {
    account_provider_service: ['provider_service_id',  'service_square',
        'people_quantity', 'account_id', 'iin', 'external_number', 'date_from', 'date_to', 'is_not_accrued',
        'not_accrued_from', 'not_accrued_to', 'is_not_balance_transfer',
        'not_balance_transfer_from', 'not_balance_transfer_to', 'is_not_printed',
        'not_printed_from', 'not_printed_to'],
    account_service_tarif: ['account_provider_service_id', 'tarif_id', 'date_from'],
    real_estate_object_provider_service: ['provider_service_id', 'real_estate_object_id', 'bank_account_id'],
    bank_account: ['bank_id', 'bank_account_type_id', 'account_number', 'provider_id']
}
export function cleanUpPayload(type, payload) {
    const filtered = {...payload}
    for (const key of Object.keys(payload)) {
        if (!allowedKeys[type].includes(key)) {
            delete filtered[key]
        }
    }
    return filtered
}

export function formatPayload(payload) {
    for (const [key, value] of Object.entries(payload)) {
        if (value === null || value === undefined || value === '') {
            delete payload[key]
            continue
        }
        if (key.startsWith('not_')) {
            if (key.endsWith('_from')) {
                const baseKey = key.replace('_from', '')
                if (!payload[`is_${baseKey}`]) {
                    delete payload[key]
                    continue
                } else {
                    payload[key] = dayjs(value).date(dayjs(value).startOf('month').date()).format()
                }
            } else if (key.endsWith('_to')) {
                const baseKey = key.replace('_to', '')
                if (!payload[`is_${baseKey}`]) {
                    delete payload[key]
                    continue
                } else {
                    payload[key] = dayjs(value).date(dayjs(value).endOf('month').date()).format()
                }
            }
        } else if (key === 'date_from' || key === 'date_to') {
            payload[key] = dayjs(value).format()
        } else if (key === 'date_begin' || key === 'date_end') {
            payload[key] = dayjs(value).format()
        }
    }
    return payload
}

export function prepareSrv(payload) {
    for (const [key, value] of Object.entries(payload)) {
        if (value === null || value === undefined) {
            delete payload[key]
            continue
        }
        if (key.startsWith('not_')) {
            if (key.endsWith('_from')) {
                const baseKey = key.replace('_from', '')
                if (!payload[`is_${baseKey}`]) {
                    delete payload[key]
                    continue
                } else {
                    payload[key] = dayjs(value).date(dayjs(value).startOf('month').date()).format()
                }
            } else if (key.endsWith('_to')) {
                const baseKey = key.replace('_to', '')
                if (!payload[`is_${baseKey}`]) {
                    delete payload[key]
                    continue
                } else {
                    payload[key] = dayjs(value).date(dayjs(value).endOf('month').date()).format()
                }
            }
        }
        if (key.includes('date') && !key.includes('penalty_calculation') && value) {
            payload[key] = dayjs(value).format()
        }
    }
    return payload
}

export function addNewSrvREO(existing, reos, allReos, type, payload) {
    const newState = [...existing]
    if (type === 'all') {
        for (const reo of allReos) {
            if (payload.rewrite_old) {
                const idx = newState.findIndex(i => i.real_estate_object_id === reo.id && i.provider_service_id === payload.provider_service_id)
                if (idx >= 0) {
                    if (newState[idx].id > 0) {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            real_estate_object_id: reo.id,
                            provider_service_id: payload.provider_service_id,
                            bank_account_id: payload.bank_account_id,
                            updated: true
                        })
                    } else {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            real_estate_object_id: reo.id,
                            provider_service_id: payload.provider_service_id,
                            bank_account_id: payload.bank_account_id,
                        })
                    }
                } else {
                    const reoMeta = allReos.find(i => i.id === reo.id)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            real_estate_object_id: reo.id,
                            bank_account_id: payload.bank_account_id,
                            provider_service_id: payload.provider_service_id,
                            town_id: reoMeta?.town_id ?? null,
                            town_distr_id: reoMeta?.town_distr_id ?? null,
                            street_id: reoMeta?.street_id ?? null,
                            house: reoMeta?.house ?? null,
                            corpus: reoMeta?.corpus ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            real_estate_object_id: reo.id,
                            bank_account_id: payload.bank_account_id,
                            provider_service_id: payload.provider_service_id,
                            town_id: reoMeta?.town_id ?? null,
                            town_distr_id: reoMeta?.town_distr_id ?? null,
                            street_id: reoMeta?.street_id ?? null,
                            house: reoMeta?.house ?? null,
                            corpus: reoMeta?.corpus ?? null,
                            id: -1
                        })
                    }
                }
            } else {
                const idx = newState.findIndex(i => i.real_estate_object_id === reo.id && i.provider_service_id === payload.provider_service_id)
                if (idx >= 0) {
                    continue
                } else {
                    const reoMeta = allReos.find(i => i.id === reo.id)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            real_estate_object_id: reo.id,
                            bank_account_id: payload.bank_account_id,
                            provider_service_id: payload.provider_service_id,
                            town_id: reoMeta?.town_id ?? null,
                            town_distr_id: reoMeta?.town_distr_id ?? null,
                            street_id: reoMeta?.street_id ?? null,
                            house: reoMeta?.house ?? null,
                            corpus: reoMeta?.corpus ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            real_estate_object_id: reo.id,
                            bank_account_id: payload.bank_account_id,
                            provider_service_id: payload.provider_service_id,
                            town_id: reoMeta?.town_id ?? null,
                            town_distr_id: reoMeta?.town_distr_id ?? null,
                            street_id: reoMeta?.street_id ?? null,
                            house: reoMeta?.house ?? null,
                            corpus: reoMeta?.corpus ?? null,
                            id: -1
                        })
                    }
                }
            }
        }
    } else if (type === 'selected') {
        for (const reoId of reos) {
            if (payload.rewrite_old) {
                const idx = newState.findIndex(i => i.real_estate_object_id === reoId && i.provider_service_id === payload.provider_service_id)
                if (idx >= 0) {
                    if (newState[idx].id > 0) {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            real_estate_object_id: reoId,
                            provider_service_id: payload.provider_service_id,
                            bank_account_id: payload.bank_account_id,
                            updated: true
                        })
                    } else {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            real_estate_object_id: reoId,
                            provider_service_id: payload.provider_service_id,
                            bank_account_id: payload.bank_account_id
                        })
                    }
                } else {
                    const reoMeta = allReos.find(i => i.id === reoId)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            real_estate_object_id: reoId,
                            bank_account_id: payload.bank_account_id,
                            provider_service_id: payload.provider_service_id,
                            town_id: reoMeta?.town_id ?? null,
                            town_distr_id: reoMeta?.town_distr_id ?? null,
                            street_id: reoMeta?.street_id ?? null,
                            house: reoMeta?.house ?? null,
                            corpus: reoMeta?.corpus ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            real_estate_object_id: reoId,
                            bank_account_id: payload.bank_account_id,
                            provider_service_id: payload.provider_service_id,
                            town_id: reoMeta?.town_id ?? null,
                            town_distr_id: reoMeta?.town_distr_id ?? null,
                            street_id: reoMeta?.street_id ?? null,
                            house: reoMeta?.house ?? null,
                            corpus: reoMeta?.corpus ?? null,
                            id: -1
                        })
                    }
                }
            } else {
                const idx = newState.findIndex(i => i.account_id === reoId && i.provider_service_id === payload.provider_service_id)
                if (idx >= 0) {
                    continue
                } else {
                    const reoMeta = allReos.find(i => i.id === reoId)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            real_estate_object_id: reoId,
                            bank_account_id: payload.bank_account_id,
                            provider_service_id: payload.provider_service_id,
                            town_id: reoMeta?.town_id ?? null,
                            town_distr_id: reoMeta?.town_distr_id ?? null,
                            street_id: reoMeta?.street_id ?? null,
                            house: reoMeta?.house ?? null,
                            corpus: reoMeta?.corpus ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            real_estate_object_id: reoId,
                            bank_account_id: payload.bank_account_id,
                            provider_service_id: payload.provider_service_id,
                            town_id: reoMeta?.town_id ?? null,
                            town_distr_id: reoMeta?.town_distr_id ?? null,
                            street_id: reoMeta?.street_id ?? null,
                            house: reoMeta?.house ?? null,
                            corpus: reoMeta?.corpus ?? null,
                            id: -1
                        })
                    }
                }
            }
        }
    }

    return newState
}

export function addNewSrvAcc(existing, accs, allAccs, type, payload) {
    const newState = [...existing]
    const prep = formatPayload(payload)
    if (type === 'all') {
        for (const acc of allAccs) {
            if (payload.rewrite_old) {
                const idx = newState.findIndex(i => i.account_id === acc.id && i.provider_service_id === payload.provider_service_id)
                if (idx >= 0) {
                    if (newState[idx].id > 0) {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            account_id: acc.id,
                            ...prep,
                            updated: true
                        })
                    } else {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            account_id: acc.id,
                            ...prep,
                        })
                    }
                } else {
                    const accMeta = allAccs.find(i => i.id === acc.id)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            account_id: acc.id,
                            ...prep,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            name_ru: accMeta.name_ru ?? null,
                            name_kz: accMeta.name_kz ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            account_id: acc.id,
                            ...prep,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            name_ru: accMeta.name_ru ?? null,
                            name_kz: accMeta.name_kz ?? null,
                            id: -1
                        })
                    }
                }
            } else {
                const idx = newState.findIndex(i => i.account_id === acc.id && i.provider_service_id === payload.provider_service_id)
                if (idx >= 0) {
                    continue
                } else {
                    const accMeta = allAccs.find(i => i.id === acc.id)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            account_id: acc.id,
                            ...prep,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            name_ru: accMeta.name_ru ?? null,
                            name_kz: accMeta.name_kz ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            account_id: acc.id,
                            ...prep,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            name_ru: accMeta.name_ru ?? null,
                            name_kz: accMeta.name_kz ?? null,
                            id: -1
                        })
                    }
                }
            }
        }
    } else if (type === 'selected') {
        for (const accId of accs) {
            if (payload.rewrite_old) {
                const idx = newState.findIndex(i => i.account_id === accId && i.provider_service_id === payload.provider_service_id)
                if (idx >= 0) {
                    if (newState[idx].id > 0) {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            account_id: accId,
                            ...prep,
                            updated: true
                        })
                    } else {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            account_id: accId,
                            ...prep,
                        })
                    }
                } else {
                    const accMeta = allAccs.find(i => i.id === accId)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            account_id: accId,
                            ...prep,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            name_ru: accMeta.name_ru ?? null,
                            name_kz: accMeta.name_kz ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            account_id: accId,
                            ...prep,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            name_ru: accMeta.name_ru ?? null,
                            name_kz: accMeta.name_kz ?? null,
                            id: -1
                        })
                    }
                }
            } else {
                const idx = newState.findIndex(i => i.account_id === accId && i.provider_service_id === payload.provider_service_id)
                if (idx >= 0) {
                    continue
                } else {
                    const accMeta = allAccs.find(i => i.id === accId)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            account_id: accId,
                            ...prep,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            name_ru: accMeta.name_ru ?? null,
                            name_kz: accMeta.name_kz ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            account_id: accId,
                            ...prep,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            name_ru: accMeta.name_ru ?? null,
                            name_kz: accMeta.name_kz ?? null,
                            id: -1
                        })
                    }
                }
            }
        }
    }

    return newState
}

export default function filterSrvAccs(accs, filters) {
    if (
        !filters.provider_service_id ||
        !(
            (isId(filters.town_id) && isId(filters.street_id)) || (filters?.account_in && filters?.account_in.length > 0) || (filters?.iin_name && filters?.iin_name.length > 0)
        )
    ) return []

    const _filter_id = (v, f) => isId(f) ? v === f : true
    const _filter_provider_service_id = (o, v) => _filter_id(o.provider_service_id, v)
    const _filter_town_id = (o, v) => _filter_id(o.town_id, v)
    const _filter_town_distr_id = (o, v) => _filter_id(o.town_distr_id, v)
    const _filter_street_id = (o, v) => _filter_id(o.street_id, v)
    const _filter_corpus = (o, v) => v !== undefined && v !== null ? o.corpus === v : true
    const _filter_iin_name = (o, v) => v !== undefined && v !== null ? !!(
        `${(o.name_ru || '').toLowerCase()} ${(o.name_kz || '').toLowerCase().includes(v)} ${(o.iin || '').toLowerCase()}`.includes(v)
    ) : true

    const _split_in = (f) => (f || '').replace(/ /g, '').split(',').map(
        v => v.split('-')
    )
    const _filter_in = (v, f) => {
        if (f === undefined || f === null || f === '') return true
        const splittedIn = _split_in(f)
        const vn = +(((v || '') + '').replace(/[^0-9]+.*$/, ''))
        return splittedIn.some(sin => {
            const [b, e] = sin
            if (e === undefined) {
                // noinspection EqualityComparisonWithCoercionJS
                return b == v
            }
            if (b === undefined) return false
            return ((+b) <= vn && vn <= (+e))
        })
    }
    const _filter_house_in = (o, v) => _filter_in(o.house, v)
    const _filter_flat_in = (o, v) => _filter_in(o.flat, v)
    const _filter_account_in = (o, v) => _filter_in(o.account_id, v)

    console.log('filterSrvAccs', filters, accs)
    let filtered = accs.filter(acc => {
        return (
            _filter_provider_service_id(acc, filters?.provider_service_id) &&
            _filter_town_id(acc, filters?.town_id) &&
            _filter_town_distr_id(acc, filters?.town_distr_id) &&
            _filter_street_id(acc, filters?.street_id) &&
            _filter_corpus(acc, filters?.corpus) &&
            _filter_iin_name(acc, filters?.iin_name) &&
            _filter_house_in(acc, filters?.house_in) &&
            _filter_flat_in(acc, filters?.flat_in) &&
            _filter_account_in(acc, filters?.account_in)
        )
    })
    console.log('filterSrvAccs filtered', filters, accs, filtered)
    return filtered
}

export function addNewSrvAccTarifs(existing, srvAccs, allSrvAccs, type, payload) {
    const newState = [...existing]
    const prep = formatPayload(payload)
    if (type === 'all') {
        for (const srvAcc of allSrvAccs) {
            if (payload.rewrite_old) {
                const idx = newState.findIndex(i => i.account_provider_service_id === srvAcc.id && i.tarif_id === payload.tarif_id)
                if (idx >= 0) {
                    if (newState[idx].id > 0) {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            account_id: srvAcc.account_id,
                            account_provider_service_id: srvAcc.id,
                            ...prep,
                            updated: true
                        })
                    } else {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            account_id: srvAcc.account_id,
                            account_provider_service_id: srvAcc.id,
                            ...prep,
                        })
                    }
                } else {
                    const accMeta = allSrvAccs.find(i => i.id === srvAcc.id)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            ...prep,
                            account_provider_service_id: srvAcc.id,
                            account_id: accMeta.account_id,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            account_name_ru: accMeta.name_ru ?? null,
                            account_name_kz: accMeta.name_kz ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            ...prep,
                            account_provider_service_id: srvAcc.id,
                            account_id: accMeta.account_id,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            account_name_ru: accMeta.name_ru ?? null,
                            account_name_kz: accMeta.name_kz ?? null,
                            id: -1
                        })
                    }
                }
            } else {
                const idx = newState.findIndex(i => i.account_provider_service_id === srvAcc.id && i.tarif_id === payload.tarif_id)
                if (idx >= 0) {
                    continue
                } else {
                    const accMeta = allSrvAccs.find(i => i.id === srvAcc.id)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            ...prep,
                            account_provider_service_id: srvAcc.id,
                            account_id: accMeta.account_id,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            account_name_ru: accMeta.name_ru ?? null,
                            account_name_kz: accMeta.name_kz ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            ...prep,
                            account_provider_service_id: srvAcc.id,
                            account_id: accMeta.account_id,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            account_name_ru: accMeta.name_ru ?? null,
                            account_name_kz: accMeta.name_kz ?? null,
                            id: -1
                        })
                    }
                }
            }
        }
    } else if (type === 'selected') {
        for (const accId of srvAccs) {
            if (payload.rewrite_old) {
                const idx = newState.findIndex(i => i.account_provider_service_id === accId && i.tarif_id === payload.tarif_id)
                if (idx >= 0) {
                    if (newState[idx].id > 0) {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            account_provider_service_id: accId,
                            ...prep,
                            updated: true
                        })
                    } else {
                        newState.splice(idx, 1, {
                            ...newState[idx],
                            account_provider_service_id: accId,
                            ...prep,
                        })
                    }
                } else {
                    const accMeta = allSrvAccs.find(i => i.id === accId)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            ...prep,
                            account_id: accMeta.account_id,
                            account_provider_service_id: accId,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            account_name_ru: accMeta.name_ru ?? null,
                            account_name_kz: accMeta.name_kz ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            ...prep,
                            account_id: accMeta.account_id,
                            account_provider_service_id: accId,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            account_name_ru: accMeta.name_ru ?? null,
                            account_name_kz: accMeta.name_kz ?? null,
                            id: -1
                        })
                    }
                }
            } else {
                const idx = newState.findIndex(i => i.account_provider_service_id === accId && i.tarif_id === payload.tarif_id)
                if (idx >= 0) {
                    continue
                } else {
                    const accMeta = allSrvAccs.find(i => i.id === accId)
                    if (newState.some(i => i.id < 0)) {
                        const lastNew = newState.find(i => i.id < 0)
                        newState.unshift({
                            ...prep,
                            account_id: accMeta.account_id,
                            account_provider_service_id: accId,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            account_name_ru: accMeta.name_ru ?? null,
                            account_name_kz: accMeta.name_kz ?? null,
                            id: lastNew.id - 1
                        })
                    } else {
                        newState.unshift({
                            ...prep,
                            account_id: accMeta.account_id,
                            account_provider_service_id: accId,
                            town_id: accMeta?.town_id ?? null,
                            town_distr_id: accMeta?.town_distr_id ?? null,
                            street_id: accMeta?.street_id ?? null,
                            house: accMeta?.house ?? null,
                            corpus: accMeta?.corpus ?? null,
                            flat: accMeta.flat ?? null,
                            account_name_ru: accMeta.name_ru ?? null,
                            account_name_kz: accMeta.name_kz ?? null,
                            id: -1
                        })
                    }
                }
            }
        }
    }

    return newState
}
