import { $WsSt,} from './stores.js';
import {sendMsgEv, WsOpen} from './events.js';
import {sample} from 'effector';
import {sendMsgFx} from './effects.js';


$WsSt
    .on(WsOpen, (state, payload) => {
        return true
    })

sample({
    clock: sendMsgEv,
    target: sendMsgFx
})






