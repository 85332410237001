import {
    $afterCreateAction,
    $createdServiceUnitsId,
    $editServiceUnitsStatus,
    $selectedServiceUnits,
    $serviceUnitsList,
} from './stores.js';
import {
    deleteServiceUnitsEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ServiceUnitsEditGate,
    ServiceUnitsListGate,
    ServiceUnitsViewGate,
    submitCreateServiceUnitsEv,
    submitEditServiceUnitsEv, ServiceUnitsCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {getServiceUnitsListFx, getServiceUnitsByIdFx} from "./effects.js";

const createServiceUnitsCompleteEv = combineEvents({events: {ev: submitCreateServiceUnitsEv, response: triggerSuccessOpsEv}, reset: ServiceUnitsCreateGate.state})
const editServiceUnitsCompleteEv = combineEvents({ev: submitEditServiceUnitsEv, response: triggerSuccessOpsEv})
const deleteServiceUnitsCompleteEv = combineEvents({ev: deleteServiceUnitsEv, response: triggerSuccessOpsEv})

$serviceUnitsList.on(getServiceUnitsListFx.doneData, (_, serviceUnits) => serviceUnits)
    .reset(ServiceUnitsListGate.close)

$selectedServiceUnits.on(getServiceUnitsByIdFx.doneData, (_, serviceUnits) => serviceUnits)
    .reset([ServiceUnitsEditGate.close, ServiceUnitsViewGate.close])


$createdServiceUnitsId.on(createServiceUnitsCompleteEv, (_, {response}) => {
    const serviceUnit = response.find( i => i.type === 'service_units')
    return serviceUnit ? serviceUnit?.value.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editServiceUnitsStatus.on(editServiceUnitsCompleteEv, () => true)
    .reset(ServiceUnitsListGate.close)

sample({
    clock: [ServiceUnitsEditGate.state, ServiceUnitsViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getServiceUnitsByIdFx
})

sample({
    clock: ServiceUnitsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServiceUnitsListFx
})

sample({
    source: ServiceUnitsListGate.state,
    clock: [createServiceUnitsCompleteEv, editServiceUnitsCompleteEv, deleteServiceUnitsCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServiceUnitsListFx
})

sample({
    clock: submitCreateServiceUnitsEv,
    fn: (payload) => {
        const actions = []
        actions.push({operation: 'create', type: 'service_units', value: {...payload, id: -1}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedServiceUnits,
    clock: submitEditServiceUnitsEv,
    fn: (current, payload) => {
        const actions = []
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'service_units', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteServiceUnitsEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'service_units', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
