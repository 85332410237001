import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $createdCorrectionId,
    $editMode, $finalAction,
    $redirectPath, $selectedCorrection,
    cancelCorrectionEv,
    finishCorrectionEv,
    ProvSrvAccCorrectionGate, resetCreatedCorrectionEv, resetFinalActionEv,
    setFinalActionEv
} from '../../../models/dictionaryCorrectionModel/index.js';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import CorrectionTypeSettings from './CorrectionTypeSettings.jsx';
import CorrectionFormMass from './MassComponents/CorrectionFormMass.jsx';
import CorrectionFormSingle from './SingleAccComponents/CorrectionFormSingle.jsx';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import {useEffect} from 'react';


export default function CorrectionProvAccServ() {
    const {t} = useTranslation()
    const {id} = useParams()
    const navigate = useNavigate()
    const editMode = useUnit($editMode)
    const url = useUnit($redirectPath)
    const setAfterAction = useUnit(setFinalActionEv)
    const resetAfterAction = useUnit(resetFinalActionEv)
    const cancelCorrection = useUnit(cancelCorrectionEv)
    const finishCorrection = useUnit(finishCorrectionEv)

    const afterAction = useUnit($finalAction)
    const currentCorrection = useUnit($selectedCorrection)
    const newCorrectionId = useUnit($createdCorrectionId)
    const resetCreated = useUnit(resetCreatedCorrectionEv)

    useEffect(() => {
        if (url && afterAction === 'close') {
            navigate(url)
        }
        return () => resetAfterAction()
    }, [url, afterAction]);

    useEffect(() => {
        if (afterAction === 'resume' && newCorrectionId && currentCorrection?.data?.id !== newCorrectionId) {
            navigate(`/corrections/edit/${newCorrectionId}`)
        }
        return () => {
            if (newCorrectionId) {
                resetCreated()
            }
            resetAfterAction()
        }
    }, [afterAction, currentCorrection, newCorrectionId])

    return <>
        <ProvSrvAccCorrectionGate id={id}/>
        <Card title={t('Корректировка Поставщик-Услуга-Лицевой счет')} size={'small'}>
            <CorrectionTypeSettings/>
            <Divider/>
            {editMode === 'mass' ? <CorrectionFormMass/> : <CorrectionFormSingle/>}
        </Card>
        <Divider/>
        <Row style={{gap: 16}}>
            <Button danger size={'small'}
                    onClick={() => {
                        setAfterAction('close')
                        cancelCorrection()
                    }}
            >
                {t('Отменить и закрыть')}
            </Button>
            <Button type={'primary'}
                    size={'small'}
                    onClick={() => {
                        setAfterAction('resume')
                        finishCorrection()
                    }}
            >
                {t('Сохранить и продолжить корректировку')}
            </Button>
            <Button
                onClick={() => {
                    setAfterAction('close')
                    finishCorrection()
                }}
            >
                {t('Сохранить и закрыть')}
            </Button>
        </Row>
    </>
}